import { graphql } from 'react-relay';

export const gqlPoshmarkBotRelistSchedule = graphql`
  query poshmarkBotRelistScheduleQuery {
    poshmarkBotRelistSchedule {
      at
      listingId
      listing {
        id
        url
        thumbnail
        title
        currentPrice
        deleted
        sold
      }
      eligible
      ineligibleReason
    }
  }
`;
