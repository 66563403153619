import { graphql } from 'react-relay';

export const OrderFragmentFull = graphql`
  fragment orderFragment_full on InvsysOrder @inline {
    id
    institution
    institutionIssuedId
    updatedAt
    createdAt
    marketplaceLink
    earliestOrderStatus
    orderInventoryUnits {
      id
      status
      updatedAt
      createdAt
      mercariListingPreview {
        title
        thumbnail
      }
      poshmarkListingPreview {
        title
        thumbnail
      }
      depopListingPreview {
        title
        thumbnail
      }
      invsysListingAssociation {
        listingId
        itemId
        listing {
          listingSku
        }
      }
    }
    balanceUpdates {
      updateType
      currencyCode
      value
      at
    }
  }
`;
