/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type invsysBusinessManagerQueryVariables = {};
export type invsysBusinessManagerQueryResponse = {
  readonly invsysBusinessManager: {
    readonly createdAt: number;
    readonly e164: string | null;
  } | null;
};
export type invsysBusinessManagerQuery = {
  readonly response: invsysBusinessManagerQueryResponse;
  readonly variables: invsysBusinessManagerQueryVariables;
};

/*
query invsysBusinessManagerQuery {
  invsysBusinessManager {
    createdAt
    e164
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = [
    {
      alias: null,
      args: null,
      concreteType: 'InvsysBusinessManager',
      kind: 'LinkedField',
      name: 'invsysBusinessManager',
      plural: false,
      selections: [
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'createdAt',
          storageKey: null,
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'e164',
          storageKey: null,
        },
      ],
      storageKey: null,
    },
  ];
  return {
    fragment: {
      argumentDefinitions: [],
      kind: 'Fragment',
      metadata: null,
      name: 'invsysBusinessManagerQuery',
      selections: v0 /*: any*/,
      type: 'Query',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [],
      kind: 'Operation',
      name: 'invsysBusinessManagerQuery',
      selections: v0 /*: any*/,
    },
    params: {
      cacheID: 'a83f5977305746076dca21ed17fa0165',
      id: null,
      metadata: {},
      name: 'invsysBusinessManagerQuery',
      operationKind: 'query',
      text: 'query invsysBusinessManagerQuery {\n  invsysBusinessManager {\n    createdAt\n    e164\n  }\n}\n',
    },
  };
})();
(node as any).hash = '858ff3dad7bb2d94acc6c4ebf8e5ebaa';
export default node;
