import { graphql } from 'react-relay';

export const AddressFragmentFull = graphql`
  fragment addressFragment_full on InvsysAddress @inline {
    id
    streetAddress
    unit
    locality
    subdivision
    postalCode
    countryCode
    postalCodeFormat
    isDefaultShipping
    isDefaultPickup
  }
`;
