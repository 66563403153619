/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type listingsSummaryQueryVariables = {};
export type listingsSummaryQueryResponse = {
  readonly invsysListingsSummary: {
    readonly nextPostAt: number | null;
    readonly scheduledListingsCount: number;
    readonly scheduledListings: {
      readonly items: ReadonlyArray<{
        readonly listingId: string;
        readonly title: string | null;
        readonly mediaUrl: string | null;
        readonly institution: number;
        readonly postAt: number | null;
      }>;
    };
  };
};
export type listingsSummaryQuery = {
  readonly response: listingsSummaryQueryResponse;
  readonly variables: listingsSummaryQueryVariables;
};

/*
query listingsSummaryQuery {
  invsysListingsSummary {
    nextPostAt
    scheduledListingsCount
    scheduledListings {
      items {
        listingId
        title
        mediaUrl
        institution
        postAt
      }
    }
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = [
    {
      alias: null,
      args: null,
      concreteType: 'InvsysListingsSummary',
      kind: 'LinkedField',
      name: 'invsysListingsSummary',
      plural: false,
      selections: [
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'nextPostAt',
          storageKey: null,
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'scheduledListingsCount',
          storageKey: null,
        },
        {
          alias: null,
          args: null,
          concreteType: 'InvsysScheduledListingsPage',
          kind: 'LinkedField',
          name: 'scheduledListings',
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              concreteType: 'InvsysScheduledListing',
              kind: 'LinkedField',
              name: 'items',
              plural: true,
              selections: [
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'listingId',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'title',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'mediaUrl',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'institution',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'postAt',
                  storageKey: null,
                },
              ],
              storageKey: null,
            },
          ],
          storageKey: null,
        },
      ],
      storageKey: null,
    },
  ];
  return {
    fragment: {
      argumentDefinitions: [],
      kind: 'Fragment',
      metadata: null,
      name: 'listingsSummaryQuery',
      selections: v0 /*: any*/,
      type: 'Query',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [],
      kind: 'Operation',
      name: 'listingsSummaryQuery',
      selections: v0 /*: any*/,
    },
    params: {
      cacheID: '8cfe3b5b696d79d60f7faab682134642',
      id: null,
      metadata: {},
      name: 'listingsSummaryQuery',
      operationKind: 'query',
      text: 'query listingsSummaryQuery {\n  invsysListingsSummary {\n    nextPostAt\n    scheduledListingsCount\n    scheduledListings {\n      items {\n        listingId\n        title\n        mediaUrl\n        institution\n        postAt\n      }\n    }\n  }\n}\n',
    },
  };
})();
(node as any).hash = '4d127b8cf283078948762b316215555f';
export default node;
