import * as Observe from 'src/state/lib/observe';
import * as PoshmarkUserLinks from 'src/state/observe/PoshmarkUserLinks';
import { recentActivityQueryResponse } from 'src/clients/Network/gql/build/recentActivityQuery.graphql';

export type ResponseDataType = NonNullable<
  recentActivityQueryResponse['superposherPoshmarkUserLink']
>['recentActivity'];

interface EntityTransactions {
  updatedAt: Date;
  data: ResponseDataType;
}

export type AsyncValueDataType = {
  [poshmarkUserLinkId: string]: EntityTransactions;
};
export const AsyncValue = Observe.AsyncValue<AsyncValueDataType>({
  merge: (prev, next) => {
    if (prev == null) {
      return next;
    } else {
      const nextState: AsyncValueDataType = { ...prev };
      for (const filter in next) {
        nextState[filter] = next[filter];
      }
      return nextState;
    }
  },
});

export const Value = Observe.DerivedValue(() => {
  return AsyncValue.getResolvedValue();
}, [AsyncValue.value]);

export const ValueFallback = Observe.WithFallback(Value);

export const SelectedValue = Observe.DerivedValue(() => {
  const selectedId = PoshmarkUserLinks.SelectedIdValue.get();
  if (selectedId != null) {
    const recentActivities = AsyncValue.getResolvedValue() ?? {};
    return recentActivities[selectedId];
  } else {
    return null;
  }
}, [AsyncValue.value, PoshmarkUserLinks.SelectedIdValue]);

export const SelectedFallback = Observe.WithFallback(SelectedValue);
