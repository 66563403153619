/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type PoshmarkListingCurrencyCodeType = 'AUD' | 'CAD' | 'USD' | '%future added value';
export type PoshmarkPoshmarkListingConditionType =
  | 'NEW_WITH_TAGS'
  | 'NONE'
  | 'NOT_NEW_WITH_TAGS'
  | '%future added value';
export type PoshmarkPoshmarkListingStatusType = 'AVAILABLE' | 'NOT_FOR_SALE' | '%future added value';
export type poshmarkListingQueryVariables = {
  listingId: string;
};
export type poshmarkListingQueryResponse = {
  readonly poshmarkPoshmarkListing: {
    readonly id: string;
    readonly poshmarkId: string | null;
    readonly listingId: string;
    readonly title: string | null;
    readonly description: string | null;
    readonly brand: string | null;
    readonly poshmarkDepartmentId: string | null;
    readonly poshmarkCategoryId: string | null;
    readonly poshmarkCategoryFeatureIds: ReadonlyArray<string>;
    readonly condition: PoshmarkPoshmarkListingConditionType | null;
    readonly price: {
      readonly id: string;
      readonly currencyCode: PoshmarkListingCurrencyCodeType | null;
      readonly val: number | null;
    };
    readonly originalPrice: {
      readonly id: string;
      readonly currencyCode: PoshmarkListingCurrencyCodeType | null;
      readonly val: number | null;
    };
    readonly costPrice: {
      readonly id: string;
      readonly currencyCode: PoshmarkListingCurrencyCodeType | null;
      readonly val: number | null;
    };
    readonly styleTags: ReadonlyArray<string>;
    readonly colors: ReadonlyArray<string>;
    readonly listingSku: string | null;
    readonly status: PoshmarkPoshmarkListingStatusType | null;
    readonly publishStatusV2: {
      readonly status: string | null;
      readonly message: string | null;
    } | null;
    readonly items: ReadonlyArray<{
      readonly poshmarkListingId: string;
      readonly itemId: string;
      readonly size: string | null;
      readonly sizeSystem: string | null;
    }>;
    readonly priceSuggestion: {
      readonly suggestedPrice: number;
      readonly feeParts: ReadonlyArray<{
        readonly value: number;
        readonly label: string;
      }>;
    } | null;
    readonly shippingInfo: {
      readonly shippingPrice: number | null;
      readonly shippingProvider: number;
      readonly shippingProviderDisplay: string;
      readonly payerContributions: ReadonlyArray<{
        readonly contribution: number | null;
        readonly shippingPayer: number | null;
        readonly shippingPayerDisplay: string | null;
      }>;
    } | null;
    readonly shippingDiscountId: string | null;
    readonly sizeSuggestion: {
      readonly sizeSuggestions: ReadonlyArray<{
        readonly id: string;
        readonly display: string;
        readonly sizeSystem: string;
      }>;
    } | null;
  } | null;
};
export type poshmarkListingQuery = {
  readonly response: poshmarkListingQueryResponse;
  readonly variables: poshmarkListingQueryVariables;
};

/*
query poshmarkListingQuery(
  $listingId: String!
) {
  poshmarkPoshmarkListing(listingId: $listingId) {
    id
    poshmarkId
    listingId
    title
    description
    brand
    poshmarkDepartmentId
    poshmarkCategoryId
    poshmarkCategoryFeatureIds
    condition
    price {
      id
      currencyCode
      val
    }
    originalPrice {
      id
      currencyCode
      val
    }
    costPrice {
      id
      currencyCode
      val
    }
    styleTags
    colors
    listingSku
    status
    publishStatusV2 {
      status
      message
    }
    items {
      poshmarkListingId
      itemId
      size
      sizeSystem
    }
    priceSuggestion {
      suggestedPrice
      feeParts {
        value
        label
      }
    }
    shippingInfo {
      shippingPrice
      shippingProvider
      shippingProviderDisplay
      payerContributions {
        contribution
        shippingPayer
        shippingPayerDisplay
      }
    }
    shippingDiscountId
    sizeSuggestion {
      sizeSuggestions {
        id
        display
        sizeSystem
      }
    }
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'listingId',
      },
    ],
    v1 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null,
    },
    v2 = [
      v1 /*: any*/,
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'currencyCode',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'val',
        storageKey: null,
      },
    ],
    v3 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'status',
      storageKey: null,
    },
    v4 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'sizeSystem',
      storageKey: null,
    },
    v5 = [
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'listingId',
            variableName: 'listingId',
          },
        ],
        concreteType: 'PoshmarkPoshmarkListing',
        kind: 'LinkedField',
        name: 'poshmarkPoshmarkListing',
        plural: false,
        selections: [
          v1 /*: any*/,
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'poshmarkId',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'listingId',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'title',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'description',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'brand',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'poshmarkDepartmentId',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'poshmarkCategoryId',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'poshmarkCategoryFeatureIds',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'condition',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            concreteType: 'PoshmarkListingPriceType',
            kind: 'LinkedField',
            name: 'price',
            plural: false,
            selections: v2 /*: any*/,
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            concreteType: 'PoshmarkListingPriceType',
            kind: 'LinkedField',
            name: 'originalPrice',
            plural: false,
            selections: v2 /*: any*/,
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            concreteType: 'PoshmarkListingPriceType',
            kind: 'LinkedField',
            name: 'costPrice',
            plural: false,
            selections: v2 /*: any*/,
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'styleTags',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'colors',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'listingSku',
            storageKey: null,
          },
          v3 /*: any*/,
          {
            alias: null,
            args: null,
            concreteType: 'PoshmarkPublishStatusV2',
            kind: 'LinkedField',
            name: 'publishStatusV2',
            plural: false,
            selections: [
              v3 /*: any*/,
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'message',
                storageKey: null,
              },
            ],
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            concreteType: 'PoshmarkPoshmarkItem',
            kind: 'LinkedField',
            name: 'items',
            plural: true,
            selections: [
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'poshmarkListingId',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'itemId',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'size',
                storageKey: null,
              },
              v4 /*: any*/,
            ],
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            concreteType: 'PoshmarkPoshmarkPriceSuggestion',
            kind: 'LinkedField',
            name: 'priceSuggestion',
            plural: false,
            selections: [
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'suggestedPrice',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                concreteType: 'PoshmarkPoshmarkPriceSuggestionFeePart',
                kind: 'LinkedField',
                name: 'feeParts',
                plural: true,
                selections: [
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'value',
                    storageKey: null,
                  },
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'label',
                    storageKey: null,
                  },
                ],
                storageKey: null,
              },
            ],
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            concreteType: 'PoshmarkShippingInfo',
            kind: 'LinkedField',
            name: 'shippingInfo',
            plural: false,
            selections: [
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'shippingPrice',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'shippingProvider',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'shippingProviderDisplay',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                concreteType: 'PoshmarkShippingPayerContribution',
                kind: 'LinkedField',
                name: 'payerContributions',
                plural: true,
                selections: [
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'contribution',
                    storageKey: null,
                  },
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'shippingPayer',
                    storageKey: null,
                  },
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'shippingPayerDisplay',
                    storageKey: null,
                  },
                ],
                storageKey: null,
              },
            ],
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'shippingDiscountId',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            concreteType: 'PoshmarkSizeSuggestion',
            kind: 'LinkedField',
            name: 'sizeSuggestion',
            plural: false,
            selections: [
              {
                alias: null,
                args: null,
                concreteType: 'PoshmarkSizeSuggestionSize',
                kind: 'LinkedField',
                name: 'sizeSuggestions',
                plural: true,
                selections: [
                  v1 /*: any*/,
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'display',
                    storageKey: null,
                  },
                  v4 /*: any*/,
                ],
                storageKey: null,
              },
            ],
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'poshmarkListingQuery',
      selections: v5 /*: any*/,
      type: 'Query',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'poshmarkListingQuery',
      selections: v5 /*: any*/,
    },
    params: {
      cacheID: '5bdaf0955af99a2858e312da0d6a0b08',
      id: null,
      metadata: {},
      name: 'poshmarkListingQuery',
      operationKind: 'query',
      text: 'query poshmarkListingQuery(\n  $listingId: String!\n) {\n  poshmarkPoshmarkListing(listingId: $listingId) {\n    id\n    poshmarkId\n    listingId\n    title\n    description\n    brand\n    poshmarkDepartmentId\n    poshmarkCategoryId\n    poshmarkCategoryFeatureIds\n    condition\n    price {\n      id\n      currencyCode\n      val\n    }\n    originalPrice {\n      id\n      currencyCode\n      val\n    }\n    costPrice {\n      id\n      currencyCode\n      val\n    }\n    styleTags\n    colors\n    listingSku\n    status\n    publishStatusV2 {\n      status\n      message\n    }\n    items {\n      poshmarkListingId\n      itemId\n      size\n      sizeSystem\n    }\n    priceSuggestion {\n      suggestedPrice\n      feeParts {\n        value\n        label\n      }\n    }\n    shippingInfo {\n      shippingPrice\n      shippingProvider\n      shippingProviderDisplay\n      payerContributions {\n        contribution\n        shippingPayer\n        shippingPayerDisplay\n      }\n    }\n    shippingDiscountId\n    sizeSuggestion {\n      sizeSuggestions {\n        id\n        display\n        sizeSystem\n      }\n    }\n  }\n}\n',
    },
  };
})();
(node as any).hash = '00d7995f70c9c034499078a15bf639e5';
export default node;
