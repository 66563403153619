import { graphql } from 'react-relay';

export const UspsPickupLocationFragmentFull = graphql`
  fragment uspsPickupLocationFragment_full on InvsysUspsPickupLocation @inline {
    id
    addressId
    pickupInstruction
    instructionComments
    firstName
    lastName
    email
    e164
    isDefaultPickupLocation
    address {
      ...addressFragment_full
    }
  }
`;
