import { graphql } from 'react-relay';

export const gqlPutUspsPickupLocation = graphql`
  mutation putUspsPickupLocationMutation(
    $id: InvsysStringRequiredPutObject
    $addressId: InvsysStringRequiredPutObject
    $pickupInstruction: InvsysIntRequiredPutObject
    $instructionComments: InvsysStringPutObject
    $email: InvsysStringPutObject
    $makeDefaultPickupLocation: InvsysBooleanRequiredPutObject
  ) {
    invsysPutUspsPickupLocation(
      id: $id
      addressId: $addressId
      pickupInstruction: $pickupInstruction
      instructionComments: $instructionComments
      email: $email
      makeDefaultPickupLocation: $makeDefaultPickupLocation
    ) {
      success
      data {
        ...uspsPickupLocationFragment_full
      }
    }
  }
`;
