/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type MercariMercariListingColorType =
  | 'BEIGE'
  | 'BLACK'
  | 'BLUE'
  | 'BROWN'
  | 'GOLD'
  | 'GREEN'
  | 'GREY'
  | 'NONE'
  | 'ORANGE'
  | 'PINK'
  | 'PURPLE'
  | 'RED'
  | 'SILVER'
  | 'WHITE'
  | 'YELLOW'
  | '%future added value';
export type MercariMercariListingConditionType = 'FAIR' | 'GOOD' | 'LIKE_NEW' | 'NEW' | 'POOR' | '%future added value';
export type MercariIntPutObject = {
  value?: number | null;
};
export type MercariMercariListingColorTypePutObject = {
  value?: MercariMercariListingColorType | null;
};
export type MercariMercariListingConditionTypePutObject = {
  value?: MercariMercariListingConditionType | null;
};
export type MercariStringPutObject = {
  value?: string | null;
};
export type MercariArray_IntRequired_RequiredPutObject = {
  value: Array<number>;
};
export type MercariArray_StringRequired_RequiredPutObject = {
  value: Array<string>;
};
export type putMercariListingMutationVariables = {
  listingId: string;
  categoryId?: MercariIntPutObject | null;
  colorId?: MercariMercariListingColorTypePutObject | null;
  conditionId?: MercariMercariListingConditionTypePutObject | null;
  description?: MercariStringPutObject | null;
  imei?: MercariStringPutObject | null;
  minPriceForAutoDrop?: MercariIntPutObject | null;
  name?: MercariStringPutObject | null;
  price?: MercariIntPutObject | null;
  shippingClassIds?: MercariArray_IntRequired_RequiredPutObject | null;
  shippingPayerId?: MercariIntPutObject | null;
  suggestedShippingClassIds?: MercariArray_IntRequired_RequiredPutObject | null;
  tags?: MercariArray_StringRequired_RequiredPutObject | null;
  zipCode?: MercariIntPutObject | null;
  brandId?: MercariIntPutObject | null;
  photoIds?: MercariArray_StringRequired_RequiredPutObject | null;
  shippingPackageWeight?: MercariIntPutObject | null;
  dressOccasion?: MercariStringPutObject | null;
  dressStyle?: MercariStringPutObject | null;
  material?: MercariStringPutObject | null;
  shaftHeight?: MercariStringPutObject | null;
  endUse?: MercariStringPutObject | null;
  heelHeight?: MercariStringPutObject | null;
  heelType?: MercariStringPutObject | null;
  shippingType?: MercariIntPutObject | null;
};
export type putMercariListingMutationResponse = {
  readonly mercariPutMercariListing: {
    readonly success: boolean;
  } | null;
};
export type putMercariListingMutation = {
  readonly response: putMercariListingMutationResponse;
  readonly variables: putMercariListingMutationVariables;
};

/*
mutation putMercariListingMutation(
  $listingId: String!
  $categoryId: MercariIntPutObject
  $colorId: MercariMercariListingColorTypePutObject
  $conditionId: MercariMercariListingConditionTypePutObject
  $description: MercariStringPutObject
  $imei: MercariStringPutObject
  $minPriceForAutoDrop: MercariIntPutObject
  $name: MercariStringPutObject
  $price: MercariIntPutObject
  $shippingClassIds: MercariArray_IntRequired_RequiredPutObject
  $shippingPayerId: MercariIntPutObject
  $suggestedShippingClassIds: MercariArray_IntRequired_RequiredPutObject
  $tags: MercariArray_StringRequired_RequiredPutObject
  $zipCode: MercariIntPutObject
  $brandId: MercariIntPutObject
  $photoIds: MercariArray_StringRequired_RequiredPutObject
  $shippingPackageWeight: MercariIntPutObject
  $dressOccasion: MercariStringPutObject
  $dressStyle: MercariStringPutObject
  $material: MercariStringPutObject
  $shaftHeight: MercariStringPutObject
  $endUse: MercariStringPutObject
  $heelHeight: MercariStringPutObject
  $heelType: MercariStringPutObject
  $shippingType: MercariIntPutObject
) {
  mercariPutMercariListing(listingId: $listingId, categoryId: $categoryId, colorId: $colorId, conditionId: $conditionId, description: $description, imei: $imei, minPriceForAutoDrop: $minPriceForAutoDrop, name: $name, price: $price, shippingClassIds: $shippingClassIds, shippingPayerId: $shippingPayerId, suggestedShippingClassIds: $suggestedShippingClassIds, tags: $tags, zipCode: $zipCode, brandId: $brandId, photoIds: $photoIds, shippingPackageWeight: $shippingPackageWeight, dressOccasion: $dressOccasion, dressStyle: $dressStyle, material: $material, shaftHeight: $shaftHeight, endUse: $endUse, heelHeight: $heelHeight, heelType: $heelType, shippingType: $shippingType) {
    success
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'brandId',
    },
    v1 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'categoryId',
    },
    v2 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'colorId',
    },
    v3 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'conditionId',
    },
    v4 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'description',
    },
    v5 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'dressOccasion',
    },
    v6 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'dressStyle',
    },
    v7 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'endUse',
    },
    v8 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'heelHeight',
    },
    v9 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'heelType',
    },
    v10 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'imei',
    },
    v11 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'listingId',
    },
    v12 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'material',
    },
    v13 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'minPriceForAutoDrop',
    },
    v14 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'name',
    },
    v15 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'photoIds',
    },
    v16 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'price',
    },
    v17 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'shaftHeight',
    },
    v18 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'shippingClassIds',
    },
    v19 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'shippingPackageWeight',
    },
    v20 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'shippingPayerId',
    },
    v21 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'shippingType',
    },
    v22 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'suggestedShippingClassIds',
    },
    v23 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'tags',
    },
    v24 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'zipCode',
    },
    v25 = [
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'brandId',
            variableName: 'brandId',
          },
          {
            kind: 'Variable',
            name: 'categoryId',
            variableName: 'categoryId',
          },
          {
            kind: 'Variable',
            name: 'colorId',
            variableName: 'colorId',
          },
          {
            kind: 'Variable',
            name: 'conditionId',
            variableName: 'conditionId',
          },
          {
            kind: 'Variable',
            name: 'description',
            variableName: 'description',
          },
          {
            kind: 'Variable',
            name: 'dressOccasion',
            variableName: 'dressOccasion',
          },
          {
            kind: 'Variable',
            name: 'dressStyle',
            variableName: 'dressStyle',
          },
          {
            kind: 'Variable',
            name: 'endUse',
            variableName: 'endUse',
          },
          {
            kind: 'Variable',
            name: 'heelHeight',
            variableName: 'heelHeight',
          },
          {
            kind: 'Variable',
            name: 'heelType',
            variableName: 'heelType',
          },
          {
            kind: 'Variable',
            name: 'imei',
            variableName: 'imei',
          },
          {
            kind: 'Variable',
            name: 'listingId',
            variableName: 'listingId',
          },
          {
            kind: 'Variable',
            name: 'material',
            variableName: 'material',
          },
          {
            kind: 'Variable',
            name: 'minPriceForAutoDrop',
            variableName: 'minPriceForAutoDrop',
          },
          {
            kind: 'Variable',
            name: 'name',
            variableName: 'name',
          },
          {
            kind: 'Variable',
            name: 'photoIds',
            variableName: 'photoIds',
          },
          {
            kind: 'Variable',
            name: 'price',
            variableName: 'price',
          },
          {
            kind: 'Variable',
            name: 'shaftHeight',
            variableName: 'shaftHeight',
          },
          {
            kind: 'Variable',
            name: 'shippingClassIds',
            variableName: 'shippingClassIds',
          },
          {
            kind: 'Variable',
            name: 'shippingPackageWeight',
            variableName: 'shippingPackageWeight',
          },
          {
            kind: 'Variable',
            name: 'shippingPayerId',
            variableName: 'shippingPayerId',
          },
          {
            kind: 'Variable',
            name: 'shippingType',
            variableName: 'shippingType',
          },
          {
            kind: 'Variable',
            name: 'suggestedShippingClassIds',
            variableName: 'suggestedShippingClassIds',
          },
          {
            kind: 'Variable',
            name: 'tags',
            variableName: 'tags',
          },
          {
            kind: 'Variable',
            name: 'zipCode',
            variableName: 'zipCode',
          },
        ],
        concreteType: 'MercariPutMercariListingResponse',
        kind: 'LinkedField',
        name: 'mercariPutMercariListing',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'success',
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: [
        v0 /*: any*/,
        v1 /*: any*/,
        v2 /*: any*/,
        v3 /*: any*/,
        v4 /*: any*/,
        v5 /*: any*/,
        v6 /*: any*/,
        v7 /*: any*/,
        v8 /*: any*/,
        v9 /*: any*/,
        v10 /*: any*/,
        v11 /*: any*/,
        v12 /*: any*/,
        v13 /*: any*/,
        v14 /*: any*/,
        v15 /*: any*/,
        v16 /*: any*/,
        v17 /*: any*/,
        v18 /*: any*/,
        v19 /*: any*/,
        v20 /*: any*/,
        v21 /*: any*/,
        v22 /*: any*/,
        v23 /*: any*/,
        v24 /*: any*/,
      ],
      kind: 'Fragment',
      metadata: null,
      name: 'putMercariListingMutation',
      selections: v25 /*: any*/,
      type: 'Mutation',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [
        v11 /*: any*/,
        v1 /*: any*/,
        v2 /*: any*/,
        v3 /*: any*/,
        v4 /*: any*/,
        v10 /*: any*/,
        v13 /*: any*/,
        v14 /*: any*/,
        v16 /*: any*/,
        v18 /*: any*/,
        v20 /*: any*/,
        v22 /*: any*/,
        v23 /*: any*/,
        v24 /*: any*/,
        v0 /*: any*/,
        v15 /*: any*/,
        v19 /*: any*/,
        v5 /*: any*/,
        v6 /*: any*/,
        v12 /*: any*/,
        v17 /*: any*/,
        v7 /*: any*/,
        v8 /*: any*/,
        v9 /*: any*/,
        v21 /*: any*/,
      ],
      kind: 'Operation',
      name: 'putMercariListingMutation',
      selections: v25 /*: any*/,
    },
    params: {
      cacheID: 'f949f3e1fb3f999b684eff671dfdacba',
      id: null,
      metadata: {},
      name: 'putMercariListingMutation',
      operationKind: 'mutation',
      text: 'mutation putMercariListingMutation(\n  $listingId: String!\n  $categoryId: MercariIntPutObject\n  $colorId: MercariMercariListingColorTypePutObject\n  $conditionId: MercariMercariListingConditionTypePutObject\n  $description: MercariStringPutObject\n  $imei: MercariStringPutObject\n  $minPriceForAutoDrop: MercariIntPutObject\n  $name: MercariStringPutObject\n  $price: MercariIntPutObject\n  $shippingClassIds: MercariArray_IntRequired_RequiredPutObject\n  $shippingPayerId: MercariIntPutObject\n  $suggestedShippingClassIds: MercariArray_IntRequired_RequiredPutObject\n  $tags: MercariArray_StringRequired_RequiredPutObject\n  $zipCode: MercariIntPutObject\n  $brandId: MercariIntPutObject\n  $photoIds: MercariArray_StringRequired_RequiredPutObject\n  $shippingPackageWeight: MercariIntPutObject\n  $dressOccasion: MercariStringPutObject\n  $dressStyle: MercariStringPutObject\n  $material: MercariStringPutObject\n  $shaftHeight: MercariStringPutObject\n  $endUse: MercariStringPutObject\n  $heelHeight: MercariStringPutObject\n  $heelType: MercariStringPutObject\n  $shippingType: MercariIntPutObject\n) {\n  mercariPutMercariListing(listingId: $listingId, categoryId: $categoryId, colorId: $colorId, conditionId: $conditionId, description: $description, imei: $imei, minPriceForAutoDrop: $minPriceForAutoDrop, name: $name, price: $price, shippingClassIds: $shippingClassIds, shippingPayerId: $shippingPayerId, suggestedShippingClassIds: $suggestedShippingClassIds, tags: $tags, zipCode: $zipCode, brandId: $brandId, photoIds: $photoIds, shippingPackageWeight: $shippingPackageWeight, dressOccasion: $dressOccasion, dressStyle: $dressStyle, material: $material, shaftHeight: $shaftHeight, endUse: $endUse, heelHeight: $heelHeight, heelType: $heelType, shippingType: $shippingType) {\n    success\n  }\n}\n',
    },
  };
})();
(node as any).hash = 'a6ff6760493cd96d0f127afbec243ba4';
export default node;
