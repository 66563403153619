import { graphql } from 'react-relay';

export const gqlMoveStudioListingMediaGroupMembership = graphql`
  mutation moveStudioListingMediaGroupMembershipMutation(
    $listingMediaGroupId: String!
    $operations: [StudioListingMediaGroupMembershipMoveOperation!]!
  ) {
    studioMoveListingMediaGroupMembership(listingMediaGroupId: $listingMediaGroupId, operations: $operations) {
      success
    }
  }
`;
