import { graphql } from 'react-relay';

export const gqlInvsysListingAttributeSuggestions = graphql`
  query invsysListingAttributeSuggestionsQuery($formStep: Int, $invsys: InvsysInvsysListingAttributeSuggestionsArgs) {
    invsysListingAttributeSuggestions(formStep: $formStep, invsys: $invsys) {
      invsys {
        category {
          label
          value
        }
        brand
        packageSizeType
        weight
        sizeIds
        mostUsedCategories {
          label
          value
        }
      }
      depop {
        brand {
          label
          value
        }
        category {
          label
          value
        }
        size {
          label
          value
          sizeSystem
          categoryId
          sizeGroup
        }
      }
      poshmark {
        brand {
          label
          value
        }
        category {
          label
          value
        }
        size {
          label
          value
          sizeSystem
          categoryId
          sizeGroup
        }
      }
      ebay {
        brand {
          label
          value
        }
        category {
          label
          value
        }
        size {
          label
          value
          sizeSystem
          categoryId
          sizeGroup
        }
      }
      mercari {
        brand {
          label
          value
        }
        category {
          label
          value
        }
        size {
          label
          value
          sizeSystem
          categoryId
          sizeGroup
        }
      }
      grailed {
        brand {
          label
          value
        }
        category {
          label
          value
        }
        size {
          label
          value
          sizeSystem
          categoryId
          sizeGroup
        }
      }
    }
  }
`;
