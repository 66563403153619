/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type InvsysGoalFrequency = 'ANNUALLY' | 'MONTHLY' | 'WEEKLY' | '%future added value';
export type InvsysGoalType = 'GROSS_REVENUE' | 'NET_REVENUE' | 'NEW_ITEMS_LISTED' | '%future added value';
export type salesGoalHistoryQueryVariables = {};
export type salesGoalHistoryQueryResponse = {
  readonly invsysSalesGoalHistory: ReadonlyArray<{
    readonly goalType: InvsysGoalType;
    readonly target: number;
    readonly progress: number;
    readonly startAt: number;
    readonly endAt: number;
    readonly goalId: string;
    readonly frequency: InvsysGoalFrequency;
  }>;
};
export type salesGoalHistoryQuery = {
  readonly response: salesGoalHistoryQueryResponse;
  readonly variables: salesGoalHistoryQueryVariables;
};

/*
query salesGoalHistoryQuery {
  invsysSalesGoalHistory {
    goalType
    target
    progress
    startAt
    endAt
    goalId
    frequency
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = [
    {
      alias: null,
      args: null,
      concreteType: 'InvsysSalesGoalHistory',
      kind: 'LinkedField',
      name: 'invsysSalesGoalHistory',
      plural: true,
      selections: [
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'goalType',
          storageKey: null,
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'target',
          storageKey: null,
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'progress',
          storageKey: null,
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'startAt',
          storageKey: null,
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'endAt',
          storageKey: null,
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'goalId',
          storageKey: null,
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'frequency',
          storageKey: null,
        },
      ],
      storageKey: null,
    },
  ];
  return {
    fragment: {
      argumentDefinitions: [],
      kind: 'Fragment',
      metadata: null,
      name: 'salesGoalHistoryQuery',
      selections: v0 /*: any*/,
      type: 'Query',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [],
      kind: 'Operation',
      name: 'salesGoalHistoryQuery',
      selections: v0 /*: any*/,
    },
    params: {
      cacheID: 'fe0039815e872e278bc4c74cd336d5fe',
      id: null,
      metadata: {},
      name: 'salesGoalHistoryQuery',
      operationKind: 'query',
      text: 'query salesGoalHistoryQuery {\n  invsysSalesGoalHistory {\n    goalType\n    target\n    progress\n    startAt\n    endAt\n    goalId\n    frequency\n  }\n}\n',
    },
  };
})();
(node as any).hash = 'd7f0ccee72f9c195da1ef5fa430e436e';
export default node;
