import { graphql } from 'react-relay';

export const gqlListingsFilterSummary = graphql`
  query listingsFilterSummaryQuery {
    invsysListingsFilterSummary {
      filters {
        filter
        count
      }
    }
  }
`;
