/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type InvsysInvsysListingAttributeSuggestionsArgs = {
  title?: string | null;
  description?: string | null;
  categoryId?: string | null;
  sizeId?: string | null;
  brand?: string | null;
};
export type invsysListingAttributeSuggestionsQueryVariables = {
  formStep?: number | null;
  invsys?: InvsysInvsysListingAttributeSuggestionsArgs | null;
};
export type invsysListingAttributeSuggestionsQueryResponse = {
  readonly invsysListingAttributeSuggestions: {
    readonly invsys: {
      readonly category: ReadonlyArray<{
        readonly label: string;
        readonly value: string;
      }> | null;
      readonly brand: ReadonlyArray<string> | null;
      readonly packageSizeType: number | null;
      readonly weight: number | null;
      readonly sizeIds: ReadonlyArray<string> | null;
      readonly mostUsedCategories: ReadonlyArray<{
        readonly label: string;
        readonly value: string;
      }> | null;
    } | null;
    readonly depop: {
      readonly brand: ReadonlyArray<{
        readonly label: string;
        readonly value: string;
      }> | null;
      readonly category: ReadonlyArray<{
        readonly label: string;
        readonly value: string;
      }> | null;
      readonly size: ReadonlyArray<{
        readonly label: string;
        readonly value: string;
        readonly sizeSystem: string;
        readonly categoryId: string;
        readonly sizeGroup: string | null;
      }> | null;
    } | null;
    readonly poshmark: {
      readonly brand: ReadonlyArray<{
        readonly label: string;
        readonly value: string;
      }> | null;
      readonly category: ReadonlyArray<{
        readonly label: string;
        readonly value: string;
      }> | null;
      readonly size: ReadonlyArray<{
        readonly label: string;
        readonly value: string;
        readonly sizeSystem: string;
        readonly categoryId: string;
        readonly sizeGroup: string | null;
      }> | null;
    } | null;
    readonly ebay: {
      readonly brand: ReadonlyArray<{
        readonly label: string;
        readonly value: string;
      }> | null;
      readonly category: ReadonlyArray<{
        readonly label: string;
        readonly value: string;
      }> | null;
      readonly size: ReadonlyArray<{
        readonly label: string;
        readonly value: string;
        readonly sizeSystem: string;
        readonly categoryId: string;
        readonly sizeGroup: string | null;
      }> | null;
    } | null;
    readonly mercari: {
      readonly brand: ReadonlyArray<{
        readonly label: string;
        readonly value: string;
      }> | null;
      readonly category: ReadonlyArray<{
        readonly label: string;
        readonly value: string;
      }> | null;
      readonly size: ReadonlyArray<{
        readonly label: string;
        readonly value: string;
        readonly sizeSystem: string;
        readonly categoryId: string;
        readonly sizeGroup: string | null;
      }> | null;
    } | null;
    readonly grailed: {
      readonly brand: ReadonlyArray<{
        readonly label: string;
        readonly value: string;
      }> | null;
      readonly category: ReadonlyArray<{
        readonly label: string;
        readonly value: string;
      }> | null;
      readonly size: ReadonlyArray<{
        readonly label: string;
        readonly value: string;
        readonly sizeSystem: string;
        readonly categoryId: string;
        readonly sizeGroup: string | null;
      }> | null;
    } | null;
  };
};
export type invsysListingAttributeSuggestionsQuery = {
  readonly response: invsysListingAttributeSuggestionsQueryResponse;
  readonly variables: invsysListingAttributeSuggestionsQueryVariables;
};

/*
query invsysListingAttributeSuggestionsQuery(
  $formStep: Int
  $invsys: InvsysInvsysListingAttributeSuggestionsArgs
) {
  invsysListingAttributeSuggestions(formStep: $formStep, invsys: $invsys) {
    invsys {
      category {
        label
        value
      }
      brand
      packageSizeType
      weight
      sizeIds
      mostUsedCategories {
        label
        value
      }
    }
    depop {
      brand {
        label
        value
      }
      category {
        label
        value
      }
      size {
        label
        value
        sizeSystem
        categoryId
        sizeGroup
      }
    }
    poshmark {
      brand {
        label
        value
      }
      category {
        label
        value
      }
      size {
        label
        value
        sizeSystem
        categoryId
        sizeGroup
      }
    }
    ebay {
      brand {
        label
        value
      }
      category {
        label
        value
      }
      size {
        label
        value
        sizeSystem
        categoryId
        sizeGroup
      }
    }
    mercari {
      brand {
        label
        value
      }
      category {
        label
        value
      }
      size {
        label
        value
        sizeSystem
        categoryId
        sizeGroup
      }
    }
    grailed {
      brand {
        label
        value
      }
      category {
        label
        value
      }
      size {
        label
        value
        sizeSystem
        categoryId
        sizeGroup
      }
    }
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'formStep',
      },
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'invsys',
      },
    ],
    v1 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'label',
      storageKey: null,
    },
    v2 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'value',
      storageKey: null,
    },
    v3 = [v1 /*: any*/, v2 /*: any*/],
    v4 = {
      alias: null,
      args: null,
      concreteType: 'InvsysInvsysListingAttributeCategorySuggestion',
      kind: 'LinkedField',
      name: 'category',
      plural: true,
      selections: v3 /*: any*/,
      storageKey: null,
    },
    v5 = [
      {
        alias: null,
        args: null,
        concreteType: 'InvsysInvsysListingAttributeBrandSuggestion',
        kind: 'LinkedField',
        name: 'brand',
        plural: true,
        selections: v3 /*: any*/,
        storageKey: null,
      },
      v4 /*: any*/,
      {
        alias: null,
        args: null,
        concreteType: 'InvsysInvsysListingAttributeSizeSuggestion',
        kind: 'LinkedField',
        name: 'size',
        plural: true,
        selections: [
          v1 /*: any*/,
          v2 /*: any*/,
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'sizeSystem',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'categoryId',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'sizeGroup',
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ],
    v6 = [
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'formStep',
            variableName: 'formStep',
          },
          {
            kind: 'Variable',
            name: 'invsys',
            variableName: 'invsys',
          },
        ],
        concreteType: 'InvsysListingAttributeSuggestionsResponse',
        kind: 'LinkedField',
        name: 'invsysListingAttributeSuggestions',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            concreteType: 'InvsysInvsysListingAttributeSuggestionsResponse',
            kind: 'LinkedField',
            name: 'invsys',
            plural: false,
            selections: [
              v4 /*: any*/,
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'brand',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'packageSizeType',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'weight',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'sizeIds',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                concreteType: 'InvsysInvsysListingAttributeCategorySuggestion',
                kind: 'LinkedField',
                name: 'mostUsedCategories',
                plural: true,
                selections: v3 /*: any*/,
                storageKey: null,
              },
            ],
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            concreteType: 'InvsysDepopListingAttributeSuggestionsResponse',
            kind: 'LinkedField',
            name: 'depop',
            plural: false,
            selections: v5 /*: any*/,
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            concreteType: 'InvsysPoshmarkListingAttributeSuggestionsResponse',
            kind: 'LinkedField',
            name: 'poshmark',
            plural: false,
            selections: v5 /*: any*/,
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            concreteType: 'InvsysEbayListingAttributeSuggestionsResponse',
            kind: 'LinkedField',
            name: 'ebay',
            plural: false,
            selections: v5 /*: any*/,
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            concreteType: 'InvsysMercariListingAttributeSuggestionsResponse',
            kind: 'LinkedField',
            name: 'mercari',
            plural: false,
            selections: v5 /*: any*/,
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            concreteType: 'InvsysGrailedListingAttributeSuggestionsResponse',
            kind: 'LinkedField',
            name: 'grailed',
            plural: false,
            selections: v5 /*: any*/,
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'invsysListingAttributeSuggestionsQuery',
      selections: v6 /*: any*/,
      type: 'Query',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'invsysListingAttributeSuggestionsQuery',
      selections: v6 /*: any*/,
    },
    params: {
      cacheID: '0a4d2a6e71cc1795e6f2c3550b41b040',
      id: null,
      metadata: {},
      name: 'invsysListingAttributeSuggestionsQuery',
      operationKind: 'query',
      text: 'query invsysListingAttributeSuggestionsQuery(\n  $formStep: Int\n  $invsys: InvsysInvsysListingAttributeSuggestionsArgs\n) {\n  invsysListingAttributeSuggestions(formStep: $formStep, invsys: $invsys) {\n    invsys {\n      category {\n        label\n        value\n      }\n      brand\n      packageSizeType\n      weight\n      sizeIds\n      mostUsedCategories {\n        label\n        value\n      }\n    }\n    depop {\n      brand {\n        label\n        value\n      }\n      category {\n        label\n        value\n      }\n      size {\n        label\n        value\n        sizeSystem\n        categoryId\n        sizeGroup\n      }\n    }\n    poshmark {\n      brand {\n        label\n        value\n      }\n      category {\n        label\n        value\n      }\n      size {\n        label\n        value\n        sizeSystem\n        categoryId\n        sizeGroup\n      }\n    }\n    ebay {\n      brand {\n        label\n        value\n      }\n      category {\n        label\n        value\n      }\n      size {\n        label\n        value\n        sizeSystem\n        categoryId\n        sizeGroup\n      }\n    }\n    mercari {\n      brand {\n        label\n        value\n      }\n      category {\n        label\n        value\n      }\n      size {\n        label\n        value\n        sizeSystem\n        categoryId\n        sizeGroup\n      }\n    }\n    grailed {\n      brand {\n        label\n        value\n      }\n      category {\n        label\n        value\n      }\n      size {\n        label\n        value\n        sizeSystem\n        categoryId\n        sizeGroup\n      }\n    }\n  }\n}\n',
    },
  };
})();
(node as any).hash = 'f82ada7f9fc9e49365ae7f02ec947d86';
export default node;
