import { graphql } from 'react-relay';

export const gqlRecentActivity = graphql`
  query recentActivityQuery($poshmarkUserLinkId: String!) {
    superposherPoshmarkUserLink(id: $poshmarkUserLinkId) {
      id
      recentActivity {
        ... on SuperposherShareToFollowersEvent {
          __typename
          at
          listings {
            sourceUrl
          }
        }
        ... on SuperposherShareToEventEvent {
          __typename
          at
          listings {
            sourceUrl
          }
          events {
            sourceUrl
          }
        }
        ... on SuperposherTargetedFollowEvent {
          __typename
          at
          users {
            sourceUrl
          }
        }
        ... on SuperposherShareForShareEvent {
          __typename
          at
          listings {
            sourceUrl
          }
        }
        ... on SuperposherFollowForFollowEvent {
          __typename
          at
          users {
            sourceUrl
          }
        }
        ... on SuperposherOfferToLikersEvent {
          __typename
          at
          currencyCode
          discountedPrice
          listings {
            sourceUrl
          }
        }
        ... on SuperposherRelistToPoshmarkEvent {
          __typename
          at
          listings {
            sourceUrl
          }
        }
      }
    }
  }
`;
