import { graphql } from 'react-relay';

export const gqlValidateGrailedListing = graphql`
  query validateGrailedListingQuery($listingId: String!) {
    grailedValidateGrailedListing(listingId: $listingId) {
      success
      errors {
        errorId: id
        entityName
        entityId
        fieldName
        error
        message
        timeToComplete
      }
      additionalFieldsAvailable
    }
  }
`;
