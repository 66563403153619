import * as Build from 'src/clients/Network/gql/build';
import * as FragmentReader from './FragmentReader';
import * as Lib from 'src/clients/Network/gql/lib';
import * as State from 'src/state';
import * as Queries from 'src/clients/Network/gql/queries';
import * as StoreHelpers from './StoreHelpers';

export * as FragmentReader from './FragmentReader';
export * as StoreHelpers from './StoreHelpers';

export const getAppCriticalMetadata =
  Lib.promisifyQuery<Build.appListingCriticalMetadataQuery.appListingCriticalMetadataQuery>(
    Queries.gqlAppListingCriticalMetadata,
    [
      (args, p) => {
        p.then((_) => {
          State.Observe.Listings.ListingFeatureSettingsValue.set(_.invsysListingFeatureSettings);
        });
      },
    ]
  );

export const addToListingMediaGroup =
  Lib.promisifyMutation<Build.addToStudioListingMediaGroupMutation.addToStudioListingMediaGroupMutation>(
    Queries.gqlAddToStudioListingMediaGroup
  );

export const moveListingMediaGroupMembership =
  Lib.promisifyMutation<Build.moveStudioListingMediaGroupMembershipMutation.moveStudioListingMediaGroupMembershipMutation>(
    Queries.gqlMoveStudioListingMediaGroupMembership
  );

export const prepareListingMediaGroupMembership =
  Lib.promisifyMutation<Build.prepareStudioListingMediaGroupMembershipMutation.prepareStudioListingMediaGroupMembershipMutation>(
    Queries.gqlPrepareStudioListingMediaGroupMembership
  );

export const putStorageMedia = Lib.promisifyMutation<Build.putStorageMediaMutation.putStorageMediaMutation>(
  Queries.gqlPutStorageMedia
);

export const putListingMediaGroup =
  Lib.promisifyMutation<Build.putStudioListingMediaGroupMutation.putStudioListingMediaGroupMutation>(
    Queries.gqlPutStudioListingMediaGroup,
    [
      (args, p) => {
        p.then((_) => {
          const listingMediaGroup = FragmentReader.studioListingMediaGroupFull(_.studioPutListingMediaGroup.data);
          if (listingMediaGroup != null) {
            StoreHelpers.storeStudioListingMediaGroups([listingMediaGroup]);
          }
        });
      },
    ]
  );

export const removeFromListingMediaGroup =
  Lib.promisifyMutation<Build.removeFromStudioListingMediaGroupMutation.removeFromStudioListingMediaGroupMutation>(
    Queries.gqlRemoveFromStudioListingMediaGroup
  );

export const setListingMediaGroupMembershipVariant =
  Lib.promisifyMutation<Build.setStudioListingMediaGroupMembershipVariantMutation.setStudioListingMediaGroupMembershipVariantMutation>(
    Queries.gqlSetStudioListingMediaGroupMembershipVariant
  );

export const latestListingMediaGroups =
  Lib.promisifyQuery<Build.studioLatestListingMediaGroupsQuery.studioLatestListingMediaGroupsQuery>(
    Queries.gqlStudioLatestListingMediaGroups
  );

export const listingMediaGroups = Lib.promisifyQuery<Build.studioListingMediaGroupsQuery.studioListingMediaGroupsQuery>(
  Queries.gqlStudioListingMediaGroups,
  [
    (args, p) => {
      p.then((_) => {
        const listingMediaGroups = FragmentReader.map(
          _.studioListingMediaGroups,
          FragmentReader.studioListingMediaGroupFull
        );
        StoreHelpers.storeStudioListingMediaGroups(listingMediaGroups);
      });
    },
  ]
);

export const requestListingMediaGroupVariant =
  Lib.promisifyMutation<Build.requestListingMediaGroupVariantMutation.requestListingMediaGroupVariantMutation>(
    Queries.gqlRequestListingMediaGroupVariant
  );
