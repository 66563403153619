/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type GrailedBooleanPutObject = {
  value?: boolean | null;
};
export type GrailedStringPutObject = {
  value?: string | null;
};
export type GrailedArray_IntRequired_RequiredPutObject = {
  value: Array<number>;
};
export type GrailedArray_StringRequired_RequiredPutObject = {
  value: Array<string>;
};
export type GrailedStringRequiredPutObject = {
  value: string;
};
export type GrailedIntPutObject = {
  value?: number | null;
};
export type GrailedBooleanRequiredPutObject = {
  value: boolean;
};
export type putGrailedListingMutationVariables = {
  listingId: string;
  buyNow?: GrailedBooleanPutObject | null;
  categoryPath?: GrailedStringPutObject | null;
  condition?: GrailedStringPutObject | null;
  description?: GrailedStringPutObject | null;
  designerIds?: GrailedArray_IntRequired_RequiredPutObject | null;
  hashtags?: GrailedArray_StringRequired_RequiredPutObject | null;
  makeOffer?: GrailedBooleanPutObject | null;
  measurements?: GrailedStringRequiredPutObject | null;
  minimumPrice?: GrailedIntPutObject | null;
  useSmartPricing?: GrailedBooleanRequiredPutObject | null;
  photoIds?: GrailedArray_StringRequired_RequiredPutObject | null;
  price?: GrailedIntPutObject | null;
  returnAddressId?: GrailedIntPutObject | null;
  usShippingPrice?: GrailedIntPutObject | null;
  caShippingPrice?: GrailedIntPutObject | null;
  title?: GrailedStringPutObject | null;
  color?: GrailedStringPutObject | null;
  externalId?: GrailedStringPutObject | null;
  shippingType?: GrailedIntPutObject | null;
  shippingSignatureConfirmation?: GrailedBooleanRequiredPutObject | null;
};
export type putGrailedListingMutationResponse = {
  readonly grailedPutGrailedListing: {
    readonly success: boolean;
  } | null;
};
export type putGrailedListingMutation = {
  readonly response: putGrailedListingMutationResponse;
  readonly variables: putGrailedListingMutationVariables;
};

/*
mutation putGrailedListingMutation(
  $listingId: String!
  $buyNow: GrailedBooleanPutObject
  $categoryPath: GrailedStringPutObject
  $condition: GrailedStringPutObject
  $description: GrailedStringPutObject
  $designerIds: GrailedArray_IntRequired_RequiredPutObject
  $hashtags: GrailedArray_StringRequired_RequiredPutObject
  $makeOffer: GrailedBooleanPutObject
  $measurements: GrailedStringRequiredPutObject
  $minimumPrice: GrailedIntPutObject
  $useSmartPricing: GrailedBooleanRequiredPutObject
  $photoIds: GrailedArray_StringRequired_RequiredPutObject
  $price: GrailedIntPutObject
  $returnAddressId: GrailedIntPutObject
  $usShippingPrice: GrailedIntPutObject
  $caShippingPrice: GrailedIntPutObject
  $title: GrailedStringPutObject
  $color: GrailedStringPutObject
  $externalId: GrailedStringPutObject
  $shippingType: GrailedIntPutObject
  $shippingSignatureConfirmation: GrailedBooleanRequiredPutObject
) {
  grailedPutGrailedListing(listingId: $listingId, buyNow: $buyNow, categoryPath: $categoryPath, condition: $condition, description: $description, designerIds: $designerIds, hashtags: $hashtags, makeoffer: $makeOffer, measurements: $measurements, minimumPrice: $minimumPrice, useSmartPricing: $useSmartPricing, photoIds: $photoIds, price: $price, returnAddressId: $returnAddressId, usShippingPrice: $usShippingPrice, caShippingPrice: $caShippingPrice, title: $title, color: $color, externalId: $externalId, shippingType: $shippingType, shippingSignatureConfirmation: $shippingSignatureConfirmation) {
    success
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'buyNow',
    },
    v1 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'caShippingPrice',
    },
    v2 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'categoryPath',
    },
    v3 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'color',
    },
    v4 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'condition',
    },
    v5 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'description',
    },
    v6 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'designerIds',
    },
    v7 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'externalId',
    },
    v8 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'hashtags',
    },
    v9 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'listingId',
    },
    v10 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'makeOffer',
    },
    v11 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'measurements',
    },
    v12 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'minimumPrice',
    },
    v13 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'photoIds',
    },
    v14 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'price',
    },
    v15 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'returnAddressId',
    },
    v16 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'shippingSignatureConfirmation',
    },
    v17 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'shippingType',
    },
    v18 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'title',
    },
    v19 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'usShippingPrice',
    },
    v20 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'useSmartPricing',
    },
    v21 = [
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'buyNow',
            variableName: 'buyNow',
          },
          {
            kind: 'Variable',
            name: 'caShippingPrice',
            variableName: 'caShippingPrice',
          },
          {
            kind: 'Variable',
            name: 'categoryPath',
            variableName: 'categoryPath',
          },
          {
            kind: 'Variable',
            name: 'color',
            variableName: 'color',
          },
          {
            kind: 'Variable',
            name: 'condition',
            variableName: 'condition',
          },
          {
            kind: 'Variable',
            name: 'description',
            variableName: 'description',
          },
          {
            kind: 'Variable',
            name: 'designerIds',
            variableName: 'designerIds',
          },
          {
            kind: 'Variable',
            name: 'externalId',
            variableName: 'externalId',
          },
          {
            kind: 'Variable',
            name: 'hashtags',
            variableName: 'hashtags',
          },
          {
            kind: 'Variable',
            name: 'listingId',
            variableName: 'listingId',
          },
          {
            kind: 'Variable',
            name: 'makeoffer',
            variableName: 'makeOffer',
          },
          {
            kind: 'Variable',
            name: 'measurements',
            variableName: 'measurements',
          },
          {
            kind: 'Variable',
            name: 'minimumPrice',
            variableName: 'minimumPrice',
          },
          {
            kind: 'Variable',
            name: 'photoIds',
            variableName: 'photoIds',
          },
          {
            kind: 'Variable',
            name: 'price',
            variableName: 'price',
          },
          {
            kind: 'Variable',
            name: 'returnAddressId',
            variableName: 'returnAddressId',
          },
          {
            kind: 'Variable',
            name: 'shippingSignatureConfirmation',
            variableName: 'shippingSignatureConfirmation',
          },
          {
            kind: 'Variable',
            name: 'shippingType',
            variableName: 'shippingType',
          },
          {
            kind: 'Variable',
            name: 'title',
            variableName: 'title',
          },
          {
            kind: 'Variable',
            name: 'usShippingPrice',
            variableName: 'usShippingPrice',
          },
          {
            kind: 'Variable',
            name: 'useSmartPricing',
            variableName: 'useSmartPricing',
          },
        ],
        concreteType: 'GrailedPutGrailedListingResponse',
        kind: 'LinkedField',
        name: 'grailedPutGrailedListing',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'success',
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: [
        v0 /*: any*/,
        v1 /*: any*/,
        v2 /*: any*/,
        v3 /*: any*/,
        v4 /*: any*/,
        v5 /*: any*/,
        v6 /*: any*/,
        v7 /*: any*/,
        v8 /*: any*/,
        v9 /*: any*/,
        v10 /*: any*/,
        v11 /*: any*/,
        v12 /*: any*/,
        v13 /*: any*/,
        v14 /*: any*/,
        v15 /*: any*/,
        v16 /*: any*/,
        v17 /*: any*/,
        v18 /*: any*/,
        v19 /*: any*/,
        v20 /*: any*/,
      ],
      kind: 'Fragment',
      metadata: null,
      name: 'putGrailedListingMutation',
      selections: v21 /*: any*/,
      type: 'Mutation',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [
        v9 /*: any*/,
        v0 /*: any*/,
        v2 /*: any*/,
        v4 /*: any*/,
        v5 /*: any*/,
        v6 /*: any*/,
        v8 /*: any*/,
        v10 /*: any*/,
        v11 /*: any*/,
        v12 /*: any*/,
        v20 /*: any*/,
        v13 /*: any*/,
        v14 /*: any*/,
        v15 /*: any*/,
        v19 /*: any*/,
        v1 /*: any*/,
        v18 /*: any*/,
        v3 /*: any*/,
        v7 /*: any*/,
        v17 /*: any*/,
        v16 /*: any*/,
      ],
      kind: 'Operation',
      name: 'putGrailedListingMutation',
      selections: v21 /*: any*/,
    },
    params: {
      cacheID: 'a97867ac995fecac552d537c3e6c8485',
      id: null,
      metadata: {},
      name: 'putGrailedListingMutation',
      operationKind: 'mutation',
      text: 'mutation putGrailedListingMutation(\n  $listingId: String!\n  $buyNow: GrailedBooleanPutObject\n  $categoryPath: GrailedStringPutObject\n  $condition: GrailedStringPutObject\n  $description: GrailedStringPutObject\n  $designerIds: GrailedArray_IntRequired_RequiredPutObject\n  $hashtags: GrailedArray_StringRequired_RequiredPutObject\n  $makeOffer: GrailedBooleanPutObject\n  $measurements: GrailedStringRequiredPutObject\n  $minimumPrice: GrailedIntPutObject\n  $useSmartPricing: GrailedBooleanRequiredPutObject\n  $photoIds: GrailedArray_StringRequired_RequiredPutObject\n  $price: GrailedIntPutObject\n  $returnAddressId: GrailedIntPutObject\n  $usShippingPrice: GrailedIntPutObject\n  $caShippingPrice: GrailedIntPutObject\n  $title: GrailedStringPutObject\n  $color: GrailedStringPutObject\n  $externalId: GrailedStringPutObject\n  $shippingType: GrailedIntPutObject\n  $shippingSignatureConfirmation: GrailedBooleanRequiredPutObject\n) {\n  grailedPutGrailedListing(listingId: $listingId, buyNow: $buyNow, categoryPath: $categoryPath, condition: $condition, description: $description, designerIds: $designerIds, hashtags: $hashtags, makeoffer: $makeOffer, measurements: $measurements, minimumPrice: $minimumPrice, useSmartPricing: $useSmartPricing, photoIds: $photoIds, price: $price, returnAddressId: $returnAddressId, usShippingPrice: $usShippingPrice, caShippingPrice: $caShippingPrice, title: $title, color: $color, externalId: $externalId, shippingType: $shippingType, shippingSignatureConfirmation: $shippingSignatureConfirmation) {\n    success\n  }\n}\n',
    },
  };
})();
(node as any).hash = '1e43344b9d88864241332bf57de44173';
export default node;
