import { graphql } from 'react-relay';

export const gqlPoshmarkUserLinks = graphql`
  query poshmarkUserLinksQuery {
    superposherPoshmarkUserLinks {
      id
      businessId
      createdAt
      poshmarkUserId
      linkStatus
      cookieStatus
      cookieUpdatedAt
      cookieCheckedAt
      poshmarkEmail
      poshmarkUsername
      poshmarkAvatarUrl
      poshmarkFullName
      poshmarkUserCreatedAt
      shareFeatureStatus
      shareNotForSaleFeatureStatus
      shareForShareFeatureStatus
      followForFollowFeatureStatus
      followTargetedFeatureStatus
      offerToLikersFeatureStatus
      shareRandomlyFeatureStatus
      shareSkipPartiesFeatureStatus
      sharePrioritizePartiesFeatureStatus
      sharePrioritizePartiesLoopFeatureStatus
      autoRelistingFeatureStatus
      listingsBetaFeatureStatus
      comparativePrivacyFeatureStatus
      vacationModeSharesFeatureStatus
      vacationModeFollowsFeatureStatus
      vacationModeOffersFeatureStatus
      vacationModeRelistsFeatureStatus
      relistOldestFirstFeatureStatus
      statBucket7DayLookBack {
        at
        shareToFollowersFinished
        shareToPartiesFinished
        shareForSharesFinished
        followForFollowsFinished
        followUserFollowersFinished
        offerToLikersFinished
        relistingsFinished
        relistingsToPoshmarkFinished
      }
      statBucket24HourLookBack {
        at
        shareToFollowersFinished
        shareToPartiesFinished
        shareForSharesFinished
        followForFollowsFinished
        followUserFollowersFinished
        offerToLikersFinished
        relistingsFinished
        relistingsToPoshmarkFinished
      }
      statBucket48HourLookBack {
        at
        shareToFollowersFinished
        shareToPartiesFinished
        shareForSharesFinished
        followForFollowsFinished
        followUserFollowersFinished
        offerToLikersFinished
        relistingsFinished
        relistingsToPoshmarkFinished
      }
      statBucketToday {
        at
        shareToFollowersFinished
        shareToPartiesFinished
        shareForSharesFinished
        followForFollowsFinished
        followUserFollowersFinished
        offerToLikersFinished
        relistingsFinished
        relistingsToPoshmarkFinished
      }
      offerToLikersPercentOff
      offerToLikersMinRevenueTarget
      offerToLikersShippingDiscountType
      vacationHold
      listingsScrapeAdhoc
      userScrapeAdhoc
      shareJail
      maxDailyShares
      availableListingsCount
      weeklySalesRank
      weeklySalesScore
      monthlySalesRank
      monthlySalesScore
      relistInterval
      defaultOfferConfigurationId
      noDiscountOfferConfigurationId
      tld
    }
  }
`;
