import { graphql } from 'relay-runtime';

export const gqlCreateSalesGoal = graphql`
  mutation createSalesGoalMutation(
    $institution: Int
    $institutionIssuedId: String
    $goalType: InvsysGoalType!
    $target: Float!
    $frequency: InvsysGoalFrequency!
  ) {
    invsysCreateSalesGoal(
      institution: $institution
      institutionIssuedId: $institutionIssuedId
      goalType: $goalType
      target: $target
      frequency: $frequency
    ) {
      id
    }
  }
`;
