import * as State from 'src/state';

// Note (albert): Testing this
interface EntityMapIface<E> {
  [key: string]: E;
}

export const mkEntityMap = <E extends unknown>(
  entities: E[] | readonly E[],
  idSelector: (e: E) => string
): EntityMapIface<E> => {
  const entityMap: EntityMapIface<E> = {};
  for (let i = 0; i < entities.length; i++) {
    entityMap[idSelector(entities[i])] = entities[i];
  }
  return entityMap;
};

export const storeStudioListingMediaGroups = (listingMediaGroups: State.Types.StudioListingMediaGroupType[]) => {
  State.Observe.Studio.MediaValue.transform((current) => {
    return {
      ...current,
      ...mkEntityMap<State.Types.StudioMediaType>(
        listingMediaGroups.flatMap((_) =>
          _.memberships.flatMap((membership) => {
            return (membership.media != null ? [membership.media] : []).concat(
              membership.variants.flatMap((variant) => {
                return variant.media != null ? [variant.media] : [];
              })
            );
          })
        ),
        (_) => _.id
      ),
    };
  });

  const listingMediaGroupMembershipsWithAssocs: State.Types.StudioListingMediaGroupMembershipType[] = listingMediaGroups
    .flatMap((_) => _.memberships)
    .map((_) => {
      return {
        ..._,
        media: null,
        variants: [
          ..._.variants.map((_) => {
            return {
              ..._,
              media: null,
            };
          }),
        ],
      };
    });
  State.Observe.Studio.ListingMediaGroupMembershipsValue.transform((current) => {
    return {
      ...current,
      ...mkEntityMap<State.Types.StudioListingMediaGroupMembershipType>(
        listingMediaGroupMembershipsWithAssocs,
        (_) => _.mediaId
      ),
    };
  });
  State.Observe.Studio.ListingMediaGroupMediaIdsValue.transform((current) => {
    const newMap: {
      [key: string]: string[];
    } = {};

    for (let i = 0; i < listingMediaGroups.length; i++) {
      newMap[listingMediaGroups[i].id] = listingMediaGroups[i].memberships.map((_) => _.mediaId);
    }

    return {
      ...current,
      ...newMap,
    };
  });

  State.Observe.Studio.ListingToListingMediaGroupValue.transform((current) => {
    const newMap: {
      [key: string]: string;
    } = {};

    for (let i = 0; i < listingMediaGroups.length; i++) {
      const listingId = listingMediaGroups[i].listingId;
      if (listingId != null) {
        newMap[listingId] = listingMediaGroups[i].id;
      }
    }

    return {
      ...current,
      ...newMap,
    };
  });

  const listingMediaGroupsWithAssocs: State.Types.StudioListingMediaGroupType[] = listingMediaGroups.map((_) => {
    return {
      ..._,
      memberships: [],
    };
  });
  State.Observe.Studio.ListingMediaGroupsValue.transform((current) => {
    return {
      ...current,
      ...mkEntityMap<State.Types.StudioListingMediaGroupType>(listingMediaGroupsWithAssocs, (_) => _.id),
    };
  });
};
