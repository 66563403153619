/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type poshmarkBotClosetOrderingSectionsQueryVariables = {
  cursor?: string | null;
  limit?: number | null;
};
export type poshmarkBotClosetOrderingSectionsQueryResponse = {
  readonly poshmarkBotClosetOrderingSections: {
    readonly items: ReadonlyArray<{
      readonly id: string | null;
      readonly userId: string;
      readonly name: string;
      readonly rank: number;
    }>;
    readonly cursor: string | null;
  };
};
export type poshmarkBotClosetOrderingSectionsQuery = {
  readonly response: poshmarkBotClosetOrderingSectionsQueryResponse;
  readonly variables: poshmarkBotClosetOrderingSectionsQueryVariables;
};

/*
query poshmarkBotClosetOrderingSectionsQuery(
  $cursor: String
  $limit: Int
) {
  poshmarkBotClosetOrderingSections(cursor: $cursor, limit: $limit) {
    items {
      id
      userId
      name
      rank
    }
    cursor
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'cursor',
      },
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'limit',
      },
    ],
    v1 = [
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'cursor',
            variableName: 'cursor',
          },
          {
            kind: 'Variable',
            name: 'limit',
            variableName: 'limit',
          },
        ],
        concreteType: 'PoshmarkBotClosetOrderingSectionPage',
        kind: 'LinkedField',
        name: 'poshmarkBotClosetOrderingSections',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            concreteType: 'PoshmarkBotClosetOrderingSection',
            kind: 'LinkedField',
            name: 'items',
            plural: true,
            selections: [
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'id',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'userId',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'name',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'rank',
                storageKey: null,
              },
            ],
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'cursor',
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'poshmarkBotClosetOrderingSectionsQuery',
      selections: v1 /*: any*/,
      type: 'Query',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'poshmarkBotClosetOrderingSectionsQuery',
      selections: v1 /*: any*/,
    },
    params: {
      cacheID: '52c89afe435530c69e44f2812cbc1168',
      id: null,
      metadata: {},
      name: 'poshmarkBotClosetOrderingSectionsQuery',
      operationKind: 'query',
      text: 'query poshmarkBotClosetOrderingSectionsQuery(\n  $cursor: String\n  $limit: Int\n) {\n  poshmarkBotClosetOrderingSections(cursor: $cursor, limit: $limit) {\n    items {\n      id\n      userId\n      name\n      rank\n    }\n    cursor\n  }\n}\n',
    },
  };
})();
(node as any).hash = 'a50f52956e39f91e2cf300ce70e71ac1';
export default node;
