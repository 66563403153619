/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type dismissInstitutionImportStatusMutationVariables = {
  institution: number;
};
export type dismissInstitutionImportStatusMutationResponse = {
  readonly invsysDismissInstitutionImportStatus: {
    readonly success: boolean;
  };
};
export type dismissInstitutionImportStatusMutation = {
  readonly response: dismissInstitutionImportStatusMutationResponse;
  readonly variables: dismissInstitutionImportStatusMutationVariables;
};

/*
mutation dismissInstitutionImportStatusMutation(
  $institution: Int!
) {
  invsysDismissInstitutionImportStatus(institution: $institution) {
    success
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'institution',
      },
    ],
    v1 = [
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'institution',
            variableName: 'institution',
          },
        ],
        concreteType: 'InvsysDismissInstitutionImportStatusResponse',
        kind: 'LinkedField',
        name: 'invsysDismissInstitutionImportStatus',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'success',
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'dismissInstitutionImportStatusMutation',
      selections: v1 /*: any*/,
      type: 'Mutation',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'dismissInstitutionImportStatusMutation',
      selections: v1 /*: any*/,
    },
    params: {
      cacheID: '357a72726bf9b918b71c4cccfcbb7ffe',
      id: null,
      metadata: {},
      name: 'dismissInstitutionImportStatusMutation',
      operationKind: 'mutation',
      text: 'mutation dismissInstitutionImportStatusMutation(\n  $institution: Int!\n) {\n  invsysDismissInstitutionImportStatus(institution: $institution) {\n    success\n  }\n}\n',
    },
  };
})();
(node as any).hash = '90ff46398e4195dc4636707fdc860a10';
export default node;
