/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type suggestedPriceQueryVariables = {
  institution: number;
  originalPrice: number;
  categoryId?: string | null;
};
export type suggestedPriceQueryResponse = {
  readonly invsysSuggestedPrice: {
    readonly institution: number;
    readonly suggestedPrice: number;
    readonly categoryId: string;
  } | null;
};
export type suggestedPriceQuery = {
  readonly response: suggestedPriceQueryResponse;
  readonly variables: suggestedPriceQueryVariables;
};

/*
query suggestedPriceQuery(
  $institution: Int!
  $originalPrice: Int!
  $categoryId: String
) {
  invsysSuggestedPrice(institution: $institution, originalPrice: $originalPrice, categoryId: $categoryId) {
    institution
    suggestedPrice
    categoryId
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'categoryId',
    },
    v1 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'institution',
    },
    v2 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'originalPrice',
    },
    v3 = [
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'categoryId',
            variableName: 'categoryId',
          },
          {
            kind: 'Variable',
            name: 'institution',
            variableName: 'institution',
          },
          {
            kind: 'Variable',
            name: 'originalPrice',
            variableName: 'originalPrice',
          },
        ],
        concreteType: 'InvsysSuggestedPrice',
        kind: 'LinkedField',
        name: 'invsysSuggestedPrice',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'institution',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'suggestedPrice',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'categoryId',
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: [v0 /*: any*/, v1 /*: any*/, v2 /*: any*/],
      kind: 'Fragment',
      metadata: null,
      name: 'suggestedPriceQuery',
      selections: v3 /*: any*/,
      type: 'Query',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [v1 /*: any*/, v2 /*: any*/, v0 /*: any*/],
      kind: 'Operation',
      name: 'suggestedPriceQuery',
      selections: v3 /*: any*/,
    },
    params: {
      cacheID: '6618c77e1872962b57f95072b00df217',
      id: null,
      metadata: {},
      name: 'suggestedPriceQuery',
      operationKind: 'query',
      text: 'query suggestedPriceQuery(\n  $institution: Int!\n  $originalPrice: Int!\n  $categoryId: String\n) {\n  invsysSuggestedPrice(institution: $institution, originalPrice: $originalPrice, categoryId: $categoryId) {\n    institution\n    suggestedPrice\n    categoryId\n  }\n}\n',
    },
  };
})();
(node as any).hash = 'f6ccff51d9e8a75e3198cb250ee5a416';
export default node;
