import { graphql } from 'react-relay';

export const gqlRequestShippingLabels = graphql`
  mutation requestShippingLabelsMutation($orderIds: [String!]!, $format: Int!, $addTitle: Boolean, $addSku: Boolean) {
    invsysRequestShippingLabels(orderIds: $orderIds, format: $format, addTitle: $addTitle, addSku: $addSku) {
      success
      failurePublicReason
    }
  }
`;
