/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type invsysStartMobileBillingPortalSessionMutationVariables = {};
export type invsysStartMobileBillingPortalSessionMutationResponse = {
  readonly invsysStartMobileBillingPortalSession: {
    readonly url: string;
  };
};
export type invsysStartMobileBillingPortalSessionMutation = {
  readonly response: invsysStartMobileBillingPortalSessionMutationResponse;
  readonly variables: invsysStartMobileBillingPortalSessionMutationVariables;
};

/*
mutation invsysStartMobileBillingPortalSessionMutation {
  invsysStartMobileBillingPortalSession {
    url
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = [
    {
      alias: null,
      args: null,
      concreteType: 'InvsysStartBillingPortalSessionResponse',
      kind: 'LinkedField',
      name: 'invsysStartMobileBillingPortalSession',
      plural: false,
      selections: [
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'url',
          storageKey: null,
        },
      ],
      storageKey: null,
    },
  ];
  return {
    fragment: {
      argumentDefinitions: [],
      kind: 'Fragment',
      metadata: null,
      name: 'invsysStartMobileBillingPortalSessionMutation',
      selections: v0 /*: any*/,
      type: 'Mutation',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [],
      kind: 'Operation',
      name: 'invsysStartMobileBillingPortalSessionMutation',
      selections: v0 /*: any*/,
    },
    params: {
      cacheID: '9302c5369982a54b2d1c79c370faefdf',
      id: null,
      metadata: {},
      name: 'invsysStartMobileBillingPortalSessionMutation',
      operationKind: 'mutation',
      text: 'mutation invsysStartMobileBillingPortalSessionMutation {\n  invsysStartMobileBillingPortalSession {\n    url\n  }\n}\n',
    },
  };
})();
(node as any).hash = '527eda2dc552d22972c2101baa7d6781';
export default node;
