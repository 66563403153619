/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type setPoshmarkBotListingsOfferConfigurationMutationVariables = {
  listingIds: Array<string>;
  offerConfigId?: string | null;
};
export type setPoshmarkBotListingsOfferConfigurationMutationResponse = {
  readonly poshmarkSetBotListingsOfferConfiguration: {
    readonly success: boolean;
  } | null;
};
export type setPoshmarkBotListingsOfferConfigurationMutation = {
  readonly response: setPoshmarkBotListingsOfferConfigurationMutationResponse;
  readonly variables: setPoshmarkBotListingsOfferConfigurationMutationVariables;
};

/*
mutation setPoshmarkBotListingsOfferConfigurationMutation(
  $listingIds: [String!]!
  $offerConfigId: String
) {
  poshmarkSetBotListingsOfferConfiguration(listingIds: $listingIds, offerConfigId: $offerConfigId) {
    success
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'listingIds',
      },
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'offerConfigId',
      },
    ],
    v1 = [
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'listingIds',
            variableName: 'listingIds',
          },
          {
            kind: 'Variable',
            name: 'offerConfigId',
            variableName: 'offerConfigId',
          },
        ],
        concreteType: 'PoshmarkSetListingsOfferConfigurationResponse',
        kind: 'LinkedField',
        name: 'poshmarkSetBotListingsOfferConfiguration',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'success',
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'setPoshmarkBotListingsOfferConfigurationMutation',
      selections: v1 /*: any*/,
      type: 'Mutation',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'setPoshmarkBotListingsOfferConfigurationMutation',
      selections: v1 /*: any*/,
    },
    params: {
      cacheID: '0fc15db444a7d84c9b433b3e43a9a834',
      id: null,
      metadata: {},
      name: 'setPoshmarkBotListingsOfferConfigurationMutation',
      operationKind: 'mutation',
      text: 'mutation setPoshmarkBotListingsOfferConfigurationMutation(\n  $listingIds: [String!]!\n  $offerConfigId: String\n) {\n  poshmarkSetBotListingsOfferConfiguration(listingIds: $listingIds, offerConfigId: $offerConfigId) {\n    success\n  }\n}\n',
    },
  };
})();
(node as any).hash = '80b45f5d0836aa398abc685e7595443a';
export default node;
