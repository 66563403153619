import * as Lib from 'src/clients/Network/gql/lib';
import * as Build from 'src/clients/Network/gql/build';
import * as Queries from 'src/clients/Network/gql/queries';
import * as State from 'src/state';
import * as FragmentReader from './FragmentReader';
import * as StoreHelpers from './StoreHelpers';
export * as FragmentReader from './FragmentReader';

export const uspsPickupLocations = Lib.promisifyQuery<Build.uspsPickupLocationsQuery.uspsPickupLocationsQuery>(
  Queries.gqlUspsPickupLocations,
  [
    (args, p) => {
      p.then((r) => {
        const pickupLocations = FragmentReader.map(r.invsysUspsPickupLocations, FragmentReader.uspsPickupLocationFull);
        StoreHelpers.storeUspsPickupLocations(pickupLocations);
      });
    },
  ]
);

export const uspsPickupLocationSuggestion =
  Lib.promisifyQuery<Build.uspsPickupLocationSuggestionQuery.uspsPickupLocationSuggestionQuery>(
    Queries.gqlUspsPickupLocationSuggestion,
    [
      (args, p) => {
        p.then((r) => {
          const suggestion = r.invsysUspsPickupLocationSuggestion;
          State.Observe.Pickup.UspsPickupLocationSuggestion.set(suggestion);
        });
      },
    ]
  );

export const putUspsPickupLocation =
  Lib.promisifyMutation<Build.putUspsPickupLocationMutation.putUspsPickupLocationMutation>(
    Queries.gqlPutUspsPickupLocation,
    [
      (args, p) => {
        p.then((r) => {
          const pickupLocations = FragmentReader.map(
            [r.invsysPutUspsPickupLocation.data].filter(Boolean),
            FragmentReader.uspsPickupLocationFull
          );
          StoreHelpers.storeUspsPickupLocations(pickupLocations);
        });
      },
    ]
  );

export const scheduleUspsFreePickup =
  Lib.promisifyMutation<Build.scheduleUspsFreePickupMutation.scheduleUspsFreePickupMutation>(
    Queries.gqlScheduleUspsFreePickup
  );

export const uspsPickupDateOptions = Lib.promisifyQuery<Build.uspsPickupDateOptionsQuery.uspsPickupDateOptionsQuery>(
  Queries.gqlUspsPickupDateOptions,
  [
    (args, p) => {
      p.then((r) => {
        State.Observe.Pickup.UspsPickupDateOptions.set([...r.invsysUspsPickupDateOptions]);
      });
    },
  ]
);

export const validateUspsAddress = Lib.promisifyMutation<Build.validateUspsAddressMutation.validateUspsAddressMutation>(
  Queries.gqlValidateUspsAddress
);
