import * as StateTypes from 'src/state/types';
import * as Observe from 'src/util/observe';
import { UspsItem, UspsItemFormat } from 'src/state/types';

export interface SuppliesMetadataIface {
  uspsItemLabel?: {
    [UspsItem.PriorityMailShoeBox]?: 'Priority Mail Shoe Box';
    [UspsItem.PriorityMailExpressLegalFlatRateEnvelope]?: 'Priority Mail Express Legal Flat Rate Envelope';
    [UspsItem.PriorityMailBox7]?: 'Priority Mail Box - 7';
    [UspsItem.PriorityMailExpressBox1093]?: 'Priority Mail Express Box - 1093';
    [UspsItem.PriorityMailFlatRateSmallBox]?: 'Priority Mail Flat Rate Small Box';
  };
  uspsItemFormatMaxQuantity?: {
    [UspsItemFormat.PriorityMailShoeboxPack10]?: 1;
    [UspsItemFormat.PriorityMailShoeboxPack25]?: 20;
    [UspsItemFormat.PriorityMailExpressLegalFlatRateEnvelopePack10]?: 60;
    [UspsItemFormat.PriorityMailBox7Pack10]?: 1;
    [UspsItemFormat.PriorityMailBox7Pack25]?: 20;
    [UspsItemFormat.PriorityMailExpressBox1093Pack10]?: 1;
    [UspsItemFormat.PriorityMailExpressBox1093Pack25]?: 20;
    [UspsItemFormat.PriorityMailFlatRateSmallBox1to5]?: 5;
    [UspsItemFormat.PriorityMailFlatRateSmallBoxPack10]?: 1;
    [UspsItemFormat.PriorityMailFlatRateSmallBoxPack25]?: 20;
  };
  uspsItemFormatPackCount?: {
    [UspsItemFormat.PriorityMailShoeboxPack10]?: 10;
    [UspsItemFormat.PriorityMailShoeboxPack25]?: 25;
    [UspsItemFormat.PriorityMailExpressLegalFlatRateEnvelopePack10]?: 10;
    [UspsItemFormat.PriorityMailBox7Pack10]?: 10;
    [UspsItemFormat.PriorityMailBox7Pack25]?: 25;
    [UspsItemFormat.PriorityMailExpressBox1093Pack10]?: 10;
    [UspsItemFormat.PriorityMailExpressBox1093Pack25]?: 25;
    [UspsItemFormat.PriorityMailFlatRateSmallBox1to5]?: 5;
    [UspsItemFormat.PriorityMailFlatRateSmallBoxPack10]?: 10;
    [UspsItemFormat.PriorityMailFlatRateSmallBoxPack25]?: 25;
  };
  uspsItemFormatOptionsReverse?: {
    [UspsItemFormat.PriorityMailShoeboxPack10]?: 1;
    [UspsItemFormat.PriorityMailShoeboxPack25]?: 1;
    [UspsItemFormat.PriorityMailExpressLegalFlatRateEnvelopePack10]?: 2;
    [UspsItemFormat.PriorityMailBox7Pack10]?: 3;
    [UspsItemFormat.PriorityMailBox7Pack25]?: 3;
    [UspsItemFormat.PriorityMailExpressBox1093Pack10]?: 4;
    [UspsItemFormat.PriorityMailExpressBox1093Pack25]?: 4;
    [UspsItemFormat.PriorityMailFlatRateSmallBox1to5]?: 5;
    [UspsItemFormat.PriorityMailFlatRateSmallBoxPack10]?: 5;
    [UspsItemFormat.PriorityMailFlatRateSmallBoxPack25]?: 5;
  };
  uspsItemUrl?: {
    [UspsItem.PriorityMailShoeBox]?: string;
    [UspsItem.PriorityMailExpressLegalFlatRateEnvelope]?: string;
    [UspsItem.PriorityMailBox7]?: string;
    [UspsItem.PriorityMailExpressBox1093]?: string;
    [UspsItem.PriorityMailFlatRateSmallBox]?: string;
  };
  uspsItemDimensions?: {
    [UspsItem.PriorityMailShoeBox]?: {
      length?: string;
      width?: string;
      height?: string;
    };
    [UspsItem.PriorityMailExpressLegalFlatRateEnvelope]?: {
      length?: string;
      width?: string;
      height?: string;
    };
    [UspsItem.PriorityMailBox7]?: {
      length?: string;
      width?: string;
      height?: string;
    };
    [UspsItem.PriorityMailExpressBox1093]?: {
      length?: string;
      width?: string;
      height?: string;
    };
    [UspsItem.PriorityMailFlatRateSmallBox]?: {
      length?: string;
      width?: string;
      height?: string;
    };
  };
  availableUspsItemFormats?: UspsItemFormat[];
}

export const SuppliesMetadata = Observe.Value<SuppliesMetadataIface | null>(null);
export const UspsFreeSupplyOrders = Observe.LookupStoreValue<StateTypes.UspsFreeSuppliesOrderItem>();
export const UspsFreeSupplyOrdersPaginationState =
  Observe.React.WithPaginationState<StateTypes.UspsFreeSuppliesOrderItem>({
    idSelector: (_) => _.id,
    lookupStoreValue: UspsFreeSupplyOrders,
  });
export const UspsFreeSupplyOrderTimeRemaining = Observe.Value<number | null>(null);

export const SuppliesOrderPendingValue = Observe.Value<boolean>(false);
