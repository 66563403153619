import { graphql } from 'react-relay';

export const gqlRequestListingMediaGroupVariant = graphql`
  mutation requestListingMediaGroupVariantMutation(
    $listingMediaGroupId: String!
    $membershipIds: [String!]!
    $variantType: Int!
  ) {
    studioRequestListingMediaGroupVariant(
      listingMediaGroupId: $listingMediaGroupId
      membershipIds: $membershipIds
      variantType: $variantType
    ) {
      success
    }
  }
`;
