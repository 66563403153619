/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type SuperposherCurrencyCode = 'AUD' | 'CAD' | 'USD' | '%future added value';
export type recentActivityQueryVariables = {
  poshmarkUserLinkId: string;
};
export type recentActivityQueryResponse = {
  readonly superposherPoshmarkUserLink: {
    readonly id: string;
    readonly recentActivity: ReadonlyArray<
      | {
          readonly __typename: 'SuperposherShareToFollowersEvent';
          readonly at: number;
          readonly listings: ReadonlyArray<{
            readonly sourceUrl: string;
          } | null>;
        }
      | {
          readonly __typename: 'SuperposherShareToEventEvent';
          readonly at: number;
          readonly listings: ReadonlyArray<{
            readonly sourceUrl: string;
          } | null>;
          readonly events: ReadonlyArray<{
            readonly sourceUrl: string;
          } | null>;
        }
      | {
          readonly __typename: 'SuperposherTargetedFollowEvent';
          readonly at: number;
          readonly users: ReadonlyArray<{
            readonly sourceUrl: string;
          } | null>;
        }
      | {
          readonly __typename: 'SuperposherShareForShareEvent';
          readonly at: number;
          readonly listings: ReadonlyArray<{
            readonly sourceUrl: string;
          } | null>;
        }
      | {
          readonly __typename: 'SuperposherFollowForFollowEvent';
          readonly at: number;
          readonly users: ReadonlyArray<{
            readonly sourceUrl: string;
          } | null>;
        }
      | {
          readonly __typename: 'SuperposherOfferToLikersEvent';
          readonly at: number;
          readonly currencyCode: SuperposherCurrencyCode;
          readonly discountedPrice: number;
          readonly listings: ReadonlyArray<{
            readonly sourceUrl: string;
          } | null>;
        }
      | {
          readonly __typename: 'SuperposherRelistToPoshmarkEvent';
          readonly at: number;
          readonly listings: ReadonlyArray<{
            readonly sourceUrl: string;
          } | null>;
        }
      | {
          /*This will never be '%other', but we need some
            value in case none of the concrete values match.*/
          readonly __typename: '%other';
        }
    >;
  } | null;
};
export type recentActivityQuery = {
  readonly response: recentActivityQueryResponse;
  readonly variables: recentActivityQueryVariables;
};

/*
query recentActivityQuery(
  $poshmarkUserLinkId: String!
) {
  superposherPoshmarkUserLink(id: $poshmarkUserLinkId) {
    id
    recentActivity {
      __typename
      ... on SuperposherShareToFollowersEvent {
        __typename
        at
        listings {
          sourceUrl
        }
      }
      ... on SuperposherShareToEventEvent {
        __typename
        at
        listings {
          sourceUrl
        }
        events {
          sourceUrl
        }
      }
      ... on SuperposherTargetedFollowEvent {
        __typename
        at
        users {
          sourceUrl
        }
      }
      ... on SuperposherShareForShareEvent {
        __typename
        at
        listings {
          sourceUrl
        }
      }
      ... on SuperposherFollowForFollowEvent {
        __typename
        at
        users {
          sourceUrl
        }
      }
      ... on SuperposherOfferToLikersEvent {
        __typename
        at
        currencyCode
        discountedPrice
        listings {
          sourceUrl
        }
      }
      ... on SuperposherRelistToPoshmarkEvent {
        __typename
        at
        listings {
          sourceUrl
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'poshmarkUserLinkId',
      },
    ],
    v1 = [
      {
        kind: 'Variable',
        name: 'id',
        variableName: 'poshmarkUserLinkId',
      },
    ],
    v2 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null,
    },
    v3 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: '__typename',
      storageKey: null,
    },
    v4 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'at',
      storageKey: null,
    },
    v5 = [
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'sourceUrl',
        storageKey: null,
      },
    ],
    v6 = {
      alias: null,
      args: null,
      concreteType: 'SuperposherPoshmarkLookup',
      kind: 'LinkedField',
      name: 'listings',
      plural: true,
      selections: v5 /*: any*/,
      storageKey: null,
    },
    v7 = [v3 /*: any*/, v4 /*: any*/, v6 /*: any*/],
    v8 = {
      alias: null,
      args: null,
      concreteType: 'SuperposherPoshmarkLookup',
      kind: 'LinkedField',
      name: 'events',
      plural: true,
      selections: v5 /*: any*/,
      storageKey: null,
    },
    v9 = {
      alias: null,
      args: null,
      concreteType: 'SuperposherPoshmarkLookup',
      kind: 'LinkedField',
      name: 'users',
      plural: true,
      selections: v5 /*: any*/,
      storageKey: null,
    },
    v10 = [v3 /*: any*/, v4 /*: any*/, v9 /*: any*/],
    v11 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'currencyCode',
      storageKey: null,
    },
    v12 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'discountedPrice',
      storageKey: null,
    },
    v13 = [v4 /*: any*/, v6 /*: any*/],
    v14 = [v4 /*: any*/, v9 /*: any*/];
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'recentActivityQuery',
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: 'SuperposherPoshmarkUserLink',
          kind: 'LinkedField',
          name: 'superposherPoshmarkUserLink',
          plural: false,
          selections: [
            v2 /*: any*/,
            {
              alias: null,
              args: null,
              concreteType: null,
              kind: 'LinkedField',
              name: 'recentActivity',
              plural: true,
              selections: [
                {
                  kind: 'InlineFragment',
                  selections: v7 /*: any*/,
                  type: 'SuperposherShareToFollowersEvent',
                  abstractKey: null,
                },
                {
                  kind: 'InlineFragment',
                  selections: [v3 /*: any*/, v4 /*: any*/, v6 /*: any*/, v8 /*: any*/],
                  type: 'SuperposherShareToEventEvent',
                  abstractKey: null,
                },
                {
                  kind: 'InlineFragment',
                  selections: v10 /*: any*/,
                  type: 'SuperposherTargetedFollowEvent',
                  abstractKey: null,
                },
                {
                  kind: 'InlineFragment',
                  selections: v7 /*: any*/,
                  type: 'SuperposherShareForShareEvent',
                  abstractKey: null,
                },
                {
                  kind: 'InlineFragment',
                  selections: v10 /*: any*/,
                  type: 'SuperposherFollowForFollowEvent',
                  abstractKey: null,
                },
                {
                  kind: 'InlineFragment',
                  selections: [v3 /*: any*/, v4 /*: any*/, v11 /*: any*/, v12 /*: any*/, v6 /*: any*/],
                  type: 'SuperposherOfferToLikersEvent',
                  abstractKey: null,
                },
                {
                  kind: 'InlineFragment',
                  selections: v7 /*: any*/,
                  type: 'SuperposherRelistToPoshmarkEvent',
                  abstractKey: null,
                },
              ],
              storageKey: null,
            },
          ],
          storageKey: null,
        },
      ],
      type: 'Query',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'recentActivityQuery',
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: 'SuperposherPoshmarkUserLink',
          kind: 'LinkedField',
          name: 'superposherPoshmarkUserLink',
          plural: false,
          selections: [
            v2 /*: any*/,
            {
              alias: null,
              args: null,
              concreteType: null,
              kind: 'LinkedField',
              name: 'recentActivity',
              plural: true,
              selections: [
                v3 /*: any*/,
                {
                  kind: 'InlineFragment',
                  selections: v13 /*: any*/,
                  type: 'SuperposherShareToFollowersEvent',
                  abstractKey: null,
                },
                {
                  kind: 'InlineFragment',
                  selections: [v4 /*: any*/, v6 /*: any*/, v8 /*: any*/],
                  type: 'SuperposherShareToEventEvent',
                  abstractKey: null,
                },
                {
                  kind: 'InlineFragment',
                  selections: v14 /*: any*/,
                  type: 'SuperposherTargetedFollowEvent',
                  abstractKey: null,
                },
                {
                  kind: 'InlineFragment',
                  selections: v13 /*: any*/,
                  type: 'SuperposherShareForShareEvent',
                  abstractKey: null,
                },
                {
                  kind: 'InlineFragment',
                  selections: v14 /*: any*/,
                  type: 'SuperposherFollowForFollowEvent',
                  abstractKey: null,
                },
                {
                  kind: 'InlineFragment',
                  selections: [v4 /*: any*/, v11 /*: any*/, v12 /*: any*/, v6 /*: any*/],
                  type: 'SuperposherOfferToLikersEvent',
                  abstractKey: null,
                },
                {
                  kind: 'InlineFragment',
                  selections: v13 /*: any*/,
                  type: 'SuperposherRelistToPoshmarkEvent',
                  abstractKey: null,
                },
              ],
              storageKey: null,
            },
          ],
          storageKey: null,
        },
      ],
    },
    params: {
      cacheID: 'b76840261e7932bbd826a1ca058f6c56',
      id: null,
      metadata: {},
      name: 'recentActivityQuery',
      operationKind: 'query',
      text: 'query recentActivityQuery(\n  $poshmarkUserLinkId: String!\n) {\n  superposherPoshmarkUserLink(id: $poshmarkUserLinkId) {\n    id\n    recentActivity {\n      __typename\n      ... on SuperposherShareToFollowersEvent {\n        __typename\n        at\n        listings {\n          sourceUrl\n        }\n      }\n      ... on SuperposherShareToEventEvent {\n        __typename\n        at\n        listings {\n          sourceUrl\n        }\n        events {\n          sourceUrl\n        }\n      }\n      ... on SuperposherTargetedFollowEvent {\n        __typename\n        at\n        users {\n          sourceUrl\n        }\n      }\n      ... on SuperposherShareForShareEvent {\n        __typename\n        at\n        listings {\n          sourceUrl\n        }\n      }\n      ... on SuperposherFollowForFollowEvent {\n        __typename\n        at\n        users {\n          sourceUrl\n        }\n      }\n      ... on SuperposherOfferToLikersEvent {\n        __typename\n        at\n        currencyCode\n        discountedPrice\n        listings {\n          sourceUrl\n        }\n      }\n      ... on SuperposherRelistToPoshmarkEvent {\n        __typename\n        at\n        listings {\n          sourceUrl\n        }\n      }\n    }\n  }\n}\n',
    },
  };
})();
(node as any).hash = '2cbc1ec4998d7a502d1ee648403064c4';
export default node;
