import { graphql } from 'react-relay';

export const gqlAddAddress = graphql`
  mutation addAddressMutation(
    $streetAddress: String
    $unit: String
    $locality: String
    $subdivision: String
    $postalCode: String
    $countryCode: String
    $makeDefaultShipping: Boolean
    $makeDefaultPickup: Boolean
  ) {
    invsysAddAddress(
      streetAddress: $streetAddress
      unit: $unit
      locality: $locality
      subdivision: $subdivision
      postalCode: $postalCode
      countryCode: $countryCode
      makeDefaultShipping: $makeDefaultShipping
      makeDefaultPickup: $makeDefaultPickup
    ) {
      success
      address {
        ...addressFragment_full
      }
    }
  }
`;
