import * as StateTypes from 'src/state/types';
import * as Observe from 'src/util/observe';

export const MediaValue = Observe.LookupStoreValue<StateTypes.StudioMediaType>();
export const ListingMediaGroupMembershipsValue =
  Observe.LookupStoreValue<StateTypes.StudioListingMediaGroupMembershipType>();
export const ListingToListingMediaGroupValue = Observe.LookupStoreValue<string>();
export const ListingMediaGroupMediaIdsValue = Observe.LookupStoreValue<string[]>();
export const ListingMediaGroupsValue = Observe.LookupStoreValue<StateTypes.StudioListingMediaGroupType>();
export const ListingMediaGroupsPaginationState =
  Observe.React.WithPaginationState<StateTypes.StudioListingMediaGroupType>({
    idSelector: (_) => _.id,
    lookupStoreValue: ListingMediaGroupsValue,
  });
export const SelectedListingMediaGroupIdValue = Observe.Value<string | null>(null);
export const SelectedListingMediaGroupMediaIdsValue = Observe.Value<string[]>([]);

export type SelectedListingMediaGroupActionType = 'remove' | 'cutout';
export const SelectedListingMediaGroupAction = Observe.Value<SelectedListingMediaGroupActionType>('remove');

export const MediaViewerItemIndexValue = Observe.Value<number>(0);
export const MediaViewerMediaUrlsValue = Observe.Value<string[]>([]);
