/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type startTrialMutationVariables = {};
export type startTrialMutationResponse = {
  readonly invsysStartTrial: {
    readonly success: boolean;
  };
};
export type startTrialMutation = {
  readonly response: startTrialMutationResponse;
  readonly variables: startTrialMutationVariables;
};

/*
mutation startTrialMutation {
  invsysStartTrial {
    success
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = [
    {
      alias: null,
      args: null,
      concreteType: 'InvsysStartTrialResponse',
      kind: 'LinkedField',
      name: 'invsysStartTrial',
      plural: false,
      selections: [
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'success',
          storageKey: null,
        },
      ],
      storageKey: null,
    },
  ];
  return {
    fragment: {
      argumentDefinitions: [],
      kind: 'Fragment',
      metadata: null,
      name: 'startTrialMutation',
      selections: v0 /*: any*/,
      type: 'Mutation',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [],
      kind: 'Operation',
      name: 'startTrialMutation',
      selections: v0 /*: any*/,
    },
    params: {
      cacheID: 'aa0d129528373e4f62aed4bbdf989608',
      id: null,
      metadata: {},
      name: 'startTrialMutation',
      operationKind: 'mutation',
      text: 'mutation startTrialMutation {\n  invsysStartTrial {\n    success\n  }\n}\n',
    },
  };
})();
(node as any).hash = '9546af5b5f5cac6d220f96b9952ec7cd';
export default node;
