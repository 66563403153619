import * as Observe from 'src/util/observe';
import * as Network from 'src/clients/Network';

export const AsyncStateValue = Observe.AsyncStateValue();

export const Value = Observe.Value<
  Network.gql.build.poshmarkUserLinksQuery.poshmarkUserLinksQueryResponse['superposherPoshmarkUserLinks']
>([]);
export const ValueFallback = Observe.React.WithFallback(Value);

export const SelectedIdValue = Observe.DerivedValue<null | string>(() => {
  return Value.get()[0]?.id ?? null;
}, [Value]);

export const SelectedValue = Observe.DerivedValue(() => {
  const selectedId = SelectedIdValue.get();
  if (selectedId != null) {
    const userLinks = Value.get();
    return userLinks.find((_) => _.id === selectedId);
  } else {
    return null;
  }
}, [Value, SelectedIdValue]);

export const SelectedFallback = Observe.React.WithFallback(SelectedValue);
