/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from 'relay-runtime';
export type uspsPickupLocationFragment_full = {
  readonly id: string;
  readonly addressId: string;
  readonly pickupInstruction: number;
  readonly instructionComments: string | null;
  readonly firstName: string | null;
  readonly lastName: string | null;
  readonly email: string;
  readonly e164: string | null;
  readonly isDefaultPickupLocation: boolean;
  readonly address: {
    readonly ' $fragmentRefs': FragmentRefs<'addressFragment_full'>;
  };
  readonly ' $refType': 'uspsPickupLocationFragment_full';
};
export type uspsPickupLocationFragment_full$data = uspsPickupLocationFragment_full;
export type uspsPickupLocationFragment_full$key = {
  readonly ' $data'?: uspsPickupLocationFragment_full$data;
  readonly ' $fragmentRefs': FragmentRefs<'uspsPickupLocationFragment_full'>;
};

const node: ReaderInlineDataFragment = {
  kind: 'InlineDataFragment',
  name: 'uspsPickupLocationFragment_full',
};
(node as any).hash = '7b17394e7f243a153e13a7c2826c9ee2';
export default node;
