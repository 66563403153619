import * as StateTypes from 'src/state/types';
import * as Observe from 'src/util/observe';

export const OrdersValue = Observe.LookupStoreValue<StateTypes.OrderType>();

export const OrdersPaginationState = Observe.React.WithPaginationState<StateTypes.OrderType>({
  idSelector: (_) => _.id,
  lookupStoreValue: OrdersValue,
});

export const ActionableOrdersPaginationState = Observe.React.WithPaginationState<StateTypes.OrderType>({
  idSelector: (_) => _.id,
  lookupStoreValue: OrdersValue,
});

export const LastSalesSyncCompletedOverallAt = Observe.Value<number | null>(null);
