import { graphql } from 'react-relay';

export const gqlPutListing = graphql`
  mutation putListingMutation(
    $id: String
    $title: InvsysStringPutObject
    $description: InvsysStringPutObject
    $skus: InvsysArray_StringRequired_RequiredPutObject
    $tags: InvsysArray_StringRequired_RequiredPutObject
    $notes: InvsysArray_StringRequired_RequiredPutObject
    $mediaIds: InvsysArray_StringRequired_RequiredPutObject
    $listingSku: InvsysStringPutObject
    $price: InvsysFloatPutObject
    $currencyCode: InvsysListingCurrencyCodeTypePutObject
    $weight: InvsysFloatPutObject
    $width: InvsysFloatPutObject
    $length: InvsysFloatPutObject
    $height: InvsysFloatPutObject
    $archived: InvsysBooleanPutObject
    $userDeleted: InvsysBooleanPutObject
    $targetInstitutions: InvsysArray_IntRequired_PutObject
    $searchTags: InvsysArray_StringRequired_RequiredPutObject
    $itemOperations: InvsysArray_PutItemOperationRequired_RequiredPutObject
  ) {
    invsysPutListing(
      id: $id
      title: $title
      description: $description
      skus: $skus
      tags: $tags
      notes: $notes
      mediaIds: $mediaIds
      listingSku: $listingSku
      price: $price
      currencyCode: $currencyCode
      weight: $weight
      width: $width
      length: $length
      height: $height
      archived: $archived
      userDeleted: $userDeleted
      targetInstitutions: $targetInstitutions
      searchTags: $searchTags
      itemOperations: $itemOperations
    ) {
      success
      data {
        ...listingFragment_full
      }
    }
  }
`;
