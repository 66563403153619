import * as Observe from 'src/util/observe';
import * as StateTypes from 'src/state/types';
import { Platform } from 'react-native';

interface NuxQAIface {
  q?: string;
  a?: {
    label: string;
    value: number;
  }[];
}

interface AsyncValueDataType {
  ['2021-06-27-listing']?: boolean;
  ['2021-06-27-generic-auth']?: boolean;
  ['2021-06-29-beta-listing-business-ids']?: string[];
  ['2021-07-23-oneshop-waitlist-on']?: boolean;
  ['2021-08-07-goal-setting']?: boolean;
  ['2021-08-07-beta-goal-setting-business-ids']?: string[];
  ['2021-08-08-oneshop-waitlist-on']?: boolean;
  ['2021-08-08-oneshop-waitlist-on']?: boolean;
  ['2021-09-18-social']?: boolean;
  ['2021-09-18-social-listing-business-ids']?: string[];
  ['2021-10-24-social-post-media-screen-on']?: boolean;
  ['2021-10-26-social-report-post-screen-on']?: boolean;
  ['2021-10-28-poshmark-importer']?: boolean;
  ['2021-10-28-poshmark-importer-business-ids']?: string[];
  ['2021-10-29-active-sellers-screen-on']?: string[];
  ['2021-11-04-active-listing-institutions']?: StateTypes.ListingSupportedEnum[];
  ['2021-11-13-listing-v4-business-ids']?: string[];
  ['2021-11-13-listing-v4']?: boolean;
  ['2021-11-18-profile-link-blocks']?: boolean;
  ['2021-11-24-discover-tab']?: boolean;
  ['2021-11-24-group-creation-waitlist-on']?: boolean;
  ['2021-11-24-group-creation-business-ids']?: string[];
  ['2021-12-04-poshmark-offer-v2']?: boolean;
  ['2021-12-04-poshmark-schedule-bulk-offers']?: boolean;
  ['2021-12-05-poshmark-shipping-discount-labels']?: {
    [tld: string]: {
      [shippingDiscountId: string]: string;
    };
  };
  ['2021-12-14-groups-on']?: boolean;
  ['2021-12-15-pricing-monthly-only']?: boolean;
  ['2022-01-13-conversations']?: boolean;
  ['2022-01-19-sales-orders-web']?: boolean;
  ['2022-01-24-ebay-listing-business-ids']?: string[];
  ['2022-02-08-order-free-usps-supplies-web']?: boolean;
  ['2022-02-08-request-free-usps-pickup-web']?: boolean;
  ['2022-02-10-grailed-listing-business-ids']?: string[];
  ['2022-02-28-active-importing-institutions']?: StateTypes.InstitutionEnum[];
  ['2022-04-03-tab-bar-no-label']?: boolean;
  ['2022-04-10-title-keyword-relevant-category-suggestion']?: {
    [keyword: string]: {
      [variantLabel: string]: string;
    };
  };
  ['2022-04-28-package-dimension-config']?: {
    [id: string]: {
      dimensions: {
        length: number;
        height: number;
        width: number;
      };
    };
  };
  ['2022-05-02-closet-ordering-business-ids']?: string[];
  ['2022-05-02-closet-ordering-on']?: boolean;
  ['2022-05-07-poshmark-offer-v2-business-ids']?: string[];
  ['2022-06-15-duplicate-listing']?: boolean;
  ['2022-05-18-depop-refreshing-business-ids']?: string[];
  ['2022-05-18-depop-refreshing-on']?: boolean;
  ['2022-06-15-schedule-listing']?: boolean;
  ['2022-06-06-studio-business-ids']?: string[];
  ['2022-06-06-studio']?: boolean;
  ['2022-06-08-price-drop-event-time-offsets']?: {
    start?: number;
    end?: number;
    fromEnd?: number;
    todayStart?: number;
  };
  ['2022-06-09-listing-v4-final-details-v2']?: boolean;
  ['2022-06-09-listing-v4-final-details-v2-business-ids']?: string[];
  ['2022-06-10-price-drop-event-max-listings']?: number;
  ['2022-06-11-event']?: {
    title?: string;
    description?: string;
    actionLabel?: string;
    onPress?: {
      url?: string;
    };
  };
  ['2022-06-11-shipping-labels-business-ids']?: string[];
  ['2022-06-11-shipping-labels']?: boolean;
  ['2022-06-13-shipping-labels-supported-institutions']?: number[];
  ['2022-06-13-shipping-labels-supported-formats']?: {
    label: string;
    value: number;
  }[];
  ['2022-06-21-signup-attribution-step']?: NuxQAIface;
  ['2022-06-21-primary-institution-step']?: NuxQAIface;
  ['2022-06-21-primary-listing-form-step']?: NuxQAIface;
  ['2022-06-21-anticipated-feature-step']?: NuxQAIface;
  ['2022-06-21-experience-level-step']?: NuxQAIface;
  ['2022-06-21-competitors-step']?: NuxQAIface;
  ['2022-06-22-poshmark-shipping-discount-labels']?: {
    [countryCode: string]: {
      [shippingDiscountId: string]: string;
    };
  };
  ['2022-06-26-listing-es']?: boolean;
  ['2022-06-26-listing-es-business-ids']?: string[];
  ['2022-06-28-listings-relist']?: boolean;
  ['2022-06-28-listings-relist-business-ids']?: string[];
  ['2022-07-05-listing-v4-final-details-v3']?: boolean;
  ['2022-07-05-listing-v4-final-details-v3-business-ids']?: string[];
  ['2022-07-07-crosslist-quick-action-disabled']?: boolean;
  ['2022-07-08-listing-optional-fields']?: boolean;
  ['2022-07-22-money']?: boolean;
  ['2022-07-22-money-business-ids']?: string[];
  ['2022-07-31-photo-upload-concurrency']?: number;
  ['2022-07-31-photo-process-concurrency']?: number;
  ['2022-08-01-shipping-choices']?: boolean;
  ['2022-08-01-shipping-choices-business-ids']?: string[];
  ['2022-08-01-shipping-choices-instituion-options']?: {
    poshmark?: {
      [countryCode: string]: {
        [shippingDiscountId: string]: string;
      };
    };
    mercari?: {
      type: {
        value: number;
        display: string;
        details: string;
      }[];
      dependencies: {
        [field: string]: number[];
      };
    };
    tradesy?: {
      type: {
        value: number;
        display: string;
        details: string;
      }[];
      dependencies: {
        [field: string]: number[];
      };
    };
    depop?: {
      type: {
        value: number;
        display: string;
        details: string;
      }[];
      dependencies: {
        [field: string]: number[];
      };
    };
    grailed?: {
      type: {
        value: number;
        display: string;
        details: string;
      }[];
      dependencies: {
        [field: string]: number[];
      };
    };
    ebay?: {
      type: {
        value: string;
        display: string;
      }[];
      dependencies: {
        [field: string]: string[];
      };
    };
  };
  ['2022-08-02-institution-shipping-support-links']?: {
    [institution: string]: string;
  };
  ['2022-08-05-rn-fetch-blob-photo-upload']?: boolean;
  ['2022-08-07-intro-modals']?: boolean;
  ['2022-08-08-top-level-size']?: boolean;
  ['2022-08-08-top-level-size-business-ids']?: string[];
  ['2022-08-08-listing-reminders-and-streaks']?: boolean;
  ['2022-08-08-listing-reminders-and-streaks-business-ids']?: string[];
  ['2022-08-14-bookkeeping-waitlist-link']?: string;
  ['2022-09-05-today-listing-actions-inline']?: boolean;
  ['2022-09-05-b-url']?: string;
  ['2022-09-13-importer-v2']?: boolean;
  ['2022-09-13-importer-v2-business-ids']?: string[];
  ['2022-09-13-importer-v2-feed-types']?: {
    [institution: string]: {
      label: string;
      value: number;
    }[];
  };
  ['2022-09-15-sites-business-ids']?: string[];
  ['2022-09-15-sites']?: boolean;
  ['2022-09-15-today-business-ids']?: string[];
  ['2022-09-15-today']?: boolean;
  ['2022-09-15-money-business-ids']?: string[];
  ['2022-09-15-money']?: boolean;
  ['2022-10-03-ebay-shipping-policies']?: boolean;
  ['2022-10-03-ebay-shipping-policies-business-ids']?: string[];
  ['2022-10-03-ebay-shipping-options']?: boolean;
  ['2022-10-03-ebay-shipping-options-business-ids']?: string[];
  ['2022-09-05-custom-camera-roll-picker-platforms']?: (typeof Platform.OS)[];
  ['2022-10-05-media-upload-v2-retry-kill-switch']?: boolean;
  ['2022-10-07-recent-import-expiry-threshold']?: number;
  ['2022-10-07-studio-tab']?: boolean;
  ['2022-10-07-studio-tab-business-ids']?: string[];
  ['2022-10-10-media-upload-v2']?: boolean;
  ['2022-10-10-media-upload-v2-business-ids']?: string[];
  ['2022-10-11-primary-ext-jpg']?: boolean;
  ['2022-10-12-packing-slip']?: boolean;
  ['2022-10-12-packing-slip-business-ids']?: string[];
  ['2022-11-14-final-details-banner']?: {
    message: string;
    institutions?: StateTypes.ListingSupportedEnum[];
  };
  ['2022-11-14-usps-supplies-unavailable-message']?: string;
  ['2022-11-14-usps-pickup-unavailable-message']?: string;
  ['2022-11-26-studio-tab-disabled']?: boolean;
  ['2022-11-29-ebay-optional-fields']?: boolean;
  ['2022-11-29-ebay-optional-fields-business-ids']?: string[];
  ['2022-11-29-ebay-shipping-labels']?: boolean;
  ['2022-12-08-porter-domains']?: boolean;
  ['2022-12-13-ebay-return-policies']?: boolean;
  ['2022-12-13-ebay-return-policies-business-ids']?: string[];
  ['2022-12-13-reset-oneshop-listings']?: boolean;
  ['2022-12-14-ebay-promote-listings']?: boolean;
  ['2022-12-14-samsung-textinput']?: boolean;
  ['2022-12-15-image-compression-quality']?: number;
  ['2022-12-16-ebay-manage-business-policies-link']?: string;
  ['2022-12-18-show-assistant-upsell']?: boolean;
  ['2022-12-18-show-assistant-upsell-business-ids']?: string[];
  ['2022-12-20-banking-business-ids']?: string[];
  ['2022-12-20-banking']?: boolean;
  ['2022-12-28-grailed-linking']?: boolean;
  ['2022-12-28-grailed-linking-business-ids']?: string[];
}

export const Value = Observe.Value<AsyncValueDataType>({});
