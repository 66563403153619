import * as Observe from 'src/state/lib/observe';
import * as StateTypes from 'src/state/types';

export const SelectedGoalTypeValue = Observe.Value<StateTypes.SalesGoalType['goalType']>('NEW_ITEMS_LISTED');

export interface EntityAsyncValueDataType<E> {
  [id: string]: E | null;
}

export const SalesGoalsAsyncValue = Observe.AsyncValue<EntityAsyncValueDataType<StateTypes.SalesGoalType>>({
  merge: (prev, next) => {
    if (prev == null) {
      return next;
    } else {
      return { ...prev, ...next };
    }
  },
});

export const SalesGoalsValue = Observe.DerivedValue<EntityAsyncValueDataType<StateTypes.SalesGoalType>>(() => {
  return SalesGoalsAsyncValue.getValueWithFallback() ?? {};
}, [SalesGoalsAsyncValue.value]);

export const SalesGoalsFallback = Observe.WithFallback(SalesGoalsValue);

export const SalesGoalHistoryAsyncValue = Observe.AsyncValue<EntityAsyncValueDataType<StateTypes.SalesGoalHistoryType>>(
  {
    merge: (prev, next) => {
      if (prev == null) {
        return next;
      } else {
        return { ...prev, ...next };
      }
    },
  }
);

export const SalesGoalHistoryValue = Observe.DerivedValue<
  EntityAsyncValueDataType<StateTypes.SalesGoalHistoryType>
>(() => {
  return SalesGoalHistoryAsyncValue.getValueWithFallback() ?? {};
}, [SalesGoalHistoryAsyncValue.value]);

export const SalesGoalHistoryFallback = Observe.WithFallback(SalesGoalHistoryValue);

export const SelectedGoalIdValue = Observe.Value<string | null>(null);
export const SelectedFrequencyValue = Observe.Value<StateTypes.SalesFrequencyType | null>(null);

export const SelectedGoalValue = Observe.DerivedValue<StateTypes.SalesGoalType | null>(() => {
  const id = SelectedGoalIdValue.get();
  if (id != null) {
    const salesGoalEntities = SalesGoalsAsyncValue.getValueWithFallback() ?? {};
    return salesGoalEntities[id] ?? null;
  } else {
    return null;
  }
}, [SelectedGoalIdValue, SalesGoalsAsyncValue.value]);

export const SelectedGoalFallback = Observe.WithFallback(SelectedGoalValue);
