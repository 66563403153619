/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type scheduleListMutationVariables = {
  listingId: string;
  linkId: string;
  at?: number | null;
};
export type scheduleListMutationResponse = {
  readonly invsysScheduleList: {
    readonly success: boolean;
    readonly validationErrors: ReadonlyArray<{
      readonly errorId: number;
      readonly entityName: string | null;
      readonly entityId: string | null;
      readonly fieldName: string | null;
      readonly error: string | null;
      readonly message: string;
    }>;
  };
};
export type scheduleListMutation = {
  readonly response: scheduleListMutationResponse;
  readonly variables: scheduleListMutationVariables;
};

/*
mutation scheduleListMutation(
  $listingId: String!
  $linkId: String!
  $at: Float
) {
  invsysScheduleList(listingId: $listingId, linkId: $linkId, at: $at) {
    success
    validationErrors {
      errorId: id
      entityName
      entityId
      fieldName
      error
      message
    }
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'at',
    },
    v1 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'linkId',
    },
    v2 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'listingId',
    },
    v3 = [
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'at',
            variableName: 'at',
          },
          {
            kind: 'Variable',
            name: 'linkId',
            variableName: 'linkId',
          },
          {
            kind: 'Variable',
            name: 'listingId',
            variableName: 'listingId',
          },
        ],
        concreteType: 'InvsysListingScheduleListResponse',
        kind: 'LinkedField',
        name: 'invsysScheduleList',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'success',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            concreteType: 'InvsysListingValidationErrorType',
            kind: 'LinkedField',
            name: 'validationErrors',
            plural: true,
            selections: [
              {
                alias: 'errorId',
                args: null,
                kind: 'ScalarField',
                name: 'id',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'entityName',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'entityId',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'fieldName',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'error',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'message',
                storageKey: null,
              },
            ],
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: [v0 /*: any*/, v1 /*: any*/, v2 /*: any*/],
      kind: 'Fragment',
      metadata: null,
      name: 'scheduleListMutation',
      selections: v3 /*: any*/,
      type: 'Mutation',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [v2 /*: any*/, v1 /*: any*/, v0 /*: any*/],
      kind: 'Operation',
      name: 'scheduleListMutation',
      selections: v3 /*: any*/,
    },
    params: {
      cacheID: '5f87bd2556f49d58e12dbb60dff2f215',
      id: null,
      metadata: {},
      name: 'scheduleListMutation',
      operationKind: 'mutation',
      text: 'mutation scheduleListMutation(\n  $listingId: String!\n  $linkId: String!\n  $at: Float\n) {\n  invsysScheduleList(listingId: $listingId, linkId: $linkId, at: $at) {\n    success\n    validationErrors {\n      errorId: id\n      entityName\n      entityId\n      fieldName\n      error\n      message\n    }\n  }\n}\n',
    },
  };
})();
(node as any).hash = 'd38e5c4b69af783aa01ff86341840103';
export default node;
