import * as Build from 'src/clients/Network/gql/build';
import * as Lib from 'src/clients/Network/gql/lib';
import * as State from 'src/state';
import * as Queries from 'src/clients/Network/gql/queries';

export const updateListingDescription =
  Lib.promisifyMutation<Build.invsysUpdateListingDescriptionMutation.invsysUpdateListingDescriptionMutation>(
    Queries.gqlInvsysUpdateListingDescription,
    [
      (args, p) => {
        State.Observe.Listings.UpdateListingDescriptionAsyncValue.subscribe(p);
        p.then((r) => {
          if (r.invsysUpdateListingDescription.success) {
            State.Observe.Listings.Value.transform((current) => {
              if (current[args.id] == null) {
                return current;
              }
              return {
                ...current,
                [args.id]: {
                  ...current[args.id],
                  description: args.description,
                },
              };
            });
          }
        });
      },
    ]
  );

export const viewListingOnMobile =
  Lib.promisifyMutation<Build.invsysViewListingOnMobileMutation.invsysViewListingOnMobileMutation>(
    Queries.gqlInvsysViewListingOnMobile
  );

export const invsysListingAttributeSuggestions =
  Lib.promisifyQuery<Build.invsysListingAttributeSuggestionsQuery.invsysListingAttributeSuggestionsQuery>(
    Queries.gqlInvsysListingAttributeSuggestions
  );

export const getAppCriticalMetadata =
  Lib.promisifyQuery<Build.appListingCriticalMetadataQuery.appListingCriticalMetadataQuery>(
    Queries.gqlAppListingCriticalMetadata,
    [
      (args, p) => {
        State.Observe.Listings.ListingAppCriticalMetadataAsyncValue.subscribe(p);
        p.then((_) => {
          State.Observe.Listings.ListingFeatureSettingsValue.set(_.invsysListingFeatureSettings);
          State.Observe.Listings.CrosslistStreakTimePointsValue.set(Array.from(_.invsysCrosslistStreakTimePoints));
          State.Observe.Listings.ListingRemindersSettingsValue.set(_.invsysListingReminderSettings);
          State.Observe.Listings.ImportInstitutionListingsRequestHistoryValue.set(
            Array.from(_.invsysImportInstitutionListingsRequestHistory)
          );
          State.Observe.InstitutionLinks.EbayProfileValue.set(_.ebayProfile);
        });
      },
    ]
  );

export const putFeatureSettings =
  Lib.promisifyMutation<Build.putListingFeatureSettingsMutation.putListingFeatureSettingsMutation>(
    Queries.gqlPutListingFeatureSettings
  );

export const validateListing = Lib.promisifyQuery<Build.validateListingQuery.validateListingQuery>(
  Queries.gqlValidateListing
);

export const marketplaceListing = Lib.promisifyQuery<Build.marketplaceListingQuery.marketplaceListingQuery>(
  Queries.gqlMarketplaceListing,
  [
    (args, p) => {
      p.then((_) => {
        State.Observe.Listings.SelectedDepopListingValue.set(_.invsysMarketplaceListings?.depopListing ?? null);
        State.Observe.Listings.SelectedEbayListingValue.set(_.invsysMarketplaceListings?.ebayListing ?? null);
        State.Observe.Listings.SelectedGrailedListingValue.set(_.invsysMarketplaceListings?.grailedListing ?? null);
        State.Observe.Listings.SelectedMercariListingValue.set(_.invsysMarketplaceListings?.mercariListing ?? null);
        State.Observe.Listings.SelectedPoshmarkListingValue.set(_.invsysMarketplaceListings?.poshmarkListing ?? null);
        State.Observe.Listings.SelectedTradesyListingValue.set(_.invsysMarketplaceListings?.tradesyListing ?? null);
        State.Observe.Listings.SelectedPoshmarkValidateListingValue.set(
          _.invsysMarketplaceListings?.poshmarkListingValidation ?? null
        );
        State.Observe.Listings.SelectedMercariValidateListingValue.set(
          _.invsysMarketplaceListings?.mercariListingValidation ?? null
        );
        State.Observe.Listings.SelectedDepopValidateListingValue.set(
          _.invsysMarketplaceListings?.depopListingValidation ?? null
        );
        State.Observe.Listings.SelectedTradesyValidateListingValue.set(
          _.invsysMarketplaceListings?.tradesyListingValidation ?? null
        );
        State.Observe.Listings.SelectedGrailedValidateListingValue.set(
          _.invsysMarketplaceListings?.grailedListingValidation ?? null
        );
        State.Observe.Listings.SelectedEbayValidateListingValue.set(
          _.invsysMarketplaceListings?.ebayListingValidation ?? null
        );
      });
    },
  ]
);
