/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type importInstitutionListingsMutationVariables = {
  institution: number;
};
export type importInstitutionListingsMutationResponse = {
  readonly invsysImportInstitutionListings: {
    readonly success: boolean;
  };
};
export type importInstitutionListingsMutation = {
  readonly response: importInstitutionListingsMutationResponse;
  readonly variables: importInstitutionListingsMutationVariables;
};

/*
mutation importInstitutionListingsMutation(
  $institution: Int!
) {
  invsysImportInstitutionListings(institution: $institution) {
    success
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'institution',
      },
    ],
    v1 = [
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'institution',
            variableName: 'institution',
          },
        ],
        concreteType: 'InvsysImportInstitutionListingsResponse',
        kind: 'LinkedField',
        name: 'invsysImportInstitutionListings',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'success',
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'importInstitutionListingsMutation',
      selections: v1 /*: any*/,
      type: 'Mutation',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'importInstitutionListingsMutation',
      selections: v1 /*: any*/,
    },
    params: {
      cacheID: '1a46415749c2c88179dd5c82922d17cc',
      id: null,
      metadata: {},
      name: 'importInstitutionListingsMutation',
      operationKind: 'mutation',
      text: 'mutation importInstitutionListingsMutation(\n  $institution: Int!\n) {\n  invsysImportInstitutionListings(institution: $institution) {\n    success\n  }\n}\n',
    },
  };
})();
(node as any).hash = 'e1913a631ecdeb4657afc67e407f8b7b';
export default node;
