import { graphql } from 'react-relay';

export const gqlInvsysStripePaymentMethodByCustomerDefaultSource = graphql`
  query invsysStripePaymentMethodByCustomerDefaultSourceQuery {
    invsysStripePaymentMethodByCustomerDefaultSource {
      stripeId
      createdAt
      cardExpMonth
      cardExpYear
      cardLast4
    }
  }
`;
