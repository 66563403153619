/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type validateUspsAddressMutationVariables = {
  streetAddress?: string | null;
  unit?: string | null;
  locality?: string | null;
  subdivision?: string | null;
  postalCode?: string | null;
  countryCode?: string | null;
};
export type validateUspsAddressMutationResponse = {
  readonly invsysValidateUspsAddress: {
    readonly success: boolean;
    readonly address: {
      readonly streetAddress: string;
      readonly unit: string | null;
      readonly locality: string;
      readonly subdivision: string;
      readonly postalCode: string;
      readonly countryCode: string;
    } | null;
    readonly errorMessage: string | null;
  };
};
export type validateUspsAddressMutation = {
  readonly response: validateUspsAddressMutationResponse;
  readonly variables: validateUspsAddressMutationVariables;
};

/*
mutation validateUspsAddressMutation(
  $streetAddress: String
  $unit: String
  $locality: String
  $subdivision: String
  $postalCode: String
  $countryCode: String
) {
  invsysValidateUspsAddress(streetAddress: $streetAddress, unit: $unit, locality: $locality, subdivision: $subdivision, postalCode: $postalCode, countryCode: $countryCode) {
    success
    address {
      streetAddress
      unit
      locality
      subdivision
      postalCode
      countryCode
    }
    errorMessage
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'countryCode',
    },
    v1 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'locality',
    },
    v2 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'postalCode',
    },
    v3 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'streetAddress',
    },
    v4 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'subdivision',
    },
    v5 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'unit',
    },
    v6 = [
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'countryCode',
            variableName: 'countryCode',
          },
          {
            kind: 'Variable',
            name: 'locality',
            variableName: 'locality',
          },
          {
            kind: 'Variable',
            name: 'postalCode',
            variableName: 'postalCode',
          },
          {
            kind: 'Variable',
            name: 'streetAddress',
            variableName: 'streetAddress',
          },
          {
            kind: 'Variable',
            name: 'subdivision',
            variableName: 'subdivision',
          },
          {
            kind: 'Variable',
            name: 'unit',
            variableName: 'unit',
          },
        ],
        concreteType: 'InvsysValidateUspsAddressResponse',
        kind: 'LinkedField',
        name: 'invsysValidateUspsAddress',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'success',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            concreteType: 'InvsysRawUspsAddress',
            kind: 'LinkedField',
            name: 'address',
            plural: false,
            selections: [
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'streetAddress',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'unit',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'locality',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'subdivision',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'postalCode',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'countryCode',
                storageKey: null,
              },
            ],
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'errorMessage',
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: [v0 /*: any*/, v1 /*: any*/, v2 /*: any*/, v3 /*: any*/, v4 /*: any*/, v5 /*: any*/],
      kind: 'Fragment',
      metadata: null,
      name: 'validateUspsAddressMutation',
      selections: v6 /*: any*/,
      type: 'Mutation',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [v3 /*: any*/, v5 /*: any*/, v1 /*: any*/, v4 /*: any*/, v2 /*: any*/, v0 /*: any*/],
      kind: 'Operation',
      name: 'validateUspsAddressMutation',
      selections: v6 /*: any*/,
    },
    params: {
      cacheID: '2eecccd914c1d688aaabb44c7e2bb93a',
      id: null,
      metadata: {},
      name: 'validateUspsAddressMutation',
      operationKind: 'mutation',
      text: 'mutation validateUspsAddressMutation(\n  $streetAddress: String\n  $unit: String\n  $locality: String\n  $subdivision: String\n  $postalCode: String\n  $countryCode: String\n) {\n  invsysValidateUspsAddress(streetAddress: $streetAddress, unit: $unit, locality: $locality, subdivision: $subdivision, postalCode: $postalCode, countryCode: $countryCode) {\n    success\n    address {\n      streetAddress\n      unit\n      locality\n      subdivision\n      postalCode\n      countryCode\n    }\n    errorMessage\n  }\n}\n',
    },
  };
})();
(node as any).hash = '44207cdc5d363ee1756e053b4995ead2';
export default node;
