/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type poshmarkBotRecentRelistActivityQueryVariables = {};
export type poshmarkBotRecentRelistActivityQueryResponse = {
  readonly poshmarkBotRecentRelistActivity: ReadonlyArray<{
    readonly at: number;
    readonly listingId: string;
    readonly listing: {
      readonly id: string;
      readonly url: string | null;
      readonly thumbnail: string | null;
      readonly title: string;
      readonly currentPrice: number | null;
      readonly deleted: boolean;
      readonly sold: boolean;
    } | null;
    readonly newListingId: string | null;
    readonly newListing: {
      readonly id: string;
      readonly url: string | null;
      readonly thumbnail: string | null;
      readonly title: string;
      readonly currentPrice: number | null;
      readonly deleted: boolean;
      readonly sold: boolean;
    } | null;
    readonly success: boolean;
    readonly error: number | null;
  }>;
};
export type poshmarkBotRecentRelistActivityQuery = {
  readonly response: poshmarkBotRecentRelistActivityQueryResponse;
  readonly variables: poshmarkBotRecentRelistActivityQueryVariables;
};

/*
query poshmarkBotRecentRelistActivityQuery {
  poshmarkBotRecentRelistActivity {
    at
    listingId
    listing {
      id
      url
      thumbnail
      title
      currentPrice
      deleted
      sold
    }
    newListingId
    newListing {
      id
      url
      thumbnail
      title
      currentPrice
      deleted
      sold
    }
    success
    error
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'id',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'url',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'thumbnail',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'title',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'currentPrice',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'deleted',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'sold',
        storageKey: null,
      },
    ],
    v1 = [
      {
        alias: null,
        args: null,
        concreteType: 'PoshmarkBotRelistAttemptType',
        kind: 'LinkedField',
        name: 'poshmarkBotRecentRelistActivity',
        plural: true,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'at',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'listingId',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            concreteType: 'PoshmarkBotListing',
            kind: 'LinkedField',
            name: 'listing',
            plural: false,
            selections: v0 /*: any*/,
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'newListingId',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            concreteType: 'PoshmarkBotListing',
            kind: 'LinkedField',
            name: 'newListing',
            plural: false,
            selections: v0 /*: any*/,
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'success',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'error',
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: [],
      kind: 'Fragment',
      metadata: null,
      name: 'poshmarkBotRecentRelistActivityQuery',
      selections: v1 /*: any*/,
      type: 'Query',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [],
      kind: 'Operation',
      name: 'poshmarkBotRecentRelistActivityQuery',
      selections: v1 /*: any*/,
    },
    params: {
      cacheID: '5659898b150d83613e630f696bd3f48f',
      id: null,
      metadata: {},
      name: 'poshmarkBotRecentRelistActivityQuery',
      operationKind: 'query',
      text: 'query poshmarkBotRecentRelistActivityQuery {\n  poshmarkBotRecentRelistActivity {\n    at\n    listingId\n    listing {\n      id\n      url\n      thumbnail\n      title\n      currentPrice\n      deleted\n      sold\n    }\n    newListingId\n    newListing {\n      id\n      url\n      thumbnail\n      title\n      currentPrice\n      deleted\n      sold\n    }\n    success\n    error\n  }\n}\n',
    },
  };
})();
(node as any).hash = 'd8f9f283ae64a7ca6c6f87882d0ab4c7';
export default node;
