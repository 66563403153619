import * as Lib from 'src/clients/Network/gql/lib';
import * as Build from 'src/clients/Network/gql/build';
export * as FragmentReader from './FragmentReader';
import * as Queries from 'src/clients/Network/gql/queries';
import * as State from 'src/state';

export const shippingLabelReel = Lib.promisifyQuery<Build.shippingLabelReelQuery.shippingLabelReelQuery>(
  Queries.gqlShippingLabelReel,
  [
    (args, p) => {
      p.then((r) => {
        State.Observe.ShippingLabels.ShippingLabelReelValue.set(r.invsysShippingLabelReel);
      });
    },
  ]
);

export const requestShippingLabel =
  Lib.promisifyMutation<Build.requestShippingLabelsMutation.requestShippingLabelsMutation>(
    Queries.gqlRequestShippingLabels
  );
