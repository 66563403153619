import * as Observe from 'src/state/lib/observe';
import * as StateTypes from 'src/state/types';

export interface ListingEnumIface {
  display: string;
  value: string;
}

export interface ListingEnumsIface {
  conditions: ListingEnumIface[];
  colors: ListingEnumIface[];
  currencyCodes: ListingEnumIface[];
  sizeUnits: ListingEnumIface[];
  weightUnits: ListingEnumIface[];
  packageSizeTypes: ListingEnumIface[];
}

export type InstitutionListingEnumsIface = {
  institution: StateTypes.InstitutionEnum;
} & ListingEnumsIface;

export interface ListingEnumsMetadataIface {
  invsys: ListingEnumsIface;
  institutions: InstitutionListingEnumsIface[];
}

export type SelectedEditPageFilterType = 'standard' | 'custom';

export const ListingEnumsMetadataAsyncValue = Observe.AsyncValue<ListingEnumsMetadataIface>({});

export const ListingEnumsMetadataValue = Observe.DerivedValue(() => {
  return ListingEnumsMetadataAsyncValue.getValueWithFallback() ?? null;
}, [ListingEnumsMetadataAsyncValue.value]);

export const ListingEnumsMetadataProvider = Observe.WithFallback(ListingEnumsMetadataValue);

export const UploadModalViewingValue = Observe.Value<boolean>(false);

export const SelectedInstitutionFormValue = Observe.Value<null | StateTypes.InstitutionEnum>(null);
export const SelectedInstitutionFormProvider = Observe.WithFallback(SelectedInstitutionFormValue);

export const SelectedEditPageFilter = Observe.Value<SelectedEditPageFilterType>('standard');
export const FinalDetailsOnValid = Observe.Value<boolean>(false);

export const ShowListingErrors = Observe.Value<boolean>(false);
export const ScheduleListAt = Observe.Value<number | null>(null);

export * as Poshmark from './Poshmark';
export * as Mercari from './Mercari';
export * as Tradesy from './Tradesy';
export * as Depop from './Depop';
export * as Ebay from './Ebay';
export * as EbayV2 from './EbayV2';
