import { graphql } from 'react-relay';

export const gqlPersonalRanking = graphql`
  query personalRankingQuery(
    $institution: Int
    $competition: InvsysAnalyticsCompetitionType!
    $frequency: InvsysAnalyticsCompetitionFrequencyType!
  ) {
    invsysPersonalRanking(institution: $institution, competition: $competition, frequency: $frequency) {
      score
      rank
      imageUrl
    }
  }
`;
