import { graphql } from 'react-relay';

export const gqlPutMercariListing = graphql`
  mutation putMercariListingMutation(
    $listingId: String!
    $categoryId: MercariIntPutObject
    $colorId: MercariMercariListingColorTypePutObject
    $conditionId: MercariMercariListingConditionTypePutObject
    $description: MercariStringPutObject
    $imei: MercariStringPutObject
    $minPriceForAutoDrop: MercariIntPutObject
    $name: MercariStringPutObject
    $price: MercariIntPutObject
    $shippingClassIds: MercariArray_IntRequired_RequiredPutObject
    $shippingPayerId: MercariIntPutObject
    $suggestedShippingClassIds: MercariArray_IntRequired_RequiredPutObject
    $tags: MercariArray_StringRequired_RequiredPutObject
    $zipCode: MercariIntPutObject
    $brandId: MercariIntPutObject
    $photoIds: MercariArray_StringRequired_RequiredPutObject
    $shippingPackageWeight: MercariIntPutObject
    $dressOccasion: MercariStringPutObject
    $dressStyle: MercariStringPutObject
    $material: MercariStringPutObject
    $shaftHeight: MercariStringPutObject
    $endUse: MercariStringPutObject
    $heelHeight: MercariStringPutObject
    $heelType: MercariStringPutObject
    $shippingType: MercariIntPutObject
  ) {
    mercariPutMercariListing(
      listingId: $listingId
      categoryId: $categoryId
      colorId: $colorId
      conditionId: $conditionId
      description: $description
      imei: $imei
      minPriceForAutoDrop: $minPriceForAutoDrop
      name: $name
      price: $price
      shippingClassIds: $shippingClassIds
      shippingPayerId: $shippingPayerId
      suggestedShippingClassIds: $suggestedShippingClassIds
      tags: $tags
      zipCode: $zipCode
      brandId: $brandId
      photoIds: $photoIds
      shippingPackageWeight: $shippingPackageWeight
      dressOccasion: $dressOccasion
      dressStyle: $dressStyle
      material: $material
      shaftHeight: $shaftHeight
      endUse: $endUse
      heelHeight: $heelHeight
      heelType: $heelType
      shippingType: $shippingType
    ) {
      success
    }
  }
`;
