/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type updateClosetSectionMutationVariables = {
  poshmarkUserId: string;
  id: string;
  name: string;
};
export type updateClosetSectionMutationResponse = {
  readonly superposherUpdateClosetSection: {
    readonly success: boolean;
  };
};
export type updateClosetSectionMutation = {
  readonly response: updateClosetSectionMutationResponse;
  readonly variables: updateClosetSectionMutationVariables;
};

/*
mutation updateClosetSectionMutation(
  $poshmarkUserId: String!
  $id: String!
  $name: String!
) {
  superposherUpdateClosetSection(poshmarkUserId: $poshmarkUserId, id: $id, name: $name) {
    success
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'id',
    },
    v1 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'name',
    },
    v2 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'poshmarkUserId',
    },
    v3 = [
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'id',
            variableName: 'id',
          },
          {
            kind: 'Variable',
            name: 'name',
            variableName: 'name',
          },
          {
            kind: 'Variable',
            name: 'poshmarkUserId',
            variableName: 'poshmarkUserId',
          },
        ],
        concreteType: 'SuperposherUpdateClosetSectionResponse',
        kind: 'LinkedField',
        name: 'superposherUpdateClosetSection',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'success',
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: [v0 /*: any*/, v1 /*: any*/, v2 /*: any*/],
      kind: 'Fragment',
      metadata: null,
      name: 'updateClosetSectionMutation',
      selections: v3 /*: any*/,
      type: 'Mutation',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [v2 /*: any*/, v0 /*: any*/, v1 /*: any*/],
      kind: 'Operation',
      name: 'updateClosetSectionMutation',
      selections: v3 /*: any*/,
    },
    params: {
      cacheID: '10ecf89d54d43e313091ce9c6ff1a76f',
      id: null,
      metadata: {},
      name: 'updateClosetSectionMutation',
      operationKind: 'mutation',
      text: 'mutation updateClosetSectionMutation(\n  $poshmarkUserId: String!\n  $id: String!\n  $name: String!\n) {\n  superposherUpdateClosetSection(poshmarkUserId: $poshmarkUserId, id: $id, name: $name) {\n    success\n  }\n}\n',
    },
  };
})();
(node as any).hash = 'e05160cb60f477b1979c37655184f98c';
export default node;
