/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type requestShippingLabelsMutationVariables = {
  orderIds: Array<string>;
  format: number;
  addTitle?: boolean | null;
  addSku?: boolean | null;
};
export type requestShippingLabelsMutationResponse = {
  readonly invsysRequestShippingLabels: {
    readonly success: boolean;
    readonly failurePublicReason: string | null;
  };
};
export type requestShippingLabelsMutation = {
  readonly response: requestShippingLabelsMutationResponse;
  readonly variables: requestShippingLabelsMutationVariables;
};

/*
mutation requestShippingLabelsMutation(
  $orderIds: [String!]!
  $format: Int!
  $addTitle: Boolean
  $addSku: Boolean
) {
  invsysRequestShippingLabels(orderIds: $orderIds, format: $format, addTitle: $addTitle, addSku: $addSku) {
    success
    failurePublicReason
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'addSku',
    },
    v1 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'addTitle',
    },
    v2 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'format',
    },
    v3 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'orderIds',
    },
    v4 = [
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'addSku',
            variableName: 'addSku',
          },
          {
            kind: 'Variable',
            name: 'addTitle',
            variableName: 'addTitle',
          },
          {
            kind: 'Variable',
            name: 'format',
            variableName: 'format',
          },
          {
            kind: 'Variable',
            name: 'orderIds',
            variableName: 'orderIds',
          },
        ],
        concreteType: 'InvsysRequestShippingLabelsResponse',
        kind: 'LinkedField',
        name: 'invsysRequestShippingLabels',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'success',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'failurePublicReason',
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: [v0 /*: any*/, v1 /*: any*/, v2 /*: any*/, v3 /*: any*/],
      kind: 'Fragment',
      metadata: null,
      name: 'requestShippingLabelsMutation',
      selections: v4 /*: any*/,
      type: 'Mutation',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [v3 /*: any*/, v2 /*: any*/, v1 /*: any*/, v0 /*: any*/],
      kind: 'Operation',
      name: 'requestShippingLabelsMutation',
      selections: v4 /*: any*/,
    },
    params: {
      cacheID: 'b750a5be98fe2568db5e2353a1378aeb',
      id: null,
      metadata: {},
      name: 'requestShippingLabelsMutation',
      operationKind: 'mutation',
      text: 'mutation requestShippingLabelsMutation(\n  $orderIds: [String!]!\n  $format: Int!\n  $addTitle: Boolean\n  $addSku: Boolean\n) {\n  invsysRequestShippingLabels(orderIds: $orderIds, format: $format, addTitle: $addTitle, addSku: $addSku) {\n    success\n    failurePublicReason\n  }\n}\n',
    },
  };
})();
(node as any).hash = '1347551672f11a1ef99d3f95c14b76c6';
export default node;
