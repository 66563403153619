import { graphql } from 'react-relay';

export const gqlPutStudioListingMediaGroup = graphql`
  mutation putStudioListingMediaGroupMutation(
    $id: String
    $name: StudioStringPutObject
    $note: StudioStringPutObject
    $listingId: StudioStringPutObject
  ) {
    studioPutListingMediaGroup(id: $id, name: $name, note: $note, listingId: $listingId) {
      success
      data {
        ...studioListingMediaGroupFragment_full
      }
    }
  }
`;
