/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type newItemsListedTimeseriesQueryVariables = {};
export type newItemsListedTimeseriesQueryResponse = {
  readonly invsysNewItemsListedTimeseries: {
    readonly thisWeekPoints: ReadonlyArray<{
      readonly at: number;
      readonly value: number;
    }>;
    readonly thisWeekAggregationBucket: number;
    readonly lastWeekTotal: number;
    readonly thisMonthPoints: ReadonlyArray<{
      readonly at: number;
      readonly value: number;
    }>;
    readonly thisMonthAggregationBucket: number;
    readonly lastMonthTotal: number;
    readonly thisYearPoints: ReadonlyArray<{
      readonly at: number;
      readonly value: number;
    }>;
    readonly thisYearAggregationBucket: number;
    readonly lastYearTotal: number;
    readonly allTimePoints: ReadonlyArray<{
      readonly at: number;
      readonly value: number;
    }>;
    readonly allTimeAggregationBucket: number;
  } | null;
};
export type newItemsListedTimeseriesQuery = {
  readonly response: newItemsListedTimeseriesQueryResponse;
  readonly variables: newItemsListedTimeseriesQueryVariables;
};

/*
query newItemsListedTimeseriesQuery {
  invsysNewItemsListedTimeseries {
    thisWeekPoints {
      at
      value
    }
    thisWeekAggregationBucket
    lastWeekTotal
    thisMonthPoints {
      at
      value
    }
    thisMonthAggregationBucket
    lastMonthTotal
    thisYearPoints {
      at
      value
    }
    thisYearAggregationBucket
    lastYearTotal
    allTimePoints {
      at
      value
    }
    allTimeAggregationBucket
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'at',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'value',
        storageKey: null,
      },
    ],
    v1 = [
      {
        alias: null,
        args: null,
        concreteType: 'InvsysNewItemsListedTimeseriesBreakdown',
        kind: 'LinkedField',
        name: 'invsysNewItemsListedTimeseries',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            concreteType: 'InvsysTimeseriesPoint',
            kind: 'LinkedField',
            name: 'thisWeekPoints',
            plural: true,
            selections: v0 /*: any*/,
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'thisWeekAggregationBucket',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'lastWeekTotal',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            concreteType: 'InvsysTimeseriesPoint',
            kind: 'LinkedField',
            name: 'thisMonthPoints',
            plural: true,
            selections: v0 /*: any*/,
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'thisMonthAggregationBucket',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'lastMonthTotal',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            concreteType: 'InvsysTimeseriesPoint',
            kind: 'LinkedField',
            name: 'thisYearPoints',
            plural: true,
            selections: v0 /*: any*/,
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'thisYearAggregationBucket',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'lastYearTotal',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            concreteType: 'InvsysTimeseriesPoint',
            kind: 'LinkedField',
            name: 'allTimePoints',
            plural: true,
            selections: v0 /*: any*/,
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'allTimeAggregationBucket',
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: [],
      kind: 'Fragment',
      metadata: null,
      name: 'newItemsListedTimeseriesQuery',
      selections: v1 /*: any*/,
      type: 'Query',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [],
      kind: 'Operation',
      name: 'newItemsListedTimeseriesQuery',
      selections: v1 /*: any*/,
    },
    params: {
      cacheID: 'ade006c0c1e6ea8645482f382e942a3b',
      id: null,
      metadata: {},
      name: 'newItemsListedTimeseriesQuery',
      operationKind: 'query',
      text: 'query newItemsListedTimeseriesQuery {\n  invsysNewItemsListedTimeseries {\n    thisWeekPoints {\n      at\n      value\n    }\n    thisWeekAggregationBucket\n    lastWeekTotal\n    thisMonthPoints {\n      at\n      value\n    }\n    thisMonthAggregationBucket\n    lastMonthTotal\n    thisYearPoints {\n      at\n      value\n    }\n    thisYearAggregationBucket\n    lastYearTotal\n    allTimePoints {\n      at\n      value\n    }\n    allTimeAggregationBucket\n  }\n}\n',
    },
  };
})();
(node as any).hash = '62b26e1c76d3774307950eb65ce6bc50';
export default node;
