/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type scheduleUspsFreePickupMutationVariables = {
  uspsPickupLocationId?: string | null;
  priorityCount: number;
  otherCount: number;
  pickupDate: string;
  totalWeight: number;
};
export type scheduleUspsFreePickupMutationResponse = {
  readonly invsysScheduleUspsFreePickup: {
    readonly success: boolean;
  };
};
export type scheduleUspsFreePickupMutation = {
  readonly response: scheduleUspsFreePickupMutationResponse;
  readonly variables: scheduleUspsFreePickupMutationVariables;
};

/*
mutation scheduleUspsFreePickupMutation(
  $uspsPickupLocationId: String
  $priorityCount: Int!
  $otherCount: Int!
  $pickupDate: String!
  $totalWeight: Int!
) {
  invsysScheduleUspsFreePickup(uspsPickupLocationId: $uspsPickupLocationId, priorityCount: $priorityCount, otherCount: $otherCount, pickupDate: $pickupDate, totalWeight: $totalWeight) {
    success
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'otherCount',
    },
    v1 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'pickupDate',
    },
    v2 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'priorityCount',
    },
    v3 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'totalWeight',
    },
    v4 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'uspsPickupLocationId',
    },
    v5 = [
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'otherCount',
            variableName: 'otherCount',
          },
          {
            kind: 'Variable',
            name: 'pickupDate',
            variableName: 'pickupDate',
          },
          {
            kind: 'Variable',
            name: 'priorityCount',
            variableName: 'priorityCount',
          },
          {
            kind: 'Variable',
            name: 'totalWeight',
            variableName: 'totalWeight',
          },
          {
            kind: 'Variable',
            name: 'uspsPickupLocationId',
            variableName: 'uspsPickupLocationId',
          },
        ],
        concreteType: 'InvsysScheduleUspsPickupResponse',
        kind: 'LinkedField',
        name: 'invsysScheduleUspsFreePickup',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'success',
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: [v0 /*: any*/, v1 /*: any*/, v2 /*: any*/, v3 /*: any*/, v4 /*: any*/],
      kind: 'Fragment',
      metadata: null,
      name: 'scheduleUspsFreePickupMutation',
      selections: v5 /*: any*/,
      type: 'Mutation',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [v4 /*: any*/, v2 /*: any*/, v0 /*: any*/, v1 /*: any*/, v3 /*: any*/],
      kind: 'Operation',
      name: 'scheduleUspsFreePickupMutation',
      selections: v5 /*: any*/,
    },
    params: {
      cacheID: '60fd2dd3e8e0859b9696be28bc9f48e4',
      id: null,
      metadata: {},
      name: 'scheduleUspsFreePickupMutation',
      operationKind: 'mutation',
      text: 'mutation scheduleUspsFreePickupMutation(\n  $uspsPickupLocationId: String\n  $priorityCount: Int!\n  $otherCount: Int!\n  $pickupDate: String!\n  $totalWeight: Int!\n) {\n  invsysScheduleUspsFreePickup(uspsPickupLocationId: $uspsPickupLocationId, priorityCount: $priorityCount, otherCount: $otherCount, pickupDate: $pickupDate, totalWeight: $totalWeight) {\n    success\n  }\n}\n',
    },
  };
})();
(node as any).hash = 'f79eee15f45afffd3a34d5e598a05b7b';
export default node;
