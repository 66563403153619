/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type rewriteUncategorizedListingOrderingMutationVariables = {
  poshmarkUserId: string;
  listingIds: Array<string>;
};
export type rewriteUncategorizedListingOrderingMutationResponse = {
  readonly superposherRewriteUncategorizedListingOrdering: {
    readonly success: boolean;
  };
};
export type rewriteUncategorizedListingOrderingMutation = {
  readonly response: rewriteUncategorizedListingOrderingMutationResponse;
  readonly variables: rewriteUncategorizedListingOrderingMutationVariables;
};

/*
mutation rewriteUncategorizedListingOrderingMutation(
  $poshmarkUserId: String!
  $listingIds: [String!]!
) {
  superposherRewriteUncategorizedListingOrdering(poshmarkUserId: $poshmarkUserId, listingIds: $listingIds) {
    success
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'listingIds',
    },
    v1 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'poshmarkUserId',
    },
    v2 = [
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'listingIds',
            variableName: 'listingIds',
          },
          {
            kind: 'Variable',
            name: 'poshmarkUserId',
            variableName: 'poshmarkUserId',
          },
        ],
        concreteType: 'SuperposherRewriteUncategorizedListingOrdering',
        kind: 'LinkedField',
        name: 'superposherRewriteUncategorizedListingOrdering',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'success',
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: [v0 /*: any*/, v1 /*: any*/],
      kind: 'Fragment',
      metadata: null,
      name: 'rewriteUncategorizedListingOrderingMutation',
      selections: v2 /*: any*/,
      type: 'Mutation',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [v1 /*: any*/, v0 /*: any*/],
      kind: 'Operation',
      name: 'rewriteUncategorizedListingOrderingMutation',
      selections: v2 /*: any*/,
    },
    params: {
      cacheID: 'a693e73732b7b0651baa54d5aa5f52fe',
      id: null,
      metadata: {},
      name: 'rewriteUncategorizedListingOrderingMutation',
      operationKind: 'mutation',
      text: 'mutation rewriteUncategorizedListingOrderingMutation(\n  $poshmarkUserId: String!\n  $listingIds: [String!]!\n) {\n  superposherRewriteUncategorizedListingOrdering(poshmarkUserId: $poshmarkUserId, listingIds: $listingIds) {\n    success\n  }\n}\n',
    },
  };
})();
(node as any).hash = '8d21134dfe00cbd683113801a36f22f9';
export default node;
