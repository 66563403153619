/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type uspsFreeSupplyOrderingLedgerQueryVariables = {
  cursor?: string | null;
  limit?: number | null;
};
export type uspsFreeSupplyOrderingLedgerQueryResponse = {
  readonly invsysUspsFreeSupplyOrderingLedger: {
    readonly orderingAvailable: boolean;
    readonly orderInProgress: boolean;
    readonly timeLeftUntilAvailable: number;
    readonly cursor: string | null;
    readonly items: ReadonlyArray<{
      readonly id: string;
      readonly cartItems: ReadonlyArray<{
        readonly item: number;
        readonly quantity: number;
        readonly format: string;
      }>;
      readonly email: string;
      readonly firstName: string;
      readonly lastName: string;
      readonly phoneNumber: string;
      readonly streetAddress: string;
      readonly unit: string | null;
      readonly locality: string;
      readonly subdivision: string;
      readonly postalCode: string;
      readonly countryCode: string;
      readonly orderedAt: number;
    }>;
  };
};
export type uspsFreeSupplyOrderingLedgerQuery = {
  readonly response: uspsFreeSupplyOrderingLedgerQueryResponse;
  readonly variables: uspsFreeSupplyOrderingLedgerQueryVariables;
};

/*
query uspsFreeSupplyOrderingLedgerQuery(
  $cursor: String
  $limit: Int
) {
  invsysUspsFreeSupplyOrderingLedger(cursor: $cursor, limit: $limit) {
    orderingAvailable
    orderInProgress
    timeLeftUntilAvailable
    cursor
    items {
      id
      cartItems {
        item
        quantity
        format
      }
      email
      firstName
      lastName
      phoneNumber
      streetAddress
      unit
      locality
      subdivision
      postalCode
      countryCode
      orderedAt
    }
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'cursor',
      },
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'limit',
      },
    ],
    v1 = [
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'cursor',
            variableName: 'cursor',
          },
          {
            kind: 'Variable',
            name: 'limit',
            variableName: 'limit',
          },
        ],
        concreteType: 'InvsysUspsShippingLedger',
        kind: 'LinkedField',
        name: 'invsysUspsFreeSupplyOrderingLedger',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'orderingAvailable',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'orderInProgress',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'timeLeftUntilAvailable',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'cursor',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            concreteType: 'InvsysUspsFreeSupplyOrder',
            kind: 'LinkedField',
            name: 'items',
            plural: true,
            selections: [
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'id',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                concreteType: 'InvsysCartItemFull',
                kind: 'LinkedField',
                name: 'cartItems',
                plural: true,
                selections: [
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'item',
                    storageKey: null,
                  },
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'quantity',
                    storageKey: null,
                  },
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'format',
                    storageKey: null,
                  },
                ],
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'email',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'firstName',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'lastName',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'phoneNumber',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'streetAddress',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'unit',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'locality',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'subdivision',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'postalCode',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'countryCode',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'orderedAt',
                storageKey: null,
              },
            ],
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'uspsFreeSupplyOrderingLedgerQuery',
      selections: v1 /*: any*/,
      type: 'Query',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'uspsFreeSupplyOrderingLedgerQuery',
      selections: v1 /*: any*/,
    },
    params: {
      cacheID: 'cdd77563ece760ffb2120b3e6b4165e6',
      id: null,
      metadata: {},
      name: 'uspsFreeSupplyOrderingLedgerQuery',
      operationKind: 'query',
      text: 'query uspsFreeSupplyOrderingLedgerQuery(\n  $cursor: String\n  $limit: Int\n) {\n  invsysUspsFreeSupplyOrderingLedger(cursor: $cursor, limit: $limit) {\n    orderingAvailable\n    orderInProgress\n    timeLeftUntilAvailable\n    cursor\n    items {\n      id\n      cartItems {\n        item\n        quantity\n        format\n      }\n      email\n      firstName\n      lastName\n      phoneNumber\n      streetAddress\n      unit\n      locality\n      subdivision\n      postalCode\n      countryCode\n      orderedAt\n    }\n  }\n}\n',
    },
  };
})();
(node as any).hash = '1feca27a3d0de294de561baa19c6477e';
export default node;
