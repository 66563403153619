import { graphql } from 'react-relay';

export const gqlPoshmarkBotRecentRelistActivity = graphql`
  query poshmarkBotRecentRelistActivityQuery {
    poshmarkBotRecentRelistActivity {
      at
      listingId
      listing {
        id
        url
        thumbnail
        title
        currentPrice
        deleted
        sold
      }
      newListingId
      newListing {
        id
        url
        thumbnail
        title
        currentPrice
        deleted
        sold
      }
      success
      error
    }
  }
`;
