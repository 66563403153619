/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type createAwsPresignedUrlMutationVariables = {
  fileType: string;
};
export type createAwsPresignedUrlMutationResponse = {
  readonly invsysCreateAwsPresignedUploadUrl: {
    readonly success: boolean;
    readonly data: {
      readonly presignedUrl: string;
      readonly uploadUrl: string;
    } | null;
  };
};
export type createAwsPresignedUrlMutation = {
  readonly response: createAwsPresignedUrlMutationResponse;
  readonly variables: createAwsPresignedUrlMutationVariables;
};

/*
mutation createAwsPresignedUrlMutation(
  $fileType: String!
) {
  invsysCreateAwsPresignedUploadUrl(fileType: $fileType) {
    success
    data {
      presignedUrl
      uploadUrl
    }
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'fileType',
      },
    ],
    v1 = [
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'fileType',
            variableName: 'fileType',
          },
        ],
        concreteType: 'InvsysCreateAwsPresignedUploadUrlResponse',
        kind: 'LinkedField',
        name: 'invsysCreateAwsPresignedUploadUrl',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'success',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            concreteType: 'InvsysCreateAwsPresignedUploadUrlResponseData',
            kind: 'LinkedField',
            name: 'data',
            plural: false,
            selections: [
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'presignedUrl',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'uploadUrl',
                storageKey: null,
              },
            ],
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'createAwsPresignedUrlMutation',
      selections: v1 /*: any*/,
      type: 'Mutation',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'createAwsPresignedUrlMutation',
      selections: v1 /*: any*/,
    },
    params: {
      cacheID: 'e48f8cdf1e1aec8fbd41efa941cfae42',
      id: null,
      metadata: {},
      name: 'createAwsPresignedUrlMutation',
      operationKind: 'mutation',
      text: 'mutation createAwsPresignedUrlMutation(\n  $fileType: String!\n) {\n  invsysCreateAwsPresignedUploadUrl(fileType: $fileType) {\n    success\n    data {\n      presignedUrl\n      uploadUrl\n    }\n  }\n}\n',
    },
  };
})();
(node as any).hash = '2a13879ab3c86354e2ed69a0c8c7616c';
export default node;
