import { graphql } from 'react-relay';

export const StudioListingMediaGroupFragmentFull = graphql`
  fragment studioListingMediaGroupFragment_full on StudioListingMediaGroup @inline {
    id
    name
    note
    listingId
    createdAt
    updatedAt
    memberships {
      id
      listingMediaGroupId
      mediaId
      media {
        id
        createdAt
        url
        mediaType
        width
        height
        uploadStatus
        originUri
        originPlatformOs
        originPlatformMajorVersion
      }
      preparationStatus
      rank
      variants {
        membershipId
        mediaId
        variantType
        media {
          id
          createdAt
          url
          mediaType
          width
          height
          uploadStatus
          originUri
          originPlatformOs
          originPlatformMajorVersion
        }
      }
    }
    pendingVariantRequests {
      id
      listingMediaGroupId
      createdAt
      variantType
      membershipIds
    }
  }
`;
