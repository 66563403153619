import { graphql } from 'react-relay';

export const ListingFragmentFull = graphql`
  fragment listingFragment_full on InvsysSkusListing @inline {
    id
    title
    description
    listingSku
    skus
    tags
    notes
    searchTags
    marketplacesSummary {
      marketplaces
      draftMarketplaces
    }
    price {
      id
      currencyCode
      val
    }
    createdAt
    updatedAt
    items {
      id
      title
      description
      brand
      categoryId
      sizeId
      price {
        currencyCode
        val
      }
      retailPrice {
        currencyCode
        val
      }
      costPrice {
        currencyCode
        val
      }
      size {
        val
        system
      }
      condition
      tags
      skuAlias
      colors
      weight
      height
      width
      length
      weightUnit
      sizeUnit
      packageSizeType
    }
    archived
    targetInstitutions
    validationResult {
      success
      errors {
        errorId: id
        message
        entityName
        fieldName
      }
    }
  }
`;
