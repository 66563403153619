/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type poshmarkBotOfferToLikersAllListingsQueryVariables = {
  cursor?: string | null;
  limit?: number | null;
};
export type poshmarkBotOfferToLikersAllListingsQueryResponse = {
  readonly poshmarkBotOfferToLikersAllListings: {
    readonly items: ReadonlyArray<{
      readonly listingId: string;
      readonly offerConfigId: string | null;
      readonly listing: {
        readonly id: string;
        readonly url: string | null;
        readonly thumbnail: string | null;
        readonly title: string;
        readonly currentPrice: number | null;
        readonly deleted: boolean;
        readonly sold: boolean;
      } | null;
      readonly listingFailure: {
        readonly createdAt: number;
        readonly failurePublicReason: string;
      } | null;
      readonly offer: {
        readonly percentOff: number;
        readonly minRevenue: number;
        readonly shippingDiscountId: string | null;
        readonly listingMinAgeDays: number;
        readonly updatedAt: number;
        readonly userEditOk: boolean;
        readonly usageCount: number;
      } | null;
      readonly createdAt: number;
    }>;
    readonly cursor: string | null;
  } | null;
};
export type poshmarkBotOfferToLikersAllListingsQuery = {
  readonly response: poshmarkBotOfferToLikersAllListingsQueryResponse;
  readonly variables: poshmarkBotOfferToLikersAllListingsQueryVariables;
};

/*
query poshmarkBotOfferToLikersAllListingsQuery(
  $cursor: String
  $limit: Int
) {
  poshmarkBotOfferToLikersAllListings(cursor: $cursor, limit: $limit) {
    items {
      listingId
      offerConfigId
      listing {
        id
        url
        thumbnail
        title
        currentPrice
        deleted
        sold
      }
      listingFailure {
        createdAt
        failurePublicReason
      }
      offer {
        percentOff
        minRevenue
        shippingDiscountId
        listingMinAgeDays
        updatedAt
        userEditOk
        usageCount
      }
      createdAt
    }
    cursor
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'cursor',
      },
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'limit',
      },
    ],
    v1 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'createdAt',
      storageKey: null,
    },
    v2 = [
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'cursor',
            variableName: 'cursor',
          },
          {
            kind: 'Variable',
            name: 'limit',
            variableName: 'limit',
          },
        ],
        concreteType: 'PoshmarkBotListingOfferConfigurationPage',
        kind: 'LinkedField',
        name: 'poshmarkBotOfferToLikersAllListings',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            concreteType: 'PoshmarkBotListingOfferConfiguration',
            kind: 'LinkedField',
            name: 'items',
            plural: true,
            selections: [
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'listingId',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'offerConfigId',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                concreteType: 'PoshmarkBotListing',
                kind: 'LinkedField',
                name: 'listing',
                plural: false,
                selections: [
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'id',
                    storageKey: null,
                  },
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'url',
                    storageKey: null,
                  },
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'thumbnail',
                    storageKey: null,
                  },
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'title',
                    storageKey: null,
                  },
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'currentPrice',
                    storageKey: null,
                  },
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'deleted',
                    storageKey: null,
                  },
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'sold',
                    storageKey: null,
                  },
                ],
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                concreteType: 'PoshmarkBotOfferToLikersListingFailure',
                kind: 'LinkedField',
                name: 'listingFailure',
                plural: false,
                selections: [
                  v1 /*: any*/,
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'failurePublicReason',
                    storageKey: null,
                  },
                ],
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                concreteType: 'PoshmarkBotOfferConfiguration',
                kind: 'LinkedField',
                name: 'offer',
                plural: false,
                selections: [
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'percentOff',
                    storageKey: null,
                  },
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'minRevenue',
                    storageKey: null,
                  },
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'shippingDiscountId',
                    storageKey: null,
                  },
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'listingMinAgeDays',
                    storageKey: null,
                  },
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'updatedAt',
                    storageKey: null,
                  },
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'userEditOk',
                    storageKey: null,
                  },
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'usageCount',
                    storageKey: null,
                  },
                ],
                storageKey: null,
              },
              v1 /*: any*/,
            ],
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'cursor',
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'poshmarkBotOfferToLikersAllListingsQuery',
      selections: v2 /*: any*/,
      type: 'Query',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'poshmarkBotOfferToLikersAllListingsQuery',
      selections: v2 /*: any*/,
    },
    params: {
      cacheID: 'db084c42a352020ab042c95a3539b5eb',
      id: null,
      metadata: {},
      name: 'poshmarkBotOfferToLikersAllListingsQuery',
      operationKind: 'query',
      text: 'query poshmarkBotOfferToLikersAllListingsQuery(\n  $cursor: String\n  $limit: Int\n) {\n  poshmarkBotOfferToLikersAllListings(cursor: $cursor, limit: $limit) {\n    items {\n      listingId\n      offerConfigId\n      listing {\n        id\n        url\n        thumbnail\n        title\n        currentPrice\n        deleted\n        sold\n      }\n      listingFailure {\n        createdAt\n        failurePublicReason\n      }\n      offer {\n        percentOff\n        minRevenue\n        shippingDiscountId\n        listingMinAgeDays\n        updatedAt\n        userEditOk\n        usageCount\n      }\n      createdAt\n    }\n    cursor\n  }\n}\n',
    },
  };
})();
(node as any).hash = '590e3b1b40db415f012d049c9f1fceed';
export default node;
