import * as Observe from 'src/state/lib/observe';

export interface NewsItemLinkIface {
  label: string;
  href: string;
}

export interface NewsItemIface {
  at: number;
  title: string;
  links: NewsItemLinkIface[];
  description: string[];
  clients: {
    ios: {
      minVersion: string;
    };
    android: {
      minVersion: string;
    };
  };
}

interface AsyncValueDataType {
  version: number;
  data: NewsItemIface[];
}

export const AsyncValue = Observe.AsyncValue<AsyncValueDataType>({});

export const Value = Observe.DerivedValue(() => {
  return AsyncValue.getValueWithFallback();
}, [AsyncValue.value]);

export const ValueFallback = Observe.WithFallback(Value);
