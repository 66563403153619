import { graphql } from 'react-relay';

export const gqlCreateAwsPresignedUrl = graphql`
  mutation createAwsPresignedUrlMutation($fileType: String!) {
    invsysCreateAwsPresignedUploadUrl(fileType: $fileType) {
      success
      data {
        presignedUrl
        uploadUrl
      }
    }
  }
`;
