/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type InvsysGoalFrequency = 'ANNUALLY' | 'MONTHLY' | 'WEEKLY' | '%future added value';
export type InvsysGoalType = 'GROSS_REVENUE' | 'NET_REVENUE' | 'NEW_ITEMS_LISTED' | '%future added value';
export type createSalesGoalMutationVariables = {
  institution?: number | null;
  institutionIssuedId?: string | null;
  goalType: InvsysGoalType;
  target: number;
  frequency: InvsysGoalFrequency;
};
export type createSalesGoalMutationResponse = {
  readonly invsysCreateSalesGoal: {
    readonly id: string;
  };
};
export type createSalesGoalMutation = {
  readonly response: createSalesGoalMutationResponse;
  readonly variables: createSalesGoalMutationVariables;
};

/*
mutation createSalesGoalMutation(
  $institution: Int
  $institutionIssuedId: String
  $goalType: InvsysGoalType!
  $target: Float!
  $frequency: InvsysGoalFrequency!
) {
  invsysCreateSalesGoal(institution: $institution, institutionIssuedId: $institutionIssuedId, goalType: $goalType, target: $target, frequency: $frequency) {
    id
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'frequency',
    },
    v1 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'goalType',
    },
    v2 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'institution',
    },
    v3 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'institutionIssuedId',
    },
    v4 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'target',
    },
    v5 = [
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'frequency',
            variableName: 'frequency',
          },
          {
            kind: 'Variable',
            name: 'goalType',
            variableName: 'goalType',
          },
          {
            kind: 'Variable',
            name: 'institution',
            variableName: 'institution',
          },
          {
            kind: 'Variable',
            name: 'institutionIssuedId',
            variableName: 'institutionIssuedId',
          },
          {
            kind: 'Variable',
            name: 'target',
            variableName: 'target',
          },
        ],
        concreteType: 'InvsysSalesGoal',
        kind: 'LinkedField',
        name: 'invsysCreateSalesGoal',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'id',
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: [v0 /*: any*/, v1 /*: any*/, v2 /*: any*/, v3 /*: any*/, v4 /*: any*/],
      kind: 'Fragment',
      metadata: null,
      name: 'createSalesGoalMutation',
      selections: v5 /*: any*/,
      type: 'Mutation',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [v2 /*: any*/, v3 /*: any*/, v1 /*: any*/, v4 /*: any*/, v0 /*: any*/],
      kind: 'Operation',
      name: 'createSalesGoalMutation',
      selections: v5 /*: any*/,
    },
    params: {
      cacheID: 'a1ffad8a5ff8f1bc798cb8c967e93506',
      id: null,
      metadata: {},
      name: 'createSalesGoalMutation',
      operationKind: 'mutation',
      text: 'mutation createSalesGoalMutation(\n  $institution: Int\n  $institutionIssuedId: String\n  $goalType: InvsysGoalType!\n  $target: Float!\n  $frequency: InvsysGoalFrequency!\n) {\n  invsysCreateSalesGoal(institution: $institution, institutionIssuedId: $institutionIssuedId, goalType: $goalType, target: $target, frequency: $frequency) {\n    id\n  }\n}\n',
    },
  };
})();
(node as any).hash = 'c4a956c4e1aa16a9bf2583c7245fcdbe';
export default node;
