/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type duplicateListingMutationVariables = {
  id: string;
};
export type duplicateListingMutationResponse = {
  readonly invsysDuplicateListing: {
    readonly success: boolean;
    readonly data: {
      readonly id: string;
    } | null;
  };
};
export type duplicateListingMutation = {
  readonly response: duplicateListingMutationResponse;
  readonly variables: duplicateListingMutationVariables;
};

/*
mutation duplicateListingMutation(
  $id: String!
) {
  invsysDuplicateListing(id: $id) {
    success
    data {
      id
    }
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'id',
      },
    ],
    v1 = [
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'id',
            variableName: 'id',
          },
        ],
        concreteType: 'InvsysDuplicateListingResponse',
        kind: 'LinkedField',
        name: 'invsysDuplicateListing',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'success',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            concreteType: 'InvsysSkusListing',
            kind: 'LinkedField',
            name: 'data',
            plural: false,
            selections: [
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'id',
                storageKey: null,
              },
            ],
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'duplicateListingMutation',
      selections: v1 /*: any*/,
      type: 'Mutation',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'duplicateListingMutation',
      selections: v1 /*: any*/,
    },
    params: {
      cacheID: '2a7ef885038a38061f1c58ef5f7227ec',
      id: null,
      metadata: {},
      name: 'duplicateListingMutation',
      operationKind: 'mutation',
      text: 'mutation duplicateListingMutation(\n  $id: String!\n) {\n  invsysDuplicateListing(id: $id) {\n    success\n    data {\n      id\n    }\n  }\n}\n',
    },
  };
})();
(node as any).hash = 'a07e2e0341d629b4a610ed400153cea9';
export default node;
