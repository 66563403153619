import { graphql } from 'react-relay';

export const gqlMarketplaceListingsValidations = graphql`
  query marketplaceListingsValidationsQuery($listingId: String!, $marketplaces: [Int!]!) {
    invsysMarketplaceListingsValidations(listingId: $listingId, marketplaces: $marketplaces) {
      poshmarkListingValidation {
        success
        errors {
          errorId: id
          entityName
          entityId
          fieldName
          error
          message
          timeToComplete
        }
        additionalFieldsAvailable
      }
      mercariListingValidation {
        success
        errors {
          errorId: id
          entityName
          entityId
          fieldName
          error
          message
          timeToComplete
        }
        additionalFieldsAvailable
      }
      depopListingValidation {
        success
        errors {
          errorId: id
          entityName
          entityId
          fieldName
          error
          message
          timeToComplete
        }
        additionalFieldsAvailable
      }
      tradesyListingValidation {
        success
        errors {
          errorId: id
          entityName
          entityId
          fieldName
          error
          message
          timeToComplete
        }
        additionalFieldsAvailable
      }
      ebayListingValidation {
        success
        errors {
          errorId: id
          entityName
          entityId
          fieldName
          error
          message
          timeToComplete
        }
        additionalFieldsAvailable
      }
      grailedListingValidation {
        success
        errors {
          errorId: id
          entityName
          entityId
          fieldName
          error
          message
          timeToComplete
        }
        additionalFieldsAvailable
      }
    }
  }
`;
