/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type addListingsToClosetSectionMutationVariables = {
  poshmarkUserId: string;
  closetSectionId: string;
  listingIds: Array<string>;
};
export type addListingsToClosetSectionMutationResponse = {
  readonly superposherAddListingsToClosetSection: {
    readonly success: boolean;
  };
};
export type addListingsToClosetSectionMutation = {
  readonly response: addListingsToClosetSectionMutationResponse;
  readonly variables: addListingsToClosetSectionMutationVariables;
};

/*
mutation addListingsToClosetSectionMutation(
  $poshmarkUserId: String!
  $closetSectionId: String!
  $listingIds: [String!]!
) {
  superposherAddListingsToClosetSection(poshmarkUserId: $poshmarkUserId, closetSectionId: $closetSectionId, listingIds: $listingIds) {
    success
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'closetSectionId',
    },
    v1 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'listingIds',
    },
    v2 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'poshmarkUserId',
    },
    v3 = [
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'closetSectionId',
            variableName: 'closetSectionId',
          },
          {
            kind: 'Variable',
            name: 'listingIds',
            variableName: 'listingIds',
          },
          {
            kind: 'Variable',
            name: 'poshmarkUserId',
            variableName: 'poshmarkUserId',
          },
        ],
        concreteType: 'SuperposherAddListingsToClosetSectionResponse',
        kind: 'LinkedField',
        name: 'superposherAddListingsToClosetSection',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'success',
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: [v0 /*: any*/, v1 /*: any*/, v2 /*: any*/],
      kind: 'Fragment',
      metadata: null,
      name: 'addListingsToClosetSectionMutation',
      selections: v3 /*: any*/,
      type: 'Mutation',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [v2 /*: any*/, v0 /*: any*/, v1 /*: any*/],
      kind: 'Operation',
      name: 'addListingsToClosetSectionMutation',
      selections: v3 /*: any*/,
    },
    params: {
      cacheID: 'e9e285faa32f3c49f64bfef51f877232',
      id: null,
      metadata: {},
      name: 'addListingsToClosetSectionMutation',
      operationKind: 'mutation',
      text: 'mutation addListingsToClosetSectionMutation(\n  $poshmarkUserId: String!\n  $closetSectionId: String!\n  $listingIds: [String!]!\n) {\n  superposherAddListingsToClosetSection(poshmarkUserId: $poshmarkUserId, closetSectionId: $closetSectionId, listingIds: $listingIds) {\n    success\n  }\n}\n',
    },
  };
})();
(node as any).hash = 'bf1ef1cdafbe25af1e4f50e4367598bf';
export default node;
