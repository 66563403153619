import * as Network from 'src/clients/Network';
import * as State from 'src/state';
import { v4 as uuidv4 } from 'uuid';

export enum SearchStringConditionFieldType {
  Display = 0,
  Path = 1,
}

export enum FilterConditionFieldType {}

interface SearchStringConditionIface {
  fields?: SearchStringConditionFieldType[];
  rawValue?: string;
}

interface FilterConditionIface {
  field?: FilterConditionFieldType;
  rawValue?: string[];
}

export interface TypedQueryIface {
  searchString?: SearchStringConditionIface;
  filter?: FilterConditionIface[];
}

interface SearchPageIface {
  id: string;
  cursor?: string;
  items?: State.Observe.SearchClients.ListingFormCatalogRecordV1Iface[];
  total?: {
    relation?: 'eq' | 'gte';
    value?: number;
  };
}

interface SearchArgs {
  requests: {
    id: string;
    query: TypedQueryIface;
    cursor?: string;
  }[];
}

export interface SearchOneArgs {
  query: TypedQueryIface;
  cursor?: string;
}

interface SearchResponseIface {
  results: SearchPageIface[];
}

export const search = async (args: SearchArgs): Promise<SearchResponseIface> => {
  const res = await Network.http
    .fetchSearch('/listing-form/attribute/catalog/invsys/query', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        requests: args.requests,
      }),
    })
    .then((_) => _.json());
  return res as SearchResponseIface;
};

export const searchOne = async (args: SearchOneArgs): Promise<SearchPageIface> => {
  const { results } = await search({
    requests: [
      {
        id: uuidv4(),
        ...args,
      },
    ],
  });
  return results[0];
};

interface CountPageIface {
  id: string;
  count: number;
}

interface CountArgs {
  requests: {
    id: string;
    query: TypedQueryIface;
  }[];
}

interface CountOneArgs {
  query: TypedQueryIface;
}

interface CountResponseIface {
  results: CountPageIface[];
}

export const count = async (args: CountArgs): Promise<CountResponseIface> => {
  const res = await Network.http
    .fetchSearch('/listing-form/attribute/catalog/invsys/count', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        requests: args.requests,
      }),
    })
    .then((_) => _.json());
  return res as CountResponseIface;
};

export const countOne = async (args: CountOneArgs): Promise<CountPageIface> => {
  const { results } = await count({
    requests: [
      {
        id: uuidv4(),
        ...args,
      },
    ],
  });
  return results[0];
};
