import { ValidationErrorId } from 'src/constants/ValidationError';
import { Types } from 'src/state';
import * as Observe from 'src/state/lib/observe';

export interface CategoryIface {
  departmentId: string;
  categoryId: string;
  categoryFeatureIds: string[];
}
export interface SizeIface {
  id: string;
  display: string;
  size_system: string;
}

export interface SizeArgs {
  id: string;
  size_system: string;
}

export enum AdditionalFieldsNames {
  Size = 'size',
  Brand = 'brand',
}

export const Form = {
  TitleValue: Observe.Value<string | null>(null),
  DescriptionValue: Observe.Value<string | null>(null),
  BrandValue: Observe.Value<string | null>(null),
  CategoryValue: Observe.Value<CategoryIface | null>(null),
  SizeValue: Observe.Value<SizeArgs | null>(null),
  PriceValue: Observe.Value<number | null>(null),
  ShippingDiscountIdValue: Observe.Value<string | null>(null),
};

export const CategoryNodeValue = Observe.DerivedValue(() => {
  return Form.CategoryValue.get()?.categoryFeatureIds[0] ?? Form.CategoryValue.get()?.categoryId;
}, [Form.CategoryValue]);

export const clear = () => {
  Object.keys(Form).map((key) => Form[key as keyof typeof Form].reset());
};

export const setWithListing = (listing: Types.PoshmarkListingType) => {
  Form.TitleValue.set(listing.title);
  Form.DescriptionValue.set(listing.description);
  Form.BrandValue.set(listing.brand);
  Form.CategoryValue.set(
    listing.poshmarkCategoryId != null && listing.poshmarkDepartmentId != null
      ? {
          departmentId: listing.poshmarkDepartmentId,
          categoryId: listing.poshmarkCategoryId,
          categoryFeatureIds: [...listing.poshmarkCategoryFeatureIds],
        }
      : null
  );
  Form.SizeValue.set(
    listing.items[0].size != null
      ? {
          id: listing.items[0].size,
          size_system: listing.items[0].sizeSystem as string,
        }
      : null
  );
  Form.PriceValue.set(listing.price.val);
  Form.ShippingDiscountIdValue.set(listing.shippingDiscountId);
};

export enum FormPart {
  Title,
  Description,
  Brand,
  Category,
  Size,
  PriceCurrency,
  Price,
  Shipping,
}

export interface FieldIface {
  part: FormPart;
  errors: ValidationErrorId[];
}

export const FormConfig: FieldIface[] = [
  {
    part: FormPart.Title,
    errors: [ValidationErrorId.Poshmark_PoshmarkListingTitle],
  },
  {
    part: FormPart.Brand,
    errors: [ValidationErrorId.Poshmark_PoshmarkListingBrand],
  },
  {
    part: FormPart.Category,
    errors: [
      ValidationErrorId.Poshmark_PoshmarkListingCategoryId,
      ValidationErrorId.Poshmark_PoshmarkListingDepartment,
    ],
  },
  /* Next two are unhandled, probably will remove validations in favor of resolving constraints list-time */
  {
    part: FormPart.PriceCurrency,
    errors: [ValidationErrorId.Poshmark_PoshmarkListingCurrencyCode],
  },
  {
    part: FormPart.Price,
    errors: [ValidationErrorId.Poshmark_PoshmarkListingPrice],
  },
  {
    part: FormPart.Size,
    errors: [ValidationErrorId.Poshmark_PoshmarkItemsSize],
  },
  {
    part: FormPart.Description,
    errors: [ValidationErrorId.Poshmark_PoshmarkListingDescription],
  },
  {
    part: FormPart.Shipping,
    errors: [ValidationErrorId.Poshmark_PoshmarkListingShippingDiscount],
  },
];

export const FormParts = Observe.Value<FormPart[]>([]);
export const UnhandledErrorIds = Observe.Value<number[]>([]);
