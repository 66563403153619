import { graphql } from 'react-relay';

export const gqlGenAlgoliaKey = graphql`
  mutation genAlgoliaKeyQuery {
    invsysGenAlgoliaKey {
      success
      businessScopeKey
      publicScopeKey
    }
  }
`;
