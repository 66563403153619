/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type validateListingQueryVariables = {
  id: string;
};
export type validateListingQueryResponse = {
  readonly invsysListing: {
    readonly validationResult: {
      readonly success: boolean;
    };
  } | null;
};
export type validateListingQuery = {
  readonly response: validateListingQueryResponse;
  readonly variables: validateListingQueryVariables;
};

/*
query validateListingQuery(
  $id: String!
) {
  invsysListing(id: $id) {
    validationResult {
      success
    }
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'id',
      },
    ],
    v1 = [
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'id',
            variableName: 'id',
          },
        ],
        concreteType: 'InvsysSkusListing',
        kind: 'LinkedField',
        name: 'invsysListing',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            concreteType: 'InvsysListingStepResult',
            kind: 'LinkedField',
            name: 'validationResult',
            plural: false,
            selections: [
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'success',
                storageKey: null,
              },
            ],
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'validateListingQuery',
      selections: v1 /*: any*/,
      type: 'Query',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'validateListingQuery',
      selections: v1 /*: any*/,
    },
    params: {
      cacheID: 'ba32fc45f2e884b194783443b1c46a19',
      id: null,
      metadata: {},
      name: 'validateListingQuery',
      operationKind: 'query',
      text: 'query validateListingQuery(\n  $id: String!\n) {\n  invsysListing(id: $id) {\n    validationResult {\n      success\n    }\n  }\n}\n',
    },
  };
})();
(node as any).hash = '1b652d298f370e422ed55d7b4254a7dd';
export default node;
