import { graphql } from 'react-relay';

export const gqlPoshmarkListing = graphql`
  query poshmarkListingQuery($listingId: String!) {
    poshmarkPoshmarkListing(listingId: $listingId) {
      id
      poshmarkId
      listingId
      title
      description
      brand
      poshmarkDepartmentId
      poshmarkCategoryId
      poshmarkCategoryFeatureIds
      condition
      price {
        id
        currencyCode
        val
      }
      originalPrice {
        id
        currencyCode
        val
      }
      costPrice {
        id
        currencyCode
        val
      }
      styleTags
      colors
      listingSku
      status
      publishStatusV2 {
        status
        message
      }
      items {
        poshmarkListingId
        itemId
        size
        sizeSystem
      }
      priceSuggestion {
        suggestedPrice
        feeParts {
          value
          label
        }
      }
      shippingInfo {
        shippingPrice
        shippingProvider
        shippingProviderDisplay
        payerContributions {
          contribution
          shippingPayer
          shippingPayerDisplay
        }
      }
      shippingDiscountId
      sizeSuggestion {
        sizeSuggestions {
          id
          display
          sizeSystem
        }
      }
    }
  }
`;
