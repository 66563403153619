/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type EbayArray_StringRequired_RequiredPutObject = {
  value: Array<string>;
};
export type editEbayItemMutationVariables = {
  itemId: string;
  attributesSizeType?: EbayArray_StringRequired_RequiredPutObject | null;
  attributesSize?: EbayArray_StringRequired_RequiredPutObject | null;
  attributesUsShoeSize?: EbayArray_StringRequired_RequiredPutObject | null;
};
export type editEbayItemMutationResponse = {
  readonly ebayEditEbayItem: {
    readonly success: boolean;
  } | null;
};
export type editEbayItemMutation = {
  readonly response: editEbayItemMutationResponse;
  readonly variables: editEbayItemMutationVariables;
};

/*
mutation editEbayItemMutation(
  $itemId: String!
  $attributesSizeType: EbayArray_StringRequired_RequiredPutObject
  $attributesSize: EbayArray_StringRequired_RequiredPutObject
  $attributesUsShoeSize: EbayArray_StringRequired_RequiredPutObject
) {
  ebayEditEbayItem(itemId: $itemId, attributesSizeType: $attributesSizeType, attributesSize: $attributesSize, attributesUsShoeSize: $attributesUsShoeSize) {
    success
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'attributesSize',
    },
    v1 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'attributesSizeType',
    },
    v2 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'attributesUsShoeSize',
    },
    v3 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'itemId',
    },
    v4 = [
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'attributesSize',
            variableName: 'attributesSize',
          },
          {
            kind: 'Variable',
            name: 'attributesSizeType',
            variableName: 'attributesSizeType',
          },
          {
            kind: 'Variable',
            name: 'attributesUsShoeSize',
            variableName: 'attributesUsShoeSize',
          },
          {
            kind: 'Variable',
            name: 'itemId',
            variableName: 'itemId',
          },
        ],
        concreteType: 'EbayEditEbayItemResponse',
        kind: 'LinkedField',
        name: 'ebayEditEbayItem',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'success',
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: [v0 /*: any*/, v1 /*: any*/, v2 /*: any*/, v3 /*: any*/],
      kind: 'Fragment',
      metadata: null,
      name: 'editEbayItemMutation',
      selections: v4 /*: any*/,
      type: 'Mutation',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [v3 /*: any*/, v1 /*: any*/, v0 /*: any*/, v2 /*: any*/],
      kind: 'Operation',
      name: 'editEbayItemMutation',
      selections: v4 /*: any*/,
    },
    params: {
      cacheID: '14325dbcd48f42840d4b6bf9203561e4',
      id: null,
      metadata: {},
      name: 'editEbayItemMutation',
      operationKind: 'mutation',
      text: 'mutation editEbayItemMutation(\n  $itemId: String!\n  $attributesSizeType: EbayArray_StringRequired_RequiredPutObject\n  $attributesSize: EbayArray_StringRequired_RequiredPutObject\n  $attributesUsShoeSize: EbayArray_StringRequired_RequiredPutObject\n) {\n  ebayEditEbayItem(itemId: $itemId, attributesSizeType: $attributesSizeType, attributesSize: $attributesSize, attributesUsShoeSize: $attributesUsShoeSize) {\n    success\n  }\n}\n',
    },
  };
})();
(node as any).hash = 'b4932b3ec251ff2d71da0f198d0b41d7';
export default node;
