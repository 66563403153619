/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type PoshmarkListingCurrencyCodeType = 'AUD' | 'CAD' | 'USD' | '%future added value';
export type PoshmarkPoshmarkListingConditionType =
  | 'NEW_WITH_TAGS'
  | 'NONE'
  | 'NOT_NEW_WITH_TAGS'
  | '%future added value';
export type PoshmarkPoshmarkListingStatusType = 'AVAILABLE' | 'NOT_FOR_SALE' | '%future added value';
export type PoshmarkStringPutObject = {
  value?: string | null;
};
export type PoshmarkArray_StringRequired_RequiredPutObject = {
  value: Array<string>;
};
export type PoshmarkPoshmarkListingStatusTypePutObject = {
  value?: PoshmarkPoshmarkListingStatusType | null;
};
export type PoshmarkPoshmarkListingConditionTypePutObject = {
  value?: PoshmarkPoshmarkListingConditionType | null;
};
export type PoshmarkFloatPutObject = {
  value?: number | null;
};
export type PoshmarkListingCurrencyCodeTypePutObject = {
  value?: PoshmarkListingCurrencyCodeType | null;
};
export type putPoshmarkListingMutationVariables = {
  listingId: string;
  title?: PoshmarkStringPutObject | null;
  description?: PoshmarkStringPutObject | null;
  brand?: PoshmarkStringPutObject | null;
  poshmarkDepartmentId?: PoshmarkStringPutObject | null;
  poshmarkCategoryId?: PoshmarkStringPutObject | null;
  poshmarkCategoryFeatureIds?: PoshmarkArray_StringRequired_RequiredPutObject | null;
  coverShotId?: PoshmarkStringPutObject | null;
  pictureIds?: PoshmarkArray_StringRequired_RequiredPutObject | null;
  videoIds?: PoshmarkArray_StringRequired_RequiredPutObject | null;
  styleTags?: PoshmarkArray_StringRequired_RequiredPutObject | null;
  colors?: PoshmarkArray_StringRequired_RequiredPutObject | null;
  status?: PoshmarkPoshmarkListingStatusTypePutObject | null;
  condition?: PoshmarkPoshmarkListingConditionTypePutObject | null;
  price?: PoshmarkFloatPutObject | null;
  originalPrice?: PoshmarkFloatPutObject | null;
  costPrice?: PoshmarkFloatPutObject | null;
  listingSku?: PoshmarkStringPutObject | null;
  currencyCode?: PoshmarkListingCurrencyCodeTypePutObject | null;
  shippingDiscountId?: PoshmarkStringPutObject | null;
};
export type putPoshmarkListingMutationResponse = {
  readonly poshmarkPutPoshmarkListing: {
    readonly success: boolean;
  } | null;
};
export type putPoshmarkListingMutation = {
  readonly response: putPoshmarkListingMutationResponse;
  readonly variables: putPoshmarkListingMutationVariables;
};

/*
mutation putPoshmarkListingMutation(
  $listingId: String!
  $title: PoshmarkStringPutObject
  $description: PoshmarkStringPutObject
  $brand: PoshmarkStringPutObject
  $poshmarkDepartmentId: PoshmarkStringPutObject
  $poshmarkCategoryId: PoshmarkStringPutObject
  $poshmarkCategoryFeatureIds: PoshmarkArray_StringRequired_RequiredPutObject
  $coverShotId: PoshmarkStringPutObject
  $pictureIds: PoshmarkArray_StringRequired_RequiredPutObject
  $videoIds: PoshmarkArray_StringRequired_RequiredPutObject
  $styleTags: PoshmarkArray_StringRequired_RequiredPutObject
  $colors: PoshmarkArray_StringRequired_RequiredPutObject
  $status: PoshmarkPoshmarkListingStatusTypePutObject
  $condition: PoshmarkPoshmarkListingConditionTypePutObject
  $price: PoshmarkFloatPutObject
  $originalPrice: PoshmarkFloatPutObject
  $costPrice: PoshmarkFloatPutObject
  $listingSku: PoshmarkStringPutObject
  $currencyCode: PoshmarkListingCurrencyCodeTypePutObject
  $shippingDiscountId: PoshmarkStringPutObject
) {
  poshmarkPutPoshmarkListing(listingId: $listingId, title: $title, description: $description, brand: $brand, poshmarkDepartmentId: $poshmarkDepartmentId, poshmarkCategoryId: $poshmarkCategoryId, poshmarkCategoryFeatureIds: $poshmarkCategoryFeatureIds, coverShotId: $coverShotId, pictureIds: $pictureIds, videoIds: $videoIds, styleTags: $styleTags, colors: $colors, status: $status, condition: $condition, price: $price, originalPrice: $originalPrice, costPrice: $costPrice, listingSku: $listingSku, currencyCode: $currencyCode, shippingDiscountId: $shippingDiscountId) {
    success
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'brand',
    },
    v1 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'colors',
    },
    v2 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'condition',
    },
    v3 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'costPrice',
    },
    v4 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'coverShotId',
    },
    v5 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'currencyCode',
    },
    v6 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'description',
    },
    v7 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'listingId',
    },
    v8 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'listingSku',
    },
    v9 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'originalPrice',
    },
    v10 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'pictureIds',
    },
    v11 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'poshmarkCategoryFeatureIds',
    },
    v12 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'poshmarkCategoryId',
    },
    v13 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'poshmarkDepartmentId',
    },
    v14 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'price',
    },
    v15 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'shippingDiscountId',
    },
    v16 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'status',
    },
    v17 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'styleTags',
    },
    v18 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'title',
    },
    v19 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'videoIds',
    },
    v20 = [
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'brand',
            variableName: 'brand',
          },
          {
            kind: 'Variable',
            name: 'colors',
            variableName: 'colors',
          },
          {
            kind: 'Variable',
            name: 'condition',
            variableName: 'condition',
          },
          {
            kind: 'Variable',
            name: 'costPrice',
            variableName: 'costPrice',
          },
          {
            kind: 'Variable',
            name: 'coverShotId',
            variableName: 'coverShotId',
          },
          {
            kind: 'Variable',
            name: 'currencyCode',
            variableName: 'currencyCode',
          },
          {
            kind: 'Variable',
            name: 'description',
            variableName: 'description',
          },
          {
            kind: 'Variable',
            name: 'listingId',
            variableName: 'listingId',
          },
          {
            kind: 'Variable',
            name: 'listingSku',
            variableName: 'listingSku',
          },
          {
            kind: 'Variable',
            name: 'originalPrice',
            variableName: 'originalPrice',
          },
          {
            kind: 'Variable',
            name: 'pictureIds',
            variableName: 'pictureIds',
          },
          {
            kind: 'Variable',
            name: 'poshmarkCategoryFeatureIds',
            variableName: 'poshmarkCategoryFeatureIds',
          },
          {
            kind: 'Variable',
            name: 'poshmarkCategoryId',
            variableName: 'poshmarkCategoryId',
          },
          {
            kind: 'Variable',
            name: 'poshmarkDepartmentId',
            variableName: 'poshmarkDepartmentId',
          },
          {
            kind: 'Variable',
            name: 'price',
            variableName: 'price',
          },
          {
            kind: 'Variable',
            name: 'shippingDiscountId',
            variableName: 'shippingDiscountId',
          },
          {
            kind: 'Variable',
            name: 'status',
            variableName: 'status',
          },
          {
            kind: 'Variable',
            name: 'styleTags',
            variableName: 'styleTags',
          },
          {
            kind: 'Variable',
            name: 'title',
            variableName: 'title',
          },
          {
            kind: 'Variable',
            name: 'videoIds',
            variableName: 'videoIds',
          },
        ],
        concreteType: 'PoshmarkPutPoshmarkListingResponse',
        kind: 'LinkedField',
        name: 'poshmarkPutPoshmarkListing',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'success',
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: [
        v0 /*: any*/,
        v1 /*: any*/,
        v2 /*: any*/,
        v3 /*: any*/,
        v4 /*: any*/,
        v5 /*: any*/,
        v6 /*: any*/,
        v7 /*: any*/,
        v8 /*: any*/,
        v9 /*: any*/,
        v10 /*: any*/,
        v11 /*: any*/,
        v12 /*: any*/,
        v13 /*: any*/,
        v14 /*: any*/,
        v15 /*: any*/,
        v16 /*: any*/,
        v17 /*: any*/,
        v18 /*: any*/,
        v19 /*: any*/,
      ],
      kind: 'Fragment',
      metadata: null,
      name: 'putPoshmarkListingMutation',
      selections: v20 /*: any*/,
      type: 'Mutation',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [
        v7 /*: any*/,
        v18 /*: any*/,
        v6 /*: any*/,
        v0 /*: any*/,
        v13 /*: any*/,
        v12 /*: any*/,
        v11 /*: any*/,
        v4 /*: any*/,
        v10 /*: any*/,
        v19 /*: any*/,
        v17 /*: any*/,
        v1 /*: any*/,
        v16 /*: any*/,
        v2 /*: any*/,
        v14 /*: any*/,
        v9 /*: any*/,
        v3 /*: any*/,
        v8 /*: any*/,
        v5 /*: any*/,
        v15 /*: any*/,
      ],
      kind: 'Operation',
      name: 'putPoshmarkListingMutation',
      selections: v20 /*: any*/,
    },
    params: {
      cacheID: 'f947fe04ed7fddd2b7822e4c2f570a84',
      id: null,
      metadata: {},
      name: 'putPoshmarkListingMutation',
      operationKind: 'mutation',
      text: 'mutation putPoshmarkListingMutation(\n  $listingId: String!\n  $title: PoshmarkStringPutObject\n  $description: PoshmarkStringPutObject\n  $brand: PoshmarkStringPutObject\n  $poshmarkDepartmentId: PoshmarkStringPutObject\n  $poshmarkCategoryId: PoshmarkStringPutObject\n  $poshmarkCategoryFeatureIds: PoshmarkArray_StringRequired_RequiredPutObject\n  $coverShotId: PoshmarkStringPutObject\n  $pictureIds: PoshmarkArray_StringRequired_RequiredPutObject\n  $videoIds: PoshmarkArray_StringRequired_RequiredPutObject\n  $styleTags: PoshmarkArray_StringRequired_RequiredPutObject\n  $colors: PoshmarkArray_StringRequired_RequiredPutObject\n  $status: PoshmarkPoshmarkListingStatusTypePutObject\n  $condition: PoshmarkPoshmarkListingConditionTypePutObject\n  $price: PoshmarkFloatPutObject\n  $originalPrice: PoshmarkFloatPutObject\n  $costPrice: PoshmarkFloatPutObject\n  $listingSku: PoshmarkStringPutObject\n  $currencyCode: PoshmarkListingCurrencyCodeTypePutObject\n  $shippingDiscountId: PoshmarkStringPutObject\n) {\n  poshmarkPutPoshmarkListing(listingId: $listingId, title: $title, description: $description, brand: $brand, poshmarkDepartmentId: $poshmarkDepartmentId, poshmarkCategoryId: $poshmarkCategoryId, poshmarkCategoryFeatureIds: $poshmarkCategoryFeatureIds, coverShotId: $coverShotId, pictureIds: $pictureIds, videoIds: $videoIds, styleTags: $styleTags, colors: $colors, status: $status, condition: $condition, price: $price, originalPrice: $originalPrice, costPrice: $costPrice, listingSku: $listingSku, currencyCode: $currencyCode, shippingDiscountId: $shippingDiscountId) {\n    success\n  }\n}\n',
    },
  };
})();
(node as any).hash = '418f20476361c9b88801db12bb729528';
export default node;
