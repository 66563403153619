import { graphql } from 'react-relay';

export const gqlInventoryMetadata = graphql`
  query inventoryMetadataQuery {
    superposherPoshmarkUserLinks {
      id
      closetSections {
        id
        name
        listingCount
      }
      availableListingsCount
      totalListingsCount
      uncategorizedListingCount
    }
  }
`;
