import * as Lib from 'src/clients/Network/gql/lib';
import * as State from 'src/state';
import * as Build from 'src/clients/Network/gql/build';
import * as Queries from 'src/clients/Network/gql/queries';

export const closetOrderingSections =
  Lib.promisifyQuery<Build.poshmarkBotClosetOrderingSectionsQuery.poshmarkBotClosetOrderingSectionsQuery>(
    Queries.gqlPoshmarkBotClosetOrderingSections,
    [
      (args, p) => {
        p.then((_) => {
          const items = _.poshmarkBotClosetOrderingSections.items.filter((_) => _.id != null);
          State.Observe.Bots.Poshmark.ClosetOrderingSectionsValue.transform((current) => {
            return {
              ...current,
              ...mkEntityMap(items, (_) => _.id as string),
            };
          });
        });
      },
    ]
  );

export const putOfferConfiguration =
  Lib.promisifyMutation<Build.putPoshmarkBotOfferConfigurationMutation.putPoshmarkBotOfferConfigurationMutation>(
    Queries.gqlPutPoshmarkBotOfferConfiguration
  );

export const setupOfferConfigurationFeature =
  Lib.promisifyMutation<Build.setupPoshmarkBotOfferConfigurationFeatureMutation.setupPoshmarkBotOfferConfigurationFeatureMutation>(
    Queries.gqlSetupPoshmarkBotOfferConfigurationFeature
  );

export const recentRelistActivity =
  Lib.promisifyQuery<Build.poshmarkBotRecentRelistActivityQuery.poshmarkBotRecentRelistActivityQuery>(
    Queries.gqlPoshmarkBotRecentRelistActivity,
    [
      (args, p) => {
        p.then((_) => {
          const listings = _.poshmarkBotRecentRelistActivity.flatMap((_) => [
            ...(_.listing != null ? [_.listing] : []),
            ...(_.newListing != null ? [_.newListing] : []),
          ]);
          State.Observe.Bots.Poshmark.PoshmarkBotListingsValue.transform((current) => {
            return {
              ...current,
              ...mkEntityMap(listings, (_) => _.id),
            };
          });
          const events = _.poshmarkBotRecentRelistActivity.map((_) => ({
            ..._,
            listing: null,
          }));
          State.Observe.Bots.Poshmark.PoshmarkBotRecentRelistActivityEventsValue.set(events);
        });
      },
    ]
  );

export const relistSchedule = Lib.promisifyQuery<Build.poshmarkBotRelistScheduleQuery.poshmarkBotRelistScheduleQuery>(
  Queries.gqlPoshmarkBotRelistSchedule,
  [
    (args, p) => {
      p.then((_) => {
        const listings = _.poshmarkBotRelistSchedule.flatMap((_) => (_.listing != null ? [_.listing] : []));
        State.Observe.Bots.Poshmark.PoshmarkBotListingsValue.transform((current) => {
          return {
            ...current,
            ...mkEntityMap(listings, (_) => _.id),
          };
        });
        const events = _.poshmarkBotRelistSchedule.map((_) => ({
          ..._,
          listing: null,
        }));
        State.Observe.Bots.Poshmark.PoshmarkBotRelistScheduleEventsValue.set(events);
      });
    },
  ]
);

export const offerConfigurations =
  Lib.promisifyQuery<Build.poshmarkBotOfferConfigurationsQuery.poshmarkBotOfferConfigurationsQuery>(
    Queries.gqlPoshmarkBotOfferConfigurations,
    [
      (args, p) => {
        p.then((_) => {
          State.Observe.Bots.Poshmark.PoshmarkBotOfferConfigurationsValue.set([..._.poshmarkBotOfferConfigurations]);
        });
      },
    ]
  );

// Note (albert): Testing this
interface EntityMapIface<E> {
  [key: string]: E;
}

const mkEntityMap = <E extends unknown>(
  entities: E[] | readonly E[],
  idSelector: (e: E) => string
): EntityMapIface<E> => {
  const entityMap: EntityMapIface<E> = {};
  for (let i = 0; i < entities.length; i++) {
    entityMap[idSelector(entities[i])] = entities[i];
  }
  return entityMap;
};

export const offerConfigurationListings =
  Lib.promisifyQuery<Build.poshmarkBotOfferConfigurationListingsQuery.poshmarkBotOfferConfigurationListingsQuery>(
    Queries.gqlPoshmarkBotOfferConfigurationListings,
    [
      (args, p) => {
        p.then((_) => {
          State.Observe.Bots.Poshmark.OfferConfigurationListingsValue.transform((current) => {
            return {
              ...current,
              ...mkEntityMap(_.poshmarkBotOfferConfigurationListings?.items ?? [], (_) => _.listingId),
            };
          });
        });
      },
    ]
  );

export const setListingsOfferConfigurations =
  Lib.promisifyMutation<Build.setPoshmarkBotListingsOfferConfigurationMutation.setPoshmarkBotListingsOfferConfigurationMutation>(
    Queries.gqlSetPoshmarkBotListingsOfferConfiguration
  );

export const offerToLikersAllListings =
  Lib.promisifyQuery<Build.poshmarkBotOfferToLikersAllListingsQuery.poshmarkBotOfferToLikersAllListingsQuery>(
    Queries.gqlPoshmarkBotOfferToLikersAllListings,
    [
      (args, p) => {
        p.then((_) => {
          State.Observe.Bots.Poshmark.OfferConfigurationListingsValue.transform((current) => {
            return {
              ...current,
              ...mkEntityMap(_.poshmarkBotOfferToLikersAllListings?.items ?? [], (_) => _.listingId),
            };
          });
        });
      },
    ]
  );

export const deleteOfferConfigurations =
  Lib.promisifyMutation<Build.deleteBotOfferConfigurationMutation.deleteBotOfferConfigurationMutation>(
    Queries.gqlDeleteBotOfferConfiguration
  );

export const offerToLikersEvidenceLogs =
  Lib.promisifyQuery<Build.poshmarkBotOfferToLikersEvidenceLogsQuery.poshmarkBotOfferToLikersEvidenceLogsQuery>(
    Queries.gqlPoshmarkBotOfferToLikersEvidenceLogs,
    [
      (args, p) => {
        p.then((_) => {
          State.Observe.Bots.Poshmark.OfferToLikersEvidenceLogsValue.transform((current) => {
            return {
              ...current,
              ...mkEntityMap(_.poshmarkBotOfferToLikersEvidenceLogs?.items ?? [], (_) => _.listingId),
            };
          });
        });
      },
    ]
  );

export const setPoshmarkBotDefaultOfferConfiguration =
  Lib.promisifyMutation<Build.setPoshmarkBotDefaultOfferConfigurationMutation.setPoshmarkBotDefaultOfferConfigurationMutation>(
    Queries.gqlSetPoshmarkBotDefaultOfferConfiguration
  );
