/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type InvsysFinishAwsMultipartUploadPartInput = {
  etag: string;
  partNumber: number;
};
export type finishAwsMultipartUploadMutationVariables = {
  uploadId: string;
  uploadKey: string;
  parts: Array<InvsysFinishAwsMultipartUploadPartInput>;
};
export type finishAwsMultipartUploadMutationResponse = {
  readonly invsysFinishAwsMultipartUpload: {
    readonly success: boolean;
  };
};
export type finishAwsMultipartUploadMutation = {
  readonly response: finishAwsMultipartUploadMutationResponse;
  readonly variables: finishAwsMultipartUploadMutationVariables;
};

/*
mutation finishAwsMultipartUploadMutation(
  $uploadId: String!
  $uploadKey: String!
  $parts: [InvsysFinishAwsMultipartUploadPartInput!]!
) {
  invsysFinishAwsMultipartUpload(uploadId: $uploadId, uploadKey: $uploadKey, parts: $parts) {
    success
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'parts',
    },
    v1 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'uploadId',
    },
    v2 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'uploadKey',
    },
    v3 = [
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'parts',
            variableName: 'parts',
          },
          {
            kind: 'Variable',
            name: 'uploadId',
            variableName: 'uploadId',
          },
          {
            kind: 'Variable',
            name: 'uploadKey',
            variableName: 'uploadKey',
          },
        ],
        concreteType: 'InvsysFinishAwsMultipartUploadResponse',
        kind: 'LinkedField',
        name: 'invsysFinishAwsMultipartUpload',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'success',
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: [v0 /*: any*/, v1 /*: any*/, v2 /*: any*/],
      kind: 'Fragment',
      metadata: null,
      name: 'finishAwsMultipartUploadMutation',
      selections: v3 /*: any*/,
      type: 'Mutation',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [v1 /*: any*/, v2 /*: any*/, v0 /*: any*/],
      kind: 'Operation',
      name: 'finishAwsMultipartUploadMutation',
      selections: v3 /*: any*/,
    },
    params: {
      cacheID: '28e723aa7b5e915a6e5b45ffeeb9a210',
      id: null,
      metadata: {},
      name: 'finishAwsMultipartUploadMutation',
      operationKind: 'mutation',
      text: 'mutation finishAwsMultipartUploadMutation(\n  $uploadId: String!\n  $uploadKey: String!\n  $parts: [InvsysFinishAwsMultipartUploadPartInput!]!\n) {\n  invsysFinishAwsMultipartUpload(uploadId: $uploadId, uploadKey: $uploadKey, parts: $parts) {\n    success\n  }\n}\n',
    },
  };
})();
(node as any).hash = '0991c9c82ce02f0388b1ec7ac595ad18';
export default node;
