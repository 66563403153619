import { graphql } from 'react-relay';

export const gqlMercariListing = graphql`
  query mercariListingQuery($listingId: String!) {
    mercariMercariListing(listingId: $listingId) {
      listingId
      categoryId
      conditionId
      description
      imei
      minPriceForAutoDrop
      name
      price
      shippingClassIds
      brandId
      shippingPackageWeight
      shippingPayerId
      suggestedShippingClassIds
      shippingType
      tags
      zipCode
      publishStatusV2 {
        status
        message
      }
      colorId
      dressOccasion
      dressStyle
      material
      shaftHeight
      endUse
      heelHeight
      heelType
      items {
        mercariListingId
        mercariId
        itemId
        publishStatusV2 {
          status
          message
        }
        sizeId
      }
      priceSuggestion {
        suggestedPrice
        feeParts {
          value
          label
        }
      }
      shippingInfo {
        shippingPrice
        shippingProvider
        shippingProviderDisplay
        payerContributions {
          contribution
          shippingPayer
          shippingPayerDisplay
        }
      }
      sizeSuggestion {
        sizeSuggestions {
          id
          display
          sizeSystem
        }
      }
    }
  }
`;
