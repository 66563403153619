/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type InvsysColorType =
  | 'BLACK'
  | 'BLUE'
  | 'BROWN'
  | 'CREAM'
  | 'GOLD'
  | 'GRAY'
  | 'GREEN'
  | 'ORANGE'
  | 'PINK'
  | 'PURPLE'
  | 'RED'
  | 'SILVER'
  | 'TAN'
  | 'WHITE'
  | 'YELLOW'
  | '%future added value';
export type InvsysListingConditionType =
  | 'NEW_WITHOUT_TAGS'
  | 'NEW_WITH_DEFECTS'
  | 'NEW_WITH_TAGS'
  | 'POOR'
  | 'PRE_OWNED'
  | '%future added value';
export type InvsysListingCurrencyCodeType = 'AUD' | 'CAD' | 'USD' | '%future added value';
export type InvsysSizeUnitType = 'CENTIMETERS' | 'INCHES' | '%future added value';
export type InvsysWeightUnitType = 'GRAMS' | 'KILOGRAMS' | 'OUNCES' | 'POUNDS' | '%future added value';
export type InvsysStringPutObject = {
  value?: string | null;
};
export type InvsysArray_StringRequired_RequiredPutObject = {
  value: Array<string>;
};
export type InvsysListingConditionTypePutObject = {
  value?: InvsysListingConditionType | null;
};
export type InvsysFloatPutObject = {
  value?: number | null;
};
export type InvsysArray_ColorTypeRequired_RequiredPutObject = {
  value: Array<InvsysColorType>;
};
export type InvsysListingCurrencyCodeTypePutObject = {
  value?: InvsysListingCurrencyCodeType | null;
};
export type InvsysWeightUnitTypePutObject = {
  value?: InvsysWeightUnitType | null;
};
export type InvsysSizeUnitTypePutObject = {
  value?: InvsysSizeUnitType | null;
};
export type InvsysIntPutObject = {
  value?: number | null;
};
export type putItemMutationVariables = {
  id?: string | null;
  title?: InvsysStringPutObject | null;
  description?: InvsysStringPutObject | null;
  mediaIds?: InvsysArray_StringRequired_RequiredPutObject | null;
  condition?: InvsysListingConditionTypePutObject | null;
  brand?: InvsysStringPutObject | null;
  retailPrice?: InvsysFloatPutObject | null;
  costPrice?: InvsysFloatPutObject | null;
  colors?: InvsysArray_ColorTypeRequired_RequiredPutObject | null;
  tags?: InvsysArray_StringRequired_RequiredPutObject | null;
  notes?: InvsysArray_StringRequired_RequiredPutObject | null;
  skuAlias?: InvsysStringPutObject | null;
  currencyCode?: InvsysListingCurrencyCodeTypePutObject | null;
  weight?: InvsysFloatPutObject | null;
  weightUnit?: InvsysWeightUnitTypePutObject | null;
  width?: InvsysFloatPutObject | null;
  length?: InvsysFloatPutObject | null;
  height?: InvsysFloatPutObject | null;
  sizeUnit?: InvsysSizeUnitTypePutObject | null;
  categoryId?: InvsysStringPutObject | null;
  packageSizeType?: InvsysIntPutObject | null;
};
export type putItemMutationResponse = {
  readonly invsysPutItem: {
    readonly success: boolean | null;
    readonly data: {
      readonly id: string;
    } | null;
  };
};
export type putItemMutation = {
  readonly response: putItemMutationResponse;
  readonly variables: putItemMutationVariables;
};

/*
mutation putItemMutation(
  $id: String
  $title: InvsysStringPutObject
  $description: InvsysStringPutObject
  $mediaIds: InvsysArray_StringRequired_RequiredPutObject
  $condition: InvsysListingConditionTypePutObject
  $brand: InvsysStringPutObject
  $retailPrice: InvsysFloatPutObject
  $costPrice: InvsysFloatPutObject
  $colors: InvsysArray_ColorTypeRequired_RequiredPutObject
  $tags: InvsysArray_StringRequired_RequiredPutObject
  $notes: InvsysArray_StringRequired_RequiredPutObject
  $skuAlias: InvsysStringPutObject
  $currencyCode: InvsysListingCurrencyCodeTypePutObject
  $weight: InvsysFloatPutObject
  $weightUnit: InvsysWeightUnitTypePutObject
  $width: InvsysFloatPutObject
  $length: InvsysFloatPutObject
  $height: InvsysFloatPutObject
  $sizeUnit: InvsysSizeUnitTypePutObject
  $categoryId: InvsysStringPutObject
  $packageSizeType: InvsysIntPutObject
) {
  invsysPutItem(id: $id, title: $title, description: $description, mediaIds: $mediaIds, condition: $condition, brand: $brand, retailPrice: $retailPrice, costPrice: $costPrice, colors: $colors, tags: $tags, notes: $notes, skuAlias: $skuAlias, currencyCode: $currencyCode, weight: $weight, weightUnit: $weightUnit, width: $width, length: $length, height: $height, sizeUnit: $sizeUnit, categoryId: $categoryId, packageSizeType: $packageSizeType) {
    success
    data {
      id
    }
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'brand',
    },
    v1 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'categoryId',
    },
    v2 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'colors',
    },
    v3 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'condition',
    },
    v4 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'costPrice',
    },
    v5 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'currencyCode',
    },
    v6 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'description',
    },
    v7 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'height',
    },
    v8 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'id',
    },
    v9 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'length',
    },
    v10 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'mediaIds',
    },
    v11 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'notes',
    },
    v12 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'packageSizeType',
    },
    v13 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'retailPrice',
    },
    v14 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'sizeUnit',
    },
    v15 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'skuAlias',
    },
    v16 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'tags',
    },
    v17 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'title',
    },
    v18 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'weight',
    },
    v19 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'weightUnit',
    },
    v20 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'width',
    },
    v21 = [
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'brand',
            variableName: 'brand',
          },
          {
            kind: 'Variable',
            name: 'categoryId',
            variableName: 'categoryId',
          },
          {
            kind: 'Variable',
            name: 'colors',
            variableName: 'colors',
          },
          {
            kind: 'Variable',
            name: 'condition',
            variableName: 'condition',
          },
          {
            kind: 'Variable',
            name: 'costPrice',
            variableName: 'costPrice',
          },
          {
            kind: 'Variable',
            name: 'currencyCode',
            variableName: 'currencyCode',
          },
          {
            kind: 'Variable',
            name: 'description',
            variableName: 'description',
          },
          {
            kind: 'Variable',
            name: 'height',
            variableName: 'height',
          },
          {
            kind: 'Variable',
            name: 'id',
            variableName: 'id',
          },
          {
            kind: 'Variable',
            name: 'length',
            variableName: 'length',
          },
          {
            kind: 'Variable',
            name: 'mediaIds',
            variableName: 'mediaIds',
          },
          {
            kind: 'Variable',
            name: 'notes',
            variableName: 'notes',
          },
          {
            kind: 'Variable',
            name: 'packageSizeType',
            variableName: 'packageSizeType',
          },
          {
            kind: 'Variable',
            name: 'retailPrice',
            variableName: 'retailPrice',
          },
          {
            kind: 'Variable',
            name: 'sizeUnit',
            variableName: 'sizeUnit',
          },
          {
            kind: 'Variable',
            name: 'skuAlias',
            variableName: 'skuAlias',
          },
          {
            kind: 'Variable',
            name: 'tags',
            variableName: 'tags',
          },
          {
            kind: 'Variable',
            name: 'title',
            variableName: 'title',
          },
          {
            kind: 'Variable',
            name: 'weight',
            variableName: 'weight',
          },
          {
            kind: 'Variable',
            name: 'weightUnit',
            variableName: 'weightUnit',
          },
          {
            kind: 'Variable',
            name: 'width',
            variableName: 'width',
          },
        ],
        concreteType: 'InvsysPutItemResponse',
        kind: 'LinkedField',
        name: 'invsysPutItem',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'success',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            concreteType: 'InvsysSkuAttribute',
            kind: 'LinkedField',
            name: 'data',
            plural: false,
            selections: [
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'id',
                storageKey: null,
              },
            ],
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: [
        v0 /*: any*/,
        v1 /*: any*/,
        v2 /*: any*/,
        v3 /*: any*/,
        v4 /*: any*/,
        v5 /*: any*/,
        v6 /*: any*/,
        v7 /*: any*/,
        v8 /*: any*/,
        v9 /*: any*/,
        v10 /*: any*/,
        v11 /*: any*/,
        v12 /*: any*/,
        v13 /*: any*/,
        v14 /*: any*/,
        v15 /*: any*/,
        v16 /*: any*/,
        v17 /*: any*/,
        v18 /*: any*/,
        v19 /*: any*/,
        v20 /*: any*/,
      ],
      kind: 'Fragment',
      metadata: null,
      name: 'putItemMutation',
      selections: v21 /*: any*/,
      type: 'Mutation',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [
        v8 /*: any*/,
        v17 /*: any*/,
        v6 /*: any*/,
        v10 /*: any*/,
        v3 /*: any*/,
        v0 /*: any*/,
        v13 /*: any*/,
        v4 /*: any*/,
        v2 /*: any*/,
        v16 /*: any*/,
        v11 /*: any*/,
        v15 /*: any*/,
        v5 /*: any*/,
        v18 /*: any*/,
        v19 /*: any*/,
        v20 /*: any*/,
        v9 /*: any*/,
        v7 /*: any*/,
        v14 /*: any*/,
        v1 /*: any*/,
        v12 /*: any*/,
      ],
      kind: 'Operation',
      name: 'putItemMutation',
      selections: v21 /*: any*/,
    },
    params: {
      cacheID: '92b0cf140096f67094d9705b7b7eff21',
      id: null,
      metadata: {},
      name: 'putItemMutation',
      operationKind: 'mutation',
      text: 'mutation putItemMutation(\n  $id: String\n  $title: InvsysStringPutObject\n  $description: InvsysStringPutObject\n  $mediaIds: InvsysArray_StringRequired_RequiredPutObject\n  $condition: InvsysListingConditionTypePutObject\n  $brand: InvsysStringPutObject\n  $retailPrice: InvsysFloatPutObject\n  $costPrice: InvsysFloatPutObject\n  $colors: InvsysArray_ColorTypeRequired_RequiredPutObject\n  $tags: InvsysArray_StringRequired_RequiredPutObject\n  $notes: InvsysArray_StringRequired_RequiredPutObject\n  $skuAlias: InvsysStringPutObject\n  $currencyCode: InvsysListingCurrencyCodeTypePutObject\n  $weight: InvsysFloatPutObject\n  $weightUnit: InvsysWeightUnitTypePutObject\n  $width: InvsysFloatPutObject\n  $length: InvsysFloatPutObject\n  $height: InvsysFloatPutObject\n  $sizeUnit: InvsysSizeUnitTypePutObject\n  $categoryId: InvsysStringPutObject\n  $packageSizeType: InvsysIntPutObject\n) {\n  invsysPutItem(id: $id, title: $title, description: $description, mediaIds: $mediaIds, condition: $condition, brand: $brand, retailPrice: $retailPrice, costPrice: $costPrice, colors: $colors, tags: $tags, notes: $notes, skuAlias: $skuAlias, currencyCode: $currencyCode, weight: $weight, weightUnit: $weightUnit, width: $width, length: $length, height: $height, sizeUnit: $sizeUnit, categoryId: $categoryId, packageSizeType: $packageSizeType) {\n    success\n    data {\n      id\n    }\n  }\n}\n',
    },
  };
})();
(node as any).hash = '903b57535013c123ba2ee8e6b55a008e';
export default node;
