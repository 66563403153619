/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type MercariMercariListingColorType =
  | 'BEIGE'
  | 'BLACK'
  | 'BLUE'
  | 'BROWN'
  | 'GOLD'
  | 'GREEN'
  | 'GREY'
  | 'NONE'
  | 'ORANGE'
  | 'PINK'
  | 'PURPLE'
  | 'RED'
  | 'SILVER'
  | 'WHITE'
  | 'YELLOW'
  | '%future added value';
export type MercariMercariListingConditionType = 'FAIR' | 'GOOD' | 'LIKE_NEW' | 'NEW' | 'POOR' | '%future added value';
export type mercariListingQueryVariables = {
  listingId: string;
};
export type mercariListingQueryResponse = {
  readonly mercariMercariListing: {
    readonly listingId: string;
    readonly categoryId: number | null;
    readonly conditionId: MercariMercariListingConditionType | null;
    readonly description: string | null;
    readonly imei: string | null;
    readonly minPriceForAutoDrop: number | null;
    readonly name: string | null;
    readonly price: number | null;
    readonly shippingClassIds: ReadonlyArray<number>;
    readonly brandId: string | null;
    readonly shippingPackageWeight: number | null;
    readonly shippingPayerId: number | null;
    readonly suggestedShippingClassIds: ReadonlyArray<number>;
    readonly shippingType: number | null;
    readonly tags: ReadonlyArray<string>;
    readonly zipCode: number | null;
    readonly publishStatusV2: {
      readonly status: string | null;
      readonly message: string | null;
    } | null;
    readonly colorId: MercariMercariListingColorType | null;
    readonly dressOccasion: string | null;
    readonly dressStyle: string | null;
    readonly material: string | null;
    readonly shaftHeight: string | null;
    readonly endUse: string | null;
    readonly heelHeight: string | null;
    readonly heelType: string | null;
    readonly items: ReadonlyArray<{
      readonly mercariListingId: string;
      readonly mercariId: string | null;
      readonly itemId: string;
      readonly publishStatusV2: {
        readonly status: string | null;
        readonly message: string | null;
      } | null;
      readonly sizeId: number | null;
    }>;
    readonly priceSuggestion: {
      readonly suggestedPrice: number;
      readonly feeParts: ReadonlyArray<{
        readonly value: number;
        readonly label: string;
      }>;
    } | null;
    readonly shippingInfo: {
      readonly shippingPrice: number | null;
      readonly shippingProvider: number;
      readonly shippingProviderDisplay: string;
      readonly payerContributions: ReadonlyArray<{
        readonly contribution: number | null;
        readonly shippingPayer: number | null;
        readonly shippingPayerDisplay: string | null;
      }>;
    } | null;
    readonly sizeSuggestion: {
      readonly sizeSuggestions: ReadonlyArray<{
        readonly id: string;
        readonly display: string;
        readonly sizeSystem: string;
      }>;
    } | null;
  } | null;
};
export type mercariListingQuery = {
  readonly response: mercariListingQueryResponse;
  readonly variables: mercariListingQueryVariables;
};

/*
query mercariListingQuery(
  $listingId: String!
) {
  mercariMercariListing(listingId: $listingId) {
    listingId
    categoryId
    conditionId
    description
    imei
    minPriceForAutoDrop
    name
    price
    shippingClassIds
    brandId
    shippingPackageWeight
    shippingPayerId
    suggestedShippingClassIds
    shippingType
    tags
    zipCode
    publishStatusV2 {
      status
      message
    }
    colorId
    dressOccasion
    dressStyle
    material
    shaftHeight
    endUse
    heelHeight
    heelType
    items {
      mercariListingId
      mercariId
      itemId
      publishStatusV2 {
        status
        message
      }
      sizeId
    }
    priceSuggestion {
      suggestedPrice
      feeParts {
        value
        label
      }
    }
    shippingInfo {
      shippingPrice
      shippingProvider
      shippingProviderDisplay
      payerContributions {
        contribution
        shippingPayer
        shippingPayerDisplay
      }
    }
    sizeSuggestion {
      sizeSuggestions {
        id
        display
        sizeSystem
      }
    }
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'listingId',
      },
    ],
    v1 = {
      alias: null,
      args: null,
      concreteType: 'MercariPublishStatusV2',
      kind: 'LinkedField',
      name: 'publishStatusV2',
      plural: false,
      selections: [
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'status',
          storageKey: null,
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'message',
          storageKey: null,
        },
      ],
      storageKey: null,
    },
    v2 = [
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'listingId',
            variableName: 'listingId',
          },
        ],
        concreteType: 'MercariMercariListing',
        kind: 'LinkedField',
        name: 'mercariMercariListing',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'listingId',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'categoryId',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'conditionId',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'description',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'imei',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'minPriceForAutoDrop',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'name',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'price',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'shippingClassIds',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'brandId',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'shippingPackageWeight',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'shippingPayerId',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'suggestedShippingClassIds',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'shippingType',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'tags',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'zipCode',
            storageKey: null,
          },
          v1 /*: any*/,
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'colorId',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'dressOccasion',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'dressStyle',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'material',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'shaftHeight',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'endUse',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'heelHeight',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'heelType',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            concreteType: 'MercariMercariItem',
            kind: 'LinkedField',
            name: 'items',
            plural: true,
            selections: [
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'mercariListingId',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'mercariId',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'itemId',
                storageKey: null,
              },
              v1 /*: any*/,
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'sizeId',
                storageKey: null,
              },
            ],
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            concreteType: 'MercariMercariPriceSuggestion',
            kind: 'LinkedField',
            name: 'priceSuggestion',
            plural: false,
            selections: [
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'suggestedPrice',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                concreteType: 'MercariMercariPriceSuggestionFeePart',
                kind: 'LinkedField',
                name: 'feeParts',
                plural: true,
                selections: [
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'value',
                    storageKey: null,
                  },
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'label',
                    storageKey: null,
                  },
                ],
                storageKey: null,
              },
            ],
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            concreteType: 'MercariShippingInfo',
            kind: 'LinkedField',
            name: 'shippingInfo',
            plural: false,
            selections: [
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'shippingPrice',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'shippingProvider',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'shippingProviderDisplay',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                concreteType: 'MercariShippingPayerContribution',
                kind: 'LinkedField',
                name: 'payerContributions',
                plural: true,
                selections: [
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'contribution',
                    storageKey: null,
                  },
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'shippingPayer',
                    storageKey: null,
                  },
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'shippingPayerDisplay',
                    storageKey: null,
                  },
                ],
                storageKey: null,
              },
            ],
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            concreteType: 'MercariSizeSuggestion',
            kind: 'LinkedField',
            name: 'sizeSuggestion',
            plural: false,
            selections: [
              {
                alias: null,
                args: null,
                concreteType: 'MercariSizeSuggestionSize',
                kind: 'LinkedField',
                name: 'sizeSuggestions',
                plural: true,
                selections: [
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'id',
                    storageKey: null,
                  },
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'display',
                    storageKey: null,
                  },
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'sizeSystem',
                    storageKey: null,
                  },
                ],
                storageKey: null,
              },
            ],
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'mercariListingQuery',
      selections: v2 /*: any*/,
      type: 'Query',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'mercariListingQuery',
      selections: v2 /*: any*/,
    },
    params: {
      cacheID: '3a988b52fff547456c6317c00b44a986',
      id: null,
      metadata: {},
      name: 'mercariListingQuery',
      operationKind: 'query',
      text: 'query mercariListingQuery(\n  $listingId: String!\n) {\n  mercariMercariListing(listingId: $listingId) {\n    listingId\n    categoryId\n    conditionId\n    description\n    imei\n    minPriceForAutoDrop\n    name\n    price\n    shippingClassIds\n    brandId\n    shippingPackageWeight\n    shippingPayerId\n    suggestedShippingClassIds\n    shippingType\n    tags\n    zipCode\n    publishStatusV2 {\n      status\n      message\n    }\n    colorId\n    dressOccasion\n    dressStyle\n    material\n    shaftHeight\n    endUse\n    heelHeight\n    heelType\n    items {\n      mercariListingId\n      mercariId\n      itemId\n      publishStatusV2 {\n        status\n        message\n      }\n      sizeId\n    }\n    priceSuggestion {\n      suggestedPrice\n      feeParts {\n        value\n        label\n      }\n    }\n    shippingInfo {\n      shippingPrice\n      shippingProvider\n      shippingProviderDisplay\n      payerContributions {\n        contribution\n        shippingPayer\n        shippingPayerDisplay\n      }\n    }\n    sizeSuggestion {\n      sizeSuggestions {\n        id\n        display\n        sizeSystem\n      }\n    }\n  }\n}\n',
    },
  };
})();
(node as any).hash = '869cd7c1b5bd75bed61f7c4753cf50c2';
export default node;
