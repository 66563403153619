import { graphql } from 'react-relay';

export const gqlStatBuckets = graphql`
  query statBucketsQuery {
    superposherPoshmarkUserLinks {
      id
      statBucket7DayLookBack {
        at
        shareToFollowersFinished
        shareToPartiesFinished
        shareForSharesFinished
        followForFollowsFinished
        followUserFollowersFinished
        offerToLikersFinished
        relistingsFinished
        relistingsToPoshmarkFinished
      }
      statBucket24HourLookBack {
        at
        shareToFollowersFinished
        shareToPartiesFinished
        shareForSharesFinished
        followForFollowsFinished
        followUserFollowersFinished
        offerToLikersFinished
        relistingsFinished
        relistingsToPoshmarkFinished
      }
      statBucket48HourLookBack {
        at
        shareToFollowersFinished
        shareToPartiesFinished
        shareForSharesFinished
        followForFollowsFinished
        followUserFollowersFinished
        offerToLikersFinished
        relistingsFinished
        relistingsToPoshmarkFinished
      }
      statBucketToday {
        at
        shareToFollowersFinished
        shareToPartiesFinished
        shareForSharesFinished
        followForFollowsFinished
        followUserFollowersFinished
        offerToLikersFinished
        relistingsFinished
        relistingsToPoshmarkFinished
      }
    }
  }
`;
