/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type startInstitutionLinkMutationVariables = {
  institution: number;
  username?: string | null;
  password?: string | null;
};
export type startInstitutionLinkMutationResponse = {
  readonly invsysStartInstitutionLink: {
    readonly requestId: string | null;
  };
};
export type startInstitutionLinkMutation = {
  readonly response: startInstitutionLinkMutationResponse;
  readonly variables: startInstitutionLinkMutationVariables;
};

/*
mutation startInstitutionLinkMutation(
  $institution: Int!
  $username: String
  $password: String
) {
  invsysStartInstitutionLink(institution: $institution, username: $username, password: $password) {
    requestId
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'institution',
    },
    v1 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'password',
    },
    v2 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'username',
    },
    v3 = [
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'institution',
            variableName: 'institution',
          },
          {
            kind: 'Variable',
            name: 'password',
            variableName: 'password',
          },
          {
            kind: 'Variable',
            name: 'username',
            variableName: 'username',
          },
        ],
        concreteType: 'InvsysStartInstitutionLinkResponse',
        kind: 'LinkedField',
        name: 'invsysStartInstitutionLink',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'requestId',
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: [v0 /*: any*/, v1 /*: any*/, v2 /*: any*/],
      kind: 'Fragment',
      metadata: null,
      name: 'startInstitutionLinkMutation',
      selections: v3 /*: any*/,
      type: 'Mutation',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [v0 /*: any*/, v2 /*: any*/, v1 /*: any*/],
      kind: 'Operation',
      name: 'startInstitutionLinkMutation',
      selections: v3 /*: any*/,
    },
    params: {
      cacheID: 'e059ec2279fd53f39dd35632ff01b5ea',
      id: null,
      metadata: {},
      name: 'startInstitutionLinkMutation',
      operationKind: 'mutation',
      text: 'mutation startInstitutionLinkMutation(\n  $institution: Int!\n  $username: String\n  $password: String\n) {\n  invsysStartInstitutionLink(institution: $institution, username: $username, password: $password) {\n    requestId\n  }\n}\n',
    },
  };
})();
(node as any).hash = '72775e027d5fe794ab964b8980aabc67';
export default node;
