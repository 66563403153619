/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type invsysCheckShopUsernameQueryVariables = {
  username: string;
};
export type invsysCheckShopUsernameQueryResponse = {
  readonly invsysCheckShopUsername: {
    readonly valid: boolean;
    readonly errorMessage: string | null;
  };
};
export type invsysCheckShopUsernameQuery = {
  readonly response: invsysCheckShopUsernameQueryResponse;
  readonly variables: invsysCheckShopUsernameQueryVariables;
};

/*
query invsysCheckShopUsernameQuery(
  $username: String!
) {
  invsysCheckShopUsername(username: $username) {
    valid
    errorMessage
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'username',
      },
    ],
    v1 = [
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'username',
            variableName: 'username',
          },
        ],
        concreteType: 'InvsysCheckShopUsernameResponse',
        kind: 'LinkedField',
        name: 'invsysCheckShopUsername',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'valid',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'errorMessage',
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'invsysCheckShopUsernameQuery',
      selections: v1 /*: any*/,
      type: 'Query',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'invsysCheckShopUsernameQuery',
      selections: v1 /*: any*/,
    },
    params: {
      cacheID: 'a0ee1aa7666d9eb5ec10e76a03e672ba',
      id: null,
      metadata: {},
      name: 'invsysCheckShopUsernameQuery',
      operationKind: 'query',
      text: 'query invsysCheckShopUsernameQuery(\n  $username: String!\n) {\n  invsysCheckShopUsername(username: $username) {\n    valid\n    errorMessage\n  }\n}\n',
    },
  };
})();
(node as any).hash = '50a8a7654259fdb74a370d3cf83dfc58';
export default node;
