/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type requestListingMediaGroupVariantMutationVariables = {
  listingMediaGroupId: string;
  membershipIds: Array<string>;
  variantType: number;
};
export type requestListingMediaGroupVariantMutationResponse = {
  readonly studioRequestListingMediaGroupVariant: {
    readonly success: boolean;
  };
};
export type requestListingMediaGroupVariantMutation = {
  readonly response: requestListingMediaGroupVariantMutationResponse;
  readonly variables: requestListingMediaGroupVariantMutationVariables;
};

/*
mutation requestListingMediaGroupVariantMutation(
  $listingMediaGroupId: String!
  $membershipIds: [String!]!
  $variantType: Int!
) {
  studioRequestListingMediaGroupVariant(listingMediaGroupId: $listingMediaGroupId, membershipIds: $membershipIds, variantType: $variantType) {
    success
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'listingMediaGroupId',
      },
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'membershipIds',
      },
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'variantType',
      },
    ],
    v1 = [
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'listingMediaGroupId',
            variableName: 'listingMediaGroupId',
          },
          {
            kind: 'Variable',
            name: 'membershipIds',
            variableName: 'membershipIds',
          },
          {
            kind: 'Variable',
            name: 'variantType',
            variableName: 'variantType',
          },
        ],
        concreteType: 'StudioRequestListingMediaGroupVariantResponse',
        kind: 'LinkedField',
        name: 'studioRequestListingMediaGroupVariant',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'success',
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'requestListingMediaGroupVariantMutation',
      selections: v1 /*: any*/,
      type: 'Mutation',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'requestListingMediaGroupVariantMutation',
      selections: v1 /*: any*/,
    },
    params: {
      cacheID: '0f94b4887c460b15a3f4a2efb86d4654',
      id: null,
      metadata: {},
      name: 'requestListingMediaGroupVariantMutation',
      operationKind: 'mutation',
      text: 'mutation requestListingMediaGroupVariantMutation(\n  $listingMediaGroupId: String!\n  $membershipIds: [String!]!\n  $variantType: Int!\n) {\n  studioRequestListingMediaGroupVariant(listingMediaGroupId: $listingMediaGroupId, membershipIds: $membershipIds, variantType: $variantType) {\n    success\n  }\n}\n',
    },
  };
})();
(node as any).hash = '9fcfd8403a5faefbbe18b6527655a649';
export default node;
