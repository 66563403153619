import { graphql } from 'react-relay';

export const gqlValidateListing = graphql`
  query validateListingQuery($id: String!) {
    invsysListing(id: $id) {
      validationResult {
        success
      }
    }
  }
`;
