import { graphql } from 'react-relay';

export const gqlDepopListing = graphql`
  query depopListingQuery($listingId: String!) {
    depopDepopListing(listingId: $listingId) {
      id
      depopId
      listingId
      categoryId
      categoryIdV2
      description
      price
      currencyCode
      brandId
      condition
      color
      occasion
      material
      bodyFit
      sources
      ages
      style
      variant_set_id
      national_shipping_price
      international_shipping_price
      shippingType
      address
      addressId
      country_code
      geo_latitude
      geo_longitude
      priceSuggestion {
        suggestedPrice
        feeParts {
          value
          label
        }
      }
      shippingInfo {
        shippingPrice
        shippingProvider
        shippingProviderDisplay
        payerContributions {
          contribution
          shippingPayer
          shippingPayerDisplay
        }
      }
      sizeSuggestion {
        sizeSuggestions {
          id
          display
          sizeSystem
        }
      }
      publishStatusV2 {
        status
        message
      }
      items {
        depopListingId
        itemId
        size
      }
    }
  }
`;
