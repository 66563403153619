/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type scheduledListingsSummaryQueryVariables = {};
export type scheduledListingsSummaryQueryResponse = {
  readonly invsysScheduledListingsSummary: {
    readonly scheduledListingsCount: number;
    readonly scheduledListings: {
      readonly items: ReadonlyArray<{
        readonly listingId: string;
        readonly title: string | null;
        readonly mediaUrl: string | null;
        readonly institution: number;
        readonly scheduleAt: number | null;
      }>;
    };
  };
};
export type scheduledListingsSummaryQuery = {
  readonly response: scheduledListingsSummaryQueryResponse;
  readonly variables: scheduledListingsSummaryQueryVariables;
};

/*
query scheduledListingsSummaryQuery {
  invsysScheduledListingsSummary {
    scheduledListingsCount
    scheduledListings {
      items {
        listingId
        title
        mediaUrl
        institution
        scheduleAt
      }
    }
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = [
    {
      alias: null,
      args: null,
      concreteType: 'InvsysScheduledListingsSummary',
      kind: 'LinkedField',
      name: 'invsysScheduledListingsSummary',
      plural: false,
      selections: [
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'scheduledListingsCount',
          storageKey: null,
        },
        {
          alias: null,
          args: null,
          concreteType: 'InvsysScheduledToPostListingsPage',
          kind: 'LinkedField',
          name: 'scheduledListings',
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              concreteType: 'InvsysScheduledToPostListing',
              kind: 'LinkedField',
              name: 'items',
              plural: true,
              selections: [
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'listingId',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'title',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'mediaUrl',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'institution',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'scheduleAt',
                  storageKey: null,
                },
              ],
              storageKey: null,
            },
          ],
          storageKey: null,
        },
      ],
      storageKey: null,
    },
  ];
  return {
    fragment: {
      argumentDefinitions: [],
      kind: 'Fragment',
      metadata: null,
      name: 'scheduledListingsSummaryQuery',
      selections: v0 /*: any*/,
      type: 'Query',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [],
      kind: 'Operation',
      name: 'scheduledListingsSummaryQuery',
      selections: v0 /*: any*/,
    },
    params: {
      cacheID: 'fe0514b3a3c631226584ee6474318f72',
      id: null,
      metadata: {},
      name: 'scheduledListingsSummaryQuery',
      operationKind: 'query',
      text: 'query scheduledListingsSummaryQuery {\n  invsysScheduledListingsSummary {\n    scheduledListingsCount\n    scheduledListings {\n      items {\n        listingId\n        title\n        mediaUrl\n        institution\n        scheduleAt\n      }\n    }\n  }\n}\n',
    },
  };
})();
(node as any).hash = 'e1d002c33d8c46c467d7c252431e9ace';
export default node;
