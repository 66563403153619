/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from 'relay-runtime';
export type addressFragment_full = {
  readonly id: string;
  readonly streetAddress: string;
  readonly unit: string | null;
  readonly locality: string;
  readonly subdivision: string;
  readonly postalCode: string;
  readonly countryCode: string;
  readonly postalCodeFormat: string;
  readonly isDefaultShipping: boolean;
  readonly isDefaultPickup: boolean;
  readonly ' $refType': 'addressFragment_full';
};
export type addressFragment_full$data = addressFragment_full;
export type addressFragment_full$key = {
  readonly ' $data'?: addressFragment_full$data;
  readonly ' $fragmentRefs': FragmentRefs<'addressFragment_full'>;
};

const node: ReaderInlineDataFragment = {
  kind: 'InlineDataFragment',
  name: 'addressFragment_full',
};
(node as any).hash = 'c09f3bffe149a145f3675e9287007ca6';
export default node;
