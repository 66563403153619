/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type setMercariFeatureStatusesMutationVariables = {
  autoRelist: boolean;
};
export type setMercariFeatureStatusesMutationResponse = {
  readonly mercariSetMercariFeatureStatuses: {
    readonly success: boolean;
  } | null;
};
export type setMercariFeatureStatusesMutation = {
  readonly response: setMercariFeatureStatusesMutationResponse;
  readonly variables: setMercariFeatureStatusesMutationVariables;
};

/*
mutation setMercariFeatureStatusesMutation(
  $autoRelist: Boolean!
) {
  mercariSetMercariFeatureStatuses(autoRelist: $autoRelist) {
    success
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'autoRelist',
      },
    ],
    v1 = [
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'autoRelist',
            variableName: 'autoRelist',
          },
        ],
        concreteType: 'MercariSetMercariFeatureStatusesResponse',
        kind: 'LinkedField',
        name: 'mercariSetMercariFeatureStatuses',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'success',
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'setMercariFeatureStatusesMutation',
      selections: v1 /*: any*/,
      type: 'Mutation',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'setMercariFeatureStatusesMutation',
      selections: v1 /*: any*/,
    },
    params: {
      cacheID: 'e9f70d204d57d0279d3df1907e7d35cf',
      id: null,
      metadata: {},
      name: 'setMercariFeatureStatusesMutation',
      operationKind: 'mutation',
      text: 'mutation setMercariFeatureStatusesMutation(\n  $autoRelist: Boolean!\n) {\n  mercariSetMercariFeatureStatuses(autoRelist: $autoRelist) {\n    success\n  }\n}\n',
    },
  };
})();
(node as any).hash = '152e388cc7755072e14d337e8dc9e6e1';
export default node;
