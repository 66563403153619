/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type TradesyTradesyHeelHeightType = 'FLAT' | 'HIGH' | 'LOW' | 'MEDIUM' | 'ULTRA_HIGH' | '%future added value';
export type TradesyTradesyHeelStyleType = 'CHUNKY' | 'KITTEN' | 'SLIM' | 'STILETTO' | 'WEDGE' | '%future added value';
export type TradesyTradesyLengthType = 'LONG' | 'MID_LENGTH' | 'SHORT' | '%future added value';
export type TradesyTradesyShoeSizeByCountryType = 'EU' | 'US' | '%future added value';
export type TradesyTradesyShoeWidthType = 'EXTRA_WIDE' | 'NARROW' | 'REGULAR' | 'WIDE' | '%future added value';
export type TradesyTradesyVeilLengthType = 'BIRDCAGE' | 'LONG' | 'MEDIUM' | 'SHORT' | '%future added value';
export type TradesyTradesyWashlookType =
  | 'ACID'
  | 'COATED'
  | 'DARK_RINSE'
  | 'DISTRESSED'
  | 'LIGHT_WASH'
  | 'MEDIUM_WASH'
  | '%future added value';
export type tradesyListingQueryVariables = {
  listingId: string;
};
export type tradesyListingQueryResponse = {
  readonly tradesyTradesyListing: {
    readonly listingId: string;
    readonly createdAt: number;
    readonly updatedAt: number;
    readonly items: ReadonlyArray<{
      readonly itemId: string;
      readonly tradesyListingId: string;
      readonly tradesyId: string | null;
      readonly size: string | null;
      readonly shoeSizeByCountry: TradesyTradesyShoeSizeByCountryType | null;
      readonly publishStatusV2: {
        readonly status: string | null;
        readonly message: string | null;
      } | null;
    }>;
    readonly length: TradesyTradesyLengthType | null;
    readonly color: string | null;
    readonly washlook: TradesyTradesyWashlookType | null;
    readonly conditionTags: boolean | null;
    readonly conditioWear: boolean | null;
    readonly description: string | null;
    readonly styleName: string | null;
    readonly styleKeywords: string | null;
    readonly originalPrice: number | null;
    readonly askingPrice: number | null;
    readonly shippingPrice: number | null;
    readonly shippingFlag: number | null;
    readonly shippingType: number | null;
    readonly acceptReturns: boolean | null;
    readonly brand: string | null;
    readonly nodeId: number | null;
    readonly veilLength: TradesyTradesyVeilLengthType | null;
    readonly shoeWidth: TradesyTradesyShoeWidthType | null;
    readonly measurementWidth: string | null;
    readonly measurementHeight: string | null;
    readonly measurementLength: string | null;
    readonly heelHeight: TradesyTradesyHeelHeightType | null;
    readonly heelStyle: TradesyTradesyHeelStyleType | null;
    readonly fabric: string | null;
    readonly publishStatusV2: {
      readonly status: string | null;
      readonly message: string | null;
    } | null;
    readonly priceSuggestion: {
      readonly suggestedPrice: number;
      readonly feeParts: ReadonlyArray<{
        readonly value: number;
        readonly label: string;
      }>;
    } | null;
    readonly shippingInfo: {
      readonly shippingPrice: number | null;
      readonly shippingProvider: number;
      readonly shippingProviderDisplay: string;
      readonly payerContributions: ReadonlyArray<{
        readonly contribution: number | null;
        readonly shippingPayer: number | null;
        readonly shippingPayerDisplay: string | null;
      }>;
    } | null;
    readonly sizeSuggestion: {
      readonly sizeSuggestions: ReadonlyArray<{
        readonly id: string;
        readonly display: string;
        readonly sizeSystem: string;
      }>;
    } | null;
  } | null;
};
export type tradesyListingQuery = {
  readonly response: tradesyListingQueryResponse;
  readonly variables: tradesyListingQueryVariables;
};

/*
query tradesyListingQuery(
  $listingId: String!
) {
  tradesyTradesyListing(listingId: $listingId) {
    listingId
    createdAt
    updatedAt
    items {
      itemId
      tradesyListingId
      tradesyId
      size
      shoeSizeByCountry
      publishStatusV2 {
        status
        message
      }
    }
    length
    color
    washlook
    conditionTags
    conditioWear
    description
    styleName
    styleKeywords
    originalPrice
    askingPrice
    shippingPrice
    shippingFlag
    shippingType
    acceptReturns
    brand
    nodeId
    veilLength
    shoeWidth
    measurementWidth
    measurementHeight
    measurementLength
    heelHeight
    heelStyle
    fabric
    publishStatusV2 {
      status
      message
    }
    priceSuggestion {
      suggestedPrice
      feeParts {
        value
        label
      }
    }
    shippingInfo {
      shippingPrice
      shippingProvider
      shippingProviderDisplay
      payerContributions {
        contribution
        shippingPayer
        shippingPayerDisplay
      }
    }
    sizeSuggestion {
      sizeSuggestions {
        id
        display
        sizeSystem
      }
    }
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'listingId',
      },
    ],
    v1 = {
      alias: null,
      args: null,
      concreteType: 'TradesyPublishStatusV2',
      kind: 'LinkedField',
      name: 'publishStatusV2',
      plural: false,
      selections: [
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'status',
          storageKey: null,
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'message',
          storageKey: null,
        },
      ],
      storageKey: null,
    },
    v2 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'shippingPrice',
      storageKey: null,
    },
    v3 = [
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'listingId',
            variableName: 'listingId',
          },
        ],
        concreteType: 'TradesyTradesyListing',
        kind: 'LinkedField',
        name: 'tradesyTradesyListing',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'listingId',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'createdAt',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'updatedAt',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            concreteType: 'TradesyTradesyItem',
            kind: 'LinkedField',
            name: 'items',
            plural: true,
            selections: [
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'itemId',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'tradesyListingId',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'tradesyId',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'size',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'shoeSizeByCountry',
                storageKey: null,
              },
              v1 /*: any*/,
            ],
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'length',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'color',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'washlook',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'conditionTags',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'conditioWear',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'description',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'styleName',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'styleKeywords',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'originalPrice',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'askingPrice',
            storageKey: null,
          },
          v2 /*: any*/,
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'shippingFlag',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'shippingType',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'acceptReturns',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'brand',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'nodeId',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'veilLength',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'shoeWidth',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'measurementWidth',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'measurementHeight',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'measurementLength',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'heelHeight',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'heelStyle',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'fabric',
            storageKey: null,
          },
          v1 /*: any*/,
          {
            alias: null,
            args: null,
            concreteType: 'TradesyTradesyPriceSuggestion',
            kind: 'LinkedField',
            name: 'priceSuggestion',
            plural: false,
            selections: [
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'suggestedPrice',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                concreteType: 'TradesyTradesyPriceSuggestionFeePart',
                kind: 'LinkedField',
                name: 'feeParts',
                plural: true,
                selections: [
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'value',
                    storageKey: null,
                  },
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'label',
                    storageKey: null,
                  },
                ],
                storageKey: null,
              },
            ],
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            concreteType: 'TradesyShippingInfo',
            kind: 'LinkedField',
            name: 'shippingInfo',
            plural: false,
            selections: [
              v2 /*: any*/,
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'shippingProvider',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'shippingProviderDisplay',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                concreteType: 'TradesyShippingPayerContribution',
                kind: 'LinkedField',
                name: 'payerContributions',
                plural: true,
                selections: [
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'contribution',
                    storageKey: null,
                  },
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'shippingPayer',
                    storageKey: null,
                  },
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'shippingPayerDisplay',
                    storageKey: null,
                  },
                ],
                storageKey: null,
              },
            ],
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            concreteType: 'TradesySizeSuggestion',
            kind: 'LinkedField',
            name: 'sizeSuggestion',
            plural: false,
            selections: [
              {
                alias: null,
                args: null,
                concreteType: 'TradesySizeSuggestionSize',
                kind: 'LinkedField',
                name: 'sizeSuggestions',
                plural: true,
                selections: [
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'id',
                    storageKey: null,
                  },
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'display',
                    storageKey: null,
                  },
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'sizeSystem',
                    storageKey: null,
                  },
                ],
                storageKey: null,
              },
            ],
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'tradesyListingQuery',
      selections: v3 /*: any*/,
      type: 'Query',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'tradesyListingQuery',
      selections: v3 /*: any*/,
    },
    params: {
      cacheID: 'da3df9bcedd6ededeea12b5405470951',
      id: null,
      metadata: {},
      name: 'tradesyListingQuery',
      operationKind: 'query',
      text: 'query tradesyListingQuery(\n  $listingId: String!\n) {\n  tradesyTradesyListing(listingId: $listingId) {\n    listingId\n    createdAt\n    updatedAt\n    items {\n      itemId\n      tradesyListingId\n      tradesyId\n      size\n      shoeSizeByCountry\n      publishStatusV2 {\n        status\n        message\n      }\n    }\n    length\n    color\n    washlook\n    conditionTags\n    conditioWear\n    description\n    styleName\n    styleKeywords\n    originalPrice\n    askingPrice\n    shippingPrice\n    shippingFlag\n    shippingType\n    acceptReturns\n    brand\n    nodeId\n    veilLength\n    shoeWidth\n    measurementWidth\n    measurementHeight\n    measurementLength\n    heelHeight\n    heelStyle\n    fabric\n    publishStatusV2 {\n      status\n      message\n    }\n    priceSuggestion {\n      suggestedPrice\n      feeParts {\n        value\n        label\n      }\n    }\n    shippingInfo {\n      shippingPrice\n      shippingProvider\n      shippingProviderDisplay\n      payerContributions {\n        contribution\n        shippingPayer\n        shippingPayerDisplay\n      }\n    }\n    sizeSuggestion {\n      sizeSuggestions {\n        id\n        display\n        sizeSystem\n      }\n    }\n  }\n}\n',
    },
  };
})();
(node as any).hash = 'fd86d5d8ea8af19f07879945fe9b5535';
export default node;
