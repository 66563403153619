/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from 'relay-runtime';
export type uspsPickupLocationsQueryVariables = {};
export type uspsPickupLocationsQueryResponse = {
  readonly invsysUspsPickupLocations: ReadonlyArray<{
    readonly ' $fragmentRefs': FragmentRefs<'uspsPickupLocationFragment_full'>;
  }>;
};
export type uspsPickupLocationsQuery = {
  readonly response: uspsPickupLocationsQueryResponse;
  readonly variables: uspsPickupLocationsQueryVariables;
};

/*
query uspsPickupLocationsQuery {
  invsysUspsPickupLocations {
    ...uspsPickupLocationFragment_full
  }
}

fragment addressFragment_full on InvsysAddress {
  id
  streetAddress
  unit
  locality
  subdivision
  postalCode
  countryCode
  postalCodeFormat
  isDefaultShipping
  isDefaultPickup
}

fragment uspsPickupLocationFragment_full on InvsysUspsPickupLocation {
  id
  addressId
  pickupInstruction
  instructionComments
  firstName
  lastName
  email
  e164
  isDefaultPickupLocation
  address {
    ...addressFragment_full
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null,
    },
    v1 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'addressId',
      storageKey: null,
    },
    v2 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'pickupInstruction',
      storageKey: null,
    },
    v3 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'instructionComments',
      storageKey: null,
    },
    v4 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'firstName',
      storageKey: null,
    },
    v5 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'lastName',
      storageKey: null,
    },
    v6 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'email',
      storageKey: null,
    },
    v7 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'e164',
      storageKey: null,
    },
    v8 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'isDefaultPickupLocation',
      storageKey: null,
    },
    v9 = [
      v0 /*: any*/,
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'streetAddress',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'unit',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'locality',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'subdivision',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'postalCode',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'countryCode',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'postalCodeFormat',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'isDefaultShipping',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'isDefaultPickup',
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: [],
      kind: 'Fragment',
      metadata: null,
      name: 'uspsPickupLocationsQuery',
      selections: [
        {
          alias: null,
          args: null,
          concreteType: 'InvsysUspsPickupLocation',
          kind: 'LinkedField',
          name: 'invsysUspsPickupLocations',
          plural: true,
          selections: [
            {
              kind: 'InlineDataFragmentSpread',
              name: 'uspsPickupLocationFragment_full',
              selections: [
                v0 /*: any*/,
                v1 /*: any*/,
                v2 /*: any*/,
                v3 /*: any*/,
                v4 /*: any*/,
                v5 /*: any*/,
                v6 /*: any*/,
                v7 /*: any*/,
                v8 /*: any*/,
                {
                  alias: null,
                  args: null,
                  concreteType: 'InvsysAddress',
                  kind: 'LinkedField',
                  name: 'address',
                  plural: false,
                  selections: [
                    {
                      kind: 'InlineDataFragmentSpread',
                      name: 'addressFragment_full',
                      selections: v9 /*: any*/,
                    },
                  ],
                  storageKey: null,
                },
              ],
            },
          ],
          storageKey: null,
        },
      ],
      type: 'Query',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [],
      kind: 'Operation',
      name: 'uspsPickupLocationsQuery',
      selections: [
        {
          alias: null,
          args: null,
          concreteType: 'InvsysUspsPickupLocation',
          kind: 'LinkedField',
          name: 'invsysUspsPickupLocations',
          plural: true,
          selections: [
            v0 /*: any*/,
            v1 /*: any*/,
            v2 /*: any*/,
            v3 /*: any*/,
            v4 /*: any*/,
            v5 /*: any*/,
            v6 /*: any*/,
            v7 /*: any*/,
            v8 /*: any*/,
            {
              alias: null,
              args: null,
              concreteType: 'InvsysAddress',
              kind: 'LinkedField',
              name: 'address',
              plural: false,
              selections: v9 /*: any*/,
              storageKey: null,
            },
          ],
          storageKey: null,
        },
      ],
    },
    params: {
      cacheID: '38d99a3685a132f2faeda34f17129b6c',
      id: null,
      metadata: {},
      name: 'uspsPickupLocationsQuery',
      operationKind: 'query',
      text: 'query uspsPickupLocationsQuery {\n  invsysUspsPickupLocations {\n    ...uspsPickupLocationFragment_full\n  }\n}\n\nfragment addressFragment_full on InvsysAddress {\n  id\n  streetAddress\n  unit\n  locality\n  subdivision\n  postalCode\n  countryCode\n  postalCodeFormat\n  isDefaultShipping\n  isDefaultPickup\n}\n\nfragment uspsPickupLocationFragment_full on InvsysUspsPickupLocation {\n  id\n  addressId\n  pickupInstruction\n  instructionComments\n  firstName\n  lastName\n  email\n  e164\n  isDefaultPickupLocation\n  address {\n    ...addressFragment_full\n  }\n}\n',
    },
  };
})();
(node as any).hash = 'eb725420ed0bfeca3320fbff647e4ef7';
export default node;
