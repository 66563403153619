import * as State from 'src/state';
import * as Queries from 'src/clients/Network/gql/queries';
import { readInlineData } from 'react-relay';
import { FragmentRefs, _RefType } from 'relay-runtime';

type FragmentLikeType<Refs extends string> = {
  readonly ' $fragmentRefs': FragmentRefs<Refs>;
} | null;

export const map = <T extends unknown, Refs extends string>(
  array: FragmentLikeType<Refs>[] | ReadonlyArray<FragmentLikeType<Refs>>,
  read: (value: FragmentLikeType<Refs>) => T | null
) => {
  return array.map((_) => read(_)).filter((_): _ is T => _ != null);
};

export const studioListingMediaGroupFull = (
  value: FragmentLikeType<'studioListingMediaGroupFragment_full'>
): State.Types.StudioListingMediaGroupType | null => {
  return (
    readInlineData<State.Types.StudioListingMediaGroupType>(Queries.StudioListingMediaGroupFragmentFull, value) ?? null
  );
};
