import { graphql } from 'react-relay';

export const gqlSalesGoals = graphql`
  query salesGoalsQuery {
    invsysSalesGoals {
      id
      goalType
      goalStatus
      target
      startAt
      endAt
      frequency
    }
  }
`;
