/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type InvsysInstitutionLinkRequestStatus =
  | 'FAILED_ACCOUNT_LOCKED'
  | 'FAILED_ACCOUNT_SUSPENDED'
  | 'FAILED_ANOTHER_OWNER'
  | 'FAILED_GENERIC'
  | 'FAILED_INVALID_AUTH_METHOD'
  | 'FAILED_INVALID_COOKIES'
  | 'FAILED_MARKETPLACE_AUTH_UNAVAILABLE'
  | 'FAILED_MFA_CHANNEL_TIMEOUT'
  | 'FAILED_MFA_DENIED'
  | 'FAILED_MFA_REQUIRED'
  | 'FAILED_MFA_TOKEN_TIMEOUT'
  | 'FAILED_MFA_UNVERIFIED_PHONE_NUMBER'
  | 'FAILED_NEEDS_PASSWORD_RESET'
  | 'FAILED_NEEDS_SECURITY_QUESTIONS'
  | 'FAILED_WRONG_CREDENTIALS'
  | 'FAILED_WRONG_MFA_TOKEN'
  | 'FAILED_WRONG_PASSWORD'
  | 'FAILED_WRONG_USERNAME'
  | 'PENDING'
  | 'PENDING_MFA_ANSWER'
  | 'PENDING_MFA_CHANNEL'
  | 'PENDING_MFA_RESPONSE'
  | 'SUCCESS'
  | '%future added value';
export type InvsysInstitutionLinkState = 'LINKED' | 'RETRYABLE_FAILED' | 'TERMINAL_FAILED' | '%future added value';
export type appCriticalMetadataQueryVariables = {};
export type appCriticalMetadataQueryResponse = {
  readonly superposherBusiness: {
    readonly appReviewer: boolean;
    readonly balance: number;
    readonly baseSubscriptionPrice: number;
    readonly createdAt: number;
    readonly trialEndAt: number | null;
    readonly subscriptionStatus: {
      readonly updatedAt: number;
      readonly status: number;
      readonly statusSource: number;
    } | null;
  } | null;
  readonly invsysBusinessManager: {
    readonly createdAt: number;
    readonly e164: string | null;
  } | null;
  readonly invsysInstitutionLinks: ReadonlyArray<{
    readonly id: string;
    readonly institution: number;
    readonly institutionIssuedId: string;
    readonly linkState: InvsysInstitutionLinkState;
    readonly lastSuccessfulAt: number | null;
    readonly lastRetryableFailedAt: number | null;
    readonly lastTerminalFailedAt: number | null;
    readonly profile: {
      readonly countryCode: string | null;
      readonly username: string;
      readonly profilePictureUrl: string;
    } | null;
    readonly credential: {
      readonly username: string | null;
    } | null;
    readonly limitations: ReadonlyArray<{
      readonly value: number;
      readonly label: string;
      readonly url: string | null;
    }>;
    readonly limitationsUpdatedAt: number;
  }>;
  readonly invsysInstitutionLinkRequests: ReadonlyArray<{
    readonly id: string;
    readonly institution: number;
    readonly status: InvsysInstitutionLinkRequestStatus | null;
    readonly startAt: number;
    readonly channel: number | null;
  }>;
  readonly invsysNuxSurvey: {
    readonly body: string | null;
    readonly finishedAt: number | null;
  } | null;
  readonly invsysNotificationReceiptUnarchivedCount: number | null;
  readonly invsysShippingLabelReel: {
    readonly url: string | null;
    readonly status: string | null;
  } | null;
  readonly invsysStripePaymentMethodByCustomerDefaultSource: {
    readonly stripeId: string;
    readonly createdAt: number;
    readonly cardExpMonth: number;
    readonly cardExpYear: number;
    readonly cardLast4: string;
  } | null;
};
export type appCriticalMetadataQuery = {
  readonly response: appCriticalMetadataQueryResponse;
  readonly variables: appCriticalMetadataQueryVariables;
};

/*
query appCriticalMetadataQuery {
  superposherBusiness {
    appReviewer
    balance
    baseSubscriptionPrice
    createdAt
    trialEndAt
    subscriptionStatus {
      updatedAt
      status
      statusSource
    }
  }
  invsysBusinessManager {
    createdAt
    e164
  }
  invsysInstitutionLinks {
    id
    institution
    institutionIssuedId
    linkState
    lastSuccessfulAt
    lastRetryableFailedAt
    lastTerminalFailedAt
    profile {
      countryCode
      username
      profilePictureUrl
    }
    credential {
      username
    }
    limitations {
      value
      label
      url
    }
    limitationsUpdatedAt
  }
  invsysInstitutionLinkRequests {
    id
    institution
    status
    startAt
    channel
  }
  invsysNuxSurvey {
    body
    finishedAt
  }
  invsysNotificationReceiptUnarchivedCount
  invsysShippingLabelReel {
    url
    status
  }
  invsysStripePaymentMethodByCustomerDefaultSource {
    stripeId
    createdAt
    cardExpMonth
    cardExpYear
    cardLast4
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'createdAt',
      storageKey: null,
    },
    v1 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'status',
      storageKey: null,
    },
    v2 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null,
    },
    v3 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'institution',
      storageKey: null,
    },
    v4 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'username',
      storageKey: null,
    },
    v5 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'url',
      storageKey: null,
    },
    v6 = [
      {
        alias: null,
        args: null,
        concreteType: 'SuperposherBusiness',
        kind: 'LinkedField',
        name: 'superposherBusiness',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'appReviewer',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'balance',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'baseSubscriptionPrice',
            storageKey: null,
          },
          v0 /*: any*/,
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'trialEndAt',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            concreteType: 'SuperposherSubscriptionStatus',
            kind: 'LinkedField',
            name: 'subscriptionStatus',
            plural: false,
            selections: [
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'updatedAt',
                storageKey: null,
              },
              v1 /*: any*/,
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'statusSource',
                storageKey: null,
              },
            ],
            storageKey: null,
          },
        ],
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        concreteType: 'InvsysBusinessManager',
        kind: 'LinkedField',
        name: 'invsysBusinessManager',
        plural: false,
        selections: [
          v0 /*: any*/,
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'e164',
            storageKey: null,
          },
        ],
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        concreteType: 'InvsysInstitutionLink',
        kind: 'LinkedField',
        name: 'invsysInstitutionLinks',
        plural: true,
        selections: [
          v2 /*: any*/,
          v3 /*: any*/,
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'institutionIssuedId',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'linkState',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'lastSuccessfulAt',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'lastRetryableFailedAt',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'lastTerminalFailedAt',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            concreteType: 'InvsysAuthProfile',
            kind: 'LinkedField',
            name: 'profile',
            plural: false,
            selections: [
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'countryCode',
                storageKey: null,
              },
              v4 /*: any*/,
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'profilePictureUrl',
                storageKey: null,
              },
            ],
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            concreteType: 'InvsysAuthCredential',
            kind: 'LinkedField',
            name: 'credential',
            plural: false,
            selections: [v4 /*: any*/],
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            concreteType: 'InvsysLimitationInfo',
            kind: 'LinkedField',
            name: 'limitations',
            plural: true,
            selections: [
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'value',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'label',
                storageKey: null,
              },
              v5 /*: any*/,
            ],
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'limitationsUpdatedAt',
            storageKey: null,
          },
        ],
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        concreteType: 'InvsysInstitutionLinkRequest',
        kind: 'LinkedField',
        name: 'invsysInstitutionLinkRequests',
        plural: true,
        selections: [
          v2 /*: any*/,
          v3 /*: any*/,
          v1 /*: any*/,
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'startAt',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'channel',
            storageKey: null,
          },
        ],
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        concreteType: 'InvsysNuxSurvey',
        kind: 'LinkedField',
        name: 'invsysNuxSurvey',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'body',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'finishedAt',
            storageKey: null,
          },
        ],
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'invsysNotificationReceiptUnarchivedCount',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        concreteType: 'InvsysShippingLabelReel',
        kind: 'LinkedField',
        name: 'invsysShippingLabelReel',
        plural: false,
        selections: [v5 /*: any*/, v1 /*: any*/],
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        concreteType: 'InvsysStripePaymentMethod',
        kind: 'LinkedField',
        name: 'invsysStripePaymentMethodByCustomerDefaultSource',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'stripeId',
            storageKey: null,
          },
          v0 /*: any*/,
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'cardExpMonth',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'cardExpYear',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'cardLast4',
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: [],
      kind: 'Fragment',
      metadata: null,
      name: 'appCriticalMetadataQuery',
      selections: v6 /*: any*/,
      type: 'Query',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [],
      kind: 'Operation',
      name: 'appCriticalMetadataQuery',
      selections: v6 /*: any*/,
    },
    params: {
      cacheID: '46cb186028692619807c4b7d17930de7',
      id: null,
      metadata: {},
      name: 'appCriticalMetadataQuery',
      operationKind: 'query',
      text: 'query appCriticalMetadataQuery {\n  superposherBusiness {\n    appReviewer\n    balance\n    baseSubscriptionPrice\n    createdAt\n    trialEndAt\n    subscriptionStatus {\n      updatedAt\n      status\n      statusSource\n    }\n  }\n  invsysBusinessManager {\n    createdAt\n    e164\n  }\n  invsysInstitutionLinks {\n    id\n    institution\n    institutionIssuedId\n    linkState\n    lastSuccessfulAt\n    lastRetryableFailedAt\n    lastTerminalFailedAt\n    profile {\n      countryCode\n      username\n      profilePictureUrl\n    }\n    credential {\n      username\n    }\n    limitations {\n      value\n      label\n      url\n    }\n    limitationsUpdatedAt\n  }\n  invsysInstitutionLinkRequests {\n    id\n    institution\n    status\n    startAt\n    channel\n  }\n  invsysNuxSurvey {\n    body\n    finishedAt\n  }\n  invsysNotificationReceiptUnarchivedCount\n  invsysShippingLabelReel {\n    url\n    status\n  }\n  invsysStripePaymentMethodByCustomerDefaultSource {\n    stripeId\n    createdAt\n    cardExpMonth\n    cardExpYear\n    cardLast4\n  }\n}\n',
    },
  };
})();
(node as any).hash = 'a11e2e52c311a8382d812562ca77ae86';
export default node;
