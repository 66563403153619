import { graphql } from 'react-relay';

export const gqlPutStorageMedia = graphql`
  mutation putStorageMediaMutation(
    $id: String
    $url: InvsysStringRequiredPutObject
    $mediaType: InvsysStringRequiredPutObject
    $width: InvsysIntRequiredPutObject
    $height: InvsysIntRequiredPutObject
    $uploadStatus: InvsysIntRequiredPutObject
  ) {
    invsysPutStorageMedia(
      id: $id
      url: $url
      mediaType: $mediaType
      width: $width
      height: $height
      uploadStatus: $uploadStatus
    ) {
      success
      data {
        id
      }
    }
  }
`;
