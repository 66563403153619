/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type invsysViewListingOnMobileMutationVariables = {
  listingId: string;
};
export type invsysViewListingOnMobileMutationResponse = {
  readonly invsysViewListingOnMobile: {
    readonly success: boolean;
  };
};
export type invsysViewListingOnMobileMutation = {
  readonly response: invsysViewListingOnMobileMutationResponse;
  readonly variables: invsysViewListingOnMobileMutationVariables;
};

/*
mutation invsysViewListingOnMobileMutation(
  $listingId: String!
) {
  invsysViewListingOnMobile(listingId: $listingId) {
    success
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'listingId',
      },
    ],
    v1 = [
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'listingId',
            variableName: 'listingId',
          },
        ],
        concreteType: 'InvsysViewListingOnMobileResponse',
        kind: 'LinkedField',
        name: 'invsysViewListingOnMobile',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'success',
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'invsysViewListingOnMobileMutation',
      selections: v1 /*: any*/,
      type: 'Mutation',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'invsysViewListingOnMobileMutation',
      selections: v1 /*: any*/,
    },
    params: {
      cacheID: 'b84df1ea656b20371a25a0b596f98724',
      id: null,
      metadata: {},
      name: 'invsysViewListingOnMobileMutation',
      operationKind: 'mutation',
      text: 'mutation invsysViewListingOnMobileMutation(\n  $listingId: String!\n) {\n  invsysViewListingOnMobile(listingId: $listingId) {\n    success\n  }\n}\n',
    },
  };
})();
(node as any).hash = '57accedde8cbc43da77c06ec53c1cda5';
export default node;
