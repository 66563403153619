/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type poshmarkBotRelistScheduleQueryVariables = {};
export type poshmarkBotRelistScheduleQueryResponse = {
  readonly poshmarkBotRelistSchedule: ReadonlyArray<{
    readonly at: number;
    readonly listingId: string;
    readonly listing: {
      readonly id: string;
      readonly url: string | null;
      readonly thumbnail: string | null;
      readonly title: string;
      readonly currentPrice: number | null;
      readonly deleted: boolean;
      readonly sold: boolean;
    } | null;
    readonly eligible: boolean;
    readonly ineligibleReason: number | null;
  }>;
};
export type poshmarkBotRelistScheduleQuery = {
  readonly response: poshmarkBotRelistScheduleQueryResponse;
  readonly variables: poshmarkBotRelistScheduleQueryVariables;
};

/*
query poshmarkBotRelistScheduleQuery {
  poshmarkBotRelistSchedule {
    at
    listingId
    listing {
      id
      url
      thumbnail
      title
      currentPrice
      deleted
      sold
    }
    eligible
    ineligibleReason
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = [
    {
      alias: null,
      args: null,
      concreteType: 'PoshmarkBotRelistScheduleEventType',
      kind: 'LinkedField',
      name: 'poshmarkBotRelistSchedule',
      plural: true,
      selections: [
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'at',
          storageKey: null,
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'listingId',
          storageKey: null,
        },
        {
          alias: null,
          args: null,
          concreteType: 'PoshmarkBotListing',
          kind: 'LinkedField',
          name: 'listing',
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              kind: 'ScalarField',
              name: 'id',
              storageKey: null,
            },
            {
              alias: null,
              args: null,
              kind: 'ScalarField',
              name: 'url',
              storageKey: null,
            },
            {
              alias: null,
              args: null,
              kind: 'ScalarField',
              name: 'thumbnail',
              storageKey: null,
            },
            {
              alias: null,
              args: null,
              kind: 'ScalarField',
              name: 'title',
              storageKey: null,
            },
            {
              alias: null,
              args: null,
              kind: 'ScalarField',
              name: 'currentPrice',
              storageKey: null,
            },
            {
              alias: null,
              args: null,
              kind: 'ScalarField',
              name: 'deleted',
              storageKey: null,
            },
            {
              alias: null,
              args: null,
              kind: 'ScalarField',
              name: 'sold',
              storageKey: null,
            },
          ],
          storageKey: null,
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'eligible',
          storageKey: null,
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'ineligibleReason',
          storageKey: null,
        },
      ],
      storageKey: null,
    },
  ];
  return {
    fragment: {
      argumentDefinitions: [],
      kind: 'Fragment',
      metadata: null,
      name: 'poshmarkBotRelistScheduleQuery',
      selections: v0 /*: any*/,
      type: 'Query',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [],
      kind: 'Operation',
      name: 'poshmarkBotRelistScheduleQuery',
      selections: v0 /*: any*/,
    },
    params: {
      cacheID: 'c8e6fbdb3280bde5606496ab8162a67c',
      id: null,
      metadata: {},
      name: 'poshmarkBotRelistScheduleQuery',
      operationKind: 'query',
      text: 'query poshmarkBotRelistScheduleQuery {\n  poshmarkBotRelistSchedule {\n    at\n    listingId\n    listing {\n      id\n      url\n      thumbnail\n      title\n      currentPrice\n      deleted\n      sold\n    }\n    eligible\n    ineligibleReason\n  }\n}\n',
    },
  };
})();
(node as any).hash = '2729603c50db25b881d7fb18cc6020aa';
export default node;
