import { graphql } from 'react-relay';

export const gqlUspsFreeSupplyOrderingLedger = graphql`
  query uspsFreeSupplyOrderingLedgerQuery($cursor: String, $limit: Int) {
    invsysUspsFreeSupplyOrderingLedger(cursor: $cursor, limit: $limit) {
      orderingAvailable
      orderInProgress
      timeLeftUntilAvailable
      cursor
      items {
        id
        cartItems {
          item
          quantity
          format
        }
        email
        firstName
        lastName
        phoneNumber
        streetAddress
        unit
        locality
        subdivision
        postalCode
        countryCode
        orderedAt
      }
    }
  }
`;
