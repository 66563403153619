/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from 'relay-runtime';
export type InvsysColorType =
  | 'BLACK'
  | 'BLUE'
  | 'BROWN'
  | 'CREAM'
  | 'GOLD'
  | 'GRAY'
  | 'GREEN'
  | 'ORANGE'
  | 'PINK'
  | 'PURPLE'
  | 'RED'
  | 'SILVER'
  | 'TAN'
  | 'WHITE'
  | 'YELLOW'
  | '%future added value';
export type InvsysListingConditionType =
  | 'NEW_WITHOUT_TAGS'
  | 'NEW_WITH_DEFECTS'
  | 'NEW_WITH_TAGS'
  | 'POOR'
  | 'PRE_OWNED'
  | '%future added value';
export type InvsysListingCurrencyCodeType = 'AUD' | 'CAD' | 'USD' | '%future added value';
export type InvsysSizeUnitType = 'CENTIMETERS' | 'INCHES' | '%future added value';
export type InvsysWeightUnitType = 'GRAMS' | 'KILOGRAMS' | 'OUNCES' | 'POUNDS' | '%future added value';
export type listingFragment_full = {
  readonly id: string;
  readonly title: string | null;
  readonly description: string | null;
  readonly listingSku: string | null;
  readonly skus: ReadonlyArray<string>;
  readonly tags: ReadonlyArray<string>;
  readonly notes: ReadonlyArray<string>;
  readonly searchTags: ReadonlyArray<string>;
  readonly marketplacesSummary: {
    readonly marketplaces: ReadonlyArray<number>;
    readonly draftMarketplaces: ReadonlyArray<number>;
  };
  readonly price: {
    readonly id: string;
    readonly currencyCode: InvsysListingCurrencyCodeType | null;
    readonly val: number | null;
  };
  readonly createdAt: number;
  readonly updatedAt: number;
  readonly items: ReadonlyArray<{
    readonly id: string;
    readonly title: string | null;
    readonly description: string | null;
    readonly brand: string | null;
    readonly categoryId: string | null;
    readonly sizeId: string | null;
    readonly price: {
      readonly currencyCode: InvsysListingCurrencyCodeType | null;
      readonly val: number | null;
    };
    readonly retailPrice: {
      readonly currencyCode: InvsysListingCurrencyCodeType | null;
      readonly val: number | null;
    };
    readonly costPrice: {
      readonly currencyCode: InvsysListingCurrencyCodeType | null;
      readonly val: number | null;
    };
    readonly size: {
      readonly val: string | null;
      readonly system: string | null;
    };
    readonly condition: InvsysListingConditionType | null;
    readonly tags: ReadonlyArray<string>;
    readonly skuAlias: string | null;
    readonly colors: ReadonlyArray<InvsysColorType>;
    readonly weight: number | null;
    readonly height: number | null;
    readonly width: number | null;
    readonly length: number | null;
    readonly weightUnit: InvsysWeightUnitType | null;
    readonly sizeUnit: InvsysSizeUnitType | null;
    readonly packageSizeType: number | null;
  }>;
  readonly archived: boolean;
  readonly targetInstitutions: ReadonlyArray<number>;
  readonly validationResult: {
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly errorId: number;
      readonly message: string;
      readonly entityName: string | null;
      readonly fieldName: string | null;
    }>;
  };
  readonly ' $refType': 'listingFragment_full';
};
export type listingFragment_full$data = listingFragment_full;
export type listingFragment_full$key = {
  readonly ' $data'?: listingFragment_full$data;
  readonly ' $fragmentRefs': FragmentRefs<'listingFragment_full'>;
};

const node: ReaderInlineDataFragment = {
  kind: 'InlineDataFragment',
  name: 'listingFragment_full',
};
(node as any).hash = 'eab0757b7c48af42df5ce088014cbc0d';
export default node;
