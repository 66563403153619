import * as Observe from 'src/state/lib/observe';
import * as Network from 'src/clients/Network';

export enum SubscriptionStatus {
  Active = 0,
  PastDue = 1,
  Unpaid = 2,
  Canceled = 3,
  Incomplete = 4,
  IncompleteExpired = 5,
  Trialing = 6,
}

export type AsyncValueDataType =
  Network.gql.build.appCriticalMetadataQuery.appCriticalMetadataQueryResponse['superposherBusiness'];
export const AsyncValue = Observe.AsyncValue<AsyncValueDataType>({});

export const Value = Observe.DerivedValue(() => {
  return AsyncValue.getValueWithFallback();
}, [AsyncValue.value]);

export const ValueFallback = Observe.WithFallback(Value);

export const IsPollingValue = Observe.Value<boolean>(false);
