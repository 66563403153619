/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type manuallyImportInstitutionListingsMutationVariables = {
  importRequestId: string;
  institutionIssuedListingIds: Array<string>;
  selectAll: boolean;
};
export type manuallyImportInstitutionListingsMutationResponse = {
  readonly invsysManuallyImportInstitutionListings: {
    readonly success: boolean;
  };
};
export type manuallyImportInstitutionListingsMutation = {
  readonly response: manuallyImportInstitutionListingsMutationResponse;
  readonly variables: manuallyImportInstitutionListingsMutationVariables;
};

/*
mutation manuallyImportInstitutionListingsMutation(
  $importRequestId: String!
  $institutionIssuedListingIds: [String!]!
  $selectAll: Boolean!
) {
  invsysManuallyImportInstitutionListings(importRequestId: $importRequestId, institutionIssuedListingIds: $institutionIssuedListingIds, selectAll: $selectAll) {
    success
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'importRequestId',
      },
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'institutionIssuedListingIds',
      },
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'selectAll',
      },
    ],
    v1 = [
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'importRequestId',
            variableName: 'importRequestId',
          },
          {
            kind: 'Variable',
            name: 'institutionIssuedListingIds',
            variableName: 'institutionIssuedListingIds',
          },
          {
            kind: 'Variable',
            name: 'selectAll',
            variableName: 'selectAll',
          },
        ],
        concreteType: 'InvsysManuallyImportInstitutionListingsResponse',
        kind: 'LinkedField',
        name: 'invsysManuallyImportInstitutionListings',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'success',
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'manuallyImportInstitutionListingsMutation',
      selections: v1 /*: any*/,
      type: 'Mutation',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'manuallyImportInstitutionListingsMutation',
      selections: v1 /*: any*/,
    },
    params: {
      cacheID: 'e855404778eec8954145fb2c2e34d494',
      id: null,
      metadata: {},
      name: 'manuallyImportInstitutionListingsMutation',
      operationKind: 'mutation',
      text: 'mutation manuallyImportInstitutionListingsMutation(\n  $importRequestId: String!\n  $institutionIssuedListingIds: [String!]!\n  $selectAll: Boolean!\n) {\n  invsysManuallyImportInstitutionListings(importRequestId: $importRequestId, institutionIssuedListingIds: $institutionIssuedListingIds, selectAll: $selectAll) {\n    success\n  }\n}\n',
    },
  };
})();
(node as any).hash = '326c7a7a4256a4f58699a98a5ec5e468';
export default node;
