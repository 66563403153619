/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type genAlgoliaKeyQueryVariables = {};
export type genAlgoliaKeyQueryResponse = {
  readonly invsysGenAlgoliaKey: {
    readonly success: boolean;
    readonly businessScopeKey: string | null;
    readonly publicScopeKey: string | null;
  };
};
export type genAlgoliaKeyQuery = {
  readonly response: genAlgoliaKeyQueryResponse;
  readonly variables: genAlgoliaKeyQueryVariables;
};

/*
mutation genAlgoliaKeyQuery {
  invsysGenAlgoliaKey {
    success
    businessScopeKey
    publicScopeKey
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = [
    {
      alias: null,
      args: null,
      concreteType: 'InvsysGenAlgoliaKeyResponse',
      kind: 'LinkedField',
      name: 'invsysGenAlgoliaKey',
      plural: false,
      selections: [
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'success',
          storageKey: null,
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'businessScopeKey',
          storageKey: null,
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'publicScopeKey',
          storageKey: null,
        },
      ],
      storageKey: null,
    },
  ];
  return {
    fragment: {
      argumentDefinitions: [],
      kind: 'Fragment',
      metadata: null,
      name: 'genAlgoliaKeyQuery',
      selections: v0 /*: any*/,
      type: 'Mutation',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [],
      kind: 'Operation',
      name: 'genAlgoliaKeyQuery',
      selections: v0 /*: any*/,
    },
    params: {
      cacheID: 'a6a4e8b0babe6a87a62f3e54b3680355',
      id: null,
      metadata: {},
      name: 'genAlgoliaKeyQuery',
      operationKind: 'mutation',
      text: 'mutation genAlgoliaKeyQuery {\n  invsysGenAlgoliaKey {\n    success\n    businessScopeKey\n    publicScopeKey\n  }\n}\n',
    },
  };
})();
(node as any).hash = '8fe695c604b231269122d46d492fba90';
export default node;
