import { graphql } from 'react-relay';

export const gqlListing = graphql`
  query listingQuery($id: String!) {
    invsysListing(id: $id) {
      ...listingFragment_full
    }
    studioListingMediaGroupByListingId(listingId: $id) {
      ...studioListingMediaGroupFragment_full
    }
  }
`;
