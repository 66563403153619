/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from 'relay-runtime';
export type defaultPickupAddressQueryVariables = {};
export type defaultPickupAddressQueryResponse = {
  readonly invsysDefaultPickupAddress: {
    readonly ' $fragmentRefs': FragmentRefs<'addressFragment_full'>;
  } | null;
};
export type defaultPickupAddressQuery = {
  readonly response: defaultPickupAddressQueryResponse;
  readonly variables: defaultPickupAddressQueryVariables;
};

/*
query defaultPickupAddressQuery {
  invsysDefaultPickupAddress {
    ...addressFragment_full
  }
}

fragment addressFragment_full on InvsysAddress {
  id
  streetAddress
  unit
  locality
  subdivision
  postalCode
  countryCode
  postalCodeFormat
  isDefaultShipping
  isDefaultPickup
}
*/

const node: ConcreteRequest = (function () {
  var v0 = [
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null,
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'streetAddress',
      storageKey: null,
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'unit',
      storageKey: null,
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'locality',
      storageKey: null,
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'subdivision',
      storageKey: null,
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'postalCode',
      storageKey: null,
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'countryCode',
      storageKey: null,
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'postalCodeFormat',
      storageKey: null,
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'isDefaultShipping',
      storageKey: null,
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'isDefaultPickup',
      storageKey: null,
    },
  ];
  return {
    fragment: {
      argumentDefinitions: [],
      kind: 'Fragment',
      metadata: null,
      name: 'defaultPickupAddressQuery',
      selections: [
        {
          alias: null,
          args: null,
          concreteType: 'InvsysAddress',
          kind: 'LinkedField',
          name: 'invsysDefaultPickupAddress',
          plural: false,
          selections: [
            {
              kind: 'InlineDataFragmentSpread',
              name: 'addressFragment_full',
              selections: v0 /*: any*/,
            },
          ],
          storageKey: null,
        },
      ],
      type: 'Query',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [],
      kind: 'Operation',
      name: 'defaultPickupAddressQuery',
      selections: [
        {
          alias: null,
          args: null,
          concreteType: 'InvsysAddress',
          kind: 'LinkedField',
          name: 'invsysDefaultPickupAddress',
          plural: false,
          selections: v0 /*: any*/,
          storageKey: null,
        },
      ],
    },
    params: {
      cacheID: 'd3dc9582e9b46f21a722d8f984c7bd93',
      id: null,
      metadata: {},
      name: 'defaultPickupAddressQuery',
      operationKind: 'query',
      text: 'query defaultPickupAddressQuery {\n  invsysDefaultPickupAddress {\n    ...addressFragment_full\n  }\n}\n\nfragment addressFragment_full on InvsysAddress {\n  id\n  streetAddress\n  unit\n  locality\n  subdivision\n  postalCode\n  countryCode\n  postalCodeFormat\n  isDefaultShipping\n  isDefaultPickup\n}\n',
    },
  };
})();
(node as any).hash = '6b050cc356c946984401d4e4c805137d';
export default node;
