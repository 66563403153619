/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type caRegisteredIdentificationNumberDetailsQueryVariables = {
  id: string;
};
export type caRegisteredIdentificationNumberDetailsQueryResponse = {
  readonly invsysCaRegisteredIdentificationNumberDetails: {
    readonly raw: {
      readonly id: string;
      readonly name: string;
      readonly url: string | null;
    };
    readonly curated: {
      readonly id: string;
      readonly legalName: string | null;
      readonly colloquialName: string | null;
      readonly iconUrl: string | null;
      readonly websiteUrl: string | null;
      readonly description: string | null;
    } | null;
  } | null;
};
export type caRegisteredIdentificationNumberDetailsQuery = {
  readonly response: caRegisteredIdentificationNumberDetailsQueryResponse;
  readonly variables: caRegisteredIdentificationNumberDetailsQueryVariables;
};

/*
query caRegisteredIdentificationNumberDetailsQuery(
  $id: String!
) {
  invsysCaRegisteredIdentificationNumberDetails(id: $id) {
    raw {
      id
      name
      url
    }
    curated {
      id
      legalName
      colloquialName
      iconUrl
      websiteUrl
      description
    }
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'id',
      },
    ],
    v1 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null,
    },
    v2 = [
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'id',
            variableName: 'id',
          },
        ],
        concreteType: 'InvsysCaRegisteredIdentificationNumberDetailsResponse',
        kind: 'LinkedField',
        name: 'invsysCaRegisteredIdentificationNumberDetails',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            concreteType: 'InvsysRawCaRegisteredIdentificationNumber',
            kind: 'LinkedField',
            name: 'raw',
            plural: false,
            selections: [
              v1 /*: any*/,
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'name',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'url',
                storageKey: null,
              },
            ],
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            concreteType: 'InvsysCuratedCaRegisteredIdentificationNumber',
            kind: 'LinkedField',
            name: 'curated',
            plural: false,
            selections: [
              v1 /*: any*/,
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'legalName',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'colloquialName',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'iconUrl',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'websiteUrl',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'description',
                storageKey: null,
              },
            ],
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'caRegisteredIdentificationNumberDetailsQuery',
      selections: v2 /*: any*/,
      type: 'Query',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'caRegisteredIdentificationNumberDetailsQuery',
      selections: v2 /*: any*/,
    },
    params: {
      cacheID: '5ab806cab20419f82897d650351fc67e',
      id: null,
      metadata: {},
      name: 'caRegisteredIdentificationNumberDetailsQuery',
      operationKind: 'query',
      text: 'query caRegisteredIdentificationNumberDetailsQuery(\n  $id: String!\n) {\n  invsysCaRegisteredIdentificationNumberDetails(id: $id) {\n    raw {\n      id\n      name\n      url\n    }\n    curated {\n      id\n      legalName\n      colloquialName\n      iconUrl\n      websiteUrl\n      description\n    }\n  }\n}\n',
    },
  };
})();
(node as any).hash = '9eea6b26d7e6d516524fdbf59bd8e392';
export default node;
