import { graphql } from 'react-relay';

export const gqlActionableOrders = graphql`
  query actionableOrdersQuery($cursor: String, $limit: Int) {
    invsysActionableOrders(cursor: $cursor, limit: $limit) {
      cursor
      items {
        ...orderFragment_full
      }
    }
  }
`;
