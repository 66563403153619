import { graphql } from 'react-relay';

export const gqlPutStorageMediaMulti = graphql`
  mutation putStorageMediaMultiMutation($operations: [InvsysPutStorageMediaOperation!]!) {
    invsysPutStorageMediaMulti(operations: $operations) {
      success
      data {
        ...invsysMediaFragment_full
      }
    }
  }
`;
