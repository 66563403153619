import { graphql } from 'react-relay';

export const gqlDuplicateListing = graphql`
  mutation duplicateListingMutation($id: String!) {
    invsysDuplicateListing(id: $id) {
      success
      data {
        id
      }
    }
  }
`;
