import { graphql } from 'react-relay';

export const gqlNotificationReceipts = graphql`
  query notificationReceiptsQuery($cursor: String) {
    invsysNotificationReceipts(cursor: $cursor) {
      cursor
      items {
        notificationId
        templateId
        body
        title
        archived
        sentAt
        createdAt
      }
    }
  }
`;
