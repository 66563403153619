/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type updateOfferToLikersPercentOffMutationVariables = {
  poshmarkUserLinkId: string;
  percent: number;
};
export type updateOfferToLikersPercentOffMutationResponse = {
  readonly superposherUpdateOfferToLikersPercentOff: {
    readonly success: boolean;
  };
};
export type updateOfferToLikersPercentOffMutation = {
  readonly response: updateOfferToLikersPercentOffMutationResponse;
  readonly variables: updateOfferToLikersPercentOffMutationVariables;
};

/*
mutation updateOfferToLikersPercentOffMutation(
  $poshmarkUserLinkId: String!
  $percent: Float!
) {
  superposherUpdateOfferToLikersPercentOff(poshmarkUserLinkId: $poshmarkUserLinkId, percent: $percent) {
    success
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'percent',
    },
    v1 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'poshmarkUserLinkId',
    },
    v2 = [
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'percent',
            variableName: 'percent',
          },
          {
            kind: 'Variable',
            name: 'poshmarkUserLinkId',
            variableName: 'poshmarkUserLinkId',
          },
        ],
        concreteType: 'SuperposherUpdateOfferToLikersPercentOffResponse',
        kind: 'LinkedField',
        name: 'superposherUpdateOfferToLikersPercentOff',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'success',
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: [v0 /*: any*/, v1 /*: any*/],
      kind: 'Fragment',
      metadata: null,
      name: 'updateOfferToLikersPercentOffMutation',
      selections: v2 /*: any*/,
      type: 'Mutation',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [v1 /*: any*/, v0 /*: any*/],
      kind: 'Operation',
      name: 'updateOfferToLikersPercentOffMutation',
      selections: v2 /*: any*/,
    },
    params: {
      cacheID: '50512e2c94029718f30517d88957a041',
      id: null,
      metadata: {},
      name: 'updateOfferToLikersPercentOffMutation',
      operationKind: 'mutation',
      text: 'mutation updateOfferToLikersPercentOffMutation(\n  $poshmarkUserLinkId: String!\n  $percent: Float!\n) {\n  superposherUpdateOfferToLikersPercentOff(poshmarkUserLinkId: $poshmarkUserLinkId, percent: $percent) {\n    success\n  }\n}\n',
    },
  };
})();
(node as any).hash = 'c0504810b229836ccc749dd7e6513e8f';
export default node;
