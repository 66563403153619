/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type listingsFilterSummaryQueryVariables = {};
export type listingsFilterSummaryQueryResponse = {
  readonly invsysListingsFilterSummary: {
    readonly filters: ReadonlyArray<{
      readonly filter: number;
      readonly count: number;
    }>;
  } | null;
};
export type listingsFilterSummaryQuery = {
  readonly response: listingsFilterSummaryQueryResponse;
  readonly variables: listingsFilterSummaryQueryVariables;
};

/*
query listingsFilterSummaryQuery {
  invsysListingsFilterSummary {
    filters {
      filter
      count
    }
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = [
    {
      alias: null,
      args: null,
      concreteType: 'InvsysListingFilterSummaryResponse',
      kind: 'LinkedField',
      name: 'invsysListingsFilterSummary',
      plural: false,
      selections: [
        {
          alias: null,
          args: null,
          concreteType: 'InvsysListingFilterSummary',
          kind: 'LinkedField',
          name: 'filters',
          plural: true,
          selections: [
            {
              alias: null,
              args: null,
              kind: 'ScalarField',
              name: 'filter',
              storageKey: null,
            },
            {
              alias: null,
              args: null,
              kind: 'ScalarField',
              name: 'count',
              storageKey: null,
            },
          ],
          storageKey: null,
        },
      ],
      storageKey: null,
    },
  ];
  return {
    fragment: {
      argumentDefinitions: [],
      kind: 'Fragment',
      metadata: null,
      name: 'listingsFilterSummaryQuery',
      selections: v0 /*: any*/,
      type: 'Query',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [],
      kind: 'Operation',
      name: 'listingsFilterSummaryQuery',
      selections: v0 /*: any*/,
    },
    params: {
      cacheID: 'ba18a7f49444f4677c8fe5096db733e5',
      id: null,
      metadata: {},
      name: 'listingsFilterSummaryQuery',
      operationKind: 'query',
      text: 'query listingsFilterSummaryQuery {\n  invsysListingsFilterSummary {\n    filters {\n      filter\n      count\n    }\n  }\n}\n',
    },
  };
})();
(node as any).hash = '1ab5d7e162cf99126b3a715aaa4912f1';
export default node;
