import * as Observe from 'src/util/observe';
import * as StateTypes from 'src/state/types';

export const ListingAppCriticalMetadataAsyncValue = Observe.AsyncStateValue();

export const ListingFeatureSettingsValue = Observe.Value<StateTypes.ListingFeatureSettingsType | null>(null);
export const CrosslistStreakTimePointsValue = Observe.Value<StateTypes.StreakTimePointType[]>([]);
export const ListingRemindersSettingsValue = Observe.Value<StateTypes.ListingReminderSettingsType | null>(null);

export const ImportInstitutionListingsRequestHistoryValue = Observe.Value<
  StateTypes.ImportInstitutionListingsRequestHistoryType[]
>([]);

export const ImportInstitutionListingsValue = Observe.LookupStoreValue<StateTypes.ImportInstitutionListingsType>();
export const ImportInstitutionListingsPaginationState =
  Observe.React.WithPaginationState<StateTypes.ImportInstitutionListingsType>({
    idSelector: (_) => _.listingId ?? '',
    lookupStoreValue: ImportInstitutionListingsValue,
  });

export const Value = Observe.LookupStoreValue<StateTypes.ListingType>();

export const SelectedIdValue = Observe.Value<string | null>(null);
export const SelectedItemIdValue = Observe.Value<string | null>(null);

export const SelectedValue = Observe.DerivedValue<StateTypes.ListingType | null>(() => {
  const values = Value.get() ?? {};
  const selectedId = SelectedIdValue.get();
  return selectedId != null ? values[selectedId] : null;
}, [Value, SelectedIdValue]);

export const SelectedFallback = Observe.React.WithFallback(SelectedValue);

export const SelectedWithItemValue = Observe.DerivedValue<StateTypes.ListingType | null>(() => {
  const values = Value.get() ?? {};
  const selectedId = SelectedIdValue.get();
  return selectedId != null && values[selectedId]?.items[0] != null ? values[selectedId] : null;
}, [Value, SelectedIdValue]);

export const SelectedWithItemFallback = Observe.React.WithFallback(SelectedWithItemValue);

export const SelectedItemValue = Observe.DerivedValue<StateTypes.ListingItemType | null>(() => {
  const listing = SelectedValue.get();
  if (listing != null) {
    const selectedItemId = SelectedItemIdValue.get();
    return selectedItemId != null ? listing.items.find((_) => _.id === selectedItemId) ?? null : null;
  } else {
    return null;
  }
}, [SelectedValue, SelectedItemIdValue]);

export const SelectedItemFallback = Observe.React.WithFallback(SelectedItemValue);

export const SelectedMercariListingValue = Observe.Value<StateTypes.MercariListingType | null>(null);
export const SelectedMercariListingFallback = Observe.React.WithFallback(SelectedMercariListingValue);

export const SelectedPoshmarkListingValue = Observe.Value<StateTypes.PoshmarkListingType | null>(null);
export const SelectedPoshmarkListingFallback = Observe.React.WithFallback(SelectedPoshmarkListingValue);

export const SelectedTradesyListingValue = Observe.Value<StateTypes.TradesyListingType | null>(null);
export const SelectedTradesyListingFallback = Observe.React.WithFallback(SelectedTradesyListingValue);

export const SelectedDepopListingValue = Observe.Value<StateTypes.DepopListingType | null>(null);
export const SelectedDepopListingFallback = Observe.React.WithFallback(SelectedDepopListingValue);

export const SelectedEbayListingValue = Observe.Value<StateTypes.EbayListingType | null>(null);
export const SelectedEbayListingFallback = Observe.React.WithFallback(SelectedEbayListingValue);

export const SelectedGrailedListingValue = Observe.Value<StateTypes.GrailedListingType | null>(null);
export const SelectedGrailedListingFallback = Observe.React.WithFallback(SelectedGrailedListingValue);

export const SelectedMercariValidateListingValue = Observe.Value<StateTypes.MercariValidateListingType | null>(null);
export const SelectedMercariValidateListingFallback = Observe.React.WithFallback(SelectedMercariValidateListingValue);

export const SelectedPoshmarkValidateListingValue = Observe.Value<StateTypes.PoshmarkValidateListingType | null>(null);
export const SelectedPoshmarkValidateListingFallback = Observe.React.WithFallback(SelectedPoshmarkValidateListingValue);

export const SelectedTradesyValidateListingValue = Observe.Value<StateTypes.TradesyValidateListingType | null>(null);
export const SelectedTradesyValidateListingFallback = Observe.React.WithFallback(SelectedTradesyValidateListingValue);

export const SelectedDepopValidateListingValue = Observe.Value<StateTypes.DepopValidateListingType | null>(null);
export const SelectedDepopValidateListingFallback = Observe.React.WithFallback(SelectedDepopValidateListingValue);

export const SelectedEbayValidateListingValue = Observe.Value<StateTypes.EbayValidateListingType | null>(null);
export const SelectedEbayValidateListingFallback = Observe.React.WithFallback(SelectedEbayValidateListingValue);

export const SelectedGrailedValidateListingValue = Observe.Value<StateTypes.GrailedValidateListingType | null>(null);
export const SelectedGrailedValidateListingFallback = Observe.React.WithFallback(SelectedGrailedValidateListingValue);

export const ListingsSummaryValue = Observe.Value<StateTypes.ListingsSummaryType | null>(null);
export const ListingsSummaryFallback = Observe.React.WithFallback(ListingsSummaryValue);

export const ScheduledListingsSummaryValue = Observe.Value<StateTypes.ScheduledListingsSummaryType | null>(null);

export const ListingRecordsValue = Observe.LookupStoreValue<StateTypes.ListingRecordsType>();
export const ListingRecordsFallback = Observe.React.WithFallback(ListingRecordsValue);
export const ListingRecordsDraftPaginationState = Observe.React.WithPaginationState<StateTypes.ListingRecordsType>({
  idSelector: (_) => _.objectID,
  lookupStoreValue: ListingRecordsValue,
});
export const ListingRecordsPublishedPaginationState = Observe.React.WithPaginationState<StateTypes.ListingRecordsType>({
  idSelector: (_) => _.objectID,
  lookupStoreValue: ListingRecordsValue,
});
export const ListingRecordsArchivedPaginationState = Observe.React.WithPaginationState<StateTypes.ListingRecordsType>({
  idSelector: (_) => _.objectID,
  lookupStoreValue: ListingRecordsValue,
});
export const ListingRecordsSoldPaginationState = Observe.React.WithPaginationState<StateTypes.ListingRecordsType>({
  idSelector: (_) => _.objectID,
  lookupStoreValue: ListingRecordsValue,
});

export const SelectedTargetInstitutions = Observe.Value<StateTypes.ListingSupportedEnum[]>([]);
export const SelectedTargetInstitutionsFallback = Observe.React.WithFallback(SelectedTargetInstitutions);

export const InstitutionImportStatus = Observe.LookupStoreValue<StateTypes.ImportStatusType>();
export const SelectedImportRequestIdValue = Observe.Value<string | null>(null);
export const ListingSearchFilterSummaryValue = Observe.Value<StateTypes.ListingSearchFilterSummaryType[]>([]);

type QueuedDefaultSettingsActionTypes = 'new' | 'stash' | 'import' | 'recent-imports' | 'defaults' | 'listing-details';
export const QueuedDefaultSettingsAction = Observe.Value<QueuedDefaultSettingsActionTypes | null>(null);
export const QueuedListingDetailsId = Observe.Value<string | null>(null);

export const ListingValidationResult = Observe.Value<StateTypes.ListingValidationResultType | null>(null);

export const ListingLastSearchAtAsyncValue = Observe.AsyncStateValue();
export const ListingMediaGroupId = Observe.Value<string | null>(null);

export const UpdateListingDescriptionAsyncValue = Observe.AsyncStateValue();
