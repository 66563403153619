import { graphql } from 'react-relay';

export const gqlSuggestedPrice = graphql`
  query suggestedPriceQuery($institution: Int!, $originalPrice: Int!, $categoryId: String) {
    invsysSuggestedPrice(institution: $institution, originalPrice: $originalPrice, categoryId: $categoryId) {
      institution
      suggestedPrice
      categoryId
    }
  }
`;
