import { graphql } from 'react-relay';

export const gqlValidateMercariListing = graphql`
  query validateMercariListingQuery($listingId: String!) {
    mercariValidateMercariListing(listingId: $listingId) {
      success
      errors {
        errorId: id
        entityName
        entityId
        fieldName
        error
        message
        timeToComplete
      }
      additionalFieldsAvailable
    }
  }
`;
