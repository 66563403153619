import { graphql } from 'react-relay';

export const gqlMarketplaceListing = graphql`
  query marketplaceListingQuery($listingId: String!, $validate: Boolean) {
    invsysMarketplaceListings(listingId: $listingId, validate: $validate) {
      depopListing {
        id
        depopId
        listingId
        categoryId
        categoryIdV2
        description
        price
        currencyCode
        brandId
        condition
        color
        occasion
        material
        bodyFit
        sources
        ages
        style
        variant_set_id
        national_shipping_price
        international_shipping_price
        shippingType
        address
        addressId
        country_code
        geo_latitude
        geo_longitude
        priceSuggestion {
          suggestedPrice
          feeParts {
            value
            label
          }
        }
        shippingInfo {
          shippingPrice
          shippingProvider
          shippingProviderDisplay
          payerContributions {
            contribution
            shippingPayer
            shippingPayerDisplay
          }
        }
        sizeSuggestion {
          sizeSuggestions {
            id
            display
            sizeSystem
          }
        }
        publishStatusV2 {
          status
          message
        }
        items {
          depopListingId
          itemId
          size
        }
      }
      ebayListing {
        listingId
        categoryId
        price
        condition
        title
        description
        packageHeight
        packageWidth
        packageLength
        attributesBrand
        attributesStyle
        attributesColor
        attributesItemHeight
        attributesItemLength
        attributesItemWidth
        attributesType
        attributesMaterial
        attributesNumberOfItemsInSet
        attributesSetIncludes
        attributesBandMaterial
        attributesDepartment
        attributesDressLength
        attributesOuterShellMaterial
        attributesFrameColor
        attributesUpperMaterial
        attributesInseam
        attributesSleeveLength
        attributesDesignerBrand
        attributesExteriorMaterial
        attributesExteriorColor
        attributesSkirtLength
        attributesMainStone
        attributesMetalPurity
        attributesMetal
        attributesBeadSize
        attributesRingSize
        attributes
        upc
        isbn
        priceSuggestion {
          suggestedPrice
          feeParts {
            value
            label
          }
        }
        shippingInfo {
          shippingPrice
          shippingProvider
          shippingProviderDisplay
          payerContributions {
            contribution
            shippingPayer
            shippingPayerDisplay
          }
        }
        shippingPolicyId
        shippingPrimaryServicePaymentType
        domesticShippingService1
        domesticShippingPrice1
        shippingIntlPrimaryServicePaymentType
        intlShippingService1
        intlShippingPrice1
        offerGlobalShipping
        offerLocalPickup
        localPickupPrice
        sizeSuggestion {
          sizeSuggestions {
            id
            display
            sizeSystem
          }
        }
        fieldNames {
          attributesBrand
          attributesStyle
          attributesColor
          attributesItemHeight
          attributesItemLength
          attributesItemWidth
          attributesType
          attributesMaterial
          attributesNumberOfItemsInSet
          attributesSetIncludes
          attributesBandMaterial
          attributesDepartment
          attributesDressLength
          attributesOuterShellMaterial
          attributesFrameColor
          attributesUpperMaterial
          attributesInseam
          attributesSleeveLength
          attributesDesigner
          attributesExteriorMaterial
          attributesExteriorColor
          attributesSkirtLength
          attributesSizeType
          attributesSize
          attributesShoeSize
          attributesMainStone
          attributesMetalPurity
          attributesMetal
          attributesBeadSize
          attributesRingSize
        }
        publishStatusV2 {
          status
          message
        }
        items {
          ebayListingId
          itemId
          attributesSizeType
          attributesSize
          attributesUsShoeSize
        }
      }
      grailedListing {
        listingId
        updatedAt
        items {
          itemId
          grailedListingId
          grailedId
          size
          publishStatusV2 {
            status
            message
          }
        }
        buyNow
        categoryPath
        condition
        description
        designerIds
        hashtags
        makeOffer
        measurement1
        measurement2
        measurement3
        measurement4
        measurement5
        measurement6
        minimumPrice
        price
        returnAddressId
        usShippingPrice
        caShippingPrice
        shippingType
        title
        color
        externalId
        publishStatusV2 {
          status
          message
        }
        priceSuggestion {
          suggestedPrice
          feeParts {
            value
            label
          }
        }
        shippingInfo {
          shippingPrice
          shippingProvider
          shippingProviderDisplay
          payerContributions {
            contribution
            shippingPayer
            shippingPayerDisplay
          }
        }
        sizeSuggestion {
          sizeSuggestions {
            id
            display
            sizeSystem
          }
        }
      }
      mercariListing {
        listingId
        categoryId
        conditionId
        description
        imei
        minPriceForAutoDrop
        name
        price
        shippingClassIds
        brandId
        shippingPackageWeight
        shippingPayerId
        suggestedShippingClassIds
        shippingType
        tags
        zipCode
        publishStatusV2 {
          status
          message
        }
        colorId
        dressOccasion
        dressStyle
        material
        shaftHeight
        endUse
        heelHeight
        heelType
        items {
          mercariListingId
          mercariId
          itemId
          publishStatusV2 {
            status
            message
          }
          sizeId
        }
        priceSuggestion {
          suggestedPrice
          feeParts {
            value
            label
          }
        }
        shippingInfo {
          shippingPrice
          shippingProvider
          shippingProviderDisplay
          payerContributions {
            contribution
            shippingPayer
            shippingPayerDisplay
          }
        }
        sizeSuggestion {
          sizeSuggestions {
            id
            display
            sizeSystem
          }
        }
      }
      poshmarkListing {
        id
        poshmarkId
        listingId
        title
        description
        brand
        poshmarkDepartmentId
        poshmarkCategoryId
        poshmarkCategoryFeatureIds
        condition
        price {
          id
          currencyCode
          val
        }
        originalPrice {
          id
          currencyCode
          val
        }
        costPrice {
          id
          currencyCode
          val
        }
        styleTags
        colors
        listingSku
        status
        publishStatusV2 {
          status
          message
        }
        items {
          poshmarkListingId
          itemId
          size
          sizeSystem
        }
        priceSuggestion {
          suggestedPrice
          feeParts {
            value
            label
          }
        }
        shippingInfo {
          shippingPrice
          shippingProvider
          shippingProviderDisplay
          payerContributions {
            contribution
            shippingPayer
            shippingPayerDisplay
          }
        }
        shippingDiscountId
        sizeSuggestion {
          sizeSuggestions {
            id
            display
            sizeSystem
          }
        }
      }
      tradesyListing {
        listingId
        createdAt
        updatedAt
        items {
          itemId
          tradesyListingId
          tradesyId
          size
          shoeSizeByCountry
          publishStatusV2 {
            status
            message
          }
        }
        length
        color
        washlook
        conditionTags
        conditioWear
        description
        styleName
        styleKeywords
        originalPrice
        askingPrice
        shippingPrice
        shippingFlag
        shippingType
        acceptReturns
        brand
        nodeId
        veilLength
        shoeWidth
        measurementWidth
        measurementHeight
        measurementLength
        heelHeight
        heelStyle
        fabric
        publishStatusV2 {
          status
          message
        }
        priceSuggestion {
          suggestedPrice
          feeParts {
            value
            label
          }
        }
        shippingInfo {
          shippingPrice
          shippingProvider
          shippingProviderDisplay
          payerContributions {
            contribution
            shippingPayer
            shippingPayerDisplay
          }
        }
        sizeSuggestion {
          sizeSuggestions {
            id
            display
            sizeSystem
          }
        }
      }

      poshmarkListingValidation {
        success
        errors {
          errorId: id
          entityName
          entityId
          fieldName
          error
          message
          timeToComplete
        }
        additionalFieldsAvailable
      }
      mercariListingValidation {
        success
        errors {
          errorId: id
          entityName
          entityId
          fieldName
          error
          message
          timeToComplete
        }
        additionalFieldsAvailable
      }
      depopListingValidation {
        success
        errors {
          errorId: id
          entityName
          entityId
          fieldName
          error
          message
          timeToComplete
        }
        additionalFieldsAvailable
      }
      tradesyListingValidation {
        success
        errors {
          errorId: id
          entityName
          entityId
          fieldName
          error
          message
          timeToComplete
        }
        additionalFieldsAvailable
      }
      ebayListingValidation {
        success
        errors {
          errorId: id
          entityName
          entityId
          fieldName
          error
          message
          timeToComplete
        }
        additionalFieldsAvailable
      }
      grailedListingValidation {
        success
        errors {
          errorId: id
          entityName
          entityId
          fieldName
          error
          message
          timeToComplete
        }
        additionalFieldsAvailable
      }
    }
  }
`;
