/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from 'relay-runtime';
export type InvsysPutStorageMediaOperation = {
  id?: string | null;
  url?: InvsysStringRequiredPutObject | null;
  mediaType?: InvsysStringRequiredPutObject | null;
  width?: InvsysIntRequiredPutObject | null;
  height?: InvsysIntRequiredPutObject | null;
  uploadStatus?: InvsysIntRequiredPutObject | null;
  originUri?: InvsysStringPutObject | null;
  originPlatformOs?: InvsysIntPutObject | null;
  originPlatformMajorVersion?: InvsysIntPutObject | null;
};
export type InvsysStringRequiredPutObject = {
  value: string;
};
export type InvsysIntRequiredPutObject = {
  value: number;
};
export type InvsysStringPutObject = {
  value?: string | null;
};
export type InvsysIntPutObject = {
  value?: number | null;
};
export type putStorageMediaMultiMutationVariables = {
  operations: Array<InvsysPutStorageMediaOperation>;
};
export type putStorageMediaMultiMutationResponse = {
  readonly invsysPutStorageMediaMulti: {
    readonly success: boolean;
    readonly data: ReadonlyArray<{
      readonly ' $fragmentRefs': FragmentRefs<'invsysMediaFragment_full'>;
    }>;
  };
};
export type putStorageMediaMultiMutation = {
  readonly response: putStorageMediaMultiMutationResponse;
  readonly variables: putStorageMediaMultiMutationVariables;
};

/*
mutation putStorageMediaMultiMutation(
  $operations: [InvsysPutStorageMediaOperation!]!
) {
  invsysPutStorageMediaMulti(operations: $operations) {
    success
    data {
      ...invsysMediaFragment_full
    }
  }
}

fragment invsysMediaFragment_full on InvsysMedia {
  id
  createdAt
  url
  mediaType
  width
  height
  businessId
  businessManagerId
  uploadStatus
  originUri
  originPlatformOs
  originPlatformMajorVersion
}
*/

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'operations',
      },
    ],
    v1 = [
      {
        kind: 'Variable',
        name: 'operations',
        variableName: 'operations',
      },
    ],
    v2 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'success',
      storageKey: null,
    },
    v3 = [
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'id',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'createdAt',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'url',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'mediaType',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'width',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'height',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'businessId',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'businessManagerId',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'uploadStatus',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'originUri',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'originPlatformOs',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'originPlatformMajorVersion',
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'putStorageMediaMultiMutation',
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: 'InvsysPutStorageMediaMultiResponse',
          kind: 'LinkedField',
          name: 'invsysPutStorageMediaMulti',
          plural: false,
          selections: [
            v2 /*: any*/,
            {
              alias: null,
              args: null,
              concreteType: 'InvsysMedia',
              kind: 'LinkedField',
              name: 'data',
              plural: true,
              selections: [
                {
                  kind: 'InlineDataFragmentSpread',
                  name: 'invsysMediaFragment_full',
                  selections: v3 /*: any*/,
                },
              ],
              storageKey: null,
            },
          ],
          storageKey: null,
        },
      ],
      type: 'Mutation',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'putStorageMediaMultiMutation',
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: 'InvsysPutStorageMediaMultiResponse',
          kind: 'LinkedField',
          name: 'invsysPutStorageMediaMulti',
          plural: false,
          selections: [
            v2 /*: any*/,
            {
              alias: null,
              args: null,
              concreteType: 'InvsysMedia',
              kind: 'LinkedField',
              name: 'data',
              plural: true,
              selections: v3 /*: any*/,
              storageKey: null,
            },
          ],
          storageKey: null,
        },
      ],
    },
    params: {
      cacheID: '434c662a5591b541d66d78ea8cb538cc',
      id: null,
      metadata: {},
      name: 'putStorageMediaMultiMutation',
      operationKind: 'mutation',
      text: 'mutation putStorageMediaMultiMutation(\n  $operations: [InvsysPutStorageMediaOperation!]!\n) {\n  invsysPutStorageMediaMulti(operations: $operations) {\n    success\n    data {\n      ...invsysMediaFragment_full\n    }\n  }\n}\n\nfragment invsysMediaFragment_full on InvsysMedia {\n  id\n  createdAt\n  url\n  mediaType\n  width\n  height\n  businessId\n  businessManagerId\n  uploadStatus\n  originUri\n  originPlatformOs\n  originPlatformMajorVersion\n}\n',
    },
  };
})();
(node as any).hash = '0bbd7985d011ad14adbd8d83e0d5a353';
export default node;
