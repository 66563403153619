import { graphql } from 'react-relay';

export const gqlReferralProgramDetails = graphql`
  query referralProgramDetailsQuery {
    superposherReferralProgramDetails {
      signups
      trialing
      expired
      credited
    }
  }
`;
