import { graphql } from 'react-relay';

export const gqlInvsysMedia = graphql`
  query invsysMediaQuery($ids: [String!]!) {
    invsysMedia(ids: $ids) {
      id
      createdAt
      url
      mediaType
      width
      height
      uploadStatus
    }
  }
`;
