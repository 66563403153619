/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type poshmarkBotClosetOrderingListingsQueryVariables = {
  closetOrderingSectionId?: string | null;
  cursor?: string | null;
  limit?: number | null;
};
export type poshmarkBotClosetOrderingListingsQueryResponse = {
  readonly poshmarkBotClosetOrderingListings: {
    readonly items: ReadonlyArray<{
      readonly listingId: string;
      readonly listing: {
        readonly id: string;
        readonly userId: string;
        readonly url: string | null;
        readonly thumbnail: string | null;
        readonly title: string;
        readonly currentPrice: number | null;
        readonly deleted: boolean;
        readonly sold: boolean;
      } | null;
      readonly userId: string;
      readonly sectionId: string | null;
      readonly rank: number;
    }>;
    readonly cursor: string | null;
  };
};
export type poshmarkBotClosetOrderingListingsQuery = {
  readonly response: poshmarkBotClosetOrderingListingsQueryResponse;
  readonly variables: poshmarkBotClosetOrderingListingsQueryVariables;
};

/*
query poshmarkBotClosetOrderingListingsQuery(
  $closetOrderingSectionId: String
  $cursor: String
  $limit: Int
) {
  poshmarkBotClosetOrderingListings(closetOrderingSectionId: $closetOrderingSectionId, cursor: $cursor, limit: $limit) {
    items {
      listingId
      listing {
        id
        userId
        url
        thumbnail
        title
        currentPrice
        deleted
        sold
      }
      userId
      sectionId
      rank
    }
    cursor
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'closetOrderingSectionId',
      },
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'cursor',
      },
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'limit',
      },
    ],
    v1 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'userId',
      storageKey: null,
    },
    v2 = [
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'closetOrderingSectionId',
            variableName: 'closetOrderingSectionId',
          },
          {
            kind: 'Variable',
            name: 'cursor',
            variableName: 'cursor',
          },
          {
            kind: 'Variable',
            name: 'limit',
            variableName: 'limit',
          },
        ],
        concreteType: 'PoshmarkBotClosetOrderingListingPage',
        kind: 'LinkedField',
        name: 'poshmarkBotClosetOrderingListings',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            concreteType: 'PoshmarkBotClosetOrderingListing',
            kind: 'LinkedField',
            name: 'items',
            plural: true,
            selections: [
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'listingId',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                concreteType: 'PoshmarkBotListing',
                kind: 'LinkedField',
                name: 'listing',
                plural: false,
                selections: [
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'id',
                    storageKey: null,
                  },
                  v1 /*: any*/,
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'url',
                    storageKey: null,
                  },
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'thumbnail',
                    storageKey: null,
                  },
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'title',
                    storageKey: null,
                  },
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'currentPrice',
                    storageKey: null,
                  },
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'deleted',
                    storageKey: null,
                  },
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'sold',
                    storageKey: null,
                  },
                ],
                storageKey: null,
              },
              v1 /*: any*/,
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'sectionId',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'rank',
                storageKey: null,
              },
            ],
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'cursor',
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'poshmarkBotClosetOrderingListingsQuery',
      selections: v2 /*: any*/,
      type: 'Query',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'poshmarkBotClosetOrderingListingsQuery',
      selections: v2 /*: any*/,
    },
    params: {
      cacheID: 'ddf347353023442195bd25da04de5b89',
      id: null,
      metadata: {},
      name: 'poshmarkBotClosetOrderingListingsQuery',
      operationKind: 'query',
      text: 'query poshmarkBotClosetOrderingListingsQuery(\n  $closetOrderingSectionId: String\n  $cursor: String\n  $limit: Int\n) {\n  poshmarkBotClosetOrderingListings(closetOrderingSectionId: $closetOrderingSectionId, cursor: $cursor, limit: $limit) {\n    items {\n      listingId\n      listing {\n        id\n        userId\n        url\n        thumbnail\n        title\n        currentPrice\n        deleted\n        sold\n      }\n      userId\n      sectionId\n      rank\n    }\n    cursor\n  }\n}\n',
    },
  };
})();
(node as any).hash = 'e815f75a7fd37cab7033cba90eff38ce';
export default node;
