import { graphql } from 'react-relay';

export const gqlEbayListing = graphql`
  query ebayListingQuery($listingId: String!) {
    ebayEbayListing(listingId: $listingId) {
      listingId
      categoryId
      price
      condition
      title
      description
      packageHeight
      packageWidth
      packageLength
      attributesBrand
      attributesStyle
      attributesColor
      attributesItemHeight
      attributesItemLength
      attributesItemWidth
      attributesType
      attributesMaterial
      attributesNumberOfItemsInSet
      attributesSetIncludes
      attributesBandMaterial
      attributesDepartment
      attributesDressLength
      attributesOuterShellMaterial
      attributesFrameColor
      attributesUpperMaterial
      attributesInseam
      attributesSleeveLength
      attributesDesignerBrand
      attributesExteriorMaterial
      attributesExteriorColor
      attributesSkirtLength
      attributesMainStone
      attributesMetalPurity
      attributesMetal
      attributesBeadSize
      attributesRingSize
      attributes
      upc
      isbn
      priceSuggestion {
        suggestedPrice
        feeParts {
          value
          label
        }
      }
      shippingInfo {
        shippingPrice
        shippingProvider
        shippingProviderDisplay
        payerContributions {
          contribution
          shippingPayer
          shippingPayerDisplay
        }
      }
      shippingPolicyId
      shippingPrimaryServicePaymentType
      domesticShippingService1
      domesticShippingPrice1
      shippingIntlPrimaryServicePaymentType
      intlShippingService1
      intlShippingPrice1
      offerGlobalShipping
      offerLocalPickup
      localPickupPrice
      sizeSuggestion {
        sizeSuggestions {
          id
          display
          sizeSystem
        }
      }
      fieldNames {
        attributesBrand
        attributesStyle
        attributesColor
        attributesItemHeight
        attributesItemLength
        attributesItemWidth
        attributesType
        attributesMaterial
        attributesNumberOfItemsInSet
        attributesSetIncludes
        attributesBandMaterial
        attributesDepartment
        attributesDressLength
        attributesOuterShellMaterial
        attributesFrameColor
        attributesUpperMaterial
        attributesInseam
        attributesSleeveLength
        attributesDesigner
        attributesExteriorMaterial
        attributesExteriorColor
        attributesSkirtLength
        attributesSizeType
        attributesSize
        attributesShoeSize
        attributesMainStone
        attributesMetalPurity
        attributesMetal
        attributesBeadSize
        attributesRingSize
      }
      publishStatusV2 {
        status
        message
      }
      items {
        ebayListingId
        itemId
        attributesSizeType
        attributesSize
        attributesUsShoeSize
      }
    }
  }
`;
