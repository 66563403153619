import { graphql } from 'react-relay';

export const gqlStartSubscription = graphql`
  mutation startSubscriptionMutation {
    superposherStartSubscription {
      success
      error
    }
  }
`;
