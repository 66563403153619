import * as StateTypes from 'src/state/types';
import * as Observe from 'src/util/observe';

export const NotificationReceiptsValue = Observe.LookupStoreValue<StateTypes.NotificationReceiptType>();
export const NotificationReceiptsFallback = Observe.React.WithFallback(NotificationReceiptsValue);
export const NotificationReceiptPaginationState = Observe.React.WithPaginationState<StateTypes.NotificationReceiptType>(
  {
    idSelector: (_) => _.notificationId,
    lookupStoreValue: NotificationReceiptsValue,
  }
);
