import { graphql } from 'react-relay';

export const gqlScheduledListingsSummary = graphql`
  query scheduledListingsSummaryQuery {
    invsysScheduledListingsSummary {
      scheduledListingsCount
      scheduledListings {
        items {
          listingId
          title
          mediaUrl
          institution
          scheduleAt
        }
      }
    }
  }
`;
