/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type lastSalesSyncCompletedOverallQueryVariables = {};
export type lastSalesSyncCompletedOverallQueryResponse = {
  readonly invsysLastSalesSyncCompletedOverall: number | null;
};
export type lastSalesSyncCompletedOverallQuery = {
  readonly response: lastSalesSyncCompletedOverallQueryResponse;
  readonly variables: lastSalesSyncCompletedOverallQueryVariables;
};

/*
query lastSalesSyncCompletedOverallQuery {
  invsysLastSalesSyncCompletedOverall
}
*/

const node: ConcreteRequest = (function () {
  var v0 = [
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'invsysLastSalesSyncCompletedOverall',
      storageKey: null,
    },
  ];
  return {
    fragment: {
      argumentDefinitions: [],
      kind: 'Fragment',
      metadata: null,
      name: 'lastSalesSyncCompletedOverallQuery',
      selections: v0 /*: any*/,
      type: 'Query',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [],
      kind: 'Operation',
      name: 'lastSalesSyncCompletedOverallQuery',
      selections: v0 /*: any*/,
    },
    params: {
      cacheID: 'ac21d2dcb8fbd14dd74690710f7b78e1',
      id: null,
      metadata: {},
      name: 'lastSalesSyncCompletedOverallQuery',
      operationKind: 'query',
      text: 'query lastSalesSyncCompletedOverallQuery {\n  invsysLastSalesSyncCompletedOverall\n}\n',
    },
  };
})();
(node as any).hash = 'ca3b65fcc2725e5cc61f18d6819b11ff';
export default node;
