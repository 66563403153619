/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type inventoryMetadataQueryVariables = {};
export type inventoryMetadataQueryResponse = {
  readonly superposherPoshmarkUserLinks: ReadonlyArray<{
    readonly id: string;
    readonly closetSections: ReadonlyArray<{
      readonly id: string;
      readonly name: string;
      readonly listingCount: number;
    }>;
    readonly availableListingsCount: number;
    readonly totalListingsCount: number;
    readonly uncategorizedListingCount: number;
  }>;
};
export type inventoryMetadataQuery = {
  readonly response: inventoryMetadataQueryResponse;
  readonly variables: inventoryMetadataQueryVariables;
};

/*
query inventoryMetadataQuery {
  superposherPoshmarkUserLinks {
    id
    closetSections {
      id
      name
      listingCount
    }
    availableListingsCount
    totalListingsCount
    uncategorizedListingCount
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null,
    },
    v1 = [
      {
        alias: null,
        args: null,
        concreteType: 'SuperposherPoshmarkUserLink',
        kind: 'LinkedField',
        name: 'superposherPoshmarkUserLinks',
        plural: true,
        selections: [
          v0 /*: any*/,
          {
            alias: null,
            args: null,
            concreteType: 'SuperposherClosetSection',
            kind: 'LinkedField',
            name: 'closetSections',
            plural: true,
            selections: [
              v0 /*: any*/,
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'name',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'listingCount',
                storageKey: null,
              },
            ],
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'availableListingsCount',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'totalListingsCount',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'uncategorizedListingCount',
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: [],
      kind: 'Fragment',
      metadata: null,
      name: 'inventoryMetadataQuery',
      selections: v1 /*: any*/,
      type: 'Query',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [],
      kind: 'Operation',
      name: 'inventoryMetadataQuery',
      selections: v1 /*: any*/,
    },
    params: {
      cacheID: '4b849971d44c215111b69d236319a1df',
      id: null,
      metadata: {},
      name: 'inventoryMetadataQuery',
      operationKind: 'query',
      text: 'query inventoryMetadataQuery {\n  superposherPoshmarkUserLinks {\n    id\n    closetSections {\n      id\n      name\n      listingCount\n    }\n    availableListingsCount\n    totalListingsCount\n    uncategorizedListingCount\n  }\n}\n',
    },
  };
})();
(node as any).hash = 'f259d5cc9dd0661945e6f5c5d2862417';
export default node;
