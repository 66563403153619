import { graphql } from 'react-relay';

export const gqlPutPoshmarkListing = graphql`
  mutation putPoshmarkListingMutation(
    $listingId: String!
    $title: PoshmarkStringPutObject
    $description: PoshmarkStringPutObject
    $brand: PoshmarkStringPutObject
    $poshmarkDepartmentId: PoshmarkStringPutObject
    $poshmarkCategoryId: PoshmarkStringPutObject
    $poshmarkCategoryFeatureIds: PoshmarkArray_StringRequired_RequiredPutObject
    $coverShotId: PoshmarkStringPutObject
    $pictureIds: PoshmarkArray_StringRequired_RequiredPutObject
    $videoIds: PoshmarkArray_StringRequired_RequiredPutObject
    $styleTags: PoshmarkArray_StringRequired_RequiredPutObject
    $colors: PoshmarkArray_StringRequired_RequiredPutObject
    $status: PoshmarkPoshmarkListingStatusTypePutObject
    $condition: PoshmarkPoshmarkListingConditionTypePutObject
    $price: PoshmarkFloatPutObject
    $originalPrice: PoshmarkFloatPutObject
    $costPrice: PoshmarkFloatPutObject
    $listingSku: PoshmarkStringPutObject
    $currencyCode: PoshmarkListingCurrencyCodeTypePutObject
    $shippingDiscountId: PoshmarkStringPutObject
  ) {
    poshmarkPutPoshmarkListing(
      listingId: $listingId
      title: $title
      description: $description
      brand: $brand
      poshmarkDepartmentId: $poshmarkDepartmentId
      poshmarkCategoryId: $poshmarkCategoryId
      poshmarkCategoryFeatureIds: $poshmarkCategoryFeatureIds
      coverShotId: $coverShotId
      pictureIds: $pictureIds
      videoIds: $videoIds
      styleTags: $styleTags
      colors: $colors
      status: $status
      condition: $condition
      price: $price
      originalPrice: $originalPrice
      costPrice: $costPrice
      listingSku: $listingSku
      currencyCode: $currencyCode
      shippingDiscountId: $shippingDiscountId
    ) {
      success
    }
  }
`;
