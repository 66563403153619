/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type MercariFeatureStatus = 'DISABLED' | 'ENABLED' | 'STOPPED' | 'UNINITIALIZED' | '%future added value';
export type mercariBotCriticalMetadataQueryVariables = {};
export type mercariBotCriticalMetadataQueryResponse = {
  readonly mercariFeatureStatuses: {
    readonly autoRelistingFeatureStatus: MercariFeatureStatus;
  } | null;
  readonly mercariStatBuckets: {
    readonly last24Hours: ReadonlyArray<{
      readonly at: number;
      readonly relistingsFinished: number;
    }>;
    readonly last48Hours: ReadonlyArray<{
      readonly at: number;
      readonly relistingsFinished: number;
    }>;
    readonly last7Days: ReadonlyArray<{
      readonly at: number;
      readonly relistingsFinished: number;
    }>;
    readonly today: ReadonlyArray<{
      readonly at: number;
      readonly relistingsFinished: number;
    }>;
  };
};
export type mercariBotCriticalMetadataQuery = {
  readonly response: mercariBotCriticalMetadataQueryResponse;
  readonly variables: mercariBotCriticalMetadataQueryVariables;
};

/*
query mercariBotCriticalMetadataQuery {
  mercariFeatureStatuses {
    autoRelistingFeatureStatus
  }
  mercariStatBuckets {
    last24Hours {
      at
      relistingsFinished
    }
    last48Hours {
      at
      relistingsFinished
    }
    last7Days {
      at
      relistingsFinished
    }
    today {
      at
      relistingsFinished
    }
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'at',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'relistingsFinished',
        storageKey: null,
      },
    ],
    v1 = [
      {
        alias: null,
        args: null,
        concreteType: 'MercariFeatureStatuses',
        kind: 'LinkedField',
        name: 'mercariFeatureStatuses',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'autoRelistingFeatureStatus',
            storageKey: null,
          },
        ],
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        concreteType: 'MercariStatBuckets',
        kind: 'LinkedField',
        name: 'mercariStatBuckets',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            concreteType: 'MercariStatBucket',
            kind: 'LinkedField',
            name: 'last24Hours',
            plural: true,
            selections: v0 /*: any*/,
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            concreteType: 'MercariStatBucket',
            kind: 'LinkedField',
            name: 'last48Hours',
            plural: true,
            selections: v0 /*: any*/,
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            concreteType: 'MercariStatBucket',
            kind: 'LinkedField',
            name: 'last7Days',
            plural: true,
            selections: v0 /*: any*/,
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            concreteType: 'MercariStatBucket',
            kind: 'LinkedField',
            name: 'today',
            plural: true,
            selections: v0 /*: any*/,
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: [],
      kind: 'Fragment',
      metadata: null,
      name: 'mercariBotCriticalMetadataQuery',
      selections: v1 /*: any*/,
      type: 'Query',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [],
      kind: 'Operation',
      name: 'mercariBotCriticalMetadataQuery',
      selections: v1 /*: any*/,
    },
    params: {
      cacheID: 'f5709e3050d3e250c137997e788ac42a',
      id: null,
      metadata: {},
      name: 'mercariBotCriticalMetadataQuery',
      operationKind: 'query',
      text: 'query mercariBotCriticalMetadataQuery {\n  mercariFeatureStatuses {\n    autoRelistingFeatureStatus\n  }\n  mercariStatBuckets {\n    last24Hours {\n      at\n      relistingsFinished\n    }\n    last48Hours {\n      at\n      relistingsFinished\n    }\n    last7Days {\n      at\n      relistingsFinished\n    }\n    today {\n      at\n      relistingsFinished\n    }\n  }\n}\n',
    },
  };
})();
(node as any).hash = '2d9c6754856feb3ce4fb9658f958f764';
export default node;
