/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type InvsysListingCurrencyCodeType = 'AUD' | 'CAD' | 'USD' | '%future added value';
export type InvsysSizeUnitType = 'CENTIMETERS' | 'INCHES' | '%future added value';
export type InvsysWeightUnitType = 'GRAMS' | 'KILOGRAMS' | 'OUNCES' | 'POUNDS' | '%future added value';
export type appListingCriticalMetadataQueryVariables = {
  tz?: string | null;
};
export type appListingCriticalMetadataQueryResponse = {
  readonly invsysListingFeatureSettings: {
    readonly currencyCode: InvsysListingCurrencyCodeType | null;
    readonly sizeUnit: InvsysSizeUnitType | null;
    readonly weightUnit: InvsysWeightUnitType | null;
    readonly weight: number | null;
    readonly shippingProviders: ReadonlyArray<number> | null;
    readonly descriptionTemplate: string | null;
    readonly privateNoteTemplate: string | null;
    readonly packageSizeType: number | null;
    readonly packageCustomLength: number | null;
    readonly packageCustomWidth: number | null;
    readonly packageCustomHeight: number | null;
    readonly marketplaces: ReadonlyArray<number> | null;
    readonly poshmarkShippingDiscountId: string | null;
    readonly mercariShippingType: number | null;
    readonly mercariShippingPayerId: number | null;
    readonly depopShippingType: number | null;
    readonly depopNationalShippingPrice: number | null;
    readonly depopInternationalShippingPrice: number | null;
    readonly tradesyShippingType: number | null;
    readonly tradesyShippingPrice: number | null;
    readonly grailedShippingType: number | null;
    readonly grailedUsShippingPrice: number | null;
    readonly grailedCaShippingPrice: number | null;
  } | null;
  readonly invsysCrosslistStreakTimePoints: ReadonlyArray<{
    readonly at: number;
    readonly value: number;
  }>;
  readonly invsysListingReminderSettings: {
    readonly tz: string | null;
    readonly hour: number | null;
    readonly minute: number | null;
    readonly sunday: boolean | null;
    readonly monday: boolean | null;
    readonly tuesday: boolean | null;
    readonly wednesday: boolean | null;
    readonly thursday: boolean | null;
    readonly friday: boolean | null;
    readonly saturday: boolean | null;
  } | null;
  readonly invsysImportInstitutionListingsRequestHistory: ReadonlyArray<{
    readonly id: string;
    readonly createdAt: number;
    readonly institution: number;
    readonly status: number;
    readonly feedType: number;
    readonly autoApply: boolean;
  }>;
  readonly ebayProfile: {
    readonly userId: string;
    readonly username: string;
    readonly profilePictureUrl: string;
    readonly updatedAt: number;
    readonly businessPoliciesEnabled: boolean;
    readonly shippingPolicyIds: ReadonlyArray<string>;
  } | null;
};
export type appListingCriticalMetadataQuery = {
  readonly response: appListingCriticalMetadataQueryResponse;
  readonly variables: appListingCriticalMetadataQueryVariables;
};

/*
query appListingCriticalMetadataQuery(
  $tz: String
) {
  invsysListingFeatureSettings {
    currencyCode
    sizeUnit
    weightUnit
    weight
    shippingProviders
    descriptionTemplate
    privateNoteTemplate
    packageSizeType
    packageCustomLength
    packageCustomWidth
    packageCustomHeight
    marketplaces
    poshmarkShippingDiscountId
    mercariShippingType
    mercariShippingPayerId
    depopShippingType
    depopNationalShippingPrice
    depopInternationalShippingPrice
    tradesyShippingType
    tradesyShippingPrice
    grailedShippingType
    grailedUsShippingPrice
    grailedCaShippingPrice
  }
  invsysCrosslistStreakTimePoints(tz: $tz) {
    at
    value
  }
  invsysListingReminderSettings(tz: $tz) {
    tz
    hour
    minute
    sunday
    monday
    tuesday
    wednesday
    thursday
    friday
    saturday
  }
  invsysImportInstitutionListingsRequestHistory {
    id
    createdAt
    institution
    status
    feedType
    autoApply
  }
  ebayProfile {
    userId
    username
    profilePictureUrl
    updatedAt
    businessPoliciesEnabled
    shippingPolicyIds
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'tz',
      },
    ],
    v1 = [
      {
        kind: 'Variable',
        name: 'tz',
        variableName: 'tz',
      },
    ],
    v2 = [
      {
        alias: null,
        args: null,
        concreteType: 'InvsysListingFeatureSettings',
        kind: 'LinkedField',
        name: 'invsysListingFeatureSettings',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'currencyCode',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'sizeUnit',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'weightUnit',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'weight',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'shippingProviders',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'descriptionTemplate',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'privateNoteTemplate',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'packageSizeType',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'packageCustomLength',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'packageCustomWidth',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'packageCustomHeight',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'marketplaces',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'poshmarkShippingDiscountId',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'mercariShippingType',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'mercariShippingPayerId',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'depopShippingType',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'depopNationalShippingPrice',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'depopInternationalShippingPrice',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'tradesyShippingType',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'tradesyShippingPrice',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'grailedShippingType',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'grailedUsShippingPrice',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'grailedCaShippingPrice',
            storageKey: null,
          },
        ],
        storageKey: null,
      },
      {
        alias: null,
        args: v1 /*: any*/,
        concreteType: 'InvsysStreakTimePoint',
        kind: 'LinkedField',
        name: 'invsysCrosslistStreakTimePoints',
        plural: true,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'at',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'value',
            storageKey: null,
          },
        ],
        storageKey: null,
      },
      {
        alias: null,
        args: v1 /*: any*/,
        concreteType: 'InvsysListingReminderSettings',
        kind: 'LinkedField',
        name: 'invsysListingReminderSettings',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'tz',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'hour',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'minute',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'sunday',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'monday',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'tuesday',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'wednesday',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'thursday',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'friday',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'saturday',
            storageKey: null,
          },
        ],
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        concreteType: 'InvsysImportInstitutionListingsRequest',
        kind: 'LinkedField',
        name: 'invsysImportInstitutionListingsRequestHistory',
        plural: true,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'id',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'createdAt',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'institution',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'status',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'feedType',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'autoApply',
            storageKey: null,
          },
        ],
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        concreteType: 'EbayProfile',
        kind: 'LinkedField',
        name: 'ebayProfile',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'userId',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'username',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'profilePictureUrl',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'updatedAt',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'businessPoliciesEnabled',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'shippingPolicyIds',
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'appListingCriticalMetadataQuery',
      selections: v2 /*: any*/,
      type: 'Query',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'appListingCriticalMetadataQuery',
      selections: v2 /*: any*/,
    },
    params: {
      cacheID: '4924f2e0c0bf406ebba7cad3da2e7643',
      id: null,
      metadata: {},
      name: 'appListingCriticalMetadataQuery',
      operationKind: 'query',
      text: 'query appListingCriticalMetadataQuery(\n  $tz: String\n) {\n  invsysListingFeatureSettings {\n    currencyCode\n    sizeUnit\n    weightUnit\n    weight\n    shippingProviders\n    descriptionTemplate\n    privateNoteTemplate\n    packageSizeType\n    packageCustomLength\n    packageCustomWidth\n    packageCustomHeight\n    marketplaces\n    poshmarkShippingDiscountId\n    mercariShippingType\n    mercariShippingPayerId\n    depopShippingType\n    depopNationalShippingPrice\n    depopInternationalShippingPrice\n    tradesyShippingType\n    tradesyShippingPrice\n    grailedShippingType\n    grailedUsShippingPrice\n    grailedCaShippingPrice\n  }\n  invsysCrosslistStreakTimePoints(tz: $tz) {\n    at\n    value\n  }\n  invsysListingReminderSettings(tz: $tz) {\n    tz\n    hour\n    minute\n    sunday\n    monday\n    tuesday\n    wednesday\n    thursday\n    friday\n    saturday\n  }\n  invsysImportInstitutionListingsRequestHistory {\n    id\n    createdAt\n    institution\n    status\n    feedType\n    autoApply\n  }\n  ebayProfile {\n    userId\n    username\n    profilePictureUrl\n    updatedAt\n    businessPoliciesEnabled\n    shippingPolicyIds\n  }\n}\n',
    },
  };
})();
(node as any).hash = 'd36b06bd36706cb7ff9408d2db5913fa';
export default node;
