/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type SuperposherStartSubscriptionError = 'NO_DEFAULT_PAYMENT_METHOD' | '%future added value';
export type startSubscriptionMutationVariables = {};
export type startSubscriptionMutationResponse = {
  readonly superposherStartSubscription: {
    readonly success: boolean;
    readonly error: SuperposherStartSubscriptionError | null;
  };
};
export type startSubscriptionMutation = {
  readonly response: startSubscriptionMutationResponse;
  readonly variables: startSubscriptionMutationVariables;
};

/*
mutation startSubscriptionMutation {
  superposherStartSubscription {
    success
    error
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = [
    {
      alias: null,
      args: null,
      concreteType: 'SuperposherStartSubscriptionResponseType',
      kind: 'LinkedField',
      name: 'superposherStartSubscription',
      plural: false,
      selections: [
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'success',
          storageKey: null,
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'error',
          storageKey: null,
        },
      ],
      storageKey: null,
    },
  ];
  return {
    fragment: {
      argumentDefinitions: [],
      kind: 'Fragment',
      metadata: null,
      name: 'startSubscriptionMutation',
      selections: v0 /*: any*/,
      type: 'Mutation',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [],
      kind: 'Operation',
      name: 'startSubscriptionMutation',
      selections: v0 /*: any*/,
    },
    params: {
      cacheID: 'fef5bbeaaca3114f523663d93d9c8776',
      id: null,
      metadata: {},
      name: 'startSubscriptionMutation',
      operationKind: 'mutation',
      text: 'mutation startSubscriptionMutation {\n  superposherStartSubscription {\n    success\n    error\n  }\n}\n',
    },
  };
})();
(node as any).hash = 'aca914f70110f39631920b06189e19fd';
export default node;
