/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type PoshmarkStringPutObject = {
  value?: string | null;
};
export type editPoshmarkItemMutationVariables = {
  itemId: string;
  sizeSystem?: PoshmarkStringPutObject | null;
  sizeVal?: PoshmarkStringPutObject | null;
};
export type editPoshmarkItemMutationResponse = {
  readonly poshmarkEditPoshmarkItem: {
    readonly success: boolean;
  } | null;
};
export type editPoshmarkItemMutation = {
  readonly response: editPoshmarkItemMutationResponse;
  readonly variables: editPoshmarkItemMutationVariables;
};

/*
mutation editPoshmarkItemMutation(
  $itemId: String!
  $sizeSystem: PoshmarkStringPutObject
  $sizeVal: PoshmarkStringPutObject
) {
  poshmarkEditPoshmarkItem(itemId: $itemId, sizeSystem: $sizeSystem, sizeVal: $sizeVal) {
    success
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'itemId',
      },
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'sizeSystem',
      },
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'sizeVal',
      },
    ],
    v1 = [
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'itemId',
            variableName: 'itemId',
          },
          {
            kind: 'Variable',
            name: 'sizeSystem',
            variableName: 'sizeSystem',
          },
          {
            kind: 'Variable',
            name: 'sizeVal',
            variableName: 'sizeVal',
          },
        ],
        concreteType: 'PoshmarkEditPoshmarkItemResponse',
        kind: 'LinkedField',
        name: 'poshmarkEditPoshmarkItem',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'success',
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'editPoshmarkItemMutation',
      selections: v1 /*: any*/,
      type: 'Mutation',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'editPoshmarkItemMutation',
      selections: v1 /*: any*/,
    },
    params: {
      cacheID: '172f6dc243dd25aa443f618f11e1695e',
      id: null,
      metadata: {},
      name: 'editPoshmarkItemMutation',
      operationKind: 'mutation',
      text: 'mutation editPoshmarkItemMutation(\n  $itemId: String!\n  $sizeSystem: PoshmarkStringPutObject\n  $sizeVal: PoshmarkStringPutObject\n) {\n  poshmarkEditPoshmarkItem(itemId: $itemId, sizeSystem: $sizeSystem, sizeVal: $sizeVal) {\n    success\n  }\n}\n',
    },
  };
})();
(node as any).hash = 'df1a5a9f91ca11f88946fe23a3d465f3';
export default node;
