/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type MercariIntPutObject = {
  value?: number | null;
};
export type editMercariItemMutationVariables = {
  itemId: string;
  sizeId?: MercariIntPutObject | null;
};
export type editMercariItemMutationResponse = {
  readonly mercariEditMercariItem: {
    readonly success: boolean;
  } | null;
};
export type editMercariItemMutation = {
  readonly response: editMercariItemMutationResponse;
  readonly variables: editMercariItemMutationVariables;
};

/*
mutation editMercariItemMutation(
  $itemId: String!
  $sizeId: MercariIntPutObject
) {
  mercariEditMercariItem(itemId: $itemId, sizeId: $sizeId) {
    success
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'itemId',
      },
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'sizeId',
      },
    ],
    v1 = [
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'itemId',
            variableName: 'itemId',
          },
          {
            kind: 'Variable',
            name: 'sizeId',
            variableName: 'sizeId',
          },
        ],
        concreteType: 'MercariEditMercariItemResponse',
        kind: 'LinkedField',
        name: 'mercariEditMercariItem',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'success',
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'editMercariItemMutation',
      selections: v1 /*: any*/,
      type: 'Mutation',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'editMercariItemMutation',
      selections: v1 /*: any*/,
    },
    params: {
      cacheID: 'fae69397ef83ff68d331ef77450b171d',
      id: null,
      metadata: {},
      name: 'editMercariItemMutation',
      operationKind: 'mutation',
      text: 'mutation editMercariItemMutation(\n  $itemId: String!\n  $sizeId: MercariIntPutObject\n) {\n  mercariEditMercariItem(itemId: $itemId, sizeId: $sizeId) {\n    success\n  }\n}\n',
    },
  };
})();
(node as any).hash = 'c42b7554ba0976e7dccb54915bed54ef';
export default node;
