/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type validateGrailedListingQueryVariables = {
  listingId: string;
};
export type validateGrailedListingQueryResponse = {
  readonly grailedValidateGrailedListing: {
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly errorId: number;
      readonly entityName: string | null;
      readonly entityId: string | null;
      readonly fieldName: string | null;
      readonly error: string | null;
      readonly message: string;
      readonly timeToComplete: number;
    }>;
    readonly additionalFieldsAvailable: ReadonlyArray<string>;
  } | null;
};
export type validateGrailedListingQuery = {
  readonly response: validateGrailedListingQueryResponse;
  readonly variables: validateGrailedListingQueryVariables;
};

/*
query validateGrailedListingQuery(
  $listingId: String!
) {
  grailedValidateGrailedListing(listingId: $listingId) {
    success
    errors {
      errorId: id
      entityName
      entityId
      fieldName
      error
      message
      timeToComplete
    }
    additionalFieldsAvailable
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'listingId',
      },
    ],
    v1 = [
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'listingId',
            variableName: 'listingId',
          },
        ],
        concreteType: 'GrailedListingValidationResult',
        kind: 'LinkedField',
        name: 'grailedValidateGrailedListing',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'success',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            concreteType: 'GrailedListingValidationErrorType',
            kind: 'LinkedField',
            name: 'errors',
            plural: true,
            selections: [
              {
                alias: 'errorId',
                args: null,
                kind: 'ScalarField',
                name: 'id',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'entityName',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'entityId',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'fieldName',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'error',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'message',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'timeToComplete',
                storageKey: null,
              },
            ],
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'additionalFieldsAvailable',
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'validateGrailedListingQuery',
      selections: v1 /*: any*/,
      type: 'Query',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'validateGrailedListingQuery',
      selections: v1 /*: any*/,
    },
    params: {
      cacheID: 'd534f9e7daaffbb24e24417ec20c7124',
      id: null,
      metadata: {},
      name: 'validateGrailedListingQuery',
      operationKind: 'query',
      text: 'query validateGrailedListingQuery(\n  $listingId: String!\n) {\n  grailedValidateGrailedListing(listingId: $listingId) {\n    success\n    errors {\n      errorId: id\n      entityName\n      entityId\n      fieldName\n      error\n      message\n      timeToComplete\n    }\n    additionalFieldsAvailable\n  }\n}\n',
    },
  };
})();
(node as any).hash = 'd0e63476b9dc6d6cbcc14e1e92626284';
export default node;
