/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type deleteClosetSectionMutationVariables = {
  poshmarkUserId: string;
  id: string;
};
export type deleteClosetSectionMutationResponse = {
  readonly superposherDeleteClosetSection: {
    readonly success: boolean;
  };
};
export type deleteClosetSectionMutation = {
  readonly response: deleteClosetSectionMutationResponse;
  readonly variables: deleteClosetSectionMutationVariables;
};

/*
mutation deleteClosetSectionMutation(
  $poshmarkUserId: String!
  $id: String!
) {
  superposherDeleteClosetSection(poshmarkUserId: $poshmarkUserId, id: $id) {
    success
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'id',
    },
    v1 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'poshmarkUserId',
    },
    v2 = [
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'id',
            variableName: 'id',
          },
          {
            kind: 'Variable',
            name: 'poshmarkUserId',
            variableName: 'poshmarkUserId',
          },
        ],
        concreteType: 'SuperposherDeleteClosetSectionResponse',
        kind: 'LinkedField',
        name: 'superposherDeleteClosetSection',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'success',
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: [v0 /*: any*/, v1 /*: any*/],
      kind: 'Fragment',
      metadata: null,
      name: 'deleteClosetSectionMutation',
      selections: v2 /*: any*/,
      type: 'Mutation',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [v1 /*: any*/, v0 /*: any*/],
      kind: 'Operation',
      name: 'deleteClosetSectionMutation',
      selections: v2 /*: any*/,
    },
    params: {
      cacheID: 'b7e141c1d19ca58904fea4f5733549eb',
      id: null,
      metadata: {},
      name: 'deleteClosetSectionMutation',
      operationKind: 'mutation',
      text: 'mutation deleteClosetSectionMutation(\n  $poshmarkUserId: String!\n  $id: String!\n) {\n  superposherDeleteClosetSection(poshmarkUserId: $poshmarkUserId, id: $id) {\n    success\n  }\n}\n',
    },
  };
})();
(node as any).hash = '239fe50fedbe77646d8261eae25dc3d7';
export default node;
