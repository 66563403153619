import * as Observe from 'src/state/lib/observe';
import * as ObserveNew from 'src/util/observe';
import * as StateTypes from 'src/state/types';
import * as ListingSearchClient from 'src/clients/ListingSearch';
import * as ListingFormAttributeSearchClient from 'src/clients/ListingFormAttributeSearch';

export const ListingDefaultSearchFilterValue = Observe.Value<string | null>(null);
export const ListingDefaultSearchTextValue = Observe.Value<string>('');

export enum ListingRecordUnifiedStatusType {
  Failed = 2,
  Scheduling = 3,
  ScheduledToPost = 5,
  Delisting = 6,
  Relisting = 7,
}

export const ListingRecordSearchOneState = ObserveNew.Value<ListingSearchClient.SearchOneArgs>({
  cursor: undefined,
  query: {
    searchString: {
      fields: [],
      rawValue: '',
    },
    filter: [
      {
        field: ListingSearchClient.FilterConditionFieldType.Preset,
        rawValue: '0',
      },
    ],
  },
});

export const ListingRecordSearchOneStringValue = ObserveNew.Value<string>('');

export interface ListingRecordV2Iface {
  id: string;
  businessId: string;
  brand: string | null;
  title: string | null;
  price: number | null;
  listingSku: string | null;
  searchTags: string[];
  listedOnInstitutions: StateTypes.InstitutionEnum[];
  listedOnInstitutionsCount: number;
  unpublishedChangesInstitutions: StateTypes.InstitutionEnum[];
  unpublishedChangesInstitutionsCount: number;
  soldOnInstitutions: StateTypes.InstitutionEnum[];
  publishErrorInstitutions: StateTypes.InstitutionEnum[];
  publishErrorInstitutionsCount: number;
  firstPublishedAt: number | null;
  lastPublishedAt: number | null;
  createdAt: number;
  updatedAt: number;
  archived: boolean;
  userDeleted: boolean;
  systemDeleted: boolean;
  enqueuedToPostAt: number | null;
  privateNote: string | null;
  coverPhotoUrl: string | null;
  unsoldQuantity: number;
}

export enum TextSearchField {
  Basic = 0,
  Title = 1,
  Brand = 2,
  PrivateNote = 3,
  ListingSku = 4,
  Labels = 5,
}
export const ListingRecordSearchTextSearchFieldValue = ObserveNew.Value<TextSearchField>(TextSearchField.Basic);

export const ListingRecordV2Value = ObserveNew.LookupStoreValue<ListingRecordV2Iface>();
export const ListingRecordV2PaginationState = ObserveNew.React.WithPaginationState<ListingRecordV2Iface>({
  idSelector: (_) => _.id,
  lookupStoreValue: ListingRecordV2Value,
});

export interface ListingFormSizeRecordV1Iface {
  id: string;
  categoryIds: string[];
  display: string;
  rank: number | null;
  sizeGroup: string | null;
  sizeSystem: string;
  showUserQuery?: boolean;
  isUserQuery?: boolean;
}

export const ListingFormInvsysSizeRecordSearchOneState =
  ObserveNew.Value<ListingFormAttributeSearchClient.InvsysSize.SearchOneArgs>({
    cursor: undefined,
    query: {
      searchString: {
        fields: [0],
        rawValue: '',
      },
      filter: [
        {
          field: ListingFormAttributeSearchClient.InvsysSize.FilterConditionFieldType.Category,
          rawValue: [],
        },
      ],
    },
  });

export const ListingFormInvsysSizeRecordSearchOneStringValue = ObserveNew.Value<string>('');

export const ListingFormInvsysSizeRecordV1Value = ObserveNew.LookupStoreValue<ListingFormSizeRecordV1Iface>();
export const ListingFormInvsysSizeRecordV1PaginationState =
  ObserveNew.React.WithPaginationState<ListingFormSizeRecordV1Iface>({
    idSelector: (_) => `${_.id}:${_.sizeGroup}`,
    lookupStoreValue: ListingFormInvsysSizeRecordV1Value,
  });

export interface ListingFormInvsysSizeAggGroupBucketsIface {
  value: string;
  count: number;
}
export const ListingFormInvsysSizeAggGroupBucketsValue = ObserveNew.Value<ListingFormInvsysSizeAggGroupBucketsIface[]>(
  []
);

export const ListingFormPoshmarkSizeRecordSearchOneState =
  ObserveNew.Value<ListingFormAttributeSearchClient.PoshmarkSize.SearchOneArgs>({
    cursor: undefined,
    query: {
      searchString: {
        fields: [0],
        rawValue: '',
      },
      filter: [
        {
          field: ListingFormAttributeSearchClient.PoshmarkSize.FilterConditionFieldType.Category,
          rawValue: [],
        },
      ],
    },
  });

export const ListingFormPoshmarkSizeRecordSearchOneStringValue = ObserveNew.Value<string>('');

export const ListingFormPoshmarkSizeRecordV1Value = ObserveNew.LookupStoreValue<ListingFormSizeRecordV1Iface>();
export const ListingFormPoshmarkSizeRecordV1PaginationState =
  ObserveNew.React.WithPaginationState<ListingFormSizeRecordV1Iface>({
    idSelector: (_) => `${_.id}:${_.sizeGroup}`,
    lookupStoreValue: ListingFormPoshmarkSizeRecordV1Value,
  });

export interface ListingFormPoshmarkSizeAggGroupBucketsIface {
  value: string;
  count: number;
}
export const ListingFormPoshmarkSizeAggGroupBucketsValue = ObserveNew.Value<
  ListingFormPoshmarkSizeAggGroupBucketsIface[]
>([]);

export const ListingFormMercariSizeRecordSearchOneState =
  ObserveNew.Value<ListingFormAttributeSearchClient.MercariSize.SearchOneArgs>({
    cursor: undefined,
    query: {
      searchString: {
        fields: [0],
        rawValue: '',
      },
      filter: [
        {
          field: ListingFormAttributeSearchClient.MercariSize.FilterConditionFieldType.Category,
          rawValue: [],
        },
      ],
    },
  });

export const ListingFormMercariSizeRecordSearchOneStringValue = ObserveNew.Value<string>('');

export const ListingFormMercariSizeRecordV1Value = ObserveNew.LookupStoreValue<ListingFormSizeRecordV1Iface>();
export const ListingFormMercariSizeRecordV1PaginationState =
  ObserveNew.React.WithPaginationState<ListingFormSizeRecordV1Iface>({
    idSelector: (_) => `${_.id}:${_.sizeGroup}`,
    lookupStoreValue: ListingFormMercariSizeRecordV1Value,
  });

export interface ListingFormMercariSizeAggGroupBucketsIface {
  value: string;
  count: number;
}
export const ListingFormMercariSizeAggGroupBucketsValue = ObserveNew.Value<
  ListingFormMercariSizeAggGroupBucketsIface[]
>([]);

export const ListingFormDepopSizeRecordSearchOneState =
  ObserveNew.Value<ListingFormAttributeSearchClient.DepopSize.SearchOneArgs>({
    cursor: undefined,
    query: {
      searchString: {
        fields: [0],
        rawValue: '',
      },
      filter: [
        {
          field: ListingFormAttributeSearchClient.DepopSize.FilterConditionFieldType.Category,
          rawValue: [],
        },
      ],
    },
  });

export const ListingFormDepopSizeRecordSearchOneStringValue = ObserveNew.Value<string>('');

export const ListingFormDepopSizeRecordV1Value = ObserveNew.LookupStoreValue<ListingFormSizeRecordV1Iface>();
export const ListingFormDepopSizeRecordV1PaginationState =
  ObserveNew.React.WithPaginationState<ListingFormSizeRecordV1Iface>({
    idSelector: (_) => `${_.id}:${_.sizeGroup}`,
    lookupStoreValue: ListingFormDepopSizeRecordV1Value,
  });

export interface ListingFormDepopSizeAggGroupBucketsIface {
  value: string;
  count: number;
}
export const ListingFormDepopSizeAggGroupBucketsValue = ObserveNew.Value<ListingFormDepopSizeAggGroupBucketsIface[]>(
  []
);

export const ListingFormEbaySizeRecordSearchOneState =
  ObserveNew.Value<ListingFormAttributeSearchClient.EbaySize.SearchOneArgs>({
    cursor: undefined,
    query: {
      searchString: {
        fields: [0],
        rawValue: '',
      },
      filter: [
        {
          field: ListingFormAttributeSearchClient.EbaySize.FilterConditionFieldType.Category,
          rawValue: [],
        },
      ],
    },
  });

export const ListingFormEbaySizeRecordSearchOneStringValue = ObserveNew.Value<string>('');

export const ListingFormEbaySizeRecordV1Value = ObserveNew.LookupStoreValue<ListingFormSizeRecordV1Iface>();
export const ListingFormEbaySizeRecordV1PaginationState =
  ObserveNew.React.WithPaginationState<ListingFormSizeRecordV1Iface>({
    idSelector: (_) => `${_.id}:${_.sizeGroup}`,
    lookupStoreValue: ListingFormEbaySizeRecordV1Value,
  });

export interface ListingFormEbaySizeAggGroupBucketsIface {
  value: string;
  count: number;
}
export const ListingFormEbaySizeAggGroupBucketsValue = ObserveNew.Value<ListingFormDepopSizeAggGroupBucketsIface[]>([]);

export const ListingFormGrailedSizeRecordSearchOneState =
  ObserveNew.Value<ListingFormAttributeSearchClient.GrailedSize.SearchOneArgs>({
    cursor: undefined,
    query: {
      searchString: {
        fields: [0],
        rawValue: '',
      },
      filter: [
        {
          field: ListingFormAttributeSearchClient.GrailedSize.FilterConditionFieldType.Category,
          rawValue: [],
        },
      ],
    },
  });

export const ListingFormGrailedSizeRecordSearchOneStringValue = ObserveNew.Value<string>('');

export const ListingFormGrailedSizeRecordV1Value = ObserveNew.LookupStoreValue<ListingFormSizeRecordV1Iface>();
export const ListingFormGrailedSizeRecordV1PaginationState =
  ObserveNew.React.WithPaginationState<ListingFormSizeRecordV1Iface>({
    idSelector: (_) => `${_.id}:${_.sizeGroup}`,
    lookupStoreValue: ListingFormGrailedSizeRecordV1Value,
  });

export interface ListingFormGrailedSizeAggGroupBucketsIface {
  value: string;
  count: number;
}
export const ListingFormGrailedSizeAggGroupBucketsValue = ObserveNew.Value<
  ListingFormGrailedSizeAggGroupBucketsIface[]
>([]);

export interface ShippingPolicyRecordV1Iface {
  id: string;
  businessId: string;
  institutionIssuedId: string;
  profileName: string;
  profileDescription: string | null;
  shippingPolicyId: string | null;
  shippingPolicyVersionId: string | null;
  domesticShippingType: string | null;
  internationalShippingType: string | null;
  domesticShippingService1: string | null;
  domesticShippingService1Cost: number | null;
  domesticShippingService1AdditionalCost: number | null;
  domesticShippingService1FreeShipping: boolean;
  domesticShippingService2: string | null;
  domesticShippingService2Cost: number | null;
  domesticShippingService2AdditionalCost: number | null;
  domesticShippingService2FreeShipping: boolean;
  domesticShippingService3: string | null;
  domesticShippingService3Cost: number | null;
  domesticShippingService3AdditionalCost: number | null;
  domesticShippingService3FreeShipping: boolean;
  domesticShippingService4: string | null;
  domesticShippingService4Cost: number | null;
  domesticShippingService4AdditionalCost: number | null;
  domesticShippingService4FreeShipping: boolean;
  intlShippingService1: string | null;
  intlShippingService1Cost: number | null;
  intlShippingService1AdditionalCost: number | null;
  intlShippingService1FreeShipping: boolean;
  intlShippingService2: string | null;
  intlShippingService2Cost: number | null;
  intlShippingService2AdditionalCost: number | null;
  intlShippingService2FreeShipping: boolean;
  intlShippingService3: string | null;
  intlShippingService3Cost: number | null;
  intlShippingService3AdditionalCost: number | null;
  intlShippingService3FreeShipping: boolean;
  intlShippingService4: string | null;
  intlShippingService4Cost: number | null;
  intlShippingService4AdditionalCost: number | null;
  intlShippingService4FreeShipping: boolean;
  intlShippingService5: string | null;
  intlShippingService5Cost: number | null;
  intlShippingService5AdditionalCost: number | null;
  intlShippingService5FreeShipping: boolean;
  globalShipping: boolean;
  pickupDropOffEligibility: boolean;
  currency: string | null;
  handlingTime: number | null;
  createdAt: number;
  updatedAt: number;
}

export const ShippingPolicyRecordSearchOneState =
  ObserveNew.Value<ListingFormAttributeSearchClient.EbayShipping.SearchOneArgs>({
    cursor: undefined,
    query: {
      searchString: {
        fields: [0],
        rawValue: '',
      },
    },
  });

export const ShippingPolicyRecordSearchOneStringValue = ObserveNew.Value<string>('');

export const ShippingPolicyRecordV1Value = ObserveNew.LookupStoreValue<ShippingPolicyRecordV1Iface>();
export const ShippingPolicyRecordV1PaginationState = ObserveNew.React.WithPaginationState<ShippingPolicyRecordV1Iface>({
  idSelector: (_) => _.id,
  lookupStoreValue: ShippingPolicyRecordV1Value,
});

export interface ListingFormCatalogRecordV1Iface {
  path: string;
  pathDisplays: string[];
  institutionIssuedId: string;
  display: string;
}

export const ListingFormInvsysCatalogRecordSearchOneState =
  ObserveNew.Value<ListingFormAttributeSearchClient.InvsysCatalog.SearchOneArgs>({
    cursor: undefined,
    query: {
      searchString: {
        fields: [],
        rawValue: '',
      },
    },
  });

export const ListingFormInvsysCatalogRecordSearchOneStringValue = ObserveNew.Value<string>('');

export const ListingFormInvsysCatalogRecordV1Value = ObserveNew.LookupStoreValue<ListingFormCatalogRecordV1Iface>();
export const ListingFormInvsysCatalogRecordV1PaginationState =
  ObserveNew.React.WithPaginationState<ListingFormCatalogRecordV1Iface>({
    idSelector: (_) => _.institutionIssuedId,
    lookupStoreValue: ListingFormInvsysCatalogRecordV1Value,
  });

export const ListingFormPoshmarkCatalogRecordSearchOneState =
  ObserveNew.Value<ListingFormAttributeSearchClient.PoshmarkCatalog.SearchOneArgs>({
    cursor: undefined,
    query: {
      searchString: {
        fields: [],
        rawValue: '',
      },
    },
  });

export const ListingFormPoshmarkCatalogRecordSearchOneStringValue = ObserveNew.Value<string>('');

export const ListingFormPoshmarkCatalogRecordV1Value = ObserveNew.LookupStoreValue<ListingFormCatalogRecordV1Iface>();
export const ListingFormPoshmarkCatalogRecordV1PaginationState =
  ObserveNew.React.WithPaginationState<ListingFormCatalogRecordV1Iface>({
    idSelector: (_) => _.institutionIssuedId,
    lookupStoreValue: ListingFormPoshmarkCatalogRecordV1Value,
  });

export const ListingFormMercariCatalogRecordSearchOneState =
  ObserveNew.Value<ListingFormAttributeSearchClient.MercariCatalog.SearchOneArgs>({
    cursor: undefined,
    query: {
      searchString: {
        fields: [],
        rawValue: '',
      },
    },
  });

export const ListingFormMercariCatalogRecordSearchOneStringValue = ObserveNew.Value<string>('');

export const ListingFormMercariCatalogRecordV1Value = ObserveNew.LookupStoreValue<ListingFormCatalogRecordV1Iface>();
export const ListingFormMercariCatalogRecordV1PaginationState =
  ObserveNew.React.WithPaginationState<ListingFormCatalogRecordV1Iface>({
    idSelector: (_) => _.institutionIssuedId,
    lookupStoreValue: ListingFormMercariCatalogRecordV1Value,
  });

export const ListingFormDepopCatalogRecordSearchOneState =
  ObserveNew.Value<ListingFormAttributeSearchClient.DepopCatalog.SearchOneArgs>({
    cursor: undefined,
    query: {
      searchString: {
        fields: [],
        rawValue: '',
      },
    },
  });

export const ListingFormDepopCatalogRecordSearchOneStringValue = ObserveNew.Value<string>('');

export const ListingFormDepopCatalogRecordV1Value = ObserveNew.LookupStoreValue<ListingFormCatalogRecordV1Iface>();
export const ListingFormDepopCatalogRecordV1PaginationState =
  ObserveNew.React.WithPaginationState<ListingFormCatalogRecordV1Iface>({
    idSelector: (_) => _.institutionIssuedId,
    lookupStoreValue: ListingFormDepopCatalogRecordV1Value,
  });

export const ListingFormEbayCatalogRecordSearchOneState =
  ObserveNew.Value<ListingFormAttributeSearchClient.EbayCatalog.SearchOneArgs>({
    cursor: undefined,
    query: {
      searchString: {
        fields: [],
        rawValue: '',
      },
    },
  });

export const ListingFormEbayCatalogRecordSearchOneStringValue = ObserveNew.Value<string>('');

export const ListingFormEbayCatalogRecordV1Value = ObserveNew.LookupStoreValue<ListingFormCatalogRecordV1Iface>();
export const ListingFormEbayCatalogRecordV1PaginationState =
  ObserveNew.React.WithPaginationState<ListingFormCatalogRecordV1Iface>({
    idSelector: (_) => _.institutionIssuedId,
    lookupStoreValue: ListingFormEbayCatalogRecordV1Value,
  });

export const ListingFormGrailedCatalogRecordSearchOneState =
  ObserveNew.Value<ListingFormAttributeSearchClient.GrailedCatalog.SearchOneArgs>({
    cursor: undefined,
    query: {
      searchString: {
        fields: [],
        rawValue: '',
      },
    },
  });

export const ListingFormGrailedCatalogRecordSearchOneStringValue = ObserveNew.Value<string>('');

export const ListingFormGrailedCatalogRecordV1Value = ObserveNew.LookupStoreValue<ListingFormCatalogRecordV1Iface>();
export const ListingFormGrailedCatalogRecordV1PaginationState =
  ObserveNew.React.WithPaginationState<ListingFormCatalogRecordV1Iface>({
    idSelector: (_) => _.institutionIssuedId,
    lookupStoreValue: ListingFormGrailedCatalogRecordV1Value,
  });

export interface ListingFormBrandRecordV1Iface {
  value: string;
  id?: number;
  showUserQuery?: boolean;
}

export const ListingFormInvsysBrandRecordSearchOneState =
  ObserveNew.Value<ListingFormAttributeSearchClient.InvsysBrand.SearchOneArgs>({
    cursor: undefined,
    query: {
      searchString: {
        fields: [0],
        rawValue: '',
      },
    },
  });

export const ListingFormInvsysBrandRecordSearchOneStringValue = ObserveNew.Value<string>('');

export const ListingFormInvsysBrandRecordV1Value = ObserveNew.LookupStoreValue<ListingFormBrandRecordV1Iface>();
export const ListingFormInvsysBrandRecordV1PaginationState =
  ObserveNew.React.WithPaginationState<ListingFormBrandRecordV1Iface>({
    idSelector: (_) => _.value,
    lookupStoreValue: ListingFormInvsysBrandRecordV1Value,
  });

export const ListingFormPoshmarkBrandRecordSearchOneState =
  ObserveNew.Value<ListingFormAttributeSearchClient.PoshmarkBrand.SearchOneArgs>({
    cursor: undefined,
    query: {
      searchString: {
        fields: [0],
        rawValue: '',
      },
    },
  });

export const ListingFormPoshmarkBrandRecordSearchOneStringValue = ObserveNew.Value<string>('');

export const ListingFormPoshmarkBrandRecordV1Value = ObserveNew.LookupStoreValue<ListingFormBrandRecordV1Iface>();
export const ListingFormPoshmarkBrandRecordV1PaginationState =
  ObserveNew.React.WithPaginationState<ListingFormBrandRecordV1Iface>({
    idSelector: (_) => _.value,
    lookupStoreValue: ListingFormPoshmarkBrandRecordV1Value,
  });

export const ListingFormMercariBrandRecordSearchOneState =
  ObserveNew.Value<ListingFormAttributeSearchClient.MercariBrand.SearchOneArgs>({
    cursor: undefined,
    query: {
      searchString: {
        fields: [0],
        rawValue: '',
      },
    },
  });

export const ListingFormMercariBrandRecordSearchOneStringValue = ObserveNew.Value<string>('');

export const ListingFormMercariBrandRecordV1Value = ObserveNew.LookupStoreValue<ListingFormBrandRecordV1Iface>();
export const ListingFormMercariBrandRecordV1PaginationState =
  ObserveNew.React.WithPaginationState<ListingFormBrandRecordV1Iface>({
    idSelector: (_) => _.value,
    lookupStoreValue: ListingFormMercariBrandRecordV1Value,
  });

export const ListingFormDepopBrandRecordSearchOneState =
  ObserveNew.Value<ListingFormAttributeSearchClient.DepopBrand.SearchOneArgs>({
    cursor: undefined,
    query: {
      searchString: {
        fields: [0],
        rawValue: '',
      },
    },
  });

export const ListingFormDepopBrandRecordSearchOneStringValue = ObserveNew.Value<string>('');

export const ListingFormDepopBrandRecordV1Value = ObserveNew.LookupStoreValue<ListingFormBrandRecordV1Iface>();
export const ListingFormDepopBrandRecordV1PaginationState =
  ObserveNew.React.WithPaginationState<ListingFormBrandRecordV1Iface>({
    idSelector: (_) => _.value,
    lookupStoreValue: ListingFormDepopBrandRecordV1Value,
  });

export const ListingFormEbayBrandRecordSearchOneState =
  ObserveNew.Value<ListingFormAttributeSearchClient.InvsysBrand.SearchOneArgs>({
    cursor: undefined,
    query: {
      searchString: {
        fields: [0],
        rawValue: '',
      },
    },
  });

export const ListingFormEbayBrandRecordSearchOneStringValue = ObserveNew.Value<string>('');

export const ListingFormEbayBrandRecordV1Value = ObserveNew.LookupStoreValue<ListingFormBrandRecordV1Iface>();
export const ListingFormEbayBrandRecordV1PaginationState =
  ObserveNew.React.WithPaginationState<ListingFormBrandRecordV1Iface>({
    idSelector: (_) => _.value,
    lookupStoreValue: ListingFormEbayBrandRecordV1Value,
  });

export const ListingFormGrailedBrandRecordSearchOneState =
  ObserveNew.Value<ListingFormAttributeSearchClient.GrailedBrand.SearchOneArgs>({
    cursor: undefined,
    query: {
      searchString: {
        fields: [0],
        rawValue: '',
      },
    },
  });

export const ListingFormGrailedBrandRecordSearchOneStringValue = ObserveNew.Value<string>('');

export const ListingFormGrailedBrandRecordV1Value = ObserveNew.LookupStoreValue<ListingFormBrandRecordV1Iface>();
export const ListingFormGrailedBrandRecordV1PaginationState =
  ObserveNew.React.WithPaginationState<ListingFormBrandRecordV1Iface>({
    idSelector: (_) => _.value,
    lookupStoreValue: ListingFormGrailedBrandRecordV1Value,
  });
