import { graphql } from 'react-relay';

export const gqlListingRecords = graphql`
  query listingRecordsQuery($cursor: String, $filter: Int) {
    invsysListingRecords(cursor: $cursor, filter: $filter) {
      items {
        objectID
        title
        mediaUrl
        brand
        updatedAt
        createdAt
        price
        tags
        marketplaces
        archived
        published
        draftMarketplaces
        hasDraftChanges
        listingStatus
        soldInstitutions
        numListingErrors
        paginationFilter
      }
      cursor
    }
  }
`;
