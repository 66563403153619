/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type listingRecordsQueryVariables = {
  cursor?: string | null;
  filter?: number | null;
};
export type listingRecordsQueryResponse = {
  readonly invsysListingRecords: {
    readonly items: ReadonlyArray<{
      readonly objectID: string;
      readonly title: string | null;
      readonly mediaUrl: string | null;
      readonly brand: string | null;
      readonly updatedAt: number;
      readonly createdAt: number;
      readonly price: number | null;
      readonly tags: ReadonlyArray<string>;
      readonly marketplaces: ReadonlyArray<number>;
      readonly archived: boolean;
      readonly published: boolean;
      readonly draftMarketplaces: ReadonlyArray<number>;
      readonly hasDraftChanges: boolean;
      readonly listingStatus: number;
      readonly soldInstitutions: ReadonlyArray<number | null> | null;
      readonly numListingErrors: number | null;
      readonly paginationFilter: number | null;
    }>;
    readonly cursor: string | null;
  } | null;
};
export type listingRecordsQuery = {
  readonly response: listingRecordsQueryResponse;
  readonly variables: listingRecordsQueryVariables;
};

/*
query listingRecordsQuery(
  $cursor: String
  $filter: Int
) {
  invsysListingRecords(cursor: $cursor, filter: $filter) {
    items {
      objectID
      title
      mediaUrl
      brand
      updatedAt
      createdAt
      price
      tags
      marketplaces
      archived
      published
      draftMarketplaces
      hasDraftChanges
      listingStatus
      soldInstitutions
      numListingErrors
      paginationFilter
    }
    cursor
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'cursor',
      },
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'filter',
      },
    ],
    v1 = [
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'cursor',
            variableName: 'cursor',
          },
          {
            kind: 'Variable',
            name: 'filter',
            variableName: 'filter',
          },
        ],
        concreteType: 'InvsysListingRecordPage',
        kind: 'LinkedField',
        name: 'invsysListingRecords',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            concreteType: 'InvsysSkusListingRecord',
            kind: 'LinkedField',
            name: 'items',
            plural: true,
            selections: [
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'objectID',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'title',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'mediaUrl',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'brand',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'updatedAt',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'createdAt',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'price',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'tags',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'marketplaces',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'archived',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'published',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'draftMarketplaces',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'hasDraftChanges',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'listingStatus',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'soldInstitutions',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'numListingErrors',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'paginationFilter',
                storageKey: null,
              },
            ],
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'cursor',
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'listingRecordsQuery',
      selections: v1 /*: any*/,
      type: 'Query',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'listingRecordsQuery',
      selections: v1 /*: any*/,
    },
    params: {
      cacheID: '6d17614c9fdb81ff7ae0e502eb818160',
      id: null,
      metadata: {},
      name: 'listingRecordsQuery',
      operationKind: 'query',
      text: 'query listingRecordsQuery(\n  $cursor: String\n  $filter: Int\n) {\n  invsysListingRecords(cursor: $cursor, filter: $filter) {\n    items {\n      objectID\n      title\n      mediaUrl\n      brand\n      updatedAt\n      createdAt\n      price\n      tags\n      marketplaces\n      archived\n      published\n      draftMarketplaces\n      hasDraftChanges\n      listingStatus\n      soldInstitutions\n      numListingErrors\n      paginationFilter\n    }\n    cursor\n  }\n}\n',
    },
  };
})();
(node as any).hash = 'e1b9aa7dc8e79d5d96ef2236be3eb08e';
export default node;
