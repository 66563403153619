/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type setStudioListingMediaGroupMembershipVariantMutationVariables = {
  membershipId: string;
  variantType: number;
};
export type setStudioListingMediaGroupMembershipVariantMutationResponse = {
  readonly studioSetListingMediaGroupMembershipVariant: {
    readonly success: boolean;
  };
};
export type setStudioListingMediaGroupMembershipVariantMutation = {
  readonly response: setStudioListingMediaGroupMembershipVariantMutationResponse;
  readonly variables: setStudioListingMediaGroupMembershipVariantMutationVariables;
};

/*
mutation setStudioListingMediaGroupMembershipVariantMutation(
  $membershipId: String!
  $variantType: Int!
) {
  studioSetListingMediaGroupMembershipVariant(membershipId: $membershipId, variantType: $variantType) {
    success
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'membershipId',
      },
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'variantType',
      },
    ],
    v1 = [
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'membershipId',
            variableName: 'membershipId',
          },
          {
            kind: 'Variable',
            name: 'variantType',
            variableName: 'variantType',
          },
        ],
        concreteType: 'StudioSetListingMediaGroupMembershipVariantResponse',
        kind: 'LinkedField',
        name: 'studioSetListingMediaGroupMembershipVariant',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'success',
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'setStudioListingMediaGroupMembershipVariantMutation',
      selections: v1 /*: any*/,
      type: 'Mutation',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'setStudioListingMediaGroupMembershipVariantMutation',
      selections: v1 /*: any*/,
    },
    params: {
      cacheID: '3fbddfa83fa2dab579d56476291767a6',
      id: null,
      metadata: {},
      name: 'setStudioListingMediaGroupMembershipVariantMutation',
      operationKind: 'mutation',
      text: 'mutation setStudioListingMediaGroupMembershipVariantMutation(\n  $membershipId: String!\n  $variantType: Int!\n) {\n  studioSetListingMediaGroupMembershipVariant(membershipId: $membershipId, variantType: $variantType) {\n    success\n  }\n}\n',
    },
  };
})();
(node as any).hash = '0ff03b7ae96d085a4d04fc33fa8631df';
export default node;
