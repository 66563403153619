import { graphql } from 'react-relay';

export const gqlValidateAddress = graphql`
  mutation validateAddressMutation(
    $streetAddress: String
    $unit: String
    $locality: String
    $subdivision: String
    $postalCode: String
    $countryCode: String
  ) {
    invsysValidateAddress(
      streetAddress: $streetAddress
      unit: $unit
      locality: $locality
      subdivision: $subdivision
      postalCode: $postalCode
      countryCode: $countryCode
    ) {
      success
      address {
        streetAddress
        unit
        locality
        subdivision
        postalCode
        countryCode
      }
      errorMessage
    }
  }
`;
