/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type SuperposherBusinessProduct =
  | 'MAX_PARTY_SHARES'
  | 'RAISE_MAX_TO_6K_SHARES'
  | 'RELISTING'
  | 'SUPERPOSHER_CORE'
  | 'SUPERPOSHER_CORE_TRIAL'
  | 'SUPERPOSHER_INTRO'
  | '%future added value';
export type updateAddOnMutationVariables = {
  product: SuperposherBusinessProduct;
  quantity: number;
};
export type updateAddOnMutationResponse = {
  readonly superposherUpdateAddOn: {
    readonly success: boolean;
  };
};
export type updateAddOnMutation = {
  readonly response: updateAddOnMutationResponse;
  readonly variables: updateAddOnMutationVariables;
};

/*
mutation updateAddOnMutation(
  $product: SuperposherBusinessProduct!
  $quantity: Int!
) {
  superposherUpdateAddOn(product: $product, quantity: $quantity) {
    success
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'product',
      },
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'quantity',
      },
    ],
    v1 = [
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'product',
            variableName: 'product',
          },
          {
            kind: 'Variable',
            name: 'quantity',
            variableName: 'quantity',
          },
        ],
        concreteType: 'SuperposherUpdateAddOnResponseType',
        kind: 'LinkedField',
        name: 'superposherUpdateAddOn',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'success',
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'updateAddOnMutation',
      selections: v1 /*: any*/,
      type: 'Mutation',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'updateAddOnMutation',
      selections: v1 /*: any*/,
    },
    params: {
      cacheID: '606a718d4a425eca91191026ffc305ee',
      id: null,
      metadata: {},
      name: 'updateAddOnMutation',
      operationKind: 'mutation',
      text: 'mutation updateAddOnMutation(\n  $product: SuperposherBusinessProduct!\n  $quantity: Int!\n) {\n  superposherUpdateAddOn(product: $product, quantity: $quantity) {\n    success\n  }\n}\n',
    },
  };
})();
(node as any).hash = 'a4e3af5d8519d107960cf5204a010b5b';
export default node;
