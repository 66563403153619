/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type DepopDepopListingColorType =
  | 'BLACK'
  | 'BLUE'
  | 'BROWN'
  | 'BURGUNDY'
  | 'CREAM'
  | 'GOLD'
  | 'GREEN'
  | 'GREY'
  | 'KHAKI'
  | 'MULTI'
  | 'NAVY'
  | 'ORANGE'
  | 'PINK'
  | 'PURPLE'
  | 'RED'
  | 'SILVER'
  | 'TAN'
  | 'WHITE'
  | 'YELLOW'
  | '%future added value';
export type DepopDepopListingConditionType = 'EXCELLENT' | 'FAIR' | 'GOOD' | 'LIKE_NEW' | 'NEW' | '%future added value';
export type DepopListingCurrencyCodeType = 'AUD' | 'CAD' | 'USD' | '%future added value';
export type depopListingQueryVariables = {
  listingId: string;
};
export type depopListingQueryResponse = {
  readonly depopDepopListing: {
    readonly id: string;
    readonly depopId: number | null;
    readonly listingId: string;
    readonly categoryId: number | null;
    readonly categoryIdV2: number | null;
    readonly description: string | null;
    readonly price: number | null;
    readonly currencyCode: DepopListingCurrencyCodeType | null;
    readonly brandId: string | null;
    readonly condition: DepopDepopListingConditionType | null;
    readonly color: ReadonlyArray<DepopDepopListingColorType> | null;
    readonly occasion: ReadonlyArray<string>;
    readonly material: ReadonlyArray<string>;
    readonly bodyFit: ReadonlyArray<string>;
    readonly sources: ReadonlyArray<string> | null;
    readonly ages: ReadonlyArray<string> | null;
    readonly style: ReadonlyArray<string> | null;
    readonly variant_set_id: number | null;
    readonly national_shipping_price: number | null;
    readonly international_shipping_price: number | null;
    readonly shippingType: number | null;
    readonly address: string | null;
    readonly addressId: string | null;
    readonly country_code: string | null;
    readonly geo_latitude: number | null;
    readonly geo_longitude: number | null;
    readonly priceSuggestion: {
      readonly suggestedPrice: number;
      readonly feeParts: ReadonlyArray<{
        readonly value: number;
        readonly label: string;
      }>;
    } | null;
    readonly shippingInfo: {
      readonly shippingPrice: number | null;
      readonly shippingProvider: number;
      readonly shippingProviderDisplay: string;
      readonly payerContributions: ReadonlyArray<{
        readonly contribution: number | null;
        readonly shippingPayer: number | null;
        readonly shippingPayerDisplay: string | null;
      }>;
    } | null;
    readonly sizeSuggestion: {
      readonly sizeSuggestions: ReadonlyArray<{
        readonly id: string;
        readonly display: string;
        readonly sizeSystem: string;
      }>;
    } | null;
    readonly publishStatusV2: {
      readonly status: string | null;
      readonly message: string | null;
    } | null;
    readonly items: ReadonlyArray<{
      readonly depopListingId: string;
      readonly itemId: string;
      readonly size: string | null;
    }>;
  } | null;
};
export type depopListingQuery = {
  readonly response: depopListingQueryResponse;
  readonly variables: depopListingQueryVariables;
};

/*
query depopListingQuery(
  $listingId: String!
) {
  depopDepopListing(listingId: $listingId) {
    id
    depopId
    listingId
    categoryId
    categoryIdV2
    description
    price
    currencyCode
    brandId
    condition
    color
    occasion
    material
    bodyFit
    sources
    ages
    style
    variant_set_id
    national_shipping_price
    international_shipping_price
    shippingType
    address
    addressId
    country_code
    geo_latitude
    geo_longitude
    priceSuggestion {
      suggestedPrice
      feeParts {
        value
        label
      }
    }
    shippingInfo {
      shippingPrice
      shippingProvider
      shippingProviderDisplay
      payerContributions {
        contribution
        shippingPayer
        shippingPayerDisplay
      }
    }
    sizeSuggestion {
      sizeSuggestions {
        id
        display
        sizeSystem
      }
    }
    publishStatusV2 {
      status
      message
    }
    items {
      depopListingId
      itemId
      size
    }
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'listingId',
      },
    ],
    v1 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null,
    },
    v2 = [
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'listingId',
            variableName: 'listingId',
          },
        ],
        concreteType: 'DepopDepopListing',
        kind: 'LinkedField',
        name: 'depopDepopListing',
        plural: false,
        selections: [
          v1 /*: any*/,
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'depopId',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'listingId',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'categoryId',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'categoryIdV2',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'description',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'price',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'currencyCode',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'brandId',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'condition',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'color',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'occasion',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'material',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'bodyFit',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'sources',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'ages',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'style',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'variant_set_id',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'national_shipping_price',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'international_shipping_price',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'shippingType',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'address',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'addressId',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'country_code',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'geo_latitude',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'geo_longitude',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            concreteType: 'DepopDepopPriceSuggestion',
            kind: 'LinkedField',
            name: 'priceSuggestion',
            plural: false,
            selections: [
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'suggestedPrice',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                concreteType: 'DepopDepopPriceSuggestionFeePart',
                kind: 'LinkedField',
                name: 'feeParts',
                plural: true,
                selections: [
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'value',
                    storageKey: null,
                  },
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'label',
                    storageKey: null,
                  },
                ],
                storageKey: null,
              },
            ],
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            concreteType: 'DepopShippingInfo',
            kind: 'LinkedField',
            name: 'shippingInfo',
            plural: false,
            selections: [
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'shippingPrice',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'shippingProvider',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'shippingProviderDisplay',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                concreteType: 'DepopShippingPayerContribution',
                kind: 'LinkedField',
                name: 'payerContributions',
                plural: true,
                selections: [
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'contribution',
                    storageKey: null,
                  },
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'shippingPayer',
                    storageKey: null,
                  },
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'shippingPayerDisplay',
                    storageKey: null,
                  },
                ],
                storageKey: null,
              },
            ],
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            concreteType: 'DepopSizeSuggestion',
            kind: 'LinkedField',
            name: 'sizeSuggestion',
            plural: false,
            selections: [
              {
                alias: null,
                args: null,
                concreteType: 'DepopSizeSuggestionSize',
                kind: 'LinkedField',
                name: 'sizeSuggestions',
                plural: true,
                selections: [
                  v1 /*: any*/,
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'display',
                    storageKey: null,
                  },
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'sizeSystem',
                    storageKey: null,
                  },
                ],
                storageKey: null,
              },
            ],
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            concreteType: 'DepopPublishStatusV2',
            kind: 'LinkedField',
            name: 'publishStatusV2',
            plural: false,
            selections: [
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'status',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'message',
                storageKey: null,
              },
            ],
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            concreteType: 'DepopDepopItem',
            kind: 'LinkedField',
            name: 'items',
            plural: true,
            selections: [
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'depopListingId',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'itemId',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'size',
                storageKey: null,
              },
            ],
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'depopListingQuery',
      selections: v2 /*: any*/,
      type: 'Query',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'depopListingQuery',
      selections: v2 /*: any*/,
    },
    params: {
      cacheID: '93b65a4ff731dc5977f0c42cb0468f9d',
      id: null,
      metadata: {},
      name: 'depopListingQuery',
      operationKind: 'query',
      text: 'query depopListingQuery(\n  $listingId: String!\n) {\n  depopDepopListing(listingId: $listingId) {\n    id\n    depopId\n    listingId\n    categoryId\n    categoryIdV2\n    description\n    price\n    currencyCode\n    brandId\n    condition\n    color\n    occasion\n    material\n    bodyFit\n    sources\n    ages\n    style\n    variant_set_id\n    national_shipping_price\n    international_shipping_price\n    shippingType\n    address\n    addressId\n    country_code\n    geo_latitude\n    geo_longitude\n    priceSuggestion {\n      suggestedPrice\n      feeParts {\n        value\n        label\n      }\n    }\n    shippingInfo {\n      shippingPrice\n      shippingProvider\n      shippingProviderDisplay\n      payerContributions {\n        contribution\n        shippingPayer\n        shippingPayerDisplay\n      }\n    }\n    sizeSuggestion {\n      sizeSuggestions {\n        id\n        display\n        sizeSystem\n      }\n    }\n    publishStatusV2 {\n      status\n      message\n    }\n    items {\n      depopListingId\n      itemId\n      size\n    }\n  }\n}\n',
    },
  };
})();
(node as any).hash = '6f214d3c9c85ebc4521439d3d53b010d';
export default node;
