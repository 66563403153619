/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type StudioListingMediaGroupMembershipPreparationOperation = {
  mediaId: string;
  preparationStatus: number;
};
export type prepareStudioListingMediaGroupMembershipMutationVariables = {
  listingMediaGroupId: string;
  operations: Array<StudioListingMediaGroupMembershipPreparationOperation>;
};
export type prepareStudioListingMediaGroupMembershipMutationResponse = {
  readonly studioPrepareListingMediaGroupMembership: {
    readonly success: boolean;
  };
};
export type prepareStudioListingMediaGroupMembershipMutation = {
  readonly response: prepareStudioListingMediaGroupMembershipMutationResponse;
  readonly variables: prepareStudioListingMediaGroupMembershipMutationVariables;
};

/*
mutation prepareStudioListingMediaGroupMembershipMutation(
  $listingMediaGroupId: String!
  $operations: [StudioListingMediaGroupMembershipPreparationOperation!]!
) {
  studioPrepareListingMediaGroupMembership(listingMediaGroupId: $listingMediaGroupId, operations: $operations) {
    success
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'listingMediaGroupId',
      },
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'operations',
      },
    ],
    v1 = [
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'listingMediaGroupId',
            variableName: 'listingMediaGroupId',
          },
          {
            kind: 'Variable',
            name: 'operations',
            variableName: 'operations',
          },
        ],
        concreteType: 'StudioPrepareListingMediaGroupMembershipResponse',
        kind: 'LinkedField',
        name: 'studioPrepareListingMediaGroupMembership',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'success',
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'prepareStudioListingMediaGroupMembershipMutation',
      selections: v1 /*: any*/,
      type: 'Mutation',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'prepareStudioListingMediaGroupMembershipMutation',
      selections: v1 /*: any*/,
    },
    params: {
      cacheID: '58689fe2564d0438eb141b87b1fcd033',
      id: null,
      metadata: {},
      name: 'prepareStudioListingMediaGroupMembershipMutation',
      operationKind: 'mutation',
      text: 'mutation prepareStudioListingMediaGroupMembershipMutation(\n  $listingMediaGroupId: String!\n  $operations: [StudioListingMediaGroupMembershipPreparationOperation!]!\n) {\n  studioPrepareListingMediaGroupMembership(listingMediaGroupId: $listingMediaGroupId, operations: $operations) {\n    success\n  }\n}\n',
    },
  };
})();
(node as any).hash = 'cc34bc62772c440b19520123959ee3fc';
export default node;
