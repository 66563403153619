import { graphql } from 'react-relay';

export const gqlUsRegisteredIdentificationNumberDetails = graphql`
  query usRegisteredIdentificationNumberDetailsQuery($id: String!, $rnType: String!) {
    invsysUsRegisteredIdentificationNumberDetails(id: $id, rnType: $rnType) {
      raw {
        id
        rnType
        legalName
        url
      }
      curated {
        id
        rnType
        legalName
        colloquialName
        iconUrl
        websiteUrl
        description
      }
    }
  }
`;
