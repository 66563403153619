import { graphql } from 'react-relay';

export const gqlPutGrailedListing = graphql`
  mutation putGrailedListingMutation(
    $listingId: String!
    $buyNow: GrailedBooleanPutObject
    $categoryPath: GrailedStringPutObject
    $condition: GrailedStringPutObject
    $description: GrailedStringPutObject
    $designerIds: GrailedArray_IntRequired_RequiredPutObject
    $hashtags: GrailedArray_StringRequired_RequiredPutObject
    $makeOffer: GrailedBooleanPutObject
    $measurements: GrailedStringRequiredPutObject
    $minimumPrice: GrailedIntPutObject
    $useSmartPricing: GrailedBooleanRequiredPutObject
    $photoIds: GrailedArray_StringRequired_RequiredPutObject
    $price: GrailedIntPutObject
    $returnAddressId: GrailedIntPutObject
    $usShippingPrice: GrailedIntPutObject
    $caShippingPrice: GrailedIntPutObject
    $title: GrailedStringPutObject
    $color: GrailedStringPutObject
    $externalId: GrailedStringPutObject
    $shippingType: GrailedIntPutObject
    $shippingSignatureConfirmation: GrailedBooleanRequiredPutObject
  ) {
    grailedPutGrailedListing(
      listingId: $listingId
      buyNow: $buyNow
      categoryPath: $categoryPath
      condition: $condition
      description: $description
      designerIds: $designerIds
      hashtags: $hashtags
      makeoffer: $makeOffer
      measurements: $measurements
      minimumPrice: $minimumPrice
      useSmartPricing: $useSmartPricing
      photoIds: $photoIds
      price: $price
      returnAddressId: $returnAddressId
      usShippingPrice: $usShippingPrice
      caShippingPrice: $caShippingPrice
      title: $title
      color: $color
      externalId: $externalId
      shippingType: $shippingType
      shippingSignatureConfirmation: $shippingSignatureConfirmation
    ) {
      success
    }
  }
`;
