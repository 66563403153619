/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type invsysStripePaymentMethodByCustomerDefaultSourceQueryVariables = {};
export type invsysStripePaymentMethodByCustomerDefaultSourceQueryResponse = {
  readonly invsysStripePaymentMethodByCustomerDefaultSource: {
    readonly stripeId: string;
    readonly createdAt: number;
    readonly cardExpMonth: number;
    readonly cardExpYear: number;
    readonly cardLast4: string;
  } | null;
};
export type invsysStripePaymentMethodByCustomerDefaultSourceQuery = {
  readonly response: invsysStripePaymentMethodByCustomerDefaultSourceQueryResponse;
  readonly variables: invsysStripePaymentMethodByCustomerDefaultSourceQueryVariables;
};

/*
query invsysStripePaymentMethodByCustomerDefaultSourceQuery {
  invsysStripePaymentMethodByCustomerDefaultSource {
    stripeId
    createdAt
    cardExpMonth
    cardExpYear
    cardLast4
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = [
    {
      alias: null,
      args: null,
      concreteType: 'InvsysStripePaymentMethod',
      kind: 'LinkedField',
      name: 'invsysStripePaymentMethodByCustomerDefaultSource',
      plural: false,
      selections: [
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'stripeId',
          storageKey: null,
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'createdAt',
          storageKey: null,
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'cardExpMonth',
          storageKey: null,
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'cardExpYear',
          storageKey: null,
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'cardLast4',
          storageKey: null,
        },
      ],
      storageKey: null,
    },
  ];
  return {
    fragment: {
      argumentDefinitions: [],
      kind: 'Fragment',
      metadata: null,
      name: 'invsysStripePaymentMethodByCustomerDefaultSourceQuery',
      selections: v0 /*: any*/,
      type: 'Query',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [],
      kind: 'Operation',
      name: 'invsysStripePaymentMethodByCustomerDefaultSourceQuery',
      selections: v0 /*: any*/,
    },
    params: {
      cacheID: '97c73d8d805f10d5f4a961184ad5b738',
      id: null,
      metadata: {},
      name: 'invsysStripePaymentMethodByCustomerDefaultSourceQuery',
      operationKind: 'query',
      text: 'query invsysStripePaymentMethodByCustomerDefaultSourceQuery {\n  invsysStripePaymentMethodByCustomerDefaultSource {\n    stripeId\n    createdAt\n    cardExpMonth\n    cardExpYear\n    cardLast4\n  }\n}\n',
    },
  };
})();
(node as any).hash = '69ea7d4cc9d334b545f52a7f8552fdd5';
export default node;
