/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type notificationReceiptsQueryVariables = {
  cursor?: string | null;
};
export type notificationReceiptsQueryResponse = {
  readonly invsysNotificationReceipts: {
    readonly cursor: string | null;
    readonly items: ReadonlyArray<{
      readonly notificationId: string;
      readonly templateId: number;
      readonly body: string | null;
      readonly title: string | null;
      readonly archived: boolean;
      readonly sentAt: number | null;
      readonly createdAt: number;
    }>;
  } | null;
};
export type notificationReceiptsQuery = {
  readonly response: notificationReceiptsQueryResponse;
  readonly variables: notificationReceiptsQueryVariables;
};

/*
query notificationReceiptsQuery(
  $cursor: String
) {
  invsysNotificationReceipts(cursor: $cursor) {
    cursor
    items {
      notificationId
      templateId
      body
      title
      archived
      sentAt
      createdAt
    }
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'cursor',
      },
    ],
    v1 = [
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'cursor',
            variableName: 'cursor',
          },
        ],
        concreteType: 'InvsysNotificationReceiptPage',
        kind: 'LinkedField',
        name: 'invsysNotificationReceipts',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'cursor',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            concreteType: 'InvsysNotificationReceipt',
            kind: 'LinkedField',
            name: 'items',
            plural: true,
            selections: [
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'notificationId',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'templateId',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'body',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'title',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'archived',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'sentAt',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'createdAt',
                storageKey: null,
              },
            ],
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'notificationReceiptsQuery',
      selections: v1 /*: any*/,
      type: 'Query',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'notificationReceiptsQuery',
      selections: v1 /*: any*/,
    },
    params: {
      cacheID: '61783bf45058007760d9f4251fa5ecd0',
      id: null,
      metadata: {},
      name: 'notificationReceiptsQuery',
      operationKind: 'query',
      text: 'query notificationReceiptsQuery(\n  $cursor: String\n) {\n  invsysNotificationReceipts(cursor: $cursor) {\n    cursor\n    items {\n      notificationId\n      templateId\n      body\n      title\n      archived\n      sentAt\n      createdAt\n    }\n  }\n}\n',
    },
  };
})();
(node as any).hash = '95a55b28e9f94100871731aac2f48116';
export default node;
