/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type importInstitutionListingsV2MutationVariables = {
  institution: number;
  feedType: number;
  autoApply: boolean;
};
export type importInstitutionListingsV2MutationResponse = {
  readonly invsysImportInstitutionListingsV2: {
    readonly success: boolean;
  };
};
export type importInstitutionListingsV2Mutation = {
  readonly response: importInstitutionListingsV2MutationResponse;
  readonly variables: importInstitutionListingsV2MutationVariables;
};

/*
mutation importInstitutionListingsV2Mutation(
  $institution: Int!
  $feedType: Int!
  $autoApply: Boolean!
) {
  invsysImportInstitutionListingsV2(institution: $institution, feedType: $feedType, autoApply: $autoApply) {
    success
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'autoApply',
    },
    v1 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'feedType',
    },
    v2 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'institution',
    },
    v3 = [
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'autoApply',
            variableName: 'autoApply',
          },
          {
            kind: 'Variable',
            name: 'feedType',
            variableName: 'feedType',
          },
          {
            kind: 'Variable',
            name: 'institution',
            variableName: 'institution',
          },
        ],
        concreteType: 'InvsysImportInstitutionListingsV2Response',
        kind: 'LinkedField',
        name: 'invsysImportInstitutionListingsV2',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'success',
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: [v0 /*: any*/, v1 /*: any*/, v2 /*: any*/],
      kind: 'Fragment',
      metadata: null,
      name: 'importInstitutionListingsV2Mutation',
      selections: v3 /*: any*/,
      type: 'Mutation',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [v2 /*: any*/, v1 /*: any*/, v0 /*: any*/],
      kind: 'Operation',
      name: 'importInstitutionListingsV2Mutation',
      selections: v3 /*: any*/,
    },
    params: {
      cacheID: '563095b734fc137ddaba9e20ecbb2339',
      id: null,
      metadata: {},
      name: 'importInstitutionListingsV2Mutation',
      operationKind: 'mutation',
      text: 'mutation importInstitutionListingsV2Mutation(\n  $institution: Int!\n  $feedType: Int!\n  $autoApply: Boolean!\n) {\n  invsysImportInstitutionListingsV2(institution: $institution, feedType: $feedType, autoApply: $autoApply) {\n    success\n  }\n}\n',
    },
  };
})();
(node as any).hash = '2b8747fc44d962cb3ac46b8a31277887';
export default node;
