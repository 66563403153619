import { graphql } from 'react-relay';

export const gqlScheduleRelist = graphql`
  mutation scheduleRelistMutation($listingId: String!, $institutions: [Int!]!, $at: Float) {
    invsysScheduleRelist(listingId: $listingId, institutions: $institutions, at: $at) {
      success
      data {
        success
        institution
      }
    }
  }
`;
