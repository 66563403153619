/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type addToStudioListingMediaGroupMutationVariables = {
  mediaIds: Array<string>;
  listingMediaGroupId: string;
};
export type addToStudioListingMediaGroupMutationResponse = {
  readonly studioAddToListingMediaGroup: {
    readonly success: boolean;
  };
};
export type addToStudioListingMediaGroupMutation = {
  readonly response: addToStudioListingMediaGroupMutationResponse;
  readonly variables: addToStudioListingMediaGroupMutationVariables;
};

/*
mutation addToStudioListingMediaGroupMutation(
  $mediaIds: [String!]!
  $listingMediaGroupId: String!
) {
  studioAddToListingMediaGroup(listingMediaGroupId: $listingMediaGroupId, mediaIds: $mediaIds) {
    success
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'listingMediaGroupId',
    },
    v1 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'mediaIds',
    },
    v2 = [
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'listingMediaGroupId',
            variableName: 'listingMediaGroupId',
          },
          {
            kind: 'Variable',
            name: 'mediaIds',
            variableName: 'mediaIds',
          },
        ],
        concreteType: 'StudioAddToListingMediaGroupResponse',
        kind: 'LinkedField',
        name: 'studioAddToListingMediaGroup',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'success',
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: [v0 /*: any*/, v1 /*: any*/],
      kind: 'Fragment',
      metadata: null,
      name: 'addToStudioListingMediaGroupMutation',
      selections: v2 /*: any*/,
      type: 'Mutation',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [v1 /*: any*/, v0 /*: any*/],
      kind: 'Operation',
      name: 'addToStudioListingMediaGroupMutation',
      selections: v2 /*: any*/,
    },
    params: {
      cacheID: 'e3166cfa65a45b2bc1bb055d788d8fc0',
      id: null,
      metadata: {},
      name: 'addToStudioListingMediaGroupMutation',
      operationKind: 'mutation',
      text: 'mutation addToStudioListingMediaGroupMutation(\n  $mediaIds: [String!]!\n  $listingMediaGroupId: String!\n) {\n  studioAddToListingMediaGroup(listingMediaGroupId: $listingMediaGroupId, mediaIds: $mediaIds) {\n    success\n  }\n}\n',
    },
  };
})();
(node as any).hash = '14a990562a48428750a7a82b0d73d4d1';
export default node;
