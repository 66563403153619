/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type checkSocialUsernameQueryVariables = {
  username: string;
};
export type checkSocialUsernameQueryResponse = {
  readonly invsysCheckSocialUsername: {
    readonly valid: boolean;
    readonly errorMessage: string | null;
  };
};
export type checkSocialUsernameQuery = {
  readonly response: checkSocialUsernameQueryResponse;
  readonly variables: checkSocialUsernameQueryVariables;
};

/*
query checkSocialUsernameQuery(
  $username: String!
) {
  invsysCheckSocialUsername(username: $username) {
    valid
    errorMessage
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'username',
      },
    ],
    v1 = [
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'username',
            variableName: 'username',
          },
        ],
        concreteType: 'InvsysCheckSocialUsernameResponse',
        kind: 'LinkedField',
        name: 'invsysCheckSocialUsername',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'valid',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'errorMessage',
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'checkSocialUsernameQuery',
      selections: v1 /*: any*/,
      type: 'Query',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'checkSocialUsernameQuery',
      selections: v1 /*: any*/,
    },
    params: {
      cacheID: 'dc9777a54a1275499980334dbb345fb1',
      id: null,
      metadata: {},
      name: 'checkSocialUsernameQuery',
      operationKind: 'query',
      text: 'query checkSocialUsernameQuery(\n  $username: String!\n) {\n  invsysCheckSocialUsername(username: $username) {\n    valid\n    errorMessage\n  }\n}\n',
    },
  };
})();
(node as any).hash = '3005a4f12b34007bcda4b7b6d8ab99d8';
export default node;
