import * as Lib from './lib';
import * as State from 'src/state';
import { gqlAddListingsToClosetSection } from './queries/addListingsToClosetSection';
import { gqlClosetSectionListings } from './queries/closetSectionListings';
import { gqlCreateClosetSection } from './queries/createClosetSection';
import { gqlDeleteClosetSection } from './queries/deleteClosetSection';
import { gqlRecentActivity } from './queries/recentActivity';
import { gqlRegisterPushNotificationToken } from './queries/registerPushNotificationToken';
import { gqlRemoveListingsFromClosetSection } from './queries/removeListingsFromClosetSection';
import { gqlRewriteClosetSectionListingOrdering } from './queries/rewriteClosetSectionListingOrdering';
import { gqlRewriteClosetSectionOrdering } from './queries/rewriteClosetSectionOrdering';
import { gqlRewriteUncategorizedListingOrdering } from './queries/rewriteUncategorizedListingOrdering';
import { gqlStatBuckets } from './queries/statBuckets';
import { gqlToggleFeatureStatuses } from './queries/toggleFeatureStatuses';
import { gqlTriggerPoshmarkAuth } from './queries/triggerPoshmarkAuth';
import { gqlUpdateClosetSection } from './queries/updateClosetSection';
import { gqlUpdateOfferToLikersPercentOff } from './queries/updateOfferToLikersPercentOff';
import { addListingsToClosetSectionMutation } from './build/addListingsToClosetSectionMutation.graphql';
import { createClosetSectionMutation } from './build/createClosetSectionMutation.graphql';
import { closetSectionListingsQuery } from './build/closetSectionListingsQuery.graphql';
import { deleteClosetSectionMutation } from './build/deleteClosetSectionMutation.graphql';
import { removeListingsFromClosetSectionMutation } from './build/removeListingsFromClosetSectionMutation.graphql';
import { recentActivityQuery } from './build/recentActivityQuery.graphql';
import { statBucketsQuery } from './build/statBucketsQuery.graphql';
import { toggleFeatureStatusesMutation } from './build/toggleFeatureStatusesMutation.graphql';
import { registerPushNotificationTokenMutation } from './build/registerPushNotificationTokenMutation.graphql';
import { rewriteClosetSectionListingOrderingMutation } from './build/rewriteClosetSectionListingOrderingMutation.graphql';
import { rewriteClosetSectionOrderingMutation } from './build/rewriteClosetSectionOrderingMutation.graphql';
import { rewriteUncategorizedListingOrderingMutation } from './build/rewriteUncategorizedListingOrderingMutation.graphql';
import { triggerPoshmarkAuthMutation } from './build/triggerPoshmarkAuthMutation.graphql';
import { updateClosetSectionMutation } from './build/updateClosetSectionMutation.graphql';
import { updateOfferToLikersPercentOffMutation } from './build/updateOfferToLikersPercentOffMutation.graphql';

import * as Build from './build';
import * as Queries from './queries';
import { gqlReferralProgramDetails, gqlRequestScrapeListingsAdhoc, gqlStartBillingPortalSession } from './queries';
export * as build from './build';
export * as queries from './build';

import * as FragmentReader from './FragmentReader';
export * as Location from './api/location';
export * as Listing from './api/listing';
export * as ShippingLabels from './api/shippingLabels';
export * as Pickup from './api/pickup';
export * as Bot from './api/bot';

import * as Studio from './api/studio';
import * as ListingSearchClient from 'src/clients/ListingSearch';

export const textMobileLinks = Lib.promisifyQuery<Build.textMobileLinksQuery.textMobileLinksQuery>(
  Queries.gqlTextMobileLinks
);

export const saveAccessPin = Lib.promisifyMutation<Build.saveAccessPinMutation.saveAccessPinMutation>(
  Queries.gqlSaveAccessPin
);
export const businessManager = Lib.promisifyQuery<Build.invsysBusinessManagerQuery.invsysBusinessManagerQuery>(
  Queries.gqlInvsysBusinessManager,
  [
    (args, p) => {
      p.then((_) => {
        const businessManager = _.invsysBusinessManager;
        State.Observe.BusinessManager.BusinessManagerValue.set(businessManager);
      });
    },
  ]
);
export const getAppCriticalMetadata = Lib.promisifyQuery<Build.appCriticalMetadataQuery.appCriticalMetadataQuery>(
  Queries.gqlAppCriticalMetadata,
  [
    (args, p) => {
      p.then((_) => {
        const businessManager = _.invsysBusinessManager;
        State.Observe.BusinessManager.BusinessManagerValue.set(businessManager);
        State.Observe.Business.Value.set(_.superposherBusiness);
        State.Observe.InstitutionLinks.InstitutionLinksValue.set(Array.from(_.invsysInstitutionLinks));
        State.Observe.InstitutionLinks.InstitutionLinkRequestsValue.set(Array.from(_.invsysInstitutionLinkRequests));
        State.Observe.App.NotificationUnarchivedCountValue.set(_.invsysNotificationReceiptUnarchivedCount);
        State.Observe.ShippingLabels.ShippingLabelReelValue.set(_.invsysShippingLabelReel);
        State.Observe.NuxSurvey.Value.set(_.invsysNuxSurvey);
      });
    },
  ]
);

export const __deprecated__getPoshmarkUserLinks =
  Lib.promisifyQuery<Build.poshmarkUserLinksQuery.poshmarkUserLinksQuery>(Queries.gqlPoshmarkUserLinks, [
    (args, p) => {
      State.Observe.PoshmarkUserLinks.AsyncStateValue.subscribe(p);
      p.then((r) => {
        State.Observe.PoshmarkUserLinks.Value.set(r.superposherPoshmarkUserLinks ?? []);
      });
      State.Observe.StatBuckets.AsyncValue.resolve(p.then((r) => r.superposherPoshmarkUserLinks ?? []));
    },
  ]);

export const getSalesBalanceTimeseries =
  Lib.promisifyQuery<Build.salesBalanceTimeseriesQuery.salesBalanceTimeseriesQuery>(Queries.gqlSalesBalanceTimeseries, [
    (args, p) => {
      State.Observe.SalesStats.SalesBalanceTimeseriesAsyncValue.resolve(p.then((r) => r.invsysSalesBalanceTimeseries));
    },
  ]);
export const getClosetSectionListings = Lib.promisifyQuery<closetSectionListingsQuery>(gqlClosetSectionListings, [
  (args, p) => {
    State.Observe.ClosetSectionListings.AsyncValue.resolve(
      p.then((r) => {
        const closetSection = r.superposherPoshmarkUserLink?.closetSection;
        if (closetSection != null) {
          return {
            [closetSection.id]: {
              data: closetSection.listings,
              updatedAt: new Date(),
            },
          };
        } else {
          return {};
        }
      })
    );
  },
]);
export const getInventoryMetadata = Lib.promisifyQuery<Build.inventoryMetadataQuery.inventoryMetadataQuery>(
  Queries.gqlInventoryMetadata,
  [
    (args, p) => {
      State.Observe.InventoryMetadata.AsyncValue.resolve(p.then((_) => _.superposherPoshmarkUserLinks));
    },
  ]
);
export const getRecentActivity = Lib.promisifyQuery<recentActivityQuery>(gqlRecentActivity, [
  (args, p) => {
    State.Observe.RecentActivities.AsyncValue.resolve(
      p.then((r) => {
        const recentActivity = r.superposherPoshmarkUserLink?.recentActivity;
        if (recentActivity != null) {
          return {
            [args.poshmarkUserLinkId]: {
              data: recentActivity,
              updatedAt: new Date(),
            },
          };
        } else {
          return {};
        }
      })
    );
  },
]);
export const getTopRanking = Lib.promisifyQuery<Build.topRankingQuery.topRankingQuery>(Queries.gqlTopRanking, [
  (args, p) => {
    p.then((_) => {
      State.Observe.TopRanking.Value.set(_.invsysTopRanking);
    });
  },
]);
export const getPersonalRanking = Lib.promisifyQuery<Build.personalRankingQuery.personalRankingQuery>(
  Queries.gqlPersonalRanking,
  [
    (args, p) => {
      p.then((_) => {
        State.Observe.TopRanking.PersonalRankingValue.set(_.invsysPersonalRanking);
      });
    },
  ]
);

export const getOrders = Lib.promisifyQuery<Build.ordersQuery.ordersQuery>(Queries.gqlOrders, [
  (args, p) => {
    p.then((_) => {
      const items = _.invsysOrders?.items ?? [];
      const orders = FragmentReader.map(items, FragmentReader.orderFull);
      State.Observe.Orders.OrdersValue.transform((current) => {
        return {
          ...current,
          ...mkEntityMap<State.Types.OrderType>(orders, (_) => _.id),
        };
      });
    });
  },
]);

export const getActionableOrders = Lib.promisifyQuery<Build.actionableOrdersQuery.actionableOrdersQuery>(
  Queries.gqlActionableOrders,
  [
    (args, p) => {
      p.then((_) => {
        const items = _.invsysActionableOrders?.items ?? [];
        const orders = FragmentReader.map(items, FragmentReader.orderFull);
        State.Observe.Orders.OrdersValue.transform((current) => {
          return {
            ...current,
            ...mkEntityMap<State.Types.OrderType>(orders, (_) => _.id),
          };
        });
      });
    },
  ]
);

export const getListing = Lib.promisifyQuery<Build.listingQuery.listingQuery>(Queries.gqlListing, [
  (args, p) => {
    p.then((_) => {
      const listing = FragmentReader.listingFull(_.invsysListing);
      if (listing != null) {
        State.Observe.Listings.Value.transform((current) => {
          return {
            ...current,
            [listing.id]: listing,
          };
        });
        const listingMediaGroup = Studio.FragmentReader.studioListingMediaGroupFull(
          _.studioListingMediaGroupByListingId
        );
        if (listingMediaGroup != null) {
          Studio.StoreHelpers.storeStudioListingMediaGroups([listingMediaGroup]);
        }

        syncSearchForListing(listing.id);
      } else {
        return {};
      }
    });
  },
]);

export const getListingValidationResult =
  Lib.promisifyQuery<Build.listingValidationResultQuery.listingValidationResultQuery>(
    Queries.gqlListingValidationResult,
    [
      (args, p) => {
        p.then((res) => {
          State.Observe.Listings.ListingValidationResult.set(res.invsysListingValidationResult);
          syncSearchForListing(args.id);
        });
      },
    ]
  );

const storeListingRecords = (listings: State.Types.ListingRecordsType[]) => {
  State.Observe.Listings.ListingRecordsValue.transform((current) => {
    return {
      ...current,
      ...mkEntityMap<State.Types.ListingRecordsType>(listings, (_) => _.objectID),
    };
  });
};

export const getListingRecords = Lib.promisifyQuery<Build.listingRecordsQuery.listingRecordsQuery>(
  Queries.gqlListingRecords,
  [
    (args, p) => {
      p.then((r) => {
        storeListingRecords([...(r.invsysListingRecords?.items ?? [])]);
      });
    },
  ]
);

export const getPoshmarkListing = Lib.promisifyQuery<Build.poshmarkListingQuery.poshmarkListingQuery>(
  Queries.gqlPoshmarkListing,
  [
    (args, p) => {
      p.then((_) => {
        State.Observe.Listings.SelectedPoshmarkListingValue.set(_.poshmarkPoshmarkListing);
      });
    },
  ]
);
export const getMercariListing = Lib.promisifyQuery<Build.mercariListingQuery.mercariListingQuery>(
  Queries.gqlMercariListing,
  [
    (args, p) => {
      p.then((_) => {
        State.Observe.Listings.SelectedMercariListingValue.set(_.mercariMercariListing);
      });
    },
  ]
);
export const getTradesyListing = Lib.promisifyQuery<Build.tradesyListingQuery.tradesyListingQuery>(
  Queries.gqlTradesyListing,
  [
    (args, p) => {
      p.then((_) => {
        State.Observe.Listings.SelectedTradesyListingValue.set(_.tradesyTradesyListing);
      });
    },
  ]
);
export const getDepopListing = Lib.promisifyQuery<Build.depopListingQuery.depopListingQuery>(Queries.gqlDepopListing, [
  (args, p) => {
    p.then((_) => {
      State.Observe.Listings.SelectedDepopListingValue.set(_.depopDepopListing);
    });
  },
]);
export const getEbayListing = Lib.promisifyQuery<Build.ebayListingQuery.ebayListingQuery>(Queries.gqlEbayListing, [
  (args, p) => {
    p.then((_) => {
      State.Observe.Listings.SelectedEbayListingValue.set(_.ebayEbayListing);
    });
  },
]);
export const getGrailedListing = Lib.promisifyQuery<Build.grailedListingQuery.grailedListingQuery>(
  Queries.gqlGrailedListing,
  [
    (args, p) => {
      p.then((_) => {
        State.Observe.Listings.SelectedGrailedListingValue.set(_.grailedGrailedListing);
      });
    },
  ]
);

export const scheduleDelistAll = Lib.promisifyMutation<Build.scheduleDelistAllMutation.scheduleDelistAllMutation>(
  Queries.gqlScheduleDelistAll
);
export const scheduleRelist = Lib.promisifyMutation<Build.scheduleRelistMutation.scheduleRelistMutation>(
  Queries.gqlScheduleRelist
);

export const getReferralProgramDetails =
  Lib.promisifyQuery<Build.referralProgramDetailsQuery.referralProgramDetailsQuery>(gqlReferralProgramDetails, [
    (args, p) => {
      State.Observe.ReferralProgramDetails.AsyncValue.resolve(p.then((r) => r.superposherReferralProgramDetails));
    },
  ]);
export const getStatBuckets = Lib.promisifyQuery<statBucketsQuery>(gqlStatBuckets, [
  (args, p) => {
    State.Observe.StatBuckets.AsyncValue.resolve(p.then((r) => r.superposherPoshmarkUserLinks ?? []));
  },
]);

export const registerPushNotificationToken = Lib.promisifyMutation<registerPushNotificationTokenMutation>(
  gqlRegisterPushNotificationToken
);
export const createClosetSection = Lib.promisifyMutation<createClosetSectionMutation>(gqlCreateClosetSection);
export const deleteClosetSection = Lib.promisifyMutation<deleteClosetSectionMutation>(gqlDeleteClosetSection);
export const updateClosetSection = Lib.promisifyMutation<updateClosetSectionMutation>(gqlUpdateClosetSection);
export const addListingsToClosetSection =
  Lib.promisifyMutation<addListingsToClosetSectionMutation>(gqlAddListingsToClosetSection);
export const removeAddOn = Lib.promisifyMutation<Build.removeAddOnMutation.removeAddOnMutation>(Queries.gqlRemoveAddOn);
export const removeListingsFromClosetSection = Lib.promisifyMutation<removeListingsFromClosetSectionMutation>(
  gqlRemoveListingsFromClosetSection
);
export const requestScrapeListingsAdhoc =
  Lib.promisifyMutation<Build.requestScrapeListingsAdhocMutation.requestScrapeListingsAdhocMutation>(
    gqlRequestScrapeListingsAdhoc
  );
export const requestScrapeUserAdhoc =
  Lib.promisifyMutation<Build.requestScrapeUserAdhocMutation.requestScrapeUserAdhocMutation>(
    Queries.gqlRequestScrapeUserAdhoc
  );
export const rewriteClosetSectionListingOrdering = Lib.promisifyMutation<rewriteClosetSectionListingOrderingMutation>(
  gqlRewriteClosetSectionListingOrdering
);
export const rewriteClosetSectionOrdering = Lib.promisifyMutation<rewriteClosetSectionOrderingMutation>(
  gqlRewriteClosetSectionOrdering
);
export const rewriteUncategorizedListingOrdering = Lib.promisifyMutation<rewriteUncategorizedListingOrderingMutation>(
  gqlRewriteUncategorizedListingOrdering
);

export const startInstitutionLink =
  Lib.promisifyMutation<Build.startInstitutionLinkMutation.startInstitutionLinkMutation>(
    Queries.gqlStartInstitutionLink
  );
export const startBillingPortalSession =
  Lib.promisifyMutation<Build.startBillingPortalSessionMutation.startBillingPortalSessionMutation>(
    gqlStartBillingPortalSession
  );
export const startSubscription = Lib.promisifyMutation<Build.startSubscriptionMutation.startSubscriptionMutation>(
  Queries.gqlStartSubscription
);
export const startSubscriptionCheckout =
  Lib.promisifyMutation<Build.startSubscriptionCheckoutMutation.startSubscriptionCheckoutMutation>(
    Queries.gqlStartSubscriptionCheckout
  );
export const toggleFeatureStatuses = Lib.promisifyMutation<toggleFeatureStatusesMutation>(gqlToggleFeatureStatuses);
export const trackAppOpen = Lib.promisifyMutation<Build.trackAppOpenMutation.trackAppOpenMutation>(
  Queries.gqlTrackAppOpen
);
export const triggerPoshmarkAuth = Lib.promisifyMutation<triggerPoshmarkAuthMutation>(gqlTriggerPoshmarkAuth);
export const updateAddOn = Lib.promisifyMutation<Build.updateAddOnMutation.updateAddOnMutation>(Queries.gqlUpdateAddOn);
export const updateOfferToLikersMinRevenueTarget =
  Lib.promisifyMutation<Build.updateOfferToLikersMinRevenueTargetMutation.updateOfferToLikersMinRevenueTargetMutation>(
    Queries.gqlUpdateOfferToLikersMinRevenueTarget
  );
export const updateOfferToLikersPercentOff = Lib.promisifyMutation<updateOfferToLikersPercentOffMutation>(
  gqlUpdateOfferToLikersPercentOff
);
export const updateOfferToLikersShippingDiscountType =
  Lib.promisifyMutation<Build.updateOfferToLikersShippingDiscountTypeMutation.updateOfferToLikersShippingDiscountTypeMutation>(
    Queries.gqlUpdateOfferToLikersShippingDiscountType
  );
export const updateRelistInterval =
  Lib.promisifyMutation<Build.updateRelistIntervalMutation.updateRelistIntervalMutation>(
    Queries.gqlUpdateRelistIntervalMutation
  );

export const createAwsPresignedUrl =
  Lib.promisifyMutation<Build.createAwsPresignedUrlMutation.createAwsPresignedUrlMutation>(
    Queries.gqlCreateAwsPresignedUrl
  );
export const startAwsMultipartUpload =
  Lib.promisifyMutation<Build.startAwsMultipartUploadMutation.startAwsMultipartUploadMutation>(
    Queries.gqlStartAwsMultipartUpload
  );
export const abortAwsMultipartUpload =
  Lib.promisifyMutation<Build.abortAwsMultipartUploadMutation.abortAwsMultipartUploadMutation>(
    Queries.gqlAbortAwsMultipartUpload
  );
export const finishAwsMultipartUpload =
  Lib.promisifyMutation<Build.finishAwsMultipartUploadMutation.finishAwsMultipartUploadMutation>(
    Queries.gqlFinishAwsMultipartUpload
  );

export const importInstitutionListings =
  Lib.promisifyMutation<Build.importInstitutionListingsMutation.importInstitutionListingsMutation>(
    Queries.gqlImportInstitutionListings
  );

export const importInstitutionListingsV2 =
  Lib.promisifyMutation<Build.importInstitutionListingsV2Mutation.importInstitutionListingsV2Mutation>(
    Queries.gqlImportInstitutionListingsV2
  );

export const manuallyImportInstitutionListings =
  Lib.promisifyMutation<Build.manuallyImportInstitutionListingsMutation.manuallyImportInstitutionListingsMutation>(
    Queries.gqlManuallyImportInstitutionListing
  );

export const invsysImportInstitutionListings =
  Lib.promisifyQuery<Build.invsysImportInstitutionListingsPageQuery.invsysImportInstitutionListingsPageQuery>(
    Queries.gqlImportInstitutionListingsPage,
    [
      (args, p) => {
        p.then((_) => {
          State.Observe.Listings.ImportInstitutionListingsValue.transform((current) => {
            return {
              ...current,
              ...mkEntityMap(_.invsysImportInstitutionListingsPage?.items ?? [], (_) => _.listingId ?? ''),
            };
          });
        });
      },
    ]
  );

export const checkInstitutionImportStatus =
  Lib.promisifyMutation<Build.checkInstitutionImportStatusMutation.checkInstitutionImportStatusMutation>(
    Queries.gqlCheckInstitutionImportStatus,
    [
      (args, p) => {
        p.then((r) =>
          State.Observe.Listings.InstitutionImportStatus.transform((v) => ({
            ...v,
            [args.institution]: r.invsysCheckInstitutionImportStatus.status,
          }))
        );
      },
    ]
  );

export const dismissInstitutionImportStatus =
  Lib.promisifyMutation<Build.dismissInstitutionImportStatusMutation.dismissInstitutionImportStatusMutation>(
    Queries.gqlDismissInstitutionImportStatus,
    [
      (args, p) => {
        p.then((r) =>
          State.Observe.Listings.InstitutionImportStatus.transform((v) => ({
            ...v,
            [args.institution]: 'NOT_IMPORTING',
          }))
        );
      },
    ]
  );

const syncSearchForListing = async (listingId: string): Promise<void> => {
  const searchResults = await ListingSearchClient.searchOne({
    query: {
      filter: [
        {
          field: ListingSearchClient.FilterConditionFieldType.Id,
          rawValue: listingId,
        },
      ],
    },
  });
  State.Observe.SearchClients.ListingRecordV2Value.transform((current) => {
    const next = {
      ...current,
    };
    searchResults.items?.forEach((item) => {
      if (item.id != null) {
        next[item.id] = item;
      }
    });
    return next;
  });
};

export const putItem = Lib.promisifyMutation<Build.putItemMutation.putItemMutation>(Queries.gqlPutItem);
export const putListing = Lib.promisifyMutation<Build.putListingMutation.putListingMutation>(Queries.gqlPutListing, [
  (args, p) => {
    p.then((_) => {
      const listing = FragmentReader.listingFull(_.invsysPutListing.data);
      if (listing != null) {
        State.Observe.Listings.Value.transform((current) => {
          return {
            ...current,
            [listing.id]: listing,
          };
        });

        syncSearchForListing(listing.id);
      } else {
        return {};
      }
    });
  },
]);
export const duplicateListing = Lib.promisifyMutation<Build.duplicateListingMutation.duplicateListingMutation>(
  Queries.gqlDuplicateListing
);
export const editPoshmarkItem = Lib.promisifyMutation<Build.editPoshmarkItemMutation.editPoshmarkItemMutation>(
  Queries.gqlEditPoshmarkItem
);
export const editMercariItem = Lib.promisifyMutation<Build.editMercariItemMutation.editMercariItemMutation>(
  Queries.gqlEditMercariItem
);
export const editTradesyItem = Lib.promisifyMutation<Build.editTradesyItemMutation.editTradesyItemMutation>(
  Queries.gqlEditTradesyItem
);
export const editDepopItem = Lib.promisifyMutation<Build.editDepopItemMutation.editDepopItemMutation>(
  Queries.gqlEditDepopItem
);
export const editEbayItem = Lib.promisifyMutation<Build.editEbayItemMutation.editEbayItemMutation>(
  Queries.gqlEditEbayItem
);
export const editGrailedItem = Lib.promisifyMutation<Build.editGrailedItemMutation.editGrailedItemMutation>(
  Queries.gqlEditGrailedItem
);
export const putPoshmarkListing = Lib.promisifyMutation<Build.putPoshmarkListingMutation.putPoshmarkListingMutation>(
  Queries.gqlPutPoshmarkListing
);
export const putMercariListing = Lib.promisifyMutation<Build.putMercariListingMutation.putMercariListingMutation>(
  Queries.gqlPutMercariListing
);
export const putTradesyListing = Lib.promisifyMutation<Build.putTradesyListingMutation.putTradesyListingMutation>(
  Queries.gqlPutTradesyListing
);
export const putDepopListing = Lib.promisifyMutation<Build.putDepopListingMutation.putDepopListingMutation>(
  Queries.gqlPutDepopListing
);
export const putDepopListingV2 = Lib.promisifyMutation<Build.putDepopListingV2Mutation.putDepopListingV2Mutation>(
  Queries.gqlPutDepopListingV2
);
export const putEbayListing = Lib.promisifyMutation<Build.putEbayListingMutation.putEbayListingMutation>(
  Queries.gqlPutEbayListing
);
export const putGrailedListing = Lib.promisifyMutation<Build.putGrailedListingMutation.putGrailedListingMutation>(
  Queries.gqlPutGrailedListing
);
export const putMfaToken = Lib.promisifyMutation<Build.putMfaTokenMutation.putMfaTokenMutation>(Queries.gqlPutMfaToken);
export const validateMarketplaceListings =
  Lib.promisifyQuery<Build.marketplaceListingsValidationsQuery.marketplaceListingsValidationsQuery>(
    Queries.gqlMarketplaceListingsValidations,
    [
      (args, p) => {
        p.then((_) => {
          State.Observe.Listings.SelectedPoshmarkValidateListingValue.set(
            _.invsysMarketplaceListingsValidations?.poshmarkListingValidation ?? null
          );
          State.Observe.Listings.SelectedMercariValidateListingValue.set(
            _.invsysMarketplaceListingsValidations?.mercariListingValidation ?? null
          );
          State.Observe.Listings.SelectedDepopValidateListingValue.set(
            _.invsysMarketplaceListingsValidations?.depopListingValidation ?? null
          );
          State.Observe.Listings.SelectedTradesyValidateListingValue.set(
            _.invsysMarketplaceListingsValidations?.tradesyListingValidation ?? null
          );
          State.Observe.Listings.SelectedGrailedValidateListingValue.set(
            _.invsysMarketplaceListingsValidations?.grailedListingValidation ?? null
          );
          State.Observe.Listings.SelectedEbayValidateListingValue.set(
            _.invsysMarketplaceListingsValidations?.ebayListingValidation ?? null
          );
        });
      },
    ]
  );
export const validatePoshmarkListing =
  Lib.promisifyQuery<Build.validatePoshmarkListingQuery.validatePoshmarkListingQuery>(
    Queries.gqlValidatePoshmarkListing,
    [
      (args, p) => {
        p.then((_) => {
          State.Observe.Listings.SelectedPoshmarkValidateListingValue.set(_.poshmarkValidatePoshmarkListing);
        });
      },
    ]
  );
export const validateMercariListing = Lib.promisifyQuery<Build.validateMercariListingQuery.validateMercariListingQuery>(
  Queries.gqlValidateMercariListing,
  [
    (args, p) => {
      p.then((_) => {
        State.Observe.Listings.SelectedMercariValidateListingValue.set(_.mercariValidateMercariListing);
      });
    },
  ]
);
export const validateTradesyListing = Lib.promisifyQuery<Build.validateTradesyListingQuery.validateTradesyListingQuery>(
  Queries.gqlValidateTradesyListing,
  [
    (args, p) => {
      p.then((_) => {
        State.Observe.Listings.SelectedTradesyValidateListingValue.set(_.tradesyValidateTradesyListing);
      });
    },
  ]
);

export const validateDepopListing = Lib.promisifyQuery<Build.validateDepopListingQuery.validateDepopListingQuery>(
  Queries.gqlValidateDepopListing,
  [
    (args, p) => {
      p.then((_) => {
        State.Observe.Listings.SelectedDepopValidateListingValue.set(_.depopValidateDepopListing);
      });
    },
  ]
);

export const validateEbayListing = Lib.promisifyQuery<Build.validateEbayListingQuery.validateEbayListingQuery>(
  Queries.gqlValidateEbayListing,
  [
    (args, p) => {
      p.then((_) => {
        State.Observe.Listings.SelectedEbayValidateListingValue.set(_.ebayValidateEbayListing);
      });
    },
  ]
);

export const validateGrailedListing = Lib.promisifyQuery<Build.validateGrailedListingQuery.validateGrailedListingQuery>(
  Queries.gqlValidateGrailedListing,
  [
    (args, p) => {
      p.then((_) => {
        State.Observe.Listings.SelectedGrailedValidateListingValue.set(_.grailedValidateGrailedListing);
      });
    },
  ]
);

export const scheduleList = Lib.promisifyMutation<Build.scheduleListMutation.scheduleListMutation>(
  Queries.gqlScheduleList,
  [
    (args, p) => {
      // TODO (albert): Handle errors
      p.then((_) => listingsSummary({}));
    },
  ]
);

export const cancelScheduledList = Lib.promisifyMutation<Build.cancelScheduledListMutation.cancelScheduledListMutation>(
  Queries.gqlCancelScheduledList,
  [
    (args, p) => {
      p.then((_) => scheduledListingsSummary({}));
    },
  ]
);

export const listingsSummary = Lib.promisifyQuery<Build.listingsSummaryQuery.listingsSummaryQuery>(
  Queries.gqlListingsSummary,
  [
    (args, p) => {
      p.then((_) => {
        State.Observe.Listings.ListingsSummaryValue.set(_.invsysListingsSummary);
      });
    },
  ]
);

export const scheduledListingsSummary =
  Lib.promisifyQuery<Build.scheduledListingsSummaryQuery.scheduledListingsSummaryQuery>(
    Queries.gqlScheduledListingsSummary,
    [
      (args, p) => {
        p.then((_) => {
          State.Observe.Listings.ScheduledListingsSummaryValue.set(_.invsysScheduledListingsSummary);
        });
      },
    ]
  );

export const poshmarkCatalog = Lib.promisifyQuery<Build.poshmarkCatalogQuery.poshmarkCatalogQuery>(
  Queries.gqlPoshmarkCatalog,
  [
    (args, p) => {
      State.Observe.PoshmarkCatalog.AsyncValue.resolve(
        p.then(async (r) => {
          const res = await fetch(r.superposherPoshmarkCatalog.sourceUrl);
          const json = await res.json();
          return json;
        })
      );
    },
  ]
);

// Note (albert): Testing this
interface EntityMapIface<E> {
  [key: string]: E;
}

const mkEntityMap = <E extends unknown>(
  entities: E[] | readonly E[],
  idSelector: (e: E) => string
): EntityMapIface<E> => {
  const entityMap: EntityMapIface<E> = {};
  for (let i = 0; i < entities.length; i++) {
    entityMap[idSelector(entities[i])] = entities[i];
  }
  return entityMap;
};

/**
 * Goals
 */

export const createSalesGoals = Lib.promisifyMutation<Build.createSalesGoalMutation.createSalesGoalMutation>(
  Queries.gqlCreateSalesGoal
);

export const deleteSalesGoal = Lib.promisifyMutation<Build.deleteSalesGoalMutation.deleteSalesGoalMutation>(
  Queries.gqlDeleteSalesGoal,
  [
    (args, p) => {
      State.Observe.SalesGoals.SalesGoalsAsyncValue.resolve(
        p.then((r) => {
          const entityId = r.invsysDeleteSalesGoal.id;
          return {
            [entityId]: null,
          };
        })
      );
    },
  ]
);

export const salesGoals = Lib.promisifyQuery<Build.salesGoalsQuery.salesGoalsQuery>(Queries.gqlSalesGoals, [
  (args, p) => {
    State.Observe.SalesGoals.SalesGoalsAsyncValue.resolve(
      p.then((r) => mkEntityMap<State.Types.SalesGoalType>(r.invsysSalesGoals, (_) => _.id))
    );
  },
]);

export const salesGoalHistory = Lib.promisifyQuery<Build.salesGoalHistoryQuery.salesGoalHistoryQuery>(
  Queries.gqlSalesGoalHistory,
  [
    (args, p) => {
      State.Observe.SalesGoals.SalesGoalHistoryAsyncValue.resolve(
        p.then((r) => mkEntityMap<State.Types.SalesGoalHistoryType>(r.invsysSalesGoalHistory, (_) => _.goalId))
      );
    },
  ]
);

export const checkListingImagePadding =
  Lib.promisifyMutation<Build.checkListingImagePaddingMutation.checkListingImagePaddingMutation>(
    Queries.gqlCheckListingImagePadding,
    [
      (args, p) => {
        State.Observe.ListingImagePadding.ListingImagePaddingAsyncValue.resolve(
          p.then((_) =>
            _.invsysCheckListingImagePadding.map((item) => {
              return {
                ...item,
                status: item.status as 'failed' | 'pending' | 'success',
              };
            })
          )
        );
      },
    ]
  );

export const scheduleListingImagePadding =
  Lib.promisifyMutation<Build.scheduleListingImagePaddingMutation.scheduleListingImagePaddingMutation>(
    Queries.gqlScheduleListingImagePadding,
    [
      (args, p) => {
        p.then((_) => checkListingImagePadding({ listingId: args.listingId }));
      },
    ]
  );

/*
 * Notifications
 */
export const notificationReceipts = Lib.promisifyQuery<Build.notificationReceiptsQuery.notificationReceiptsQuery>(
  Queries.gqlNotificationReceipts,
  [
    (args, p) => {
      p.then((r) => {
        State.Observe.Notification.NotificationReceiptsValue.transform((current) => ({
          ...current,
          ...mkEntityMap(r.invsysNotificationReceipts?.items ?? [], (_) => _.notificationId),
        }));
      });
    },
  ]
);

// TODO (brian): are there any states that need to be updated?
export const archiveNotifications =
  Lib.promisifyMutation<Build.archiveNotificationsMutation.archiveNotificationsMutation>(Queries.archiveNotifications);

/**
 * Registered identification numbers
 */

export const caRegisteredIdentificationNumberDetails =
  Lib.promisifyQuery<Build.caRegisteredIdentificationNumberDetailsQuery.caRegisteredIdentificationNumberDetailsQuery>(
    Queries.gqlCaRegisteredIdentificationNumberDetails
  );

export const usRegisteredIdentificationNumberDetails =
  Lib.promisifyQuery<Build.usRegisteredIdentificationNumberDetailsQuery.usRegisteredIdentificationNumberDetailsQuery>(
    Queries.gqlUsRegisteredIdentificationNumberDetails
  );

/**
 * Bots
 */
export const mercariBotCriticalMetadata =
  Lib.promisifyQuery<Build.mercariBotCriticalMetadataQuery.mercariBotCriticalMetadataQuery>(
    Queries.gqlMercariBotCriticalMetadata,
    [
      (args, p) => {
        State.Observe.Bots.Mercari.StatBucketsAsyncValue.subscribe(p);
        p.then((r) => {
          State.Observe.Bots.Mercari.FeatureStatusesValue.set(r.mercariFeatureStatuses);
          State.Observe.Bots.Mercari.StatBucketsValue.set(r.mercariStatBuckets);
        });
      },
    ]
  );

export const setMercariFeatureStatuses =
  Lib.promisifyMutation<Build.setMercariFeatureStatusesMutation.setMercariFeatureStatusesMutation>(
    Queries.gqlSetMercariFeatureStatuses
  );

export const listingFilterSummary = Lib.promisifyQuery<Build.listingsFilterSummaryQuery.listingsFilterSummaryQuery>(
  Queries.gqlListingsFilterSummary,
  [
    (args, p) => {
      p.then((_) => {
        const value = _.invsysListingsFilterSummary?.filters ? [..._.invsysListingsFilterSummary.filters] : [];
        State.Observe.Listings.ListingSearchFilterSummaryValue.set(value);
      });
    },
  ]
);

export const cloneItem = Lib.promisifyMutation<Build.cloneItemMutation.cloneItemMutation>(Queries.gqlCloneItem);

export const uspsFreeSupplyOrderingLedger =
  Lib.promisifyQuery<Build.uspsFreeSupplyOrderingLedgerQuery.uspsFreeSupplyOrderingLedgerQuery>(
    Queries.gqlUspsFreeSupplyOrderingLedger,
    [
      (args, p) => {
        p.then((_) => {
          const items = _.invsysUspsFreeSupplyOrderingLedger.items;
          State.Observe.Supplies.UspsFreeSupplyOrders.transform((current) => {
            return {
              ...current,
              ...mkEntityMap<State.Types.UspsFreeSuppliesOrderItem>(items, (_) => _.id),
            };
          });
          State.Observe.Supplies.UspsFreeSupplyOrderTimeRemaining.set(
            _.invsysUspsFreeSupplyOrderingLedger.timeLeftUntilAvailable
          );
        });
      },
    ]
  );

export const orderFreeSupplies = Lib.promisifyMutation<Build.orderFreeSuppliesMutation.orderFreeSuppliesMutation>(
  Queries.gqlOrderFreeSupplies
);

export const lastSalesSyncCompletedOverall =
  Lib.promisifyQuery<Build.lastSalesSyncCompletedOverallQuery.lastSalesSyncCompletedOverallQuery>(
    Queries.gqlLastSalesSyncCompletedOverall,
    [
      (args, p) => {
        p.then((_) => {
          const lastCompletedAt = _.invsysLastSalesSyncCompletedOverall;
          State.Observe.Orders.LastSalesSyncCompletedOverallAt.set(lastCompletedAt);
        });
      },
    ]
  );

export const putNuxSurvey = Lib.promisifyMutation<Build.putNuxSurveyMutation.putNuxSurveyMutation>(
  Queries.gqlPutNuxSurvey
);

export const startTrial = Lib.promisifyMutation<Build.startTrialMutation.startTrialMutation>(Queries.gqlStartTrial);
