import { graphql } from 'react-relay';

export const gqlPoshmarkBotClosetOrderingSections = graphql`
  query poshmarkBotClosetOrderingSectionsQuery($cursor: String, $limit: Int) {
    poshmarkBotClosetOrderingSections(cursor: $cursor, limit: $limit) {
      items {
        id
        userId
        name
        rank
      }
      cursor
    }
  }
`;
