/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from 'relay-runtime';
export type addAddressMutationVariables = {
  streetAddress?: string | null;
  unit?: string | null;
  locality?: string | null;
  subdivision?: string | null;
  postalCode?: string | null;
  countryCode?: string | null;
  makeDefaultShipping?: boolean | null;
  makeDefaultPickup?: boolean | null;
};
export type addAddressMutationResponse = {
  readonly invsysAddAddress: {
    readonly success: boolean;
    readonly address: {
      readonly ' $fragmentRefs': FragmentRefs<'addressFragment_full'>;
    } | null;
  };
};
export type addAddressMutation = {
  readonly response: addAddressMutationResponse;
  readonly variables: addAddressMutationVariables;
};

/*
mutation addAddressMutation(
  $streetAddress: String
  $unit: String
  $locality: String
  $subdivision: String
  $postalCode: String
  $countryCode: String
  $makeDefaultShipping: Boolean
  $makeDefaultPickup: Boolean
) {
  invsysAddAddress(streetAddress: $streetAddress, unit: $unit, locality: $locality, subdivision: $subdivision, postalCode: $postalCode, countryCode: $countryCode, makeDefaultShipping: $makeDefaultShipping, makeDefaultPickup: $makeDefaultPickup) {
    success
    address {
      ...addressFragment_full
    }
  }
}

fragment addressFragment_full on InvsysAddress {
  id
  streetAddress
  unit
  locality
  subdivision
  postalCode
  countryCode
  postalCodeFormat
  isDefaultShipping
  isDefaultPickup
}
*/

const node: ConcreteRequest = (function () {
  var v0 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'countryCode',
    },
    v1 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'locality',
    },
    v2 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'makeDefaultPickup',
    },
    v3 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'makeDefaultShipping',
    },
    v4 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'postalCode',
    },
    v5 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'streetAddress',
    },
    v6 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'subdivision',
    },
    v7 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'unit',
    },
    v8 = [
      {
        kind: 'Variable',
        name: 'countryCode',
        variableName: 'countryCode',
      },
      {
        kind: 'Variable',
        name: 'locality',
        variableName: 'locality',
      },
      {
        kind: 'Variable',
        name: 'makeDefaultPickup',
        variableName: 'makeDefaultPickup',
      },
      {
        kind: 'Variable',
        name: 'makeDefaultShipping',
        variableName: 'makeDefaultShipping',
      },
      {
        kind: 'Variable',
        name: 'postalCode',
        variableName: 'postalCode',
      },
      {
        kind: 'Variable',
        name: 'streetAddress',
        variableName: 'streetAddress',
      },
      {
        kind: 'Variable',
        name: 'subdivision',
        variableName: 'subdivision',
      },
      {
        kind: 'Variable',
        name: 'unit',
        variableName: 'unit',
      },
    ],
    v9 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'success',
      storageKey: null,
    },
    v10 = [
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'id',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'streetAddress',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'unit',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'locality',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'subdivision',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'postalCode',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'countryCode',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'postalCodeFormat',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'isDefaultShipping',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'isDefaultPickup',
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: [
        v0 /*: any*/,
        v1 /*: any*/,
        v2 /*: any*/,
        v3 /*: any*/,
        v4 /*: any*/,
        v5 /*: any*/,
        v6 /*: any*/,
        v7 /*: any*/,
      ],
      kind: 'Fragment',
      metadata: null,
      name: 'addAddressMutation',
      selections: [
        {
          alias: null,
          args: v8 /*: any*/,
          concreteType: 'InvsysAddAddressResponse',
          kind: 'LinkedField',
          name: 'invsysAddAddress',
          plural: false,
          selections: [
            v9 /*: any*/,
            {
              alias: null,
              args: null,
              concreteType: 'InvsysAddress',
              kind: 'LinkedField',
              name: 'address',
              plural: false,
              selections: [
                {
                  kind: 'InlineDataFragmentSpread',
                  name: 'addressFragment_full',
                  selections: v10 /*: any*/,
                },
              ],
              storageKey: null,
            },
          ],
          storageKey: null,
        },
      ],
      type: 'Mutation',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [
        v5 /*: any*/,
        v7 /*: any*/,
        v1 /*: any*/,
        v6 /*: any*/,
        v4 /*: any*/,
        v0 /*: any*/,
        v3 /*: any*/,
        v2 /*: any*/,
      ],
      kind: 'Operation',
      name: 'addAddressMutation',
      selections: [
        {
          alias: null,
          args: v8 /*: any*/,
          concreteType: 'InvsysAddAddressResponse',
          kind: 'LinkedField',
          name: 'invsysAddAddress',
          plural: false,
          selections: [
            v9 /*: any*/,
            {
              alias: null,
              args: null,
              concreteType: 'InvsysAddress',
              kind: 'LinkedField',
              name: 'address',
              plural: false,
              selections: v10 /*: any*/,
              storageKey: null,
            },
          ],
          storageKey: null,
        },
      ],
    },
    params: {
      cacheID: 'b4f2fc0afeaa6a08cf69facaeae380e4',
      id: null,
      metadata: {},
      name: 'addAddressMutation',
      operationKind: 'mutation',
      text: 'mutation addAddressMutation(\n  $streetAddress: String\n  $unit: String\n  $locality: String\n  $subdivision: String\n  $postalCode: String\n  $countryCode: String\n  $makeDefaultShipping: Boolean\n  $makeDefaultPickup: Boolean\n) {\n  invsysAddAddress(streetAddress: $streetAddress, unit: $unit, locality: $locality, subdivision: $subdivision, postalCode: $postalCode, countryCode: $countryCode, makeDefaultShipping: $makeDefaultShipping, makeDefaultPickup: $makeDefaultPickup) {\n    success\n    address {\n      ...addressFragment_full\n    }\n  }\n}\n\nfragment addressFragment_full on InvsysAddress {\n  id\n  streetAddress\n  unit\n  locality\n  subdivision\n  postalCode\n  countryCode\n  postalCodeFormat\n  isDefaultShipping\n  isDefaultPickup\n}\n',
    },
  };
})();
(node as any).hash = '5e748e7804cebb34c7c5868e3ed1222e';
export default node;
