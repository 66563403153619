/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type grailedListingQueryVariables = {
  listingId: string;
};
export type grailedListingQueryResponse = {
  readonly grailedGrailedListing: {
    readonly listingId: string;
    readonly updatedAt: number;
    readonly items: ReadonlyArray<{
      readonly itemId: string;
      readonly grailedListingId: string;
      readonly grailedId: string | null;
      readonly size: string | null;
      readonly publishStatusV2: {
        readonly status: string | null;
        readonly message: string | null;
      } | null;
    }>;
    readonly buyNow: boolean | null;
    readonly categoryPath: string | null;
    readonly condition: string | null;
    readonly description: string | null;
    readonly designerIds: ReadonlyArray<number>;
    readonly hashtags: ReadonlyArray<string>;
    readonly makeOffer: boolean | null;
    readonly measurement1: number | null;
    readonly measurement2: number | null;
    readonly measurement3: number | null;
    readonly measurement4: number | null;
    readonly measurement5: number | null;
    readonly measurement6: number | null;
    readonly minimumPrice: number | null;
    readonly price: number | null;
    readonly returnAddressId: number | null;
    readonly usShippingPrice: number | null;
    readonly caShippingPrice: number | null;
    readonly shippingType: number | null;
    readonly title: string | null;
    readonly color: string | null;
    readonly externalId: string | null;
    readonly publishStatusV2: {
      readonly status: string | null;
      readonly message: string | null;
    } | null;
    readonly priceSuggestion: {
      readonly suggestedPrice: number;
      readonly feeParts: ReadonlyArray<{
        readonly value: number;
        readonly label: string;
      }>;
    } | null;
    readonly shippingInfo: {
      readonly shippingPrice: number | null;
      readonly shippingProvider: number;
      readonly shippingProviderDisplay: string;
      readonly payerContributions: ReadonlyArray<{
        readonly contribution: number | null;
        readonly shippingPayer: number | null;
        readonly shippingPayerDisplay: string | null;
      }>;
    } | null;
    readonly sizeSuggestion: {
      readonly sizeSuggestions: ReadonlyArray<{
        readonly id: string;
        readonly display: string;
        readonly sizeSystem: string;
      }>;
    } | null;
  } | null;
};
export type grailedListingQuery = {
  readonly response: grailedListingQueryResponse;
  readonly variables: grailedListingQueryVariables;
};

/*
query grailedListingQuery(
  $listingId: String!
) {
  grailedGrailedListing(listingId: $listingId) {
    listingId
    updatedAt
    items {
      itemId
      grailedListingId
      grailedId
      size
      publishStatusV2 {
        status
        message
      }
    }
    buyNow
    categoryPath
    condition
    description
    designerIds
    hashtags
    makeOffer
    measurement1
    measurement2
    measurement3
    measurement4
    measurement5
    measurement6
    minimumPrice
    price
    returnAddressId
    usShippingPrice
    caShippingPrice
    shippingType
    title
    color
    externalId
    publishStatusV2 {
      status
      message
    }
    priceSuggestion {
      suggestedPrice
      feeParts {
        value
        label
      }
    }
    shippingInfo {
      shippingPrice
      shippingProvider
      shippingProviderDisplay
      payerContributions {
        contribution
        shippingPayer
        shippingPayerDisplay
      }
    }
    sizeSuggestion {
      sizeSuggestions {
        id
        display
        sizeSystem
      }
    }
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'listingId',
      },
    ],
    v1 = {
      alias: null,
      args: null,
      concreteType: 'GrailedPublishStatusV2',
      kind: 'LinkedField',
      name: 'publishStatusV2',
      plural: false,
      selections: [
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'status',
          storageKey: null,
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'message',
          storageKey: null,
        },
      ],
      storageKey: null,
    },
    v2 = [
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'listingId',
            variableName: 'listingId',
          },
        ],
        concreteType: 'GrailedGrailedListing',
        kind: 'LinkedField',
        name: 'grailedGrailedListing',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'listingId',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'updatedAt',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            concreteType: 'GrailedGrailedItem',
            kind: 'LinkedField',
            name: 'items',
            plural: true,
            selections: [
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'itemId',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'grailedListingId',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'grailedId',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'size',
                storageKey: null,
              },
              v1 /*: any*/,
            ],
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'buyNow',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'categoryPath',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'condition',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'description',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'designerIds',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'hashtags',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'makeOffer',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'measurement1',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'measurement2',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'measurement3',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'measurement4',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'measurement5',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'measurement6',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'minimumPrice',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'price',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'returnAddressId',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'usShippingPrice',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'caShippingPrice',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'shippingType',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'title',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'color',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'externalId',
            storageKey: null,
          },
          v1 /*: any*/,
          {
            alias: null,
            args: null,
            concreteType: 'GrailedGrailedPriceSuggestion',
            kind: 'LinkedField',
            name: 'priceSuggestion',
            plural: false,
            selections: [
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'suggestedPrice',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                concreteType: 'GrailedGrailedPriceSuggestionFeePart',
                kind: 'LinkedField',
                name: 'feeParts',
                plural: true,
                selections: [
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'value',
                    storageKey: null,
                  },
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'label',
                    storageKey: null,
                  },
                ],
                storageKey: null,
              },
            ],
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            concreteType: 'GrailedShippingInfo',
            kind: 'LinkedField',
            name: 'shippingInfo',
            plural: false,
            selections: [
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'shippingPrice',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'shippingProvider',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'shippingProviderDisplay',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                concreteType: 'GrailedShippingPayerContribution',
                kind: 'LinkedField',
                name: 'payerContributions',
                plural: true,
                selections: [
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'contribution',
                    storageKey: null,
                  },
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'shippingPayer',
                    storageKey: null,
                  },
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'shippingPayerDisplay',
                    storageKey: null,
                  },
                ],
                storageKey: null,
              },
            ],
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            concreteType: 'GrailedSizeSuggestion',
            kind: 'LinkedField',
            name: 'sizeSuggestion',
            plural: false,
            selections: [
              {
                alias: null,
                args: null,
                concreteType: 'GrailedSizeSuggestionSize',
                kind: 'LinkedField',
                name: 'sizeSuggestions',
                plural: true,
                selections: [
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'id',
                    storageKey: null,
                  },
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'display',
                    storageKey: null,
                  },
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'sizeSystem',
                    storageKey: null,
                  },
                ],
                storageKey: null,
              },
            ],
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'grailedListingQuery',
      selections: v2 /*: any*/,
      type: 'Query',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'grailedListingQuery',
      selections: v2 /*: any*/,
    },
    params: {
      cacheID: 'a957d4790ced381c7dfcf9579325e4f6',
      id: null,
      metadata: {},
      name: 'grailedListingQuery',
      operationKind: 'query',
      text: 'query grailedListingQuery(\n  $listingId: String!\n) {\n  grailedGrailedListing(listingId: $listingId) {\n    listingId\n    updatedAt\n    items {\n      itemId\n      grailedListingId\n      grailedId\n      size\n      publishStatusV2 {\n        status\n        message\n      }\n    }\n    buyNow\n    categoryPath\n    condition\n    description\n    designerIds\n    hashtags\n    makeOffer\n    measurement1\n    measurement2\n    measurement3\n    measurement4\n    measurement5\n    measurement6\n    minimumPrice\n    price\n    returnAddressId\n    usShippingPrice\n    caShippingPrice\n    shippingType\n    title\n    color\n    externalId\n    publishStatusV2 {\n      status\n      message\n    }\n    priceSuggestion {\n      suggestedPrice\n      feeParts {\n        value\n        label\n      }\n    }\n    shippingInfo {\n      shippingPrice\n      shippingProvider\n      shippingProviderDisplay\n      payerContributions {\n        contribution\n        shippingPayer\n        shippingPayerDisplay\n      }\n    }\n    sizeSuggestion {\n      sizeSuggestions {\n        id\n        display\n        sizeSystem\n      }\n    }\n  }\n}\n',
    },
  };
})();
(node as any).hash = '3624980181b71b78321d80c699f5bb14';
export default node;
