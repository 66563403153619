/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type TradesyTradesyShoeSizeByCountryType = 'EU' | 'US' | '%future added value';
export type TradesyStringPutObject = {
  value?: string | null;
};
export type TradesyTradesyShoeSizeByCountryTypePutObject = {
  value?: TradesyTradesyShoeSizeByCountryType | null;
};
export type editTradesyItemMutationVariables = {
  itemId: string;
  size?: TradesyStringPutObject | null;
  shoeSizeByCountry?: TradesyTradesyShoeSizeByCountryTypePutObject | null;
};
export type editTradesyItemMutationResponse = {
  readonly tradesyEditTradesyItem: {
    readonly success: boolean;
  } | null;
};
export type editTradesyItemMutation = {
  readonly response: editTradesyItemMutationResponse;
  readonly variables: editTradesyItemMutationVariables;
};

/*
mutation editTradesyItemMutation(
  $itemId: String!
  $size: TradesyStringPutObject
  $shoeSizeByCountry: TradesyTradesyShoeSizeByCountryTypePutObject
) {
  tradesyEditTradesyItem(itemId: $itemId, size: $size, shoeSizeByCountry: $shoeSizeByCountry) {
    success
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'itemId',
    },
    v1 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'shoeSizeByCountry',
    },
    v2 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'size',
    },
    v3 = [
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'itemId',
            variableName: 'itemId',
          },
          {
            kind: 'Variable',
            name: 'shoeSizeByCountry',
            variableName: 'shoeSizeByCountry',
          },
          {
            kind: 'Variable',
            name: 'size',
            variableName: 'size',
          },
        ],
        concreteType: 'TradesyEditTradesyItemResponse',
        kind: 'LinkedField',
        name: 'tradesyEditTradesyItem',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'success',
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: [v0 /*: any*/, v1 /*: any*/, v2 /*: any*/],
      kind: 'Fragment',
      metadata: null,
      name: 'editTradesyItemMutation',
      selections: v3 /*: any*/,
      type: 'Mutation',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [v0 /*: any*/, v2 /*: any*/, v1 /*: any*/],
      kind: 'Operation',
      name: 'editTradesyItemMutation',
      selections: v3 /*: any*/,
    },
    params: {
      cacheID: '498ae9b2db336397d804fc1f49664589',
      id: null,
      metadata: {},
      name: 'editTradesyItemMutation',
      operationKind: 'mutation',
      text: 'mutation editTradesyItemMutation(\n  $itemId: String!\n  $size: TradesyStringPutObject\n  $shoeSizeByCountry: TradesyTradesyShoeSizeByCountryTypePutObject\n) {\n  tradesyEditTradesyItem(itemId: $itemId, size: $size, shoeSizeByCountry: $shoeSizeByCountry) {\n    success\n  }\n}\n',
    },
  };
})();
(node as any).hash = '05ff3a0afe7e4178faf7eb182194ac8c';
export default node;
