/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type PoshmarkStringRequiredPutObject = {
  value: string;
};
export type PoshmarkIntRequiredPutObject = {
  value: number;
};
export type putPoshmarkBotOfferConfigurationMutationVariables = {
  id?: PoshmarkStringRequiredPutObject | null;
  percentOff?: PoshmarkIntRequiredPutObject | null;
  shippingDiscountId?: PoshmarkStringRequiredPutObject | null;
  minRevenue?: PoshmarkIntRequiredPutObject | null;
  listingMinAgeDays?: PoshmarkIntRequiredPutObject | null;
};
export type putPoshmarkBotOfferConfigurationMutationResponse = {
  readonly poshmarkPutBotOfferConfiguration: {
    readonly success: boolean;
  } | null;
};
export type putPoshmarkBotOfferConfigurationMutation = {
  readonly response: putPoshmarkBotOfferConfigurationMutationResponse;
  readonly variables: putPoshmarkBotOfferConfigurationMutationVariables;
};

/*
mutation putPoshmarkBotOfferConfigurationMutation(
  $id: PoshmarkStringRequiredPutObject
  $percentOff: PoshmarkIntRequiredPutObject
  $shippingDiscountId: PoshmarkStringRequiredPutObject
  $minRevenue: PoshmarkIntRequiredPutObject
  $listingMinAgeDays: PoshmarkIntRequiredPutObject
) {
  poshmarkPutBotOfferConfiguration(id: $id, percentOff: $percentOff, shippingDiscountId: $shippingDiscountId, minRevenue: $minRevenue, listingMinAgeDays: $listingMinAgeDays) {
    success
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'id',
    },
    v1 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'listingMinAgeDays',
    },
    v2 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'minRevenue',
    },
    v3 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'percentOff',
    },
    v4 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'shippingDiscountId',
    },
    v5 = [
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'id',
            variableName: 'id',
          },
          {
            kind: 'Variable',
            name: 'listingMinAgeDays',
            variableName: 'listingMinAgeDays',
          },
          {
            kind: 'Variable',
            name: 'minRevenue',
            variableName: 'minRevenue',
          },
          {
            kind: 'Variable',
            name: 'percentOff',
            variableName: 'percentOff',
          },
          {
            kind: 'Variable',
            name: 'shippingDiscountId',
            variableName: 'shippingDiscountId',
          },
        ],
        concreteType: 'PoshmarkPutBotOfferConfigurationResponse',
        kind: 'LinkedField',
        name: 'poshmarkPutBotOfferConfiguration',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'success',
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: [v0 /*: any*/, v1 /*: any*/, v2 /*: any*/, v3 /*: any*/, v4 /*: any*/],
      kind: 'Fragment',
      metadata: null,
      name: 'putPoshmarkBotOfferConfigurationMutation',
      selections: v5 /*: any*/,
      type: 'Mutation',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [v0 /*: any*/, v3 /*: any*/, v4 /*: any*/, v2 /*: any*/, v1 /*: any*/],
      kind: 'Operation',
      name: 'putPoshmarkBotOfferConfigurationMutation',
      selections: v5 /*: any*/,
    },
    params: {
      cacheID: 'a072a145eea5748d08bc2b6d37cde335',
      id: null,
      metadata: {},
      name: 'putPoshmarkBotOfferConfigurationMutation',
      operationKind: 'mutation',
      text: 'mutation putPoshmarkBotOfferConfigurationMutation(\n  $id: PoshmarkStringRequiredPutObject\n  $percentOff: PoshmarkIntRequiredPutObject\n  $shippingDiscountId: PoshmarkStringRequiredPutObject\n  $minRevenue: PoshmarkIntRequiredPutObject\n  $listingMinAgeDays: PoshmarkIntRequiredPutObject\n) {\n  poshmarkPutBotOfferConfiguration(id: $id, percentOff: $percentOff, shippingDiscountId: $shippingDiscountId, minRevenue: $minRevenue, listingMinAgeDays: $listingMinAgeDays) {\n    success\n  }\n}\n',
    },
  };
})();
(node as any).hash = 'e90c68e97a15d18e9fb0a71f3e79cf51';
export default node;
