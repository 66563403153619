import { graphql } from 'react-relay';

export const gqlInvsysBusinessManager = graphql`
  query invsysBusinessManagerQuery {
    invsysBusinessManager {
      createdAt
      e164
    }
  }
`;
