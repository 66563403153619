import Router from 'next/router';
import * as State from 'src/state';
import { EnvironmentManifest } from 'src/constants/Environment';

export const API_DOMAIN = (): string =>
  EnvironmentManifest.API_ENV === 'production' ? 'https://api.porter.oneshop.com' : 'http://api.dev.porter.oneshop.com';

const nativeFetch = global.fetch;

const WEB_AUTH_KEYS = {
  BusinessManagerId: 'x-sp-bm-id',
  BusinessId: 'x-sp-b-id',
  BusinessManagerToken: 'x-sp-bm-token',
};

export const mkFetch =
  (domainFn: () => string) =>
  async (path: string, init?: RequestInit): Promise<Response> => {
    const currPath = Router.asPath;
    try {
      const domain = domainFn();
      const r = await nativeFetch(`${domain}${path}`, {
        ...init,
        // https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch#sending_a_request_with_credentials_included
        credentials: init?.credentials ?? 'include',
      });
      if (r.status == 401) {
        Router.push(`/login?redirect=${currPath}`);
      }
      if (!r.ok) {
        throw new Error(`HTTP Error ${r.status}: ${r.statusText}`);
      }
      return r;
    } catch (e) {
      throw e;
    }
  };

export const mkFetchWithoutAuthCheck =
  (domainFn: () => string) =>
  async (path: string, init?: RequestInit): Promise<Response> => {
    try {
      const domain = domainFn();
      const r = await nativeFetch(`${domain}${path}`, {
        ...init,
        // https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch#sending_a_request_with_credentials_included
        credentials: init?.credentials ?? 'include',
      });
      if (!r.ok) {
        throw new Error(`HTTP Error ${r.status}: ${r.statusText}`);
      }
      return r;
    } catch (e) {
      throw e;
    }
  };

export const fetch = mkFetch(API_DOMAIN);
export const fetchWithoutAuthCheck = mkFetchWithoutAuthCheck(API_DOMAIN);

const UNSPLASH_DOMAIN = () => 'https://unsplash-api.porter.oneshop.com';
export const fetchUnsplash = mkFetch(UNSPLASH_DOMAIN);

const IMAGE_PROC_DOMAIN = () => 'https://image-proc-api.porter.oneshop.com';
export const fetchImageProc = mkFetch(IMAGE_PROC_DOMAIN);

const AUTOSAVE_LISTING_DOMAIN = () => 'https://autosave-listing.porter.oneshop.com';
export const fetchAutosaveListing = mkFetch(AUTOSAVE_LISTING_DOMAIN);

const SEARCH_DOMAIN = () => 'https://search-api.porter.oneshop.com';
export const fetchSearch = mkFetch(SEARCH_DOMAIN);
