/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type marketplaceListingsValidationsQueryVariables = {
  listingId: string;
  marketplaces: Array<number>;
};
export type marketplaceListingsValidationsQueryResponse = {
  readonly invsysMarketplaceListingsValidations: {
    readonly poshmarkListingValidation: {
      readonly success: boolean;
      readonly errors: ReadonlyArray<{
        readonly errorId: number;
        readonly entityName: string | null;
        readonly entityId: string | null;
        readonly fieldName: string | null;
        readonly error: string | null;
        readonly message: string;
        readonly timeToComplete: number;
      }>;
      readonly additionalFieldsAvailable: ReadonlyArray<string>;
    } | null;
    readonly mercariListingValidation: {
      readonly success: boolean;
      readonly errors: ReadonlyArray<{
        readonly errorId: number;
        readonly entityName: string | null;
        readonly entityId: string | null;
        readonly fieldName: string | null;
        readonly error: string | null;
        readonly message: string;
        readonly timeToComplete: number;
      }>;
      readonly additionalFieldsAvailable: ReadonlyArray<string>;
    } | null;
    readonly depopListingValidation: {
      readonly success: boolean;
      readonly errors: ReadonlyArray<{
        readonly errorId: number;
        readonly entityName: string | null;
        readonly entityId: string | null;
        readonly fieldName: string | null;
        readonly error: string | null;
        readonly message: string;
        readonly timeToComplete: number;
      }>;
      readonly additionalFieldsAvailable: ReadonlyArray<string>;
    } | null;
    readonly tradesyListingValidation: {
      readonly success: boolean;
      readonly errors: ReadonlyArray<{
        readonly errorId: number;
        readonly entityName: string | null;
        readonly entityId: string | null;
        readonly fieldName: string | null;
        readonly error: string | null;
        readonly message: string;
        readonly timeToComplete: number;
      }>;
      readonly additionalFieldsAvailable: ReadonlyArray<string>;
    } | null;
    readonly ebayListingValidation: {
      readonly success: boolean;
      readonly errors: ReadonlyArray<{
        readonly errorId: number;
        readonly entityName: string | null;
        readonly entityId: string | null;
        readonly fieldName: string | null;
        readonly error: string | null;
        readonly message: string;
        readonly timeToComplete: number;
      }>;
      readonly additionalFieldsAvailable: ReadonlyArray<string>;
    } | null;
    readonly grailedListingValidation: {
      readonly success: boolean;
      readonly errors: ReadonlyArray<{
        readonly errorId: number;
        readonly entityName: string | null;
        readonly entityId: string | null;
        readonly fieldName: string | null;
        readonly error: string | null;
        readonly message: string;
        readonly timeToComplete: number;
      }>;
      readonly additionalFieldsAvailable: ReadonlyArray<string>;
    } | null;
  } | null;
};
export type marketplaceListingsValidationsQuery = {
  readonly response: marketplaceListingsValidationsQueryResponse;
  readonly variables: marketplaceListingsValidationsQueryVariables;
};

/*
query marketplaceListingsValidationsQuery(
  $listingId: String!
  $marketplaces: [Int!]!
) {
  invsysMarketplaceListingsValidations(listingId: $listingId, marketplaces: $marketplaces) {
    poshmarkListingValidation {
      success
      errors {
        errorId: id
        entityName
        entityId
        fieldName
        error
        message
        timeToComplete
      }
      additionalFieldsAvailable
    }
    mercariListingValidation {
      success
      errors {
        errorId: id
        entityName
        entityId
        fieldName
        error
        message
        timeToComplete
      }
      additionalFieldsAvailable
    }
    depopListingValidation {
      success
      errors {
        errorId: id
        entityName
        entityId
        fieldName
        error
        message
        timeToComplete
      }
      additionalFieldsAvailable
    }
    tradesyListingValidation {
      success
      errors {
        errorId: id
        entityName
        entityId
        fieldName
        error
        message
        timeToComplete
      }
      additionalFieldsAvailable
    }
    ebayListingValidation {
      success
      errors {
        errorId: id
        entityName
        entityId
        fieldName
        error
        message
        timeToComplete
      }
      additionalFieldsAvailable
    }
    grailedListingValidation {
      success
      errors {
        errorId: id
        entityName
        entityId
        fieldName
        error
        message
        timeToComplete
      }
      additionalFieldsAvailable
    }
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'listingId',
      },
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'marketplaces',
      },
    ],
    v1 = [
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'success',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        concreteType: 'InvsysMarketplaceListingValidationError',
        kind: 'LinkedField',
        name: 'errors',
        plural: true,
        selections: [
          {
            alias: 'errorId',
            args: null,
            kind: 'ScalarField',
            name: 'id',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'entityName',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'entityId',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'fieldName',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'error',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'message',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'timeToComplete',
            storageKey: null,
          },
        ],
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'additionalFieldsAvailable',
        storageKey: null,
      },
    ],
    v2 = [
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'listingId',
            variableName: 'listingId',
          },
          {
            kind: 'Variable',
            name: 'marketplaces',
            variableName: 'marketplaces',
          },
        ],
        concreteType: 'InvsysMarketplaceListingsValidationsResult',
        kind: 'LinkedField',
        name: 'invsysMarketplaceListingsValidations',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            concreteType: 'InvsysMarketplaceListingValidation',
            kind: 'LinkedField',
            name: 'poshmarkListingValidation',
            plural: false,
            selections: v1 /*: any*/,
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            concreteType: 'InvsysMarketplaceListingValidation',
            kind: 'LinkedField',
            name: 'mercariListingValidation',
            plural: false,
            selections: v1 /*: any*/,
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            concreteType: 'InvsysMarketplaceListingValidation',
            kind: 'LinkedField',
            name: 'depopListingValidation',
            plural: false,
            selections: v1 /*: any*/,
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            concreteType: 'InvsysMarketplaceListingValidation',
            kind: 'LinkedField',
            name: 'tradesyListingValidation',
            plural: false,
            selections: v1 /*: any*/,
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            concreteType: 'InvsysMarketplaceListingValidation',
            kind: 'LinkedField',
            name: 'ebayListingValidation',
            plural: false,
            selections: v1 /*: any*/,
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            concreteType: 'InvsysMarketplaceListingValidation',
            kind: 'LinkedField',
            name: 'grailedListingValidation',
            plural: false,
            selections: v1 /*: any*/,
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'marketplaceListingsValidationsQuery',
      selections: v2 /*: any*/,
      type: 'Query',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'marketplaceListingsValidationsQuery',
      selections: v2 /*: any*/,
    },
    params: {
      cacheID: '15a7f6ae3483c6a25b0021df09a993c6',
      id: null,
      metadata: {},
      name: 'marketplaceListingsValidationsQuery',
      operationKind: 'query',
      text: 'query marketplaceListingsValidationsQuery(\n  $listingId: String!\n  $marketplaces: [Int!]!\n) {\n  invsysMarketplaceListingsValidations(listingId: $listingId, marketplaces: $marketplaces) {\n    poshmarkListingValidation {\n      success\n      errors {\n        errorId: id\n        entityName\n        entityId\n        fieldName\n        error\n        message\n        timeToComplete\n      }\n      additionalFieldsAvailable\n    }\n    mercariListingValidation {\n      success\n      errors {\n        errorId: id\n        entityName\n        entityId\n        fieldName\n        error\n        message\n        timeToComplete\n      }\n      additionalFieldsAvailable\n    }\n    depopListingValidation {\n      success\n      errors {\n        errorId: id\n        entityName\n        entityId\n        fieldName\n        error\n        message\n        timeToComplete\n      }\n      additionalFieldsAvailable\n    }\n    tradesyListingValidation {\n      success\n      errors {\n        errorId: id\n        entityName\n        entityId\n        fieldName\n        error\n        message\n        timeToComplete\n      }\n      additionalFieldsAvailable\n    }\n    ebayListingValidation {\n      success\n      errors {\n        errorId: id\n        entityName\n        entityId\n        fieldName\n        error\n        message\n        timeToComplete\n      }\n      additionalFieldsAvailable\n    }\n    grailedListingValidation {\n      success\n      errors {\n        errorId: id\n        entityName\n        entityId\n        fieldName\n        error\n        message\n        timeToComplete\n      }\n      additionalFieldsAvailable\n    }\n  }\n}\n',
    },
  };
})();
(node as any).hash = 'c48444a73a1c004bd8dcefb0fcecab27';
export default node;
