/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type deleteSalesGoalMutationVariables = {
  id: string;
};
export type deleteSalesGoalMutationResponse = {
  readonly invsysDeleteSalesGoal: {
    readonly id: string;
  };
};
export type deleteSalesGoalMutation = {
  readonly response: deleteSalesGoalMutationResponse;
  readonly variables: deleteSalesGoalMutationVariables;
};

/*
mutation deleteSalesGoalMutation(
  $id: String!
) {
  invsysDeleteSalesGoal(id: $id) {
    id
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'id',
      },
    ],
    v1 = [
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'id',
            variableName: 'id',
          },
        ],
        concreteType: 'InvsysDeleteSalesGoalResponse',
        kind: 'LinkedField',
        name: 'invsysDeleteSalesGoal',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'id',
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'deleteSalesGoalMutation',
      selections: v1 /*: any*/,
      type: 'Mutation',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'deleteSalesGoalMutation',
      selections: v1 /*: any*/,
    },
    params: {
      cacheID: '8165f25ed2c7feace76675e68d3191f1',
      id: null,
      metadata: {},
      name: 'deleteSalesGoalMutation',
      operationKind: 'mutation',
      text: 'mutation deleteSalesGoalMutation(\n  $id: String!\n) {\n  invsysDeleteSalesGoal(id: $id) {\n    id\n  }\n}\n',
    },
  };
})();
(node as any).hash = 'aa34ccd8f5c3906a79f649f13c933560';
export default node;
