/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from 'relay-runtime';
export type StudioStringPutObject = {
  value?: string | null;
};
export type putStudioListingMediaGroupMutationVariables = {
  id?: string | null;
  name?: StudioStringPutObject | null;
  note?: StudioStringPutObject | null;
  listingId?: StudioStringPutObject | null;
};
export type putStudioListingMediaGroupMutationResponse = {
  readonly studioPutListingMediaGroup: {
    readonly success: boolean;
    readonly data: {
      readonly ' $fragmentRefs': FragmentRefs<'studioListingMediaGroupFragment_full'>;
    } | null;
  };
};
export type putStudioListingMediaGroupMutation = {
  readonly response: putStudioListingMediaGroupMutationResponse;
  readonly variables: putStudioListingMediaGroupMutationVariables;
};

/*
mutation putStudioListingMediaGroupMutation(
  $id: String
  $name: StudioStringPutObject
  $note: StudioStringPutObject
  $listingId: StudioStringPutObject
) {
  studioPutListingMediaGroup(id: $id, name: $name, note: $note, listingId: $listingId) {
    success
    data {
      ...studioListingMediaGroupFragment_full
    }
  }
}

fragment studioListingMediaGroupFragment_full on StudioListingMediaGroup {
  id
  name
  note
  listingId
  createdAt
  updatedAt
  memberships {
    id
    listingMediaGroupId
    mediaId
    media {
      id
      createdAt
      url
      mediaType
      width
      height
      uploadStatus
      originUri
      originPlatformOs
      originPlatformMajorVersion
    }
    preparationStatus
    rank
    variants {
      membershipId
      mediaId
      variantType
      media {
        id
        createdAt
        url
        mediaType
        width
        height
        uploadStatus
        originUri
        originPlatformOs
        originPlatformMajorVersion
      }
    }
  }
  pendingVariantRequests {
    id
    listingMediaGroupId
    createdAt
    variantType
    membershipIds
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'id',
    },
    v1 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'listingId',
    },
    v2 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'name',
    },
    v3 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'note',
    },
    v4 = [
      {
        kind: 'Variable',
        name: 'id',
        variableName: 'id',
      },
      {
        kind: 'Variable',
        name: 'listingId',
        variableName: 'listingId',
      },
      {
        kind: 'Variable',
        name: 'name',
        variableName: 'name',
      },
      {
        kind: 'Variable',
        name: 'note',
        variableName: 'note',
      },
    ],
    v5 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'success',
      storageKey: null,
    },
    v6 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null,
    },
    v7 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'createdAt',
      storageKey: null,
    },
    v8 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'listingMediaGroupId',
      storageKey: null,
    },
    v9 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'mediaId',
      storageKey: null,
    },
    v10 = {
      alias: null,
      args: null,
      concreteType: 'StudioMedia',
      kind: 'LinkedField',
      name: 'media',
      plural: false,
      selections: [
        v6 /*: any*/,
        v7 /*: any*/,
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'url',
          storageKey: null,
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'mediaType',
          storageKey: null,
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'width',
          storageKey: null,
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'height',
          storageKey: null,
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'uploadStatus',
          storageKey: null,
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'originUri',
          storageKey: null,
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'originPlatformOs',
          storageKey: null,
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'originPlatformMajorVersion',
          storageKey: null,
        },
      ],
      storageKey: null,
    },
    v11 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'variantType',
      storageKey: null,
    },
    v12 = [
      v6 /*: any*/,
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'name',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'note',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'listingId',
        storageKey: null,
      },
      v7 /*: any*/,
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'updatedAt',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        concreteType: 'StudioListingMediaGroupMembership',
        kind: 'LinkedField',
        name: 'memberships',
        plural: true,
        selections: [
          v6 /*: any*/,
          v8 /*: any*/,
          v9 /*: any*/,
          v10 /*: any*/,
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'preparationStatus',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'rank',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            concreteType: 'StudioListingMediaGroupMembershipVariant',
            kind: 'LinkedField',
            name: 'variants',
            plural: true,
            selections: [
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'membershipId',
                storageKey: null,
              },
              v9 /*: any*/,
              v11 /*: any*/,
              v10 /*: any*/,
            ],
            storageKey: null,
          },
        ],
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        concreteType: 'StudioListingMediaGroupVariantRequest',
        kind: 'LinkedField',
        name: 'pendingVariantRequests',
        plural: true,
        selections: [
          v6 /*: any*/,
          v8 /*: any*/,
          v7 /*: any*/,
          v11 /*: any*/,
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'membershipIds',
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: [v0 /*: any*/, v1 /*: any*/, v2 /*: any*/, v3 /*: any*/],
      kind: 'Fragment',
      metadata: null,
      name: 'putStudioListingMediaGroupMutation',
      selections: [
        {
          alias: null,
          args: v4 /*: any*/,
          concreteType: 'StudioPutListingMediaGroupResponse',
          kind: 'LinkedField',
          name: 'studioPutListingMediaGroup',
          plural: false,
          selections: [
            v5 /*: any*/,
            {
              alias: null,
              args: null,
              concreteType: 'StudioListingMediaGroup',
              kind: 'LinkedField',
              name: 'data',
              plural: false,
              selections: [
                {
                  kind: 'InlineDataFragmentSpread',
                  name: 'studioListingMediaGroupFragment_full',
                  selections: v12 /*: any*/,
                },
              ],
              storageKey: null,
            },
          ],
          storageKey: null,
        },
      ],
      type: 'Mutation',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [v0 /*: any*/, v2 /*: any*/, v3 /*: any*/, v1 /*: any*/],
      kind: 'Operation',
      name: 'putStudioListingMediaGroupMutation',
      selections: [
        {
          alias: null,
          args: v4 /*: any*/,
          concreteType: 'StudioPutListingMediaGroupResponse',
          kind: 'LinkedField',
          name: 'studioPutListingMediaGroup',
          plural: false,
          selections: [
            v5 /*: any*/,
            {
              alias: null,
              args: null,
              concreteType: 'StudioListingMediaGroup',
              kind: 'LinkedField',
              name: 'data',
              plural: false,
              selections: v12 /*: any*/,
              storageKey: null,
            },
          ],
          storageKey: null,
        },
      ],
    },
    params: {
      cacheID: 'c0aee50096ade023d20d2612f8e375c7',
      id: null,
      metadata: {},
      name: 'putStudioListingMediaGroupMutation',
      operationKind: 'mutation',
      text: 'mutation putStudioListingMediaGroupMutation(\n  $id: String\n  $name: StudioStringPutObject\n  $note: StudioStringPutObject\n  $listingId: StudioStringPutObject\n) {\n  studioPutListingMediaGroup(id: $id, name: $name, note: $note, listingId: $listingId) {\n    success\n    data {\n      ...studioListingMediaGroupFragment_full\n    }\n  }\n}\n\nfragment studioListingMediaGroupFragment_full on StudioListingMediaGroup {\n  id\n  name\n  note\n  listingId\n  createdAt\n  updatedAt\n  memberships {\n    id\n    listingMediaGroupId\n    mediaId\n    media {\n      id\n      createdAt\n      url\n      mediaType\n      width\n      height\n      uploadStatus\n      originUri\n      originPlatformOs\n      originPlatformMajorVersion\n    }\n    preparationStatus\n    rank\n    variants {\n      membershipId\n      mediaId\n      variantType\n      media {\n        id\n        createdAt\n        url\n        mediaType\n        width\n        height\n        uploadStatus\n        originUri\n        originPlatformOs\n        originPlatformMajorVersion\n      }\n    }\n  }\n  pendingVariantRequests {\n    id\n    listingMediaGroupId\n    createdAt\n    variantType\n    membershipIds\n  }\n}\n',
    },
  };
})();
(node as any).hash = '209a5a06b271c3443f0843d068c7a0f5';
export default node;
