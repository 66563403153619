import * as Lib from 'src/clients/Network/gql/lib';
import * as State from 'src/state';
import * as Build from 'src/clients/Network/gql/build';
import * as Queries from 'src/clients/Network/gql/queries';
import * as FragmentReader from './FragmentReader';
import * as StoreHelpers from './StoreHelpers';

export * as FragmentReader from './FragmentReader';
export * as StoreHelpers from './StoreHelpers';

export const addAddress = Lib.promisifyMutation<Build.addAddressMutation.addAddressMutation>(Queries.gqlAddAddress, [
  (args, p) => {
    p.then((res) => {
      const address = FragmentReader.addressFull(res.invsysAddAddress.address);
      if (address == null) {
        return;
      }
      StoreHelpers.storeAddresses([address]);
    });
  },
]);

export const deleteAddress = Lib.promisifyMutation<Build.deleteAddressMutation.deleteAddressMutation>(
  Queries.gqlDeleteAddress
);

export const validateAddress = Lib.promisifyMutation<Build.validateAddressMutation.validateAddressMutation>(
  Queries.gqlValidateAddress
);

export const addresses = Lib.promisifyQuery<Build.addressesQuery.addressesQuery>(Queries.gqlAddresses, [
  (args, p) => {
    p.then((res) => {
      const addresses = FragmentReader.map(res.invsysAddresses, FragmentReader.addressFull);
      StoreHelpers.storeAddresses(addresses);
    });
  },
]);

export const defaultShippingAddress = Lib.promisifyQuery<Build.defaultShippingAddressQuery.defaultShippingAddressQuery>(
  Queries.gqlDefaultShippingAddress,
  [
    (args, p) => {
      p.then((res) => {
        const address = FragmentReader.addressFull(res.invsysDefaultShippingAddress);
        if (address != null) {
          StoreHelpers.storeAddresses([address]);
        } else {
          State.Observe.Location.DefaultPickupAddressIdValue.set(null);
        }
      });
    },
  ]
);

export const defaultPickupAddress = Lib.promisifyQuery<Build.defaultPickupAddressQuery.defaultPickupAddressQuery>(
  Queries.gqlDefaultPickupAddress,
  [
    (args, p) => {
      p.then((res) => {
        const address = FragmentReader.addressFull(res.invsysDefaultPickupAddress);
        if (address != null) {
          StoreHelpers.storeAddresses([address]);
        } else {
          State.Observe.Location.DefaultPickupAddressIdValue.set(null);
        }
      });
    },
  ]
);
