import * as Observe from 'src/state/lib/observe';
import * as Network from 'src/clients/Network';

export type StatFilterType = '1W' | '1M' | '1Y' | 'All';

export const StatFilterValue = Observe.Value<StatFilterType>('1M');

export type SalesBalanceTimeseriesAsyncValueDataType =
  Network.gql.build.salesBalanceTimeseriesQuery.salesBalanceTimeseriesQuery['response']['invsysSalesBalanceTimeseries'];
export const SalesBalanceTimeseriesAsyncValue = Observe.AsyncValue<SalesBalanceTimeseriesAsyncValueDataType>({});

export const SalesBalanceTimeseriesValue = Observe.DerivedValue(() => {
  return SalesBalanceTimeseriesAsyncValue.getValueWithFallback();
}, [SalesBalanceTimeseriesAsyncValue.value]);

export const SalesBalanceTimeseriesValueFallback = Observe.WithFallback(SalesBalanceTimeseriesValue);
