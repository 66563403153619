import { graphql } from 'react-relay';

export const gqlEditEbayItem = graphql`
  mutation editEbayItemMutation(
    $itemId: String!
    $attributesSizeType: EbayArray_StringRequired_RequiredPutObject
    $attributesSize: EbayArray_StringRequired_RequiredPutObject
    $attributesUsShoeSize: EbayArray_StringRequired_RequiredPutObject
  ) {
    ebayEditEbayItem(
      itemId: $itemId
      attributesSizeType: $attributesSizeType
      attributesSize: $attributesSize
      attributesUsShoeSize: $attributesUsShoeSize
    ) {
      success
    }
  }
`;
