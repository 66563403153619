import { graphql } from 'react-relay';

export const gqlFinishAwsMultipartUpload = graphql`
  mutation finishAwsMultipartUploadMutation(
    $uploadId: String!
    $uploadKey: String!
    $parts: [InvsysFinishAwsMultipartUploadPartInput!]!
  ) {
    invsysFinishAwsMultipartUpload(uploadId: $uploadId, uploadKey: $uploadKey, parts: $parts) {
      success
    }
  }
`;
