/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type deleteAddressMutationVariables = {
  addressId: string;
};
export type deleteAddressMutationResponse = {
  readonly invsysDeleteAddress: {
    readonly success: boolean;
  };
};
export type deleteAddressMutation = {
  readonly response: deleteAddressMutationResponse;
  readonly variables: deleteAddressMutationVariables;
};

/*
mutation deleteAddressMutation(
  $addressId: String!
) {
  invsysDeleteAddress(addressId: $addressId) {
    success
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'addressId',
      },
    ],
    v1 = [
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'addressId',
            variableName: 'addressId',
          },
        ],
        concreteType: 'InvsysDeleteAddressResponse',
        kind: 'LinkedField',
        name: 'invsysDeleteAddress',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'success',
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'deleteAddressMutation',
      selections: v1 /*: any*/,
      type: 'Mutation',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'deleteAddressMutation',
      selections: v1 /*: any*/,
    },
    params: {
      cacheID: '63705e967e34d484c2e31fe5792bc3e3',
      id: null,
      metadata: {},
      name: 'deleteAddressMutation',
      operationKind: 'mutation',
      text: 'mutation deleteAddressMutation(\n  $addressId: String!\n) {\n  invsysDeleteAddress(addressId: $addressId) {\n    success\n  }\n}\n',
    },
  };
})();
(node as any).hash = 'b3e8d75a58a9710c66fb28b266c6c62d';
export default node;
