import * as Observe from 'src/state/lib/observe';
import * as Network from 'src/clients/Network';

export type AsyncValueDataType =
  Network.gql.build.referralProgramDetailsQuery.referralProgramDetailsQueryResponse['superposherReferralProgramDetails'];
export const AsyncValue = Observe.AsyncValue<AsyncValueDataType>({});

export const Value = Observe.DerivedValue(() => {
  return AsyncValue.getValueWithFallback();
}, [AsyncValue.value]);

export const ValueFallback = Observe.WithFallback(Value);
