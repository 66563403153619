import * as Observe from 'src/state/lib/observe';

/* Intentionally not navigable */
export enum NuxRoute {
  Attribution,
  BotSettingsRejectDefaults,
  BotSettingsSuggestDefaults,
  BotFeatureUpsell,
  Finale,
  InstitutionUsage,
  LinkInstitution,
  ListingPlatformUsage,
  ListingsSaleCount,
  PoshmarkRequiredNotice,
  SignupPurpose,
  Welcome,
}

export const RouteValue = Observe.Value<NuxRoute>(NuxRoute.Welcome);
