import { graphql } from 'react-relay';

export const gqlPoshmarkBotOfferToLikersEvidenceLogs = graphql`
  query poshmarkBotOfferToLikersEvidenceLogsQuery($offerConfigId: String, $cursor: String, $limit: Int) {
    poshmarkBotOfferToLikersEvidenceLogs(offerConfigId: $offerConfigId, cursor: $cursor, limit: $limit) {
      items {
        listing {
          id
          url
          thumbnail
          title
          currentPrice
          deleted
          sold
        }
        failurePublicReason
        listingUrl
        listingId
        createdAt
      }
      cursor
    }
  }
`;
