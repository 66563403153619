/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type InvsysStringRequiredPutObject = {
  value: string;
};
export type InvsysBooleanRequiredPutObject = {
  value: boolean;
};
export type putNuxSurveyMutationVariables = {
  body?: InvsysStringRequiredPutObject | null;
  finished?: InvsysBooleanRequiredPutObject | null;
};
export type putNuxSurveyMutationResponse = {
  readonly invsysPutNuxSurvey: {
    readonly success: boolean;
  };
};
export type putNuxSurveyMutation = {
  readonly response: putNuxSurveyMutationResponse;
  readonly variables: putNuxSurveyMutationVariables;
};

/*
mutation putNuxSurveyMutation(
  $body: InvsysStringRequiredPutObject
  $finished: InvsysBooleanRequiredPutObject
) {
  invsysPutNuxSurvey(body: $body, finished: $finished) {
    success
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'body',
      },
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'finished',
      },
    ],
    v1 = [
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'body',
            variableName: 'body',
          },
          {
            kind: 'Variable',
            name: 'finished',
            variableName: 'finished',
          },
        ],
        concreteType: 'InvsysPutNuxSurveyResponse',
        kind: 'LinkedField',
        name: 'invsysPutNuxSurvey',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'success',
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'putNuxSurveyMutation',
      selections: v1 /*: any*/,
      type: 'Mutation',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'putNuxSurveyMutation',
      selections: v1 /*: any*/,
    },
    params: {
      cacheID: '0e102f8e34912dcebc94606d6bb2634c',
      id: null,
      metadata: {},
      name: 'putNuxSurveyMutation',
      operationKind: 'mutation',
      text: 'mutation putNuxSurveyMutation(\n  $body: InvsysStringRequiredPutObject\n  $finished: InvsysBooleanRequiredPutObject\n) {\n  invsysPutNuxSurvey(body: $body, finished: $finished) {\n    success\n  }\n}\n',
    },
  };
})();
(node as any).hash = '1199dd7e4ae6d760d1f7ab8519c0b8f9';
export default node;
