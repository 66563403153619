/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from 'relay-runtime';
export type studioLatestListingMediaGroupsQueryVariables = {
  cursor?: string | null;
  limit?: number | null;
};
export type studioLatestListingMediaGroupsQueryResponse = {
  readonly studioLatestListingMediaGroups: {
    readonly cursor: string | null;
    readonly items: ReadonlyArray<{
      readonly ' $fragmentRefs': FragmentRefs<'studioListingMediaGroupFragment_full'>;
    }>;
  };
};
export type studioLatestListingMediaGroupsQuery = {
  readonly response: studioLatestListingMediaGroupsQueryResponse;
  readonly variables: studioLatestListingMediaGroupsQueryVariables;
};

/*
query studioLatestListingMediaGroupsQuery(
  $cursor: String
  $limit: Int
) {
  studioLatestListingMediaGroups(cursor: $cursor, limit: $limit) {
    cursor
    items {
      ...studioListingMediaGroupFragment_full
    }
  }
}

fragment studioListingMediaGroupFragment_full on StudioListingMediaGroup {
  id
  name
  note
  listingId
  createdAt
  updatedAt
  memberships {
    id
    listingMediaGroupId
    mediaId
    media {
      id
      createdAt
      url
      mediaType
      width
      height
      uploadStatus
      originUri
      originPlatformOs
      originPlatformMajorVersion
    }
    preparationStatus
    rank
    variants {
      membershipId
      mediaId
      variantType
      media {
        id
        createdAt
        url
        mediaType
        width
        height
        uploadStatus
        originUri
        originPlatformOs
        originPlatformMajorVersion
      }
    }
  }
  pendingVariantRequests {
    id
    listingMediaGroupId
    createdAt
    variantType
    membershipIds
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'cursor',
      },
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'limit',
      },
    ],
    v1 = [
      {
        kind: 'Variable',
        name: 'cursor',
        variableName: 'cursor',
      },
      {
        kind: 'Variable',
        name: 'limit',
        variableName: 'limit',
      },
    ],
    v2 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'cursor',
      storageKey: null,
    },
    v3 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null,
    },
    v4 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'createdAt',
      storageKey: null,
    },
    v5 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'listingMediaGroupId',
      storageKey: null,
    },
    v6 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'mediaId',
      storageKey: null,
    },
    v7 = {
      alias: null,
      args: null,
      concreteType: 'StudioMedia',
      kind: 'LinkedField',
      name: 'media',
      plural: false,
      selections: [
        v3 /*: any*/,
        v4 /*: any*/,
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'url',
          storageKey: null,
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'mediaType',
          storageKey: null,
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'width',
          storageKey: null,
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'height',
          storageKey: null,
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'uploadStatus',
          storageKey: null,
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'originUri',
          storageKey: null,
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'originPlatformOs',
          storageKey: null,
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'originPlatformMajorVersion',
          storageKey: null,
        },
      ],
      storageKey: null,
    },
    v8 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'variantType',
      storageKey: null,
    },
    v9 = [
      v3 /*: any*/,
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'name',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'note',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'listingId',
        storageKey: null,
      },
      v4 /*: any*/,
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'updatedAt',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        concreteType: 'StudioListingMediaGroupMembership',
        kind: 'LinkedField',
        name: 'memberships',
        plural: true,
        selections: [
          v3 /*: any*/,
          v5 /*: any*/,
          v6 /*: any*/,
          v7 /*: any*/,
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'preparationStatus',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'rank',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            concreteType: 'StudioListingMediaGroupMembershipVariant',
            kind: 'LinkedField',
            name: 'variants',
            plural: true,
            selections: [
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'membershipId',
                storageKey: null,
              },
              v6 /*: any*/,
              v8 /*: any*/,
              v7 /*: any*/,
            ],
            storageKey: null,
          },
        ],
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        concreteType: 'StudioListingMediaGroupVariantRequest',
        kind: 'LinkedField',
        name: 'pendingVariantRequests',
        plural: true,
        selections: [
          v3 /*: any*/,
          v5 /*: any*/,
          v4 /*: any*/,
          v8 /*: any*/,
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'membershipIds',
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'studioLatestListingMediaGroupsQuery',
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: 'StudioListingMediaGroupPage',
          kind: 'LinkedField',
          name: 'studioLatestListingMediaGroups',
          plural: false,
          selections: [
            v2 /*: any*/,
            {
              alias: null,
              args: null,
              concreteType: 'StudioListingMediaGroup',
              kind: 'LinkedField',
              name: 'items',
              plural: true,
              selections: [
                {
                  kind: 'InlineDataFragmentSpread',
                  name: 'studioListingMediaGroupFragment_full',
                  selections: v9 /*: any*/,
                },
              ],
              storageKey: null,
            },
          ],
          storageKey: null,
        },
      ],
      type: 'Query',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'studioLatestListingMediaGroupsQuery',
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: 'StudioListingMediaGroupPage',
          kind: 'LinkedField',
          name: 'studioLatestListingMediaGroups',
          plural: false,
          selections: [
            v2 /*: any*/,
            {
              alias: null,
              args: null,
              concreteType: 'StudioListingMediaGroup',
              kind: 'LinkedField',
              name: 'items',
              plural: true,
              selections: v9 /*: any*/,
              storageKey: null,
            },
          ],
          storageKey: null,
        },
      ],
    },
    params: {
      cacheID: '96d879e5506a810424aeba60ad7f3034',
      id: null,
      metadata: {},
      name: 'studioLatestListingMediaGroupsQuery',
      operationKind: 'query',
      text: 'query studioLatestListingMediaGroupsQuery(\n  $cursor: String\n  $limit: Int\n) {\n  studioLatestListingMediaGroups(cursor: $cursor, limit: $limit) {\n    cursor\n    items {\n      ...studioListingMediaGroupFragment_full\n    }\n  }\n}\n\nfragment studioListingMediaGroupFragment_full on StudioListingMediaGroup {\n  id\n  name\n  note\n  listingId\n  createdAt\n  updatedAt\n  memberships {\n    id\n    listingMediaGroupId\n    mediaId\n    media {\n      id\n      createdAt\n      url\n      mediaType\n      width\n      height\n      uploadStatus\n      originUri\n      originPlatformOs\n      originPlatformMajorVersion\n    }\n    preparationStatus\n    rank\n    variants {\n      membershipId\n      mediaId\n      variantType\n      media {\n        id\n        createdAt\n        url\n        mediaType\n        width\n        height\n        uploadStatus\n        originUri\n        originPlatformOs\n        originPlatformMajorVersion\n      }\n    }\n  }\n  pendingVariantRequests {\n    id\n    listingMediaGroupId\n    createdAt\n    variantType\n    membershipIds\n  }\n}\n',
    },
  };
})();
(node as any).hash = 'aaa74286e8882d917240e1013bff33ec';
export default node;
