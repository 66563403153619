import { ValidationErrorId } from 'src/constants/ValidationError';
import { Types } from 'src/state';
import * as Observe from 'src/state/lib/observe';

export interface SizeIface {
  id: string;
  display: string;
  size_system: string;
}

export interface SizeArgs {
  id: string;
  size_system: string;
}

export enum AdditionalFieldsNames {
  MeasurementHeight = 'measurementHeight',
  MeasurementLength = 'measurementLength',
  MeasurementWidth = 'measurementWidth',
  ShoeSizeByCountry = 'shoeSizeByCountry',
  Size = 'size',
  HeelHeight = 'heelHeight',
  VeilLength = 'veilLength',
  ShoeWidth = 'shoeWidth',
  Brand = 'brand',
  Fabric = 'fabric',
  //optional
  HeelStyle = 'heelStyle',
  Washlook = 'washLook',
  Length = 'length',
}

export const Form = {
  TitleValue: Observe.Value<string | null>(null),
  BrandValue: Observe.Value<string | null>(null),
  CategoryValue: Observe.Value<number | null>(null),
  VielLengthValue: Observe.Value<Types.TradesyVeilLengthType | null>(null),
  HeelHeightValue: Observe.Value<Types.TradesyHeelHeightType | null>(null),
  HeelStyleValue: Observe.Value<Types.TradesyHeelStyleType | null>(null),
  WashlookValue: Observe.Value<Types.TradesyWashlookType | null>(null),
  LengthValue: Observe.Value<Types.TradesyLengthType | null>(null),
  MeasurementWidthValue: Observe.Value<string | null>(null),
  MeasurementHeightValue: Observe.Value<string | null>(null),
  MeasurementLengthValue: Observe.Value<string | null>(null),
  ShoeSizeByCountryValue: Observe.Value<Types.TradesyShoeSizeByCountryType | null>(null),
  ShoeWidthValue: Observe.Value<Types.TradesyShoeWidthType | null>(null),
  SizeValue: Observe.Value<SizeArgs | null>(null),
  PriceValue: Observe.Value<number | null>(null),
  FabricValue: Observe.Value<string | null>(null),
  ShippingPriceValue: Observe.Value<number | null>(null),
  ShippingTypeValue: Observe.Value<number | null>(null),
};

export const clear = () => {
  Object.keys(Form).map((key) => Form[key as keyof typeof Form].reset());
};

export const setWithListing = (listing: Types.TradesyListingType) => {
  Form.TitleValue.set(listing.styleName);
  Form.BrandValue.set(listing.brand);
  Form.CategoryValue.set(listing.nodeId);
  Form.VielLengthValue.set(listing.veilLength);
  Form.HeelHeightValue.set(listing.heelHeight);
  Form.HeelStyleValue.set(listing.heelStyle);
  Form.WashlookValue.set(listing.washlook);
  Form.LengthValue.set(listing.length);
  Form.MeasurementWidthValue.set(listing.measurementWidth);
  Form.MeasurementHeightValue.set(listing.measurementHeight);
  Form.MeasurementLengthValue.set(listing.measurementLength);
  Form.ShoeSizeByCountryValue.set(listing.items[0].shoeSizeByCountry);
  Form.ShoeWidthValue.set(listing.shoeWidth);
  Form.SizeValue.set(
    listing.items[0].size != null
      ? {
          id: listing.items[0].size.toString(),
          size_system: 'us',
        }
      : null
  );
  Form.PriceValue.set(listing.askingPrice);
  Form.FabricValue.set(listing.fabric);
  Form.ShippingPriceValue.set(listing.shippingPrice);
  Form.ShippingTypeValue.set(listing.shippingType);
};

export enum FormPart {
  Title,
  Brand,
  Category,
  VeilLength,
  HeelHeight,
  MeasurementWidth,
  MeasurementHeight,
  MeasurementLength,
  ShoeSizeByCountry,
  ShoeWidth,
  Size,
  Price,
  ShippingPrice,
  Photos,
  HeelStyle,
  Washlook,
  Length,
  Fabric,
  Shipping,
}

interface FieldIface {
  part: FormPart;
  errors: ValidationErrorId[];
}

export const FormConfig: FieldIface[] = [
  {
    part: FormPart.Title,
    errors: [
      ValidationErrorId.Tradesy_TradesyListingStyleNameRequired,
      ValidationErrorId.Tradesy_TradesyListingStyleNameLimit70Characters,
    ],
  },
  {
    part: FormPart.Brand,
    errors: [ValidationErrorId.Tradesy_TradesyListingBrandRequired],
  },
  {
    part: FormPart.Category,
    errors: [
      ValidationErrorId.Tradesy_TradesyListingNodeIdRequired,
      ValidationErrorId.Tradesy_TradesyListingNonLeafCategory,
    ],
  },
  {
    part: FormPart.VeilLength,
    errors: [ValidationErrorId.Tradesy_TradesyListingVeilLengthRequired],
  },
  {
    part: FormPart.HeelHeight,
    errors: [ValidationErrorId.Tradesy_TradesyListingHeelHeightRequired],
  },
  {
    part: FormPart.ShoeSizeByCountry,
    errors: [ValidationErrorId.Tradesy_TradesyListingSizeByCountryRequired],
  },
  {
    part: FormPart.ShoeWidth,
    errors: [ValidationErrorId.Tradesy_TradesyListingWidthRequired],
  },
  {
    part: FormPart.MeasurementWidth,
    errors: [
      ValidationErrorId.Tradesy_TradesyListingMeasurementWidthHeightLengthRequired,
      ValidationErrorId.Tradesy_TradesyListingMeasurementWidthRequired,
    ],
  },
  {
    part: FormPart.MeasurementHeight,
    errors: [
      ValidationErrorId.Tradesy_TradesyListingMeasurementWidthHeightLengthRequired,
      ValidationErrorId.Tradesy_TradesyListingMeasurementHeightRequired,
    ],
  },
  {
    part: FormPart.MeasurementLength,
    errors: [
      ValidationErrorId.Tradesy_TradesyListingMeasurementWidthHeightLengthRequired,
      ValidationErrorId.Tradesy_TradesyListingMeasurementLengthRequired,
    ],
  },
  {
    part: FormPart.Price,
    errors: [ValidationErrorId.Tradesy_TradesyListingAskingPriceRequired],
  },
  {
    part: FormPart.ShippingPrice,
    errors: [ValidationErrorId.Tradesy_TradesyListingShippingPriceRequired],
  },
  {
    part: FormPart.Size,
    errors: [
      ValidationErrorId.Tradesy_TradesyListingSizeRequired,
      ValidationErrorId.Tradesy_TradesyListingSizeInvalidOption,
    ],
  },
  {
    part: FormPart.Photos,
    errors: [],
  },
  {
    part: FormPart.HeelStyle,
    errors: [ValidationErrorId.Tradesy_TradesyListingHeelStyleInvalidOption],
  },
  {
    part: FormPart.Washlook,
    errors: [ValidationErrorId.Tradesy_TradesyListingWashLookInvalidOption],
  },
  {
    part: FormPart.Length,
    errors: [ValidationErrorId.Tradesy_TradesyListingLengthInvalidOption],
  },
  {
    part: FormPart.Fabric,
    errors: [ValidationErrorId.Tradesy_TradesyListingFabricRequired],
  },
  {
    part: FormPart.Shipping,
    errors: [ValidationErrorId.Tradesy_TradesyListingPersonalShippingPriceRequired],
  },
];

export const FormParts = Observe.Value<FormPart[]>([]);
export const UnhandledErrorIds = Observe.Value<number[]>([]);
export const AllowCustomSizes = Observe.Value<boolean>(false);
