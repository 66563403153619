import { graphql } from 'react-relay';

export const gqlPoshmarkBotClosetOrderingListings = graphql`
  query poshmarkBotClosetOrderingListingsQuery($closetOrderingSectionId: String, $cursor: String, $limit: Int) {
    poshmarkBotClosetOrderingListings(
      closetOrderingSectionId: $closetOrderingSectionId
      cursor: $cursor
      limit: $limit
    ) {
      items {
        listingId
        listing {
          id
          userId
          url
          thumbnail
          title
          currentPrice
          deleted
          sold
        }
        userId
        sectionId
        rank
      }
      cursor
    }
  }
`;
