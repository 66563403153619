/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type uspsPickupDateOptionsQueryVariables = {};
export type uspsPickupDateOptionsQueryResponse = {
  readonly invsysUspsPickupDateOptions: ReadonlyArray<number>;
};
export type uspsPickupDateOptionsQuery = {
  readonly response: uspsPickupDateOptionsQueryResponse;
  readonly variables: uspsPickupDateOptionsQueryVariables;
};

/*
query uspsPickupDateOptionsQuery {
  invsysUspsPickupDateOptions
}
*/

const node: ConcreteRequest = (function () {
  var v0 = [
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'invsysUspsPickupDateOptions',
      storageKey: null,
    },
  ];
  return {
    fragment: {
      argumentDefinitions: [],
      kind: 'Fragment',
      metadata: null,
      name: 'uspsPickupDateOptionsQuery',
      selections: v0 /*: any*/,
      type: 'Query',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [],
      kind: 'Operation',
      name: 'uspsPickupDateOptionsQuery',
      selections: v0 /*: any*/,
    },
    params: {
      cacheID: 'd31256903a384494dc5785c1385cb21b',
      id: null,
      metadata: {},
      name: 'uspsPickupDateOptionsQuery',
      operationKind: 'query',
      text: 'query uspsPickupDateOptionsQuery {\n  invsysUspsPickupDateOptions\n}\n',
    },
  };
})();
(node as any).hash = '31b66db5c3f66e4c4a77ff9736dd6230';
export default node;
