/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type poshmarkCatalogQueryVariables = {};
export type poshmarkCatalogQueryResponse = {
  readonly superposherPoshmarkCatalog: {
    readonly sourceUrl: string;
  };
};
export type poshmarkCatalogQuery = {
  readonly response: poshmarkCatalogQueryResponse;
  readonly variables: poshmarkCatalogQueryVariables;
};

/*
query poshmarkCatalogQuery {
  superposherPoshmarkCatalog {
    sourceUrl
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = [
    {
      alias: null,
      args: null,
      concreteType: 'SuperposherPoshmarkLookup',
      kind: 'LinkedField',
      name: 'superposherPoshmarkCatalog',
      plural: false,
      selections: [
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'sourceUrl',
          storageKey: null,
        },
      ],
      storageKey: null,
    },
  ];
  return {
    fragment: {
      argumentDefinitions: [],
      kind: 'Fragment',
      metadata: null,
      name: 'poshmarkCatalogQuery',
      selections: v0 /*: any*/,
      type: 'Query',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [],
      kind: 'Operation',
      name: 'poshmarkCatalogQuery',
      selections: v0 /*: any*/,
    },
    params: {
      cacheID: 'e33389825114a263c828ac16968e90e9',
      id: null,
      metadata: {},
      name: 'poshmarkCatalogQuery',
      operationKind: 'query',
      text: 'query poshmarkCatalogQuery {\n  superposherPoshmarkCatalog {\n    sourceUrl\n  }\n}\n',
    },
  };
})();
(node as any).hash = 'd6528f53821b45c4d6ceac21c3ecc7e3';
export default node;
