import { graphql } from 'react-relay';

export const gqlInvsysMediaFragmentFull = graphql`
  fragment invsysMediaFragment_full on InvsysMedia @inline {
    id
    createdAt
    url
    mediaType
    width
    height
    businessId
    businessManagerId
    uploadStatus
    originUri
    originPlatformOs
    originPlatformMajorVersion
  }
`;
