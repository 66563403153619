/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type listingValidationResultQueryVariables = {
  id: string;
  validationMarketplaces?: Array<number> | null;
};
export type listingValidationResultQueryResponse = {
  readonly invsysListingValidationResult: {
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly errorId: number;
      readonly message: string;
      readonly entityName: string | null;
      readonly fieldName: string | null;
    }>;
  };
};
export type listingValidationResultQuery = {
  readonly response: listingValidationResultQueryResponse;
  readonly variables: listingValidationResultQueryVariables;
};

/*
query listingValidationResultQuery(
  $id: String!
  $validationMarketplaces: [Int!]
) {
  invsysListingValidationResult(id: $id, validationMarketplaces: $validationMarketplaces) {
    success
    errors {
      errorId: id
      message
      entityName
      fieldName
    }
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'id',
      },
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'validationMarketplaces',
      },
    ],
    v1 = [
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'id',
            variableName: 'id',
          },
          {
            kind: 'Variable',
            name: 'validationMarketplaces',
            variableName: 'validationMarketplaces',
          },
        ],
        concreteType: 'InvsysListingStepResult',
        kind: 'LinkedField',
        name: 'invsysListingValidationResult',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'success',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            concreteType: 'InvsysListingStepErrorType',
            kind: 'LinkedField',
            name: 'errors',
            plural: true,
            selections: [
              {
                alias: 'errorId',
                args: null,
                kind: 'ScalarField',
                name: 'id',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'message',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'entityName',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'fieldName',
                storageKey: null,
              },
            ],
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'listingValidationResultQuery',
      selections: v1 /*: any*/,
      type: 'Query',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'listingValidationResultQuery',
      selections: v1 /*: any*/,
    },
    params: {
      cacheID: 'f4a991a07f782d4e78590344fcf16df4',
      id: null,
      metadata: {},
      name: 'listingValidationResultQuery',
      operationKind: 'query',
      text: 'query listingValidationResultQuery(\n  $id: String!\n  $validationMarketplaces: [Int!]\n) {\n  invsysListingValidationResult(id: $id, validationMarketplaces: $validationMarketplaces) {\n    success\n    errors {\n      errorId: id\n      message\n      entityName\n      fieldName\n    }\n  }\n}\n',
    },
  };
})();
(node as any).hash = 'a67e4c4b40755c02938ff386be7d78aa';
export default node;
