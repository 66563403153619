/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type rewriteClosetSectionListingOrderingMutationVariables = {
  poshmarkUserId: string;
  closetSectionId: string;
  listingIds: Array<string>;
};
export type rewriteClosetSectionListingOrderingMutationResponse = {
  readonly superposherRewriteClosetSectionListingOrdering: {
    readonly success: boolean;
  };
};
export type rewriteClosetSectionListingOrderingMutation = {
  readonly response: rewriteClosetSectionListingOrderingMutationResponse;
  readonly variables: rewriteClosetSectionListingOrderingMutationVariables;
};

/*
mutation rewriteClosetSectionListingOrderingMutation(
  $poshmarkUserId: String!
  $closetSectionId: String!
  $listingIds: [String!]!
) {
  superposherRewriteClosetSectionListingOrdering(poshmarkUserId: $poshmarkUserId, closetSectionId: $closetSectionId, listingIds: $listingIds) {
    success
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'closetSectionId',
    },
    v1 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'listingIds',
    },
    v2 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'poshmarkUserId',
    },
    v3 = [
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'closetSectionId',
            variableName: 'closetSectionId',
          },
          {
            kind: 'Variable',
            name: 'listingIds',
            variableName: 'listingIds',
          },
          {
            kind: 'Variable',
            name: 'poshmarkUserId',
            variableName: 'poshmarkUserId',
          },
        ],
        concreteType: 'SuperposherRewriteClosetSectionListingOrderingResponse',
        kind: 'LinkedField',
        name: 'superposherRewriteClosetSectionListingOrdering',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'success',
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: [v0 /*: any*/, v1 /*: any*/, v2 /*: any*/],
      kind: 'Fragment',
      metadata: null,
      name: 'rewriteClosetSectionListingOrderingMutation',
      selections: v3 /*: any*/,
      type: 'Mutation',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [v2 /*: any*/, v0 /*: any*/, v1 /*: any*/],
      kind: 'Operation',
      name: 'rewriteClosetSectionListingOrderingMutation',
      selections: v3 /*: any*/,
    },
    params: {
      cacheID: 'ba0643ce1eca3d20477767ecafbb6485',
      id: null,
      metadata: {},
      name: 'rewriteClosetSectionListingOrderingMutation',
      operationKind: 'mutation',
      text: 'mutation rewriteClosetSectionListingOrderingMutation(\n  $poshmarkUserId: String!\n  $closetSectionId: String!\n  $listingIds: [String!]!\n) {\n  superposherRewriteClosetSectionListingOrdering(poshmarkUserId: $poshmarkUserId, closetSectionId: $closetSectionId, listingIds: $listingIds) {\n    success\n  }\n}\n',
    },
  };
})();
(node as any).hash = 'db73db11cca07d757d2c1a8250576e65';
export default node;
