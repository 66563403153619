import { graphql } from 'react-relay';

export const gqlInvsysCheckShopUsername = graphql`
  query invsysCheckShopUsernameQuery($username: String!) {
    invsysCheckShopUsername(username: $username) {
      valid
      errorMessage
    }
  }
`;
