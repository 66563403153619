import { graphql } from 'react-relay';

export const gqlStartAwsMultipartUpload = graphql`
  mutation startAwsMultipartUploadMutation($fileSize: Int!, $fileType: String!) {
    invsysStartAwsMultipartUpload(fileSize: $fileSize, fileType: $fileType) {
      success
      data {
        uploadId
        uploadKey
        uploadUrl
        partMaxSize
        partUrls
      }
    }
  }
`;
