/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type InvsysGoalFrequency = 'ANNUALLY' | 'MONTHLY' | 'WEEKLY' | '%future added value';
export type InvsysGoalStatusType = 'IN_PROGRESS' | 'MISSED' | 'PASSED' | '%future added value';
export type InvsysGoalType = 'GROSS_REVENUE' | 'NET_REVENUE' | 'NEW_ITEMS_LISTED' | '%future added value';
export type salesGoalsQueryVariables = {};
export type salesGoalsQueryResponse = {
  readonly invsysSalesGoals: ReadonlyArray<{
    readonly id: string;
    readonly goalType: InvsysGoalType;
    readonly goalStatus: InvsysGoalStatusType;
    readonly target: number;
    readonly startAt: number;
    readonly endAt: number | null;
    readonly frequency: InvsysGoalFrequency;
  }>;
};
export type salesGoalsQuery = {
  readonly response: salesGoalsQueryResponse;
  readonly variables: salesGoalsQueryVariables;
};

/*
query salesGoalsQuery {
  invsysSalesGoals {
    id
    goalType
    goalStatus
    target
    startAt
    endAt
    frequency
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = [
    {
      alias: null,
      args: null,
      concreteType: 'InvsysSalesGoal',
      kind: 'LinkedField',
      name: 'invsysSalesGoals',
      plural: true,
      selections: [
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'id',
          storageKey: null,
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'goalType',
          storageKey: null,
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'goalStatus',
          storageKey: null,
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'target',
          storageKey: null,
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'startAt',
          storageKey: null,
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'endAt',
          storageKey: null,
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'frequency',
          storageKey: null,
        },
      ],
      storageKey: null,
    },
  ];
  return {
    fragment: {
      argumentDefinitions: [],
      kind: 'Fragment',
      metadata: null,
      name: 'salesGoalsQuery',
      selections: v0 /*: any*/,
      type: 'Query',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [],
      kind: 'Operation',
      name: 'salesGoalsQuery',
      selections: v0 /*: any*/,
    },
    params: {
      cacheID: '8f7f85175a1d6e239a7314794763af27',
      id: null,
      metadata: {},
      name: 'salesGoalsQuery',
      operationKind: 'query',
      text: 'query salesGoalsQuery {\n  invsysSalesGoals {\n    id\n    goalType\n    goalStatus\n    target\n    startAt\n    endAt\n    frequency\n  }\n}\n',
    },
  };
})();
(node as any).hash = '5d581a287af2c046df88fcdc0e022d8f';
export default node;
