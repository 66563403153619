/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type shippingLabelReelQueryVariables = {};
export type shippingLabelReelQueryResponse = {
  readonly invsysShippingLabelReel: {
    readonly url: string | null;
    readonly status: string | null;
  } | null;
};
export type shippingLabelReelQuery = {
  readonly response: shippingLabelReelQueryResponse;
  readonly variables: shippingLabelReelQueryVariables;
};

/*
query shippingLabelReelQuery {
  invsysShippingLabelReel {
    url
    status
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = [
    {
      alias: null,
      args: null,
      concreteType: 'InvsysShippingLabelReel',
      kind: 'LinkedField',
      name: 'invsysShippingLabelReel',
      plural: false,
      selections: [
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'url',
          storageKey: null,
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'status',
          storageKey: null,
        },
      ],
      storageKey: null,
    },
  ];
  return {
    fragment: {
      argumentDefinitions: [],
      kind: 'Fragment',
      metadata: null,
      name: 'shippingLabelReelQuery',
      selections: v0 /*: any*/,
      type: 'Query',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [],
      kind: 'Operation',
      name: 'shippingLabelReelQuery',
      selections: v0 /*: any*/,
    },
    params: {
      cacheID: '12f30df2b61d472ac00516146020d215',
      id: null,
      metadata: {},
      name: 'shippingLabelReelQuery',
      operationKind: 'query',
      text: 'query shippingLabelReelQuery {\n  invsysShippingLabelReel {\n    url\n    status\n  }\n}\n',
    },
  };
})();
(node as any).hash = '8239471437a60aa06ac06dda13533d1c';
export default node;
