export interface CacheIface<K, V> {
  get(k: K): V | undefined;
  set(k: K, v: V): void;
  size: () => number;
}

export const mk = <K, V>(maxSize: number): CacheIface<K, V> => {
  const cache = new Map<K, V>();
  const first = () => cache.keys().next().value;
  const size = () => cache.size;

  const get = (key: K): V | undefined => {
    const value = cache.get(key);
    if (value != null) {
      cache.delete(key);
      cache.set(key, value);
    }
    return value;
  };
  const set = (key: K, value: V) => {
    if (cache.has(key)) {
      cache.delete(key);
    }
    if (size() === maxSize) {
      cache.delete(first());
    }
    cache.set(key, value);
  };

  return {
    get,
    set,
    size,
  };
};
