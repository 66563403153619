/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type invsysMediaQueryVariables = {
  ids: Array<string>;
};
export type invsysMediaQueryResponse = {
  readonly invsysMedia: ReadonlyArray<{
    readonly id: string;
    readonly createdAt: number;
    readonly url: string;
    readonly mediaType: string | null;
    readonly width: number;
    readonly height: number;
    readonly uploadStatus: number;
  }>;
};
export type invsysMediaQuery = {
  readonly response: invsysMediaQueryResponse;
  readonly variables: invsysMediaQueryVariables;
};

/*
query invsysMediaQuery(
  $ids: [String!]!
) {
  invsysMedia(ids: $ids) {
    id
    createdAt
    url
    mediaType
    width
    height
    uploadStatus
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'ids',
      },
    ],
    v1 = [
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'ids',
            variableName: 'ids',
          },
        ],
        concreteType: 'InvsysMedia',
        kind: 'LinkedField',
        name: 'invsysMedia',
        plural: true,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'id',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'createdAt',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'url',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'mediaType',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'width',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'height',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'uploadStatus',
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'invsysMediaQuery',
      selections: v1 /*: any*/,
      type: 'Query',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'invsysMediaQuery',
      selections: v1 /*: any*/,
    },
    params: {
      cacheID: '2c31d3419b9a5f749fc39942633544df',
      id: null,
      metadata: {},
      name: 'invsysMediaQuery',
      operationKind: 'query',
      text: 'query invsysMediaQuery(\n  $ids: [String!]!\n) {\n  invsysMedia(ids: $ids) {\n    id\n    createdAt\n    url\n    mediaType\n    width\n    height\n    uploadStatus\n  }\n}\n',
    },
  };
})();
(node as any).hash = '2e5763a0a2cc74ce16a9709b59b7ee78';
export default node;
