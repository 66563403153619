/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type invsysUpdateListingDescriptionMutationVariables = {
  id: string;
  description: string;
};
export type invsysUpdateListingDescriptionMutationResponse = {
  readonly invsysUpdateListingDescription: {
    readonly success: boolean;
  };
};
export type invsysUpdateListingDescriptionMutation = {
  readonly response: invsysUpdateListingDescriptionMutationResponse;
  readonly variables: invsysUpdateListingDescriptionMutationVariables;
};

/*
mutation invsysUpdateListingDescriptionMutation(
  $id: String!
  $description: String!
) {
  invsysUpdateListingDescription(id: $id, description: $description) {
    success
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'description',
    },
    v1 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'id',
    },
    v2 = [
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'description',
            variableName: 'description',
          },
          {
            kind: 'Variable',
            name: 'id',
            variableName: 'id',
          },
        ],
        concreteType: 'InvsysUpdateListingDescriptionResponse',
        kind: 'LinkedField',
        name: 'invsysUpdateListingDescription',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'success',
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: [v0 /*: any*/, v1 /*: any*/],
      kind: 'Fragment',
      metadata: null,
      name: 'invsysUpdateListingDescriptionMutation',
      selections: v2 /*: any*/,
      type: 'Mutation',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [v1 /*: any*/, v0 /*: any*/],
      kind: 'Operation',
      name: 'invsysUpdateListingDescriptionMutation',
      selections: v2 /*: any*/,
    },
    params: {
      cacheID: '60ff17fef55653fc339da8576f89cce4',
      id: null,
      metadata: {},
      name: 'invsysUpdateListingDescriptionMutation',
      operationKind: 'mutation',
      text: 'mutation invsysUpdateListingDescriptionMutation(\n  $id: String!\n  $description: String!\n) {\n  invsysUpdateListingDescription(id: $id, description: $description) {\n    success\n  }\n}\n',
    },
  };
})();
(node as any).hash = '41dbfa360937d965e0a37f231b699a49';
export default node;
