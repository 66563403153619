/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from 'relay-runtime';
export type orderFragment_full = {
  readonly id: string;
  readonly institution: number;
  readonly institutionIssuedId: string;
  readonly updatedAt: number;
  readonly createdAt: number;
  readonly marketplaceLink: string;
  readonly earliestOrderStatus: number | null;
  readonly orderInventoryUnits: ReadonlyArray<{
    readonly id: string;
    readonly status: number;
    readonly updatedAt: number;
    readonly createdAt: number;
    readonly mercariListingPreview: {
      readonly title: string | null;
      readonly thumbnail: string | null;
    } | null;
    readonly poshmarkListingPreview: {
      readonly title: string | null;
      readonly thumbnail: string | null;
    } | null;
    readonly depopListingPreview: {
      readonly title: string | null;
      readonly thumbnail: string | null;
    } | null;
    readonly invsysListingAssociation: {
      readonly listingId: string;
      readonly itemId: string | null;
      readonly listing: {
        readonly listingSku: string | null;
      } | null;
    } | null;
  }>;
  readonly balanceUpdates: ReadonlyArray<{
    readonly updateType: number;
    readonly currencyCode: string;
    readonly value: number;
    readonly at: number;
  }>;
  readonly ' $refType': 'orderFragment_full';
};
export type orderFragment_full$data = orderFragment_full;
export type orderFragment_full$key = {
  readonly ' $data'?: orderFragment_full$data;
  readonly ' $fragmentRefs': FragmentRefs<'orderFragment_full'>;
};

const node: ReaderInlineDataFragment = {
  kind: 'InlineDataFragment',
  name: 'orderFragment_full',
};
(node as any).hash = '0d4dbe2f281e7de644d2a3228e12e615';
export default node;
