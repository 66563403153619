import { ValidationErrorId } from 'src/constants/ValidationError';
import { Types } from 'src/state';
import * as Observe from 'src/state/lib/observe';

export interface SizeIface {
  id: string;
  display: string;
  size_system: string;
}

export interface SizeArgs {
  id: string;
  size_system: string;
}

export enum AdditionalFieldsNames {
  Size = 'size',
  Imei = 'imei',
  Shipping = 'shipping',
  //optional
  DressOccasion = 'dress-occasion',
  DressStyle = 'dress-style',
  Material = 'material',
  ShaftHeight = 'shaft-height',
  EndUse = 'end-use',
  HeelHeight = 'heel-height',
  HeelType = 'heel-type',
}

export const Form = {
  TitleValue: Observe.Value<string | null>(null),
  DescriptionValue: Observe.Value<string | null>(null),
  BrandValue: Observe.Value<string | null>(null),
  CategoryValue: Observe.Value<number | null>(null),
  PriceValue: Observe.Value<number | null>(null),
  ImeiValue: Observe.Value<string | null>(null),
  ShippingClassIdsValue: Observe.Value<number[]>([]),
  ShippingPackageWeightValue: Observe.Value<number | null>(null),
  SizeValue: Observe.Value<SizeArgs | null>(null),
  DressOccasionValue: Observe.Value<string | null>(null),
  DressStyleValue: Observe.Value<string | null>(null),
  MaterialValue: Observe.Value<string | null>(null),
  ShaftHeightValue: Observe.Value<string | null>(null),
  EndUseValue: Observe.Value<string | null>(null),
  HeelHeightValue: Observe.Value<string | null>(null),
  HeelTypeValue: Observe.Value<string | null>(null),
  ShippingTypeValue: Observe.Value<number | null>(null),
  ShippingPayerIdValue: Observe.Value<number | null>(null),
};

export const clear = () => {
  Object.keys(Form).map((key) => Form[key as keyof typeof Form].reset());
};

export const setWithListing = (listing: Types.MercariListingType) => {
  Form.TitleValue.set(listing.name);
  Form.DescriptionValue.set(listing.description);
  Form.BrandValue.set(listing.brandId);
  Form.CategoryValue.set(listing.categoryId);
  Form.PriceValue.set(listing.price);
  Form.ImeiValue.set(listing.imei);
  Form.ShippingClassIdsValue.set([...listing.shippingClassIds]);
  Form.ShippingPackageWeightValue.set(listing.shippingPackageWeight);
  Form.SizeValue.set(
    listing.items[0].sizeId != null
      ? {
          id: listing.items[0].sizeId.toString(),
          size_system: 'us',
        }
      : null
  );
  Form.DressOccasionValue.set(listing.dressOccasion);
  Form.DressStyleValue.set(listing.dressStyle);
  Form.MaterialValue.set(listing.material);
  Form.ShaftHeightValue.set(listing.shaftHeight);
  Form.EndUseValue.set(listing.endUse);
  Form.HeelHeightValue.set(listing.heelHeight);
  Form.HeelTypeValue.set(listing.heelType);
  Form.ShippingTypeValue.set(listing.shippingType);
  Form.ShippingPayerIdValue.set(listing.shippingPayerId);
};

export enum FormPart {
  Shipping,
  Title,
  Description,
  Category,
  Size,
  Price,
  Imei,
  DressOccasion,
  DressStyle,
  Material,
  ShaftHeight,
  EndUse,
  HeelHeight,
  HeelType,
  Brand,
}

interface FieldIface {
  part: FormPart;
  errors: ValidationErrorId[];
}

export const FormConfig: FieldIface[] = [
  {
    part: FormPart.Shipping,
    errors: [
      ValidationErrorId.Mercari_MercariListingShippingClassIds,
      ValidationErrorId.Mercari_MercariListingShippingPackageWeight,
      ValidationErrorId.Mercari_MercariListingSuggestedShippingClassIds,
      ValidationErrorId.Mercari_MercariListingShipping,
    ],
  },
  {
    part: FormPart.Title,
    errors: [ValidationErrorId.Mercari_MercariListingName],
  },
  {
    part: FormPart.Description,
    errors: [ValidationErrorId.Mercari_MercariListingDescription],
  },
  {
    part: FormPart.Category,
    errors: [ValidationErrorId.Mercari_MercariListingCategoryId],
  },
  {
    part: FormPart.Size,
    errors: [ValidationErrorId.Mercari_MercariListingSizeId],
  },
  {
    part: FormPart.Price,
    errors: [
      ValidationErrorId.Mercari_MercariListingPrice,
      ValidationErrorId.Mercari_MercariListingMinPriceForAutoPriceDropRequired,
    ],
  },
  {
    part: FormPart.Imei,
    errors: [ValidationErrorId.Mercari_MercariListingImei],
  },
  {
    part: FormPart.DressOccasion,
    errors: [ValidationErrorId.Mercari_MercariListingDressOccasion],
  },
  {
    part: FormPart.DressStyle,
    errors: [ValidationErrorId.Mercari_MercariListingDressStyle],
  },
  {
    part: FormPart.Material,
    errors: [ValidationErrorId.Mercari_MercariListingMaterial],
  },
  {
    part: FormPart.ShaftHeight,
    errors: [ValidationErrorId.Mercari_MercariListingShaftHeight],
  },
  {
    part: FormPart.EndUse,
    errors: [ValidationErrorId.Mercari_MercariListingEndUse],
  },
  {
    part: FormPart.HeelHeight,
    errors: [ValidationErrorId.Mercari_MercariListingHeelHeight],
  },
  {
    part: FormPart.HeelType,
    errors: [ValidationErrorId.Mercari_MercariListingHeelType],
  },
  {
    part: FormPart.Brand,
    errors: [],
  },
];

export const FormParts = Observe.Value<FormPart[]>([]);
export const UnhandledErrorIds = Observe.Value<number[]>([]);
