import { graphql } from 'react-relay';

export const gqlTradesyListing = graphql`
  query tradesyListingQuery($listingId: String!) {
    tradesyTradesyListing(listingId: $listingId) {
      listingId
      createdAt
      updatedAt
      items {
        itemId
        tradesyListingId
        tradesyId
        size
        shoeSizeByCountry
        publishStatusV2 {
          status
          message
        }
      }
      length
      color
      washlook
      conditionTags
      conditioWear
      description
      styleName
      styleKeywords
      originalPrice
      askingPrice
      shippingPrice
      shippingFlag
      shippingType
      acceptReturns
      brand
      nodeId
      veilLength
      shoeWidth
      measurementWidth
      measurementHeight
      measurementLength
      heelHeight
      heelStyle
      fabric
      publishStatusV2 {
        status
        message
      }
      priceSuggestion {
        suggestedPrice
        feeParts {
          value
          label
        }
      }
      shippingInfo {
        shippingPrice
        shippingProvider
        shippingProviderDisplay
        payerContributions {
          contribution
          shippingPayer
          shippingPayerDisplay
        }
      }
      sizeSuggestion {
        sizeSuggestions {
          id
          display
          sizeSystem
        }
      }
    }
  }
`;
