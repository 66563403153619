/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from 'relay-runtime';
export type InvsysStringRequiredPutObject = {
  value: string;
};
export type InvsysIntRequiredPutObject = {
  value: number;
};
export type InvsysStringPutObject = {
  value?: string | null;
};
export type InvsysBooleanRequiredPutObject = {
  value: boolean;
};
export type putUspsPickupLocationMutationVariables = {
  id?: InvsysStringRequiredPutObject | null;
  addressId?: InvsysStringRequiredPutObject | null;
  pickupInstruction?: InvsysIntRequiredPutObject | null;
  instructionComments?: InvsysStringPutObject | null;
  email?: InvsysStringPutObject | null;
  makeDefaultPickupLocation?: InvsysBooleanRequiredPutObject | null;
};
export type putUspsPickupLocationMutationResponse = {
  readonly invsysPutUspsPickupLocation: {
    readonly success: boolean;
    readonly data: {
      readonly ' $fragmentRefs': FragmentRefs<'uspsPickupLocationFragment_full'>;
    } | null;
  };
};
export type putUspsPickupLocationMutation = {
  readonly response: putUspsPickupLocationMutationResponse;
  readonly variables: putUspsPickupLocationMutationVariables;
};

/*
mutation putUspsPickupLocationMutation(
  $id: InvsysStringRequiredPutObject
  $addressId: InvsysStringRequiredPutObject
  $pickupInstruction: InvsysIntRequiredPutObject
  $instructionComments: InvsysStringPutObject
  $email: InvsysStringPutObject
  $makeDefaultPickupLocation: InvsysBooleanRequiredPutObject
) {
  invsysPutUspsPickupLocation(id: $id, addressId: $addressId, pickupInstruction: $pickupInstruction, instructionComments: $instructionComments, email: $email, makeDefaultPickupLocation: $makeDefaultPickupLocation) {
    success
    data {
      ...uspsPickupLocationFragment_full
    }
  }
}

fragment addressFragment_full on InvsysAddress {
  id
  streetAddress
  unit
  locality
  subdivision
  postalCode
  countryCode
  postalCodeFormat
  isDefaultShipping
  isDefaultPickup
}

fragment uspsPickupLocationFragment_full on InvsysUspsPickupLocation {
  id
  addressId
  pickupInstruction
  instructionComments
  firstName
  lastName
  email
  e164
  isDefaultPickupLocation
  address {
    ...addressFragment_full
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'addressId',
    },
    v1 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'email',
    },
    v2 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'id',
    },
    v3 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'instructionComments',
    },
    v4 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'makeDefaultPickupLocation',
    },
    v5 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'pickupInstruction',
    },
    v6 = [
      {
        kind: 'Variable',
        name: 'addressId',
        variableName: 'addressId',
      },
      {
        kind: 'Variable',
        name: 'email',
        variableName: 'email',
      },
      {
        kind: 'Variable',
        name: 'id',
        variableName: 'id',
      },
      {
        kind: 'Variable',
        name: 'instructionComments',
        variableName: 'instructionComments',
      },
      {
        kind: 'Variable',
        name: 'makeDefaultPickupLocation',
        variableName: 'makeDefaultPickupLocation',
      },
      {
        kind: 'Variable',
        name: 'pickupInstruction',
        variableName: 'pickupInstruction',
      },
    ],
    v7 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'success',
      storageKey: null,
    },
    v8 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null,
    },
    v9 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'addressId',
      storageKey: null,
    },
    v10 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'pickupInstruction',
      storageKey: null,
    },
    v11 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'instructionComments',
      storageKey: null,
    },
    v12 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'firstName',
      storageKey: null,
    },
    v13 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'lastName',
      storageKey: null,
    },
    v14 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'email',
      storageKey: null,
    },
    v15 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'e164',
      storageKey: null,
    },
    v16 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'isDefaultPickupLocation',
      storageKey: null,
    },
    v17 = [
      v8 /*: any*/,
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'streetAddress',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'unit',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'locality',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'subdivision',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'postalCode',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'countryCode',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'postalCodeFormat',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'isDefaultShipping',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'isDefaultPickup',
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: [v0 /*: any*/, v1 /*: any*/, v2 /*: any*/, v3 /*: any*/, v4 /*: any*/, v5 /*: any*/],
      kind: 'Fragment',
      metadata: null,
      name: 'putUspsPickupLocationMutation',
      selections: [
        {
          alias: null,
          args: v6 /*: any*/,
          concreteType: 'InvsysPutPickupLocationResponse',
          kind: 'LinkedField',
          name: 'invsysPutUspsPickupLocation',
          plural: false,
          selections: [
            v7 /*: any*/,
            {
              alias: null,
              args: null,
              concreteType: 'InvsysUspsPickupLocation',
              kind: 'LinkedField',
              name: 'data',
              plural: false,
              selections: [
                {
                  kind: 'InlineDataFragmentSpread',
                  name: 'uspsPickupLocationFragment_full',
                  selections: [
                    v8 /*: any*/,
                    v9 /*: any*/,
                    v10 /*: any*/,
                    v11 /*: any*/,
                    v12 /*: any*/,
                    v13 /*: any*/,
                    v14 /*: any*/,
                    v15 /*: any*/,
                    v16 /*: any*/,
                    {
                      alias: null,
                      args: null,
                      concreteType: 'InvsysAddress',
                      kind: 'LinkedField',
                      name: 'address',
                      plural: false,
                      selections: [
                        {
                          kind: 'InlineDataFragmentSpread',
                          name: 'addressFragment_full',
                          selections: v17 /*: any*/,
                        },
                      ],
                      storageKey: null,
                    },
                  ],
                },
              ],
              storageKey: null,
            },
          ],
          storageKey: null,
        },
      ],
      type: 'Mutation',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [v2 /*: any*/, v0 /*: any*/, v5 /*: any*/, v3 /*: any*/, v1 /*: any*/, v4 /*: any*/],
      kind: 'Operation',
      name: 'putUspsPickupLocationMutation',
      selections: [
        {
          alias: null,
          args: v6 /*: any*/,
          concreteType: 'InvsysPutPickupLocationResponse',
          kind: 'LinkedField',
          name: 'invsysPutUspsPickupLocation',
          plural: false,
          selections: [
            v7 /*: any*/,
            {
              alias: null,
              args: null,
              concreteType: 'InvsysUspsPickupLocation',
              kind: 'LinkedField',
              name: 'data',
              plural: false,
              selections: [
                v8 /*: any*/,
                v9 /*: any*/,
                v10 /*: any*/,
                v11 /*: any*/,
                v12 /*: any*/,
                v13 /*: any*/,
                v14 /*: any*/,
                v15 /*: any*/,
                v16 /*: any*/,
                {
                  alias: null,
                  args: null,
                  concreteType: 'InvsysAddress',
                  kind: 'LinkedField',
                  name: 'address',
                  plural: false,
                  selections: v17 /*: any*/,
                  storageKey: null,
                },
              ],
              storageKey: null,
            },
          ],
          storageKey: null,
        },
      ],
    },
    params: {
      cacheID: '4b0a59dd9cbf25ba70cb8f9c80df46c0',
      id: null,
      metadata: {},
      name: 'putUspsPickupLocationMutation',
      operationKind: 'mutation',
      text: 'mutation putUspsPickupLocationMutation(\n  $id: InvsysStringRequiredPutObject\n  $addressId: InvsysStringRequiredPutObject\n  $pickupInstruction: InvsysIntRequiredPutObject\n  $instructionComments: InvsysStringPutObject\n  $email: InvsysStringPutObject\n  $makeDefaultPickupLocation: InvsysBooleanRequiredPutObject\n) {\n  invsysPutUspsPickupLocation(id: $id, addressId: $addressId, pickupInstruction: $pickupInstruction, instructionComments: $instructionComments, email: $email, makeDefaultPickupLocation: $makeDefaultPickupLocation) {\n    success\n    data {\n      ...uspsPickupLocationFragment_full\n    }\n  }\n}\n\nfragment addressFragment_full on InvsysAddress {\n  id\n  streetAddress\n  unit\n  locality\n  subdivision\n  postalCode\n  countryCode\n  postalCodeFormat\n  isDefaultShipping\n  isDefaultPickup\n}\n\nfragment uspsPickupLocationFragment_full on InvsysUspsPickupLocation {\n  id\n  addressId\n  pickupInstruction\n  instructionComments\n  firstName\n  lastName\n  email\n  e164\n  isDefaultPickupLocation\n  address {\n    ...addressFragment_full\n  }\n}\n',
    },
  };
})();
(node as any).hash = 'c2c8e01c576aeac9027753b56d520132';
export default node;
