import { graphql } from 'react-relay';

export const gqlScheduleList = graphql`
  mutation scheduleListMutation($listingId: String!, $linkId: String!, $at: Float) {
    invsysScheduleList(listingId: $listingId, linkId: $linkId, at: $at) {
      success
      validationErrors {
        errorId: id
        entityName
        entityId
        fieldName
        error
        message
      }
    }
  }
`;
