import { graphql } from 'react-relay';

export const gqlCloneItem = graphql`
  mutation cloneItemMutation($id: String!, $count: Int!) {
    invsysCloneItemMulti(id: $id, count: $count) {
      success
      data {
        id
      }
    }
  }
`;
