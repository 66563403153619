import * as Observe from 'src/state/lib/observe';
import * as PoshmarkUserLinks from 'src/state/observe/PoshmarkUserLinks';
import { inventoryMetadataQueryResponse } from 'src/clients/Network/gql/build/inventoryMetadataQuery.graphql';

export type AsyncValueDataType = inventoryMetadataQueryResponse['superposherPoshmarkUserLinks'];
export const AsyncValue = Observe.AsyncValue<AsyncValueDataType>({});

export const Value = Observe.DerivedValue(() => {
  return AsyncValue.getResolvedValue() ?? AsyncValue.getFallbackValue();
}, [AsyncValue.value]);

export const ValueFallback = Observe.WithFallback(Value);

export const SelectedValue = Observe.DerivedValue(() => {
  const inventoryMetadata = Value.get();
  const selectedPoshmarkUserLinkId = PoshmarkUserLinks.SelectedIdValue.get();
  return inventoryMetadata?.find((_) => _.id === selectedPoshmarkUserLinkId);
}, [PoshmarkUserLinks.SelectedIdValue, Value]);

export const SelectedFallback = Observe.WithFallback(SelectedValue);
