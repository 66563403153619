/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type updateRelistIntervalMutationVariables = {
  poshmarkUserId: string;
  interval: number;
};
export type updateRelistIntervalMutationResponse = {
  readonly superposherUpdateRelistInterval: {
    readonly success: boolean;
  };
};
export type updateRelistIntervalMutation = {
  readonly response: updateRelistIntervalMutationResponse;
  readonly variables: updateRelistIntervalMutationVariables;
};

/*
mutation updateRelistIntervalMutation(
  $poshmarkUserId: String!
  $interval: Float!
) {
  superposherUpdateRelistInterval(poshmarkUserId: $poshmarkUserId, interval: $interval) {
    success
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'interval',
    },
    v1 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'poshmarkUserId',
    },
    v2 = [
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'interval',
            variableName: 'interval',
          },
          {
            kind: 'Variable',
            name: 'poshmarkUserId',
            variableName: 'poshmarkUserId',
          },
        ],
        concreteType: 'SuperposherUpdateRelistIntervalResponse',
        kind: 'LinkedField',
        name: 'superposherUpdateRelistInterval',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'success',
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: [v0 /*: any*/, v1 /*: any*/],
      kind: 'Fragment',
      metadata: null,
      name: 'updateRelistIntervalMutation',
      selections: v2 /*: any*/,
      type: 'Mutation',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [v1 /*: any*/, v0 /*: any*/],
      kind: 'Operation',
      name: 'updateRelistIntervalMutation',
      selections: v2 /*: any*/,
    },
    params: {
      cacheID: 'ddd75c88084be2060642e283c6bba148',
      id: null,
      metadata: {},
      name: 'updateRelistIntervalMutation',
      operationKind: 'mutation',
      text: 'mutation updateRelistIntervalMutation(\n  $poshmarkUserId: String!\n  $interval: Float!\n) {\n  superposherUpdateRelistInterval(poshmarkUserId: $poshmarkUserId, interval: $interval) {\n    success\n  }\n}\n',
    },
  };
})();
(node as any).hash = '8aebad4c105fef0cb754854146dfed2c';
export default node;
