/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type setupPoshmarkBotOfferConfigurationFeatureMutationVariables = {};
export type setupPoshmarkBotOfferConfigurationFeatureMutationResponse = {
  readonly poshmarkSetupBotOfferConfigurationFeature: {
    readonly success: boolean;
  } | null;
};
export type setupPoshmarkBotOfferConfigurationFeatureMutation = {
  readonly response: setupPoshmarkBotOfferConfigurationFeatureMutationResponse;
  readonly variables: setupPoshmarkBotOfferConfigurationFeatureMutationVariables;
};

/*
mutation setupPoshmarkBotOfferConfigurationFeatureMutation {
  poshmarkSetupBotOfferConfigurationFeature {
    success
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = [
    {
      alias: null,
      args: null,
      concreteType: 'PoshmarkSetupBotOfferConfigurationFeatureResponse',
      kind: 'LinkedField',
      name: 'poshmarkSetupBotOfferConfigurationFeature',
      plural: false,
      selections: [
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'success',
          storageKey: null,
        },
      ],
      storageKey: null,
    },
  ];
  return {
    fragment: {
      argumentDefinitions: [],
      kind: 'Fragment',
      metadata: null,
      name: 'setupPoshmarkBotOfferConfigurationFeatureMutation',
      selections: v0 /*: any*/,
      type: 'Mutation',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [],
      kind: 'Operation',
      name: 'setupPoshmarkBotOfferConfigurationFeatureMutation',
      selections: v0 /*: any*/,
    },
    params: {
      cacheID: 'f2782ee1c98964393336e3402510ee57',
      id: null,
      metadata: {},
      name: 'setupPoshmarkBotOfferConfigurationFeatureMutation',
      operationKind: 'mutation',
      text: 'mutation setupPoshmarkBotOfferConfigurationFeatureMutation {\n  poshmarkSetupBotOfferConfigurationFeature {\n    success\n  }\n}\n',
    },
  };
})();
(node as any).hash = '92231e38c77d5019fdefc74b82f5ba84';
export default node;
