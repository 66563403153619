/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type cancelScheduledListMutationVariables = {
  listingId: string;
};
export type cancelScheduledListMutationResponse = {
  readonly invsysCancelScheduledList: {
    readonly success: boolean;
  };
};
export type cancelScheduledListMutation = {
  readonly response: cancelScheduledListMutationResponse;
  readonly variables: cancelScheduledListMutationVariables;
};

/*
mutation cancelScheduledListMutation(
  $listingId: String!
) {
  invsysCancelScheduledList(listingId: $listingId) {
    success
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'listingId',
      },
    ],
    v1 = [
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'listingId',
            variableName: 'listingId',
          },
        ],
        concreteType: 'InvsysListingCancelScheduledListResponse',
        kind: 'LinkedField',
        name: 'invsysCancelScheduledList',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'success',
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'cancelScheduledListMutation',
      selections: v1 /*: any*/,
      type: 'Mutation',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'cancelScheduledListMutation',
      selections: v1 /*: any*/,
    },
    params: {
      cacheID: 'f55dbbdef54ea0f888f79ab5c8393c97',
      id: null,
      metadata: {},
      name: 'cancelScheduledListMutation',
      operationKind: 'mutation',
      text: 'mutation cancelScheduledListMutation(\n  $listingId: String!\n) {\n  invsysCancelScheduledList(listingId: $listingId) {\n    success\n  }\n}\n',
    },
  };
})();
(node as any).hash = '01222f386110ae50911cdab9d2721777';
export default node;
