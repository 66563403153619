import { graphql } from 'react-relay';

export const gqlPrepareStudioListingMediaGroupMembership = graphql`
  mutation prepareStudioListingMediaGroupMembershipMutation(
    $listingMediaGroupId: String!
    $operations: [StudioListingMediaGroupMembershipPreparationOperation!]!
  ) {
    studioPrepareListingMediaGroupMembership(listingMediaGroupId: $listingMediaGroupId, operations: $operations) {
      success
    }
  }
`;
