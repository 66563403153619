import { graphql } from 'react-relay';

export const gqlPutItem = graphql`
  mutation putItemMutation(
    $id: String
    $title: InvsysStringPutObject
    $description: InvsysStringPutObject
    $mediaIds: InvsysArray_StringRequired_RequiredPutObject
    $condition: InvsysListingConditionTypePutObject
    $brand: InvsysStringPutObject
    $retailPrice: InvsysFloatPutObject
    $costPrice: InvsysFloatPutObject
    $colors: InvsysArray_ColorTypeRequired_RequiredPutObject
    $tags: InvsysArray_StringRequired_RequiredPutObject
    $notes: InvsysArray_StringRequired_RequiredPutObject
    $skuAlias: InvsysStringPutObject
    $currencyCode: InvsysListingCurrencyCodeTypePutObject
    $weight: InvsysFloatPutObject
    $weightUnit: InvsysWeightUnitTypePutObject
    $width: InvsysFloatPutObject
    $length: InvsysFloatPutObject
    $height: InvsysFloatPutObject
    $sizeUnit: InvsysSizeUnitTypePutObject
    $categoryId: InvsysStringPutObject
    $packageSizeType: InvsysIntPutObject
  ) {
    invsysPutItem(
      id: $id
      title: $title
      description: $description
      mediaIds: $mediaIds
      condition: $condition
      brand: $brand
      retailPrice: $retailPrice
      costPrice: $costPrice
      colors: $colors
      tags: $tags
      notes: $notes
      skuAlias: $skuAlias
      currencyCode: $currencyCode
      weight: $weight
      weightUnit: $weightUnit
      width: $width
      length: $length
      height: $height
      sizeUnit: $sizeUnit
      categoryId: $categoryId
      packageSizeType: $packageSizeType
    ) {
      success
      data {
        id
      }
    }
  }
`;
