/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type GrailedStringPutObject = {
  value?: string | null;
};
export type editGrailedItemMutationVariables = {
  itemId: string;
  size?: GrailedStringPutObject | null;
};
export type editGrailedItemMutationResponse = {
  readonly grailedEditGrailedItem: {
    readonly success: boolean;
  } | null;
};
export type editGrailedItemMutation = {
  readonly response: editGrailedItemMutationResponse;
  readonly variables: editGrailedItemMutationVariables;
};

/*
mutation editGrailedItemMutation(
  $itemId: String!
  $size: GrailedStringPutObject
) {
  grailedEditGrailedItem(itemId: $itemId, size: $size) {
    success
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'itemId',
      },
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'size',
      },
    ],
    v1 = [
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'itemId',
            variableName: 'itemId',
          },
          {
            kind: 'Variable',
            name: 'size',
            variableName: 'size',
          },
        ],
        concreteType: 'GrailedEditGrailedItemResponse',
        kind: 'LinkedField',
        name: 'grailedEditGrailedItem',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'success',
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'editGrailedItemMutation',
      selections: v1 /*: any*/,
      type: 'Mutation',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'editGrailedItemMutation',
      selections: v1 /*: any*/,
    },
    params: {
      cacheID: '7d6fcaa0c0e20b134f9ddf2dc2cf4b99',
      id: null,
      metadata: {},
      name: 'editGrailedItemMutation',
      operationKind: 'mutation',
      text: 'mutation editGrailedItemMutation(\n  $itemId: String!\n  $size: GrailedStringPutObject\n) {\n  grailedEditGrailedItem(itemId: $itemId, size: $size) {\n    success\n  }\n}\n',
    },
  };
})();
(node as any).hash = '15c62a660a8f713999011cb08af0f962';
export default node;
