/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type rewriteClosetSectionOrderingMutationVariables = {
  poshmarkUserId: string;
  closetSectionIds: Array<string>;
};
export type rewriteClosetSectionOrderingMutationResponse = {
  readonly superposherRewriteClosetSectionOrdering: {
    readonly success: boolean;
  };
};
export type rewriteClosetSectionOrderingMutation = {
  readonly response: rewriteClosetSectionOrderingMutationResponse;
  readonly variables: rewriteClosetSectionOrderingMutationVariables;
};

/*
mutation rewriteClosetSectionOrderingMutation(
  $poshmarkUserId: String!
  $closetSectionIds: [String!]!
) {
  superposherRewriteClosetSectionOrdering(poshmarkUserId: $poshmarkUserId, closetSectionIds: $closetSectionIds) {
    success
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'closetSectionIds',
    },
    v1 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'poshmarkUserId',
    },
    v2 = [
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'closetSectionIds',
            variableName: 'closetSectionIds',
          },
          {
            kind: 'Variable',
            name: 'poshmarkUserId',
            variableName: 'poshmarkUserId',
          },
        ],
        concreteType: 'SuperposherRewriteClosetSectionOrderingResponse',
        kind: 'LinkedField',
        name: 'superposherRewriteClosetSectionOrdering',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'success',
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: [v0 /*: any*/, v1 /*: any*/],
      kind: 'Fragment',
      metadata: null,
      name: 'rewriteClosetSectionOrderingMutation',
      selections: v2 /*: any*/,
      type: 'Mutation',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [v1 /*: any*/, v0 /*: any*/],
      kind: 'Operation',
      name: 'rewriteClosetSectionOrderingMutation',
      selections: v2 /*: any*/,
    },
    params: {
      cacheID: 'b82b5bc061354f428dfd67f8cd6dadc1',
      id: null,
      metadata: {},
      name: 'rewriteClosetSectionOrderingMutation',
      operationKind: 'mutation',
      text: 'mutation rewriteClosetSectionOrderingMutation(\n  $poshmarkUserId: String!\n  $closetSectionIds: [String!]!\n) {\n  superposherRewriteClosetSectionOrdering(poshmarkUserId: $poshmarkUserId, closetSectionIds: $closetSectionIds) {\n    success\n  }\n}\n',
    },
  };
})();
(node as any).hash = '73f44da85041b7bfcb13cd4d54a27b1c';
export default node;
