/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type textMobileLinksQueryVariables = {};
export type textMobileLinksQueryResponse = {
  readonly invsysTextMobileAppLinks: {
    readonly success: boolean;
  } | null;
};
export type textMobileLinksQuery = {
  readonly response: textMobileLinksQueryResponse;
  readonly variables: textMobileLinksQueryVariables;
};

/*
query textMobileLinksQuery {
  invsysTextMobileAppLinks {
    success
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = [
    {
      alias: null,
      args: null,
      concreteType: 'InvsysTextMobileAppLinksResponse',
      kind: 'LinkedField',
      name: 'invsysTextMobileAppLinks',
      plural: false,
      selections: [
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'success',
          storageKey: null,
        },
      ],
      storageKey: null,
    },
  ];
  return {
    fragment: {
      argumentDefinitions: [],
      kind: 'Fragment',
      metadata: null,
      name: 'textMobileLinksQuery',
      selections: v0 /*: any*/,
      type: 'Query',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [],
      kind: 'Operation',
      name: 'textMobileLinksQuery',
      selections: v0 /*: any*/,
    },
    params: {
      cacheID: '337c451b55296dd596d0d808a292030e',
      id: null,
      metadata: {},
      name: 'textMobileLinksQuery',
      operationKind: 'query',
      text: 'query textMobileLinksQuery {\n  invsysTextMobileAppLinks {\n    success\n  }\n}\n',
    },
  };
})();
(node as any).hash = '63e804b4c41529688cfe2ec44760f369';
export default node;
