import * as Observe from 'src/util/observe';

export const MediaIdToFileMapping = Observe.LookupStoreValue<File>();

export interface UploadListingMediaRequest {
  mediaFile: File;
  status: 'pending' | 'failed' | 'finished';
  mediaId?: string;
  originalMediaId?: string;
}

export const UploadListingMediaRequestsValue = Observe.Value<UploadListingMediaRequest[]>([]);

export interface UploadSocialAvatarMediaRequest {
  uri: string;
  status: 'pending' | 'failed' | 'finished';
  mediaId?: string;
  originalMediaId?: string;
}

export const UploadSocialAvatarMediaRequestsValue = Observe.Value<UploadSocialAvatarMediaRequest | null>(null);

export interface UploadSocialPostMediaRequest {
  uri: string;
  status: 'pending' | 'failed' | 'finished';
  mediaId?: string;
  originalMediaId?: string;
}

export const UploadSocialPostMediaRequestsValue = Observe.Value<UploadSocialPostMediaRequest[]>([]);
