/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type InvsysAnalyticsCompetitionFrequencyType = 'MONTHLY' | 'WEEKLY' | '%future added value';
export type InvsysAnalyticsCompetitionType = 'GROSS_REVENUE' | 'ITEMS_LISTED' | 'NET_REVENUE' | '%future added value';
export type personalRankingQueryVariables = {
  institution?: number | null;
  competition: InvsysAnalyticsCompetitionType;
  frequency: InvsysAnalyticsCompetitionFrequencyType;
};
export type personalRankingQueryResponse = {
  readonly invsysPersonalRanking: {
    readonly score: number;
    readonly rank: number;
    readonly imageUrl: string | null;
  } | null;
};
export type personalRankingQuery = {
  readonly response: personalRankingQueryResponse;
  readonly variables: personalRankingQueryVariables;
};

/*
query personalRankingQuery(
  $institution: Int
  $competition: InvsysAnalyticsCompetitionType!
  $frequency: InvsysAnalyticsCompetitionFrequencyType!
) {
  invsysPersonalRanking(institution: $institution, competition: $competition, frequency: $frequency) {
    score
    rank
    imageUrl
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'competition',
    },
    v1 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'frequency',
    },
    v2 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'institution',
    },
    v3 = [
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'competition',
            variableName: 'competition',
          },
          {
            kind: 'Variable',
            name: 'frequency',
            variableName: 'frequency',
          },
          {
            kind: 'Variable',
            name: 'institution',
            variableName: 'institution',
          },
        ],
        concreteType: 'InvsysTopRankingResponseType',
        kind: 'LinkedField',
        name: 'invsysPersonalRanking',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'score',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'rank',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'imageUrl',
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: [v0 /*: any*/, v1 /*: any*/, v2 /*: any*/],
      kind: 'Fragment',
      metadata: null,
      name: 'personalRankingQuery',
      selections: v3 /*: any*/,
      type: 'Query',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [v2 /*: any*/, v0 /*: any*/, v1 /*: any*/],
      kind: 'Operation',
      name: 'personalRankingQuery',
      selections: v3 /*: any*/,
    },
    params: {
      cacheID: 'f3b1c6696fd88d5a63909e4ff7ece759',
      id: null,
      metadata: {},
      name: 'personalRankingQuery',
      operationKind: 'query',
      text: 'query personalRankingQuery(\n  $institution: Int\n  $competition: InvsysAnalyticsCompetitionType!\n  $frequency: InvsysAnalyticsCompetitionFrequencyType!\n) {\n  invsysPersonalRanking(institution: $institution, competition: $competition, frequency: $frequency) {\n    score\n    rank\n    imageUrl\n  }\n}\n',
    },
  };
})();
(node as any).hash = 'ddfee7ecc2ed9c42049955cba2cf129b';
export default node;
