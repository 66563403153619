import { graphql } from 'react-relay';

export const gqlPutPoshmarkBotOfferConfiguration = graphql`
  mutation putPoshmarkBotOfferConfigurationMutation(
    $id: PoshmarkStringRequiredPutObject
    $percentOff: PoshmarkIntRequiredPutObject
    $shippingDiscountId: PoshmarkStringRequiredPutObject
    $minRevenue: PoshmarkIntRequiredPutObject
    $listingMinAgeDays: PoshmarkIntRequiredPutObject
  ) {
    poshmarkPutBotOfferConfiguration(
      id: $id
      percentOff: $percentOff
      shippingDiscountId: $shippingDiscountId
      minRevenue: $minRevenue
      listingMinAgeDays: $listingMinAgeDays
    ) {
      success
    }
  }
`;
