import { graphql } from 'react-relay';

export const gqlPoshmarkBotOfferConfigurationListings = graphql`
  query poshmarkBotOfferConfigurationListingsQuery($offerConfigId: String, $cursor: String, $limit: Int) {
    poshmarkBotOfferConfigurationListings(offerConfigId: $offerConfigId, cursor: $cursor, limit: $limit) {
      items {
        listingId
        offerConfigId
        listing {
          id
          url
          thumbnail
          title
          currentPrice
          deleted
          sold
        }
        listingFailure {
          createdAt
          failurePublicReason
        }
        offer {
          percentOff
          minRevenue
          shippingDiscountId
          listingMinAgeDays
          updatedAt
          userEditOk
          usageCount
        }
        createdAt
      }
      cursor
    }
  }
`;
