import * as Observe from 'src/state/lib/observe';
import * as ClosetSection from 'src/state/observe/ClosetSection';
import { closetSectionListingsQueryResponse } from 'src/clients/Network/gql/build/closetSectionListingsQuery.graphql';

export type ResponseDataType = NonNullable<
  NonNullable<closetSectionListingsQueryResponse['superposherPoshmarkUserLink']>['closetSection']
>['listings'];

interface EntityTransactions {
  updatedAt: Date;
  data: ResponseDataType;
}

export type AsyncValueDataType = {
  [closetSectionId: string]: EntityTransactions;
};
export const AsyncValue = Observe.AsyncValue<AsyncValueDataType>({
  merge: (prev, next) => {
    if (prev == null) {
      return next;
    } else {
      const nextState: AsyncValueDataType = { ...prev };
      for (const filter in next) {
        nextState[filter] = next[filter];
      }
      return nextState;
    }
  },
});

export const Value = Observe.DerivedValue(() => {
  return AsyncValue.getResolvedValue();
}, [AsyncValue.value]);

export const ValueFallback = Observe.WithFallback(Value);

export const SelectedValue = Observe.DerivedValue(() => {
  const selectedId = ClosetSection.SelectedIdValue.get();
  if (selectedId != null) {
    const closetSectionListings = AsyncValue.getResolvedValue() ?? AsyncValue.getFallbackValue() ?? {};
    return closetSectionListings[selectedId];
  } else {
    return null;
  }
}, [AsyncValue.value, ClosetSection.SelectedIdValue]);

export const SelectedFallback = Observe.WithFallback(SelectedValue);

export const EditSelectedIdsValue = Observe.Value<string[]>([]);
export const EditSelectedIdsFallback = Observe.WithFallback(EditSelectedIdsValue);
