import { graphql } from 'react-relay';

export const gqlShippingLabelReel = graphql`
  query shippingLabelReelQuery {
    invsysShippingLabelReel {
      url
      status
    }
  }
`;
