/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type closetSectionListingsQueryVariables = {
  poshmarkUserLinkId: string;
  closetSectionId: string;
  listingsLimit?: number | null;
  listingsCursor?: string | null;
};
export type closetSectionListingsQueryResponse = {
  readonly superposherPoshmarkUserLink: {
    readonly id: string;
    readonly closetSection: {
      readonly id: string;
      readonly listingCount: number;
      readonly listings: {
        readonly items: ReadonlyArray<{
          readonly id: string;
          readonly entities: ReadonlyArray<{
            readonly sourceUrl: string;
          } | null>;
        }>;
        readonly cursor: string | null;
      };
    } | null;
  } | null;
};
export type closetSectionListingsQuery = {
  readonly response: closetSectionListingsQueryResponse;
  readonly variables: closetSectionListingsQueryVariables;
};

/*
query closetSectionListingsQuery(
  $poshmarkUserLinkId: String!
  $closetSectionId: String!
  $listingsLimit: Int
  $listingsCursor: String
) {
  superposherPoshmarkUserLink(id: $poshmarkUserLinkId) {
    id
    closetSection(id: $closetSectionId) {
      id
      listingCount
      listings(limit: $listingsLimit, cursor: $listingsCursor) {
        items {
          id
          entities {
            sourceUrl
          }
        }
        cursor
      }
    }
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'closetSectionId',
    },
    v1 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'listingsCursor',
    },
    v2 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'listingsLimit',
    },
    v3 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'poshmarkUserLinkId',
    },
    v4 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null,
    },
    v5 = [
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'id',
            variableName: 'poshmarkUserLinkId',
          },
        ],
        concreteType: 'SuperposherPoshmarkUserLink',
        kind: 'LinkedField',
        name: 'superposherPoshmarkUserLink',
        plural: false,
        selections: [
          v4 /*: any*/,
          {
            alias: null,
            args: [
              {
                kind: 'Variable',
                name: 'id',
                variableName: 'closetSectionId',
              },
            ],
            concreteType: 'SuperposherClosetSection',
            kind: 'LinkedField',
            name: 'closetSection',
            plural: false,
            selections: [
              v4 /*: any*/,
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'listingCount',
                storageKey: null,
              },
              {
                alias: null,
                args: [
                  {
                    kind: 'Variable',
                    name: 'cursor',
                    variableName: 'listingsCursor',
                  },
                  {
                    kind: 'Variable',
                    name: 'limit',
                    variableName: 'listingsLimit',
                  },
                ],
                concreteType: 'SuperposherClosetSectionListingsPage',
                kind: 'LinkedField',
                name: 'listings',
                plural: false,
                selections: [
                  {
                    alias: null,
                    args: null,
                    concreteType: 'SuperposherClosetSectionListingPageItem',
                    kind: 'LinkedField',
                    name: 'items',
                    plural: true,
                    selections: [
                      v4 /*: any*/,
                      {
                        alias: null,
                        args: null,
                        concreteType: 'SuperposherPoshmarkLookup',
                        kind: 'LinkedField',
                        name: 'entities',
                        plural: true,
                        selections: [
                          {
                            alias: null,
                            args: null,
                            kind: 'ScalarField',
                            name: 'sourceUrl',
                            storageKey: null,
                          },
                        ],
                        storageKey: null,
                      },
                    ],
                    storageKey: null,
                  },
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'cursor',
                    storageKey: null,
                  },
                ],
                storageKey: null,
              },
            ],
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: [v0 /*: any*/, v1 /*: any*/, v2 /*: any*/, v3 /*: any*/],
      kind: 'Fragment',
      metadata: null,
      name: 'closetSectionListingsQuery',
      selections: v5 /*: any*/,
      type: 'Query',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [v3 /*: any*/, v0 /*: any*/, v2 /*: any*/, v1 /*: any*/],
      kind: 'Operation',
      name: 'closetSectionListingsQuery',
      selections: v5 /*: any*/,
    },
    params: {
      cacheID: '2441db2cb88531a0a05e2034423f6f6f',
      id: null,
      metadata: {},
      name: 'closetSectionListingsQuery',
      operationKind: 'query',
      text: 'query closetSectionListingsQuery(\n  $poshmarkUserLinkId: String!\n  $closetSectionId: String!\n  $listingsLimit: Int\n  $listingsCursor: String\n) {\n  superposherPoshmarkUserLink(id: $poshmarkUserLinkId) {\n    id\n    closetSection(id: $closetSectionId) {\n      id\n      listingCount\n      listings(limit: $listingsLimit, cursor: $listingsCursor) {\n        items {\n          id\n          entities {\n            sourceUrl\n          }\n        }\n        cursor\n      }\n    }\n  }\n}\n',
    },
  };
})();
(node as any).hash = '3d8fde06eb8e023d4e926613071b15fc';
export default node;
