/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type toggleFeatureStatusesMutationVariables = {
  poshmarkUserLinkId: string;
  share?: boolean | null;
  shareNotForSale?: boolean | null;
  shareForShare?: boolean | null;
  followForFollow?: boolean | null;
  followTargeted?: boolean | null;
  offerToLikers?: boolean | null;
  shareRandomly?: boolean | null;
  shareSkipParties?: boolean | null;
  sharePrioritizeParties?: boolean | null;
  autoRelist?: boolean | null;
  comparativePrivacy?: boolean | null;
  sharePrioritizePartiesLoop?: boolean | null;
  vacationModeShares?: boolean | null;
  vacationModeFollows?: boolean | null;
  vacationModeOffers?: boolean | null;
  vacationModeRelists?: boolean | null;
  relistOldestFirst?: boolean | null;
};
export type toggleFeatureStatusesMutationResponse = {
  readonly superposherToggleFeatureStatuses: {
    readonly success: boolean;
  };
};
export type toggleFeatureStatusesMutation = {
  readonly response: toggleFeatureStatusesMutationResponse;
  readonly variables: toggleFeatureStatusesMutationVariables;
};

/*
mutation toggleFeatureStatusesMutation(
  $poshmarkUserLinkId: String!
  $share: Boolean
  $shareNotForSale: Boolean
  $shareForShare: Boolean
  $followForFollow: Boolean
  $followTargeted: Boolean
  $offerToLikers: Boolean
  $shareRandomly: Boolean
  $shareSkipParties: Boolean
  $sharePrioritizeParties: Boolean
  $autoRelist: Boolean
  $comparativePrivacy: Boolean
  $sharePrioritizePartiesLoop: Boolean
  $vacationModeShares: Boolean
  $vacationModeFollows: Boolean
  $vacationModeOffers: Boolean
  $vacationModeRelists: Boolean
  $relistOldestFirst: Boolean
) {
  superposherToggleFeatureStatuses(poshmarkUserLinkId: $poshmarkUserLinkId, share: $share, shareNotForSale: $shareNotForSale, shareForShare: $shareForShare, followForFollow: $followForFollow, followTargeted: $followTargeted, offerToLikers: $offerToLikers, shareRandomly: $shareRandomly, shareSkipParties: $shareSkipParties, sharePrioritizeParties: $sharePrioritizeParties, autoRelist: $autoRelist, comparativePrivacy: $comparativePrivacy, sharePrioritizePartiesLoop: $sharePrioritizePartiesLoop, vacationModeShares: $vacationModeShares, vacationModeFollows: $vacationModeFollows, vacationModeOffers: $vacationModeOffers, vacationModeRelists: $vacationModeRelists, relistOldestFirst: $relistOldestFirst) {
    success
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'autoRelist',
    },
    v1 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'comparativePrivacy',
    },
    v2 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'followForFollow',
    },
    v3 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'followTargeted',
    },
    v4 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'offerToLikers',
    },
    v5 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'poshmarkUserLinkId',
    },
    v6 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'relistOldestFirst',
    },
    v7 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'share',
    },
    v8 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'shareForShare',
    },
    v9 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'shareNotForSale',
    },
    v10 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'sharePrioritizeParties',
    },
    v11 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'sharePrioritizePartiesLoop',
    },
    v12 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'shareRandomly',
    },
    v13 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'shareSkipParties',
    },
    v14 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'vacationModeFollows',
    },
    v15 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'vacationModeOffers',
    },
    v16 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'vacationModeRelists',
    },
    v17 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'vacationModeShares',
    },
    v18 = [
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'autoRelist',
            variableName: 'autoRelist',
          },
          {
            kind: 'Variable',
            name: 'comparativePrivacy',
            variableName: 'comparativePrivacy',
          },
          {
            kind: 'Variable',
            name: 'followForFollow',
            variableName: 'followForFollow',
          },
          {
            kind: 'Variable',
            name: 'followTargeted',
            variableName: 'followTargeted',
          },
          {
            kind: 'Variable',
            name: 'offerToLikers',
            variableName: 'offerToLikers',
          },
          {
            kind: 'Variable',
            name: 'poshmarkUserLinkId',
            variableName: 'poshmarkUserLinkId',
          },
          {
            kind: 'Variable',
            name: 'relistOldestFirst',
            variableName: 'relistOldestFirst',
          },
          {
            kind: 'Variable',
            name: 'share',
            variableName: 'share',
          },
          {
            kind: 'Variable',
            name: 'shareForShare',
            variableName: 'shareForShare',
          },
          {
            kind: 'Variable',
            name: 'shareNotForSale',
            variableName: 'shareNotForSale',
          },
          {
            kind: 'Variable',
            name: 'sharePrioritizeParties',
            variableName: 'sharePrioritizeParties',
          },
          {
            kind: 'Variable',
            name: 'sharePrioritizePartiesLoop',
            variableName: 'sharePrioritizePartiesLoop',
          },
          {
            kind: 'Variable',
            name: 'shareRandomly',
            variableName: 'shareRandomly',
          },
          {
            kind: 'Variable',
            name: 'shareSkipParties',
            variableName: 'shareSkipParties',
          },
          {
            kind: 'Variable',
            name: 'vacationModeFollows',
            variableName: 'vacationModeFollows',
          },
          {
            kind: 'Variable',
            name: 'vacationModeOffers',
            variableName: 'vacationModeOffers',
          },
          {
            kind: 'Variable',
            name: 'vacationModeRelists',
            variableName: 'vacationModeRelists',
          },
          {
            kind: 'Variable',
            name: 'vacationModeShares',
            variableName: 'vacationModeShares',
          },
        ],
        concreteType: 'SuperposherToggleFeatureStatusesResponse',
        kind: 'LinkedField',
        name: 'superposherToggleFeatureStatuses',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'success',
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: [
        v0 /*: any*/,
        v1 /*: any*/,
        v2 /*: any*/,
        v3 /*: any*/,
        v4 /*: any*/,
        v5 /*: any*/,
        v6 /*: any*/,
        v7 /*: any*/,
        v8 /*: any*/,
        v9 /*: any*/,
        v10 /*: any*/,
        v11 /*: any*/,
        v12 /*: any*/,
        v13 /*: any*/,
        v14 /*: any*/,
        v15 /*: any*/,
        v16 /*: any*/,
        v17 /*: any*/,
      ],
      kind: 'Fragment',
      metadata: null,
      name: 'toggleFeatureStatusesMutation',
      selections: v18 /*: any*/,
      type: 'Mutation',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [
        v5 /*: any*/,
        v7 /*: any*/,
        v9 /*: any*/,
        v8 /*: any*/,
        v2 /*: any*/,
        v3 /*: any*/,
        v4 /*: any*/,
        v12 /*: any*/,
        v13 /*: any*/,
        v10 /*: any*/,
        v0 /*: any*/,
        v1 /*: any*/,
        v11 /*: any*/,
        v17 /*: any*/,
        v14 /*: any*/,
        v15 /*: any*/,
        v16 /*: any*/,
        v6 /*: any*/,
      ],
      kind: 'Operation',
      name: 'toggleFeatureStatusesMutation',
      selections: v18 /*: any*/,
    },
    params: {
      cacheID: 'd12de9aeffc05046ece8ea53f58ad604',
      id: null,
      metadata: {},
      name: 'toggleFeatureStatusesMutation',
      operationKind: 'mutation',
      text: 'mutation toggleFeatureStatusesMutation(\n  $poshmarkUserLinkId: String!\n  $share: Boolean\n  $shareNotForSale: Boolean\n  $shareForShare: Boolean\n  $followForFollow: Boolean\n  $followTargeted: Boolean\n  $offerToLikers: Boolean\n  $shareRandomly: Boolean\n  $shareSkipParties: Boolean\n  $sharePrioritizeParties: Boolean\n  $autoRelist: Boolean\n  $comparativePrivacy: Boolean\n  $sharePrioritizePartiesLoop: Boolean\n  $vacationModeShares: Boolean\n  $vacationModeFollows: Boolean\n  $vacationModeOffers: Boolean\n  $vacationModeRelists: Boolean\n  $relistOldestFirst: Boolean\n) {\n  superposherToggleFeatureStatuses(poshmarkUserLinkId: $poshmarkUserLinkId, share: $share, shareNotForSale: $shareNotForSale, shareForShare: $shareForShare, followForFollow: $followForFollow, followTargeted: $followTargeted, offerToLikers: $offerToLikers, shareRandomly: $shareRandomly, shareSkipParties: $shareSkipParties, sharePrioritizeParties: $sharePrioritizeParties, autoRelist: $autoRelist, comparativePrivacy: $comparativePrivacy, sharePrioritizePartiesLoop: $sharePrioritizePartiesLoop, vacationModeShares: $vacationModeShares, vacationModeFollows: $vacationModeFollows, vacationModeOffers: $vacationModeOffers, vacationModeRelists: $vacationModeRelists, relistOldestFirst: $relistOldestFirst) {\n    success\n  }\n}\n',
    },
  };
})();
(node as any).hash = 'b697252154b18e34e582ff7775c1739d';
export default node;
