import * as Observe from 'src/state/lib/observe';
import * as UtilTypes from 'src/state/lib/types';
import * as InventoryMetadata from 'src/state/observe/InventoryMetadata';
import { inventoryMetadataQueryResponse } from 'src/clients/Network/gql/build/inventoryMetadataQuery.graphql';

export type ValueDataType = UtilTypes.FlattenReadonly<
  UtilTypes.FlattenReadonly<inventoryMetadataQueryResponse['superposherPoshmarkUserLinks']>['closetSections']
>;

export const SelectedIdValue = Observe.Value<null | string>(null);

export const SelectedValue = Observe.DerivedValue(() => {
  const selectedId = SelectedIdValue.get();
  if (selectedId != null) {
    const userLinks = InventoryMetadata.SelectedValue.get()?.closetSections ?? [];
    return userLinks.find((_) => _.id === selectedId);
  } else {
    return null;
  }
}, [InventoryMetadata.SelectedValue, SelectedIdValue]);

export const SelectedFallback = Observe.WithFallback(SelectedValue);
