import * as StateTypes from 'src/state/types';
import * as Observe from 'src/util/observe';

export const PoshmarkBotOfferConfigurationsValue = Observe.Value<null | StateTypes.PoshmarkBotOfferConfigurationType[]>(
  null
);
export const PoshmarkBotOfferConfigurationsFallback = Observe.React.WithFallback(PoshmarkBotOfferConfigurationsValue);

export const OfferConfigurationListingsValue =
  Observe.LookupStoreValue<StateTypes.PoshmarkBotOfferConfigurationListingType>();
export const OfferConfigurationListingsPaginationState =
  Observe.React.WithPaginationState<StateTypes.PoshmarkBotOfferConfigurationListingType>({
    idSelector: (_) => _.listingId,
    lookupStoreValue: OfferConfigurationListingsValue,
  });

export const PoshmarkBotListingsValue = Observe.LookupStoreValue<StateTypes.PoshmarkBotListingType>();
export const PoshmarkBotRecentRelistActivityEventsValue = Observe.Value<
  StateTypes.PoshmarkBotRecentRelistActivityEventType[]
>([]);
export const PoshmarkBotRelistScheduleEventsValue = Observe.Value<StateTypes.PoshmarkBotRelistScheduleEventType[]>([]);

export const ClosetOrderingListingsValue = Observe.LookupStoreValue<StateTypes.PoshmarkBotClosetOrderingListingType>();
export const ClosetOrderingListingsPaginationState =
  Observe.React.WithPaginationState<StateTypes.PoshmarkBotClosetOrderingListingType>({
    idSelector: (_) => _.listingId,
    lookupStoreValue: ClosetOrderingListingsValue,
  });

export const ClosetOrderingSectionsValue = Observe.LookupStoreValue<StateTypes.PoshmarkBotClosetOrderingSectionType>();
export const ClosetOrderingSectionsPaginationState =
  Observe.React.WithPaginationState<StateTypes.PoshmarkBotClosetOrderingSectionType>({
    idSelector: (_) => _.id as string,
    lookupStoreValue: ClosetOrderingSectionsValue,
  });

export enum OfferToLikersEditMode {
  REASSIGN,
  REMOVE,
}
export const OfferToLikersEditModeValue = Observe.Value<OfferToLikersEditMode | null>(null);
export const OfferToLikersEditSelectedIdsValue = Observe.Value<string[]>([]);
export const OfferToLikersInvalidateConfigListings = Observe.Value<(string | null)[]>([]);

export const OfferToLikersAllListingsPaginationState =
  Observe.React.WithPaginationState<StateTypes.PoshmarkBotOfferConfigurationListingType>({
    idSelector: (_) => _.listingId,
    lookupStoreValue: OfferConfigurationListingsValue,
  });
export const OfferToLikersEvidenceLogsValue =
  Observe.LookupStoreValue<StateTypes.PoshmarkBotOfferToLikersEvidenceLogsType>();
export const OfferToLikersEvidenceLogsPaginationState =
  Observe.React.WithPaginationState<StateTypes.PoshmarkBotOfferToLikersEvidenceLogsType>({
    idSelector: (_) => _.listingId,
    lookupStoreValue: OfferToLikersEvidenceLogsValue,
  });

export const OfferToLikersConfigId = Observe.Value<string | undefined>(undefined);
