import { graphql } from 'react-relay';

export const gqlNextUncategorizedListing = graphql`
  query nextUncategorizedListingQuery($poshmarkUserLinkId: String!) {
    superposherPoshmarkUserLink(id: $poshmarkUserLinkId) {
      id
      nextUncategorizedListing {
        id
        entities {
          sourceUrl
        }
      }
    }
  }
`;
