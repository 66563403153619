/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type trackAppOpenMutationVariables = {};
export type trackAppOpenMutationResponse = {
  readonly superposherTrackAppOpen: boolean;
};
export type trackAppOpenMutation = {
  readonly response: trackAppOpenMutationResponse;
  readonly variables: trackAppOpenMutationVariables;
};

/*
mutation trackAppOpenMutation {
  superposherTrackAppOpen
}
*/

const node: ConcreteRequest = (function () {
  var v0 = [
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'superposherTrackAppOpen',
      storageKey: null,
    },
  ];
  return {
    fragment: {
      argumentDefinitions: [],
      kind: 'Fragment',
      metadata: null,
      name: 'trackAppOpenMutation',
      selections: v0 /*: any*/,
      type: 'Mutation',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [],
      kind: 'Operation',
      name: 'trackAppOpenMutation',
      selections: v0 /*: any*/,
    },
    params: {
      cacheID: '739433bf5f5c664b316491fe2d42a3af',
      id: null,
      metadata: {},
      name: 'trackAppOpenMutation',
      operationKind: 'mutation',
      text: 'mutation trackAppOpenMutation {\n  superposherTrackAppOpen\n}\n',
    },
  };
})();
(node as any).hash = 'd899024bafddf6a6279df4d651951a57';
export default node;
