/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type startBillingPortalSessionMutationVariables = {};
export type startBillingPortalSessionMutationResponse = {
  readonly superposherStartMobileBillingPortalSession: {
    readonly url: string;
  };
};
export type startBillingPortalSessionMutation = {
  readonly response: startBillingPortalSessionMutationResponse;
  readonly variables: startBillingPortalSessionMutationVariables;
};

/*
mutation startBillingPortalSessionMutation {
  superposherStartMobileBillingPortalSession {
    url
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = [
    {
      alias: null,
      args: null,
      concreteType: 'SuperposherStartBillingPortalSessionResponse',
      kind: 'LinkedField',
      name: 'superposherStartMobileBillingPortalSession',
      plural: false,
      selections: [
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'url',
          storageKey: null,
        },
      ],
      storageKey: null,
    },
  ];
  return {
    fragment: {
      argumentDefinitions: [],
      kind: 'Fragment',
      metadata: null,
      name: 'startBillingPortalSessionMutation',
      selections: v0 /*: any*/,
      type: 'Mutation',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [],
      kind: 'Operation',
      name: 'startBillingPortalSessionMutation',
      selections: v0 /*: any*/,
    },
    params: {
      cacheID: '6898764ca8c723395fb2db8df671ca3d',
      id: null,
      metadata: {},
      name: 'startBillingPortalSessionMutation',
      operationKind: 'mutation',
      text: 'mutation startBillingPortalSessionMutation {\n  superposherStartMobileBillingPortalSession {\n    url\n  }\n}\n',
    },
  };
})();
(node as any).hash = '0f0e2287a916be00e775fd3a82d95755';
export default node;
