import { ValidationErrorId } from 'src/constants/ValidationError';
import { Types } from 'src/state';
import * as Observe from 'src/state/lib/observe';
import * as StateTypes from 'src/state/types';

export interface SizeIface {
  id: string;
  display: string;
  size_system: string;
}

export interface SizeArgs {
  id: string;
  size_system: string;
}

export interface EbayListingAttributesIface {
  values: string[];
}

export enum AdditionalFieldsNames {
  Brand = 'brand',
}

export const Form = {
  TitleValue: Observe.Value<string | null>(null),
  DescriptionValue: Observe.Value<string | null>(null),
  ConditionValue: Observe.Value<string | null>(null),
  CategoryValue: Observe.Value<string | null>(null),
  AttributesValue: Observe.Value<string | null>(null),
  UpcValue: Observe.Value<string | null>(null),
  IsbnValue: Observe.Value<string | null>(null),
  SizeValue: Observe.Value<SizeArgs | null>(null),
  PriceValue: Observe.Value<number | null>(null),
  ShoeSizeValue: Observe.Value<string | null>(null),
  ShippingPackageWeightValue: Observe.Value<number | null>(null),
  ShippingPackageWeightUnit: Observe.Value<Types.InvsysWeightUnitType | null>(null),
  ShippingPolicyIdValue: Observe.Value<string | null>(null),
  ShippingPrimaryServicePaymentTypeValue: Observe.Value<string | null>(null),
  DomesticShippingService1Value: Observe.Value<string | null>(null),
  DomesticShippingPrice1Value: Observe.Value<number | null>(null),
  ShippingIntlPrimaryServicePaymentTypeValue: Observe.Value<string | null>(null),
  IntlShippingService1Value: Observe.Value<string | null>(null),
  IntlShippingPrice1Value: Observe.Value<number | null>(null),
  OfferGlobalShippingValue: Observe.Value<boolean>(false),
  OfferLocalPickupValue: Observe.Value<boolean>(false),
  LocalPickupPriceValue: Observe.Value<number | null>(null),
  BrandValue: Observe.Value<string | null>(null),
  SizeTypeValue: Observe.Value<string | null>(null),
};

export const clear = () => {
  Object.keys(Form).map((key) => Form[key as keyof typeof Form].reset());
};

const parseNumberAttribute = (attribute: string) => (isNaN(parseInt(attribute)) ? null : parseInt(attribute));
export const setWithListing = (listing: Types.EbayListingType, invsysListing: StateTypes.ListingType) => {
  Form.TitleValue.set(listing.title);
  Form.DescriptionValue.set(listing.description);
  Form.ConditionValue.set(listing.condition);
  Form.CategoryValue.set(listing.categoryId);
  Form.BrandValue.set(listing.attributesBrand[0]);
  Form.SizeTypeValue.set(listing.items[0].attributesSizeType[0]);
  Form.SizeValue.set({
    id: listing.items[0].attributesSize[0],
    size_system: 'us',
  });
  Form.PriceValue.set(listing.price);
  Form.ShoeSizeValue.set(listing.items[0].attributesUsShoeSize[0]);
  Form.ShippingPackageWeightValue.set(invsysListing.items[0].weight);
  Form.ShippingPackageWeightUnit.set(invsysListing.items[0].weightUnit);
  Form.AttributesValue.set(listing.attributes);
  Form.UpcValue.set(listing.upc);
  Form.IsbnValue.set(listing.isbn);
  Form.ShippingPolicyIdValue.set(listing.shippingPolicyId);
  Form.ShippingPrimaryServicePaymentTypeValue.set(listing.shippingPrimaryServicePaymentType);
  Form.DomesticShippingService1Value.set(listing.domesticShippingService1);
  Form.DomesticShippingPrice1Value.set(listing.domesticShippingPrice1);
  Form.ShippingIntlPrimaryServicePaymentTypeValue.set(listing.shippingIntlPrimaryServicePaymentType);
  Form.IntlShippingService1Value.set(listing.intlShippingService1);
  Form.IntlShippingPrice1Value.set(listing.intlShippingPrice1);
  Form.OfferGlobalShippingValue.set(listing.offerGlobalShipping);
  Form.OfferLocalPickupValue.set(listing.offerLocalPickup);
  Form.LocalPickupPriceValue.set(listing.localPickupPrice);
};

export enum FormPart {
  Title,
  Description,
  Category,
  Condition,
  Color,
  FrameColor,
  ExteriorColor,
  Size,
  Height,
  Length,
  Width,
  Brand,
  ShoeSize,
  SizeType,
  ShippingWeight,
  Price,
  Shipping,
  Attributes,
  UPC,
  ISBN,
}

interface FieldIface {
  part: FormPart;
  errors: ValidationErrorId[];
}

export const FormConfig: FieldIface[] = [
  {
    part: FormPart.Title,
    errors: [ValidationErrorId.Ebay_EbayListingTitle],
  },
  {
    part: FormPart.Description,
    errors: [ValidationErrorId.Ebay_EbayListingDescription],
  },
  {
    part: FormPart.Category,
    errors: [ValidationErrorId.Ebay_EbayListingCategoryId],
  },
  {
    part: FormPart.Size,
    errors: [ValidationErrorId.Ebay_EbayListingAttributeSize],
  },
  {
    part: FormPart.Condition,
    errors: [ValidationErrorId.Ebay_EbayListingCondition],
  },
  {
    part: FormPart.Color,
    errors: [ValidationErrorId.Ebay_EbayListingAttributeColor],
  },
  {
    part: FormPart.FrameColor,
    errors: [ValidationErrorId.Ebay_EbayListingAttributeFrameColor],
  },
  {
    part: FormPart.ExteriorColor,
    errors: [ValidationErrorId.Ebay_EbayListingAttributeExteriorColor],
  },
  {
    part: FormPart.Height,
    errors: [ValidationErrorId.Ebay_EbayListingPackageDimensions],
  },
  {
    part: FormPart.Length,
    errors: [ValidationErrorId.Ebay_EbayListingPackageDimensions],
  },
  {
    part: FormPart.Width,
    errors: [ValidationErrorId.Ebay_EbayListingPackageDimensions],
  },
  {
    part: FormPart.ShoeSize,
    errors: [ValidationErrorId.Ebay_EbayListingAttributeUsShoeSize],
  },
  {
    part: FormPart.SizeType,
    errors: [ValidationErrorId.Ebay_EbayListingAttributeSizeType],
  },
  {
    part: FormPart.ShippingWeight,
    errors: [ValidationErrorId.Ebay_EbayListingShippingWeight],
  },
  {
    part: FormPart.Price,
    errors: [ValidationErrorId.Ebay_EbayListingPrice],
  },
  {
    part: FormPart.Shipping,
    errors: [
      ValidationErrorId.Ebay_EbayListingShipping,
      ValidationErrorId.Ebay_EbayListingFlatDomesticShippingCost,
      ValidationErrorId.Ebay_EbayListingFlatIntlShippingCost,
    ],
  },
  {
    part: FormPart.Attributes,
    errors: [ValidationErrorId.Ebay_EbayListingAttributes],
  },
  {
    part: FormPart.UPC,
    errors: [ValidationErrorId.Ebay_EbayListingUpc],
  },
  {
    part: FormPart.ISBN,
    errors: [ValidationErrorId.Ebay_EbayListingIsbn],
  },
];

export const FormParts = Observe.Value<FormPart[]>([]);
export const UnhandledErrorIds = Observe.Value<number[]>([]);
export const SkipFullFormUpdate = Observe.Value<boolean>(false);
