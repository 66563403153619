import { graphql } from 'react-relay';

export const gqlImportInstitutionListingsPage = graphql`
  query invsysImportInstitutionListingsPageQuery($requestId: String!, $cursor: String) {
    invsysImportInstitutionListingsPage(requestId: $requestId, cursor: $cursor) {
      cursor
      items {
        listingId
        title
        thumbnail
        price
        createdAt
      }
    }
  }
`;
