import { graphql } from 'react-relay';

export const gqlManuallyImportInstitutionListing = graphql`
  mutation manuallyImportInstitutionListingsMutation(
    $importRequestId: String!
    $institutionIssuedListingIds: [String!]!
    $selectAll: Boolean!
  ) {
    invsysManuallyImportInstitutionListings(
      importRequestId: $importRequestId
      institutionIssuedListingIds: $institutionIssuedListingIds
      selectAll: $selectAll
    ) {
      success
    }
  }
`;
