import { graphql } from 'react-relay';

export const gqlAppListingCriticalMetadata = graphql`
  query appListingCriticalMetadataQuery($tz: String) {
    invsysListingFeatureSettings {
      currencyCode
      sizeUnit
      weightUnit
      weight
      shippingProviders
      descriptionTemplate
      privateNoteTemplate
      packageSizeType
      packageCustomLength
      packageCustomWidth
      packageCustomHeight
      marketplaces
      poshmarkShippingDiscountId
      mercariShippingType
      mercariShippingPayerId
      depopShippingType
      depopNationalShippingPrice
      depopInternationalShippingPrice
      tradesyShippingType
      tradesyShippingPrice
      grailedShippingType
      grailedUsShippingPrice
      grailedCaShippingPrice
    }
    invsysCrosslistStreakTimePoints(tz: $tz) {
      at
      value
    }
    invsysListingReminderSettings(tz: $tz) {
      tz
      hour
      minute
      sunday
      monday
      tuesday
      wednesday
      thursday
      friday
      saturday
    }
    invsysImportInstitutionListingsRequestHistory {
      id
      createdAt
      institution
      status
      feedType
      autoApply
    }
    ebayProfile {
      userId
      username
      profilePictureUrl
      updatedAt
      businessPoliciesEnabled
      shippingPolicyIds
    }
  }
`;
