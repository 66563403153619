import { graphql } from 'react-relay';

export const gqlUncategorizedListings = graphql`
  query uncategorizedListingsQuery($poshmarkUserLinkId: String!, $listingsLimit: Int, $listingsCursor: String) {
    superposherPoshmarkUserLink(id: $poshmarkUserLinkId) {
      id
      uncategorizedListings(limit: $listingsLimit, cursor: $listingsCursor) {
        items {
          id
          entities {
            sourceUrl
          }
        }
        cursor
      }
    }
  }
`;
