/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type abortAwsMultipartUploadMutationVariables = {
  uploadId: string;
  uploadKey: string;
};
export type abortAwsMultipartUploadMutationResponse = {
  readonly invsysAbortAwsMultipartUpload: {
    readonly success: boolean;
  };
};
export type abortAwsMultipartUploadMutation = {
  readonly response: abortAwsMultipartUploadMutationResponse;
  readonly variables: abortAwsMultipartUploadMutationVariables;
};

/*
mutation abortAwsMultipartUploadMutation(
  $uploadId: String!
  $uploadKey: String!
) {
  invsysAbortAwsMultipartUpload(uploadId: $uploadId, uploadKey: $uploadKey) {
    success
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'uploadId',
      },
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'uploadKey',
      },
    ],
    v1 = [
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'uploadId',
            variableName: 'uploadId',
          },
          {
            kind: 'Variable',
            name: 'uploadKey',
            variableName: 'uploadKey',
          },
        ],
        concreteType: 'InvsysAbortAwsMultipartUploadResponse',
        kind: 'LinkedField',
        name: 'invsysAbortAwsMultipartUpload',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'success',
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'abortAwsMultipartUploadMutation',
      selections: v1 /*: any*/,
      type: 'Mutation',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'abortAwsMultipartUploadMutation',
      selections: v1 /*: any*/,
    },
    params: {
      cacheID: '724a9f588064aed221e56029869e0082',
      id: null,
      metadata: {},
      name: 'abortAwsMultipartUploadMutation',
      operationKind: 'mutation',
      text: 'mutation abortAwsMultipartUploadMutation(\n  $uploadId: String!\n  $uploadKey: String!\n) {\n  invsysAbortAwsMultipartUpload(uploadId: $uploadId, uploadKey: $uploadKey) {\n    success\n  }\n}\n',
    },
  };
})();
(node as any).hash = '82cba2caa87a51d77283235a9fee8d01';
export default node;
