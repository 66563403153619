import { graphql } from 'react-relay';

export const gqlOrders = graphql`
  query ordersQuery($cursor: String, $limit: Int) {
    invsysOrders(cursor: $cursor, limit: $limit) {
      cursor
      items {
        ...orderFragment_full
      }
    }
  }
`;
