import { graphql } from 'react-relay';

export const gqlUpdateOfferToLikersShippingDiscountType = graphql`
  mutation updateOfferToLikersShippingDiscountTypeMutation(
    $poshmarkUserLinkId: String!
    $type: SuperposherShippingDiscountType!
  ) {
    superposherUpdateOfferToLikersShippingDiscountType(poshmarkUserLinkId: $poshmarkUserLinkId, type: $type) {
      success
    }
  }
`;
