import { graphql } from 'react-relay';

export const gqlStudioLatestListingMediaGroups = graphql`
  query studioLatestListingMediaGroupsQuery($cursor: String, $limit: Int) {
    studioLatestListingMediaGroups(cursor: $cursor, limit: $limit) {
      cursor
      items {
        ...studioListingMediaGroupFragment_full
      }
    }
  }
`;
