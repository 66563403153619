import { ValidationErrorId } from 'src/constants/ValidationError';
import { Types } from 'src/state';
import * as Observe from 'src/state/lib/observe';
import * as StateTypes from 'src/state/types';

export interface SizeIface {
  id: string;
  display: string;
  size_system: string;
}

export interface SizeArgs {
  id: string;
  size_system: string;
}

export enum AdditionalFieldsNames {
  Brand = 'brand',
}

export const Form = {
  TitleValue: Observe.Value<string | null>(null),
  DescriptionValue: Observe.Value<string | null>(null),
  ConditionValue: Observe.Value<string | null>(null),
  CategoryValue: Observe.Value<string | null>(null),
  HeightValue: Observe.Value<number | null>(null),
  LengthValue: Observe.Value<number | null>(null),
  WidthValue: Observe.Value<number | null>(null),
  TypeValue: Observe.Value<string | null>(null),
  ColorsValue: Observe.Value<string[]>([]),
  MaterialValue: Observe.Value<string | null>(null),
  ItemsInSetValue: Observe.Value<string | null>(null),
  BrandValue: Observe.Value<string | null>(null),
  SetIncludesValue: Observe.Value<string | null>(null),
  BandMaterialValue: Observe.Value<string | null>(null),
  StyleValue: Observe.Value<string | null>(null),
  DepartmentValue: Observe.Value<string | null>(null),
  DressLengthValue: Observe.Value<string | null>(null),
  OuterShellMaterialValue: Observe.Value<string | null>(null),
  FrameColorValue: Observe.Value<string[]>([]),
  UpperMaterialValue: Observe.Value<string | null>(null),
  SizeTypeValue: Observe.Value<string | null>(null),
  InseamValue: Observe.Value<number | null>(null),
  SleeveLengthValue: Observe.Value<string | null>(null),
  DesignerValue: Observe.Value<string | null>(null),
  ExteriorMaterialValue: Observe.Value<string | null>(null),
  ExteriorColorValue: Observe.Value<string[]>([]),
  SkirtLengthValue: Observe.Value<string | null>(null),
  MainStoneValue: Observe.Value<string | null>(null),
  MetalPurityValue: Observe.Value<string | null>(null),
  SizeValue: Observe.Value<SizeArgs | null>(null),
  PriceValue: Observe.Value<number | null>(null),
  ShoeSizeValue: Observe.Value<string | null>(null),
  ShippingPackageWeightValue: Observe.Value<number | null>(null),
  ShippingPackageWeightUnit: Observe.Value<Types.InvsysWeightUnitType | null>(null),
  BeadSizeValue: Observe.Value<string | null>(null),
  RingSizeValue: Observe.Value<string | null>(null),
  MetalValue: Observe.Value<string | null>(null),
};

export const clear = () => {
  Object.keys(Form).map((key) => Form[key as keyof typeof Form].reset());
};

const parseNumberAttribute = (attribute: string) => (isNaN(parseInt(attribute)) ? null : parseInt(attribute));
export const setWithListing = (listing: Types.EbayListingType, invsysListing: StateTypes.ListingType) => {
  Form.TitleValue.set(listing.title);
  Form.DescriptionValue.set(listing.description);
  Form.ConditionValue.set(listing.condition);
  Form.CategoryValue.set(listing.categoryId);
  Form.HeightValue.set(parseNumberAttribute(listing.attributesItemHeight[0]));
  Form.LengthValue.set(parseNumberAttribute(listing.attributesItemLength[0]));
  Form.WidthValue.set(parseNumberAttribute(listing.attributesItemWidth[0]));
  Form.TypeValue.set(listing.attributesType[0]);
  Form.ColorsValue.set([...listing.attributesColor]);
  Form.MaterialValue.set(listing.attributesMaterial[0]);
  Form.ItemsInSetValue.set(listing.attributesNumberOfItemsInSet[0]);
  Form.BrandValue.set(listing.attributesBrand[0]);
  Form.SetIncludesValue.set(listing.attributesSetIncludes[0]);
  Form.BandMaterialValue.set(listing.attributesBandMaterial[0]);
  Form.StyleValue.set(listing.attributesStyle[0]);
  Form.DepartmentValue.set(listing.attributesDepartment[0]);
  Form.DressLengthValue.set(listing.attributesDressLength[0]);
  Form.OuterShellMaterialValue.set(listing.attributesOuterShellMaterial[0]);
  Form.FrameColorValue.set([...listing.attributesFrameColor]);
  Form.UpperMaterialValue.set(listing.attributesUpperMaterial[0]);
  Form.SizeTypeValue.set(listing.items[0].attributesSizeType[0]);
  Form.InseamValue.set(parseNumberAttribute(listing.attributesInseam[0]));
  Form.SleeveLengthValue.set(listing.attributesSleeveLength[0]);
  Form.DesignerValue.set(listing.attributesDesignerBrand[0]);
  Form.ExteriorMaterialValue.set(listing.attributesExteriorMaterial[0]);
  Form.ExteriorColorValue.set([...listing.attributesExteriorColor]);
  Form.SkirtLengthValue.set(listing.attributesSkirtLength[0]);
  Form.MainStoneValue.set(listing.attributesMainStone[0]);
  Form.MetalPurityValue.set(listing.attributesMetalPurity[0]);
  Form.RingSizeValue.set(listing.attributesRingSize[0]);
  Form.BeadSizeValue.set(listing.attributesBeadSize[0]);
  Form.MetalValue.set(listing.attributesMetal[0]);
  Form.SizeValue.set({
    id: listing.items[0].attributesSize[0],
    size_system: 'us',
  });
  Form.PriceValue.set(listing.price);
  Form.ShoeSizeValue.set(listing.items[0].attributesUsShoeSize[0]);

  Form.ShippingPackageWeightValue.set(invsysListing.items[0].weight);
  Form.ShippingPackageWeightUnit.set(invsysListing.items[0].weightUnit);
};

export enum FormPart {
  Title,
  Description,
  Category,
  Condition,
  Size,
  Height,
  Length,
  Width,
  Type,
  Color,
  Material,
  ItemsInSet,
  Brand,
  SetIncludes,
  BandMaterial,
  Style,
  Department,
  DressLength,
  OuterShellMaterial,
  FrameColor,
  ShoeSize,
  UpperMaterial,
  SizeType,
  Inseam,
  SleeveLength,
  Designer,
  ExteriorMaterial,
  ExteriorColor,
  SkirtLength,
  MainStone,
  MetalPurity,
  Shipping,
  Metal,
  RingSize,
  BeadSize,
  Price,
}

interface FieldIface {
  part: FormPart;
  errors: ValidationErrorId[];
}

export const FormConfig: FieldIface[] = [
  {
    part: FormPart.Title,
    errors: [ValidationErrorId.Ebay_EbayListingTitle],
  },
  {
    part: FormPart.Description,
    errors: [ValidationErrorId.Ebay_EbayListingDescription],
  },
  {
    part: FormPart.Category,
    errors: [ValidationErrorId.Ebay_EbayListingCategoryId],
  },
  {
    part: FormPart.Size,
    errors: [ValidationErrorId.Ebay_EbayListingAttributeSize],
  },
  {
    part: FormPart.Condition,
    errors: [ValidationErrorId.Ebay_EbayListingCondition],
  },
  {
    part: FormPart.Height,
    errors: [ValidationErrorId.Ebay_EbayListingPackageDimensions],
  },
  {
    part: FormPart.Length,
    errors: [ValidationErrorId.Ebay_EbayListingPackageDimensions],
  },
  {
    part: FormPart.Width,
    errors: [ValidationErrorId.Ebay_EbayListingPackageDimensions],
  },
  {
    part: FormPart.Type,
    errors: [ValidationErrorId.Ebay_EbayListingAttributeType],
  },
  {
    part: FormPart.Color,
    errors: [ValidationErrorId.Ebay_EbayListingAttributeColor],
  },
  {
    part: FormPart.Material,
    errors: [ValidationErrorId.Ebay_EbayListingAttributeMaterial],
  },
  {
    part: FormPart.ItemsInSet,
    errors: [ValidationErrorId.Ebay_EbayListingAttributeItemsInSet],
  },
  {
    part: FormPart.Brand,
    errors: [ValidationErrorId.Ebay_EbayListingAttributeBrand],
  },
  {
    part: FormPart.SetIncludes,
    errors: [ValidationErrorId.Ebay_EbayListingAttributeSetIncludes],
  },
  {
    part: FormPart.BandMaterial,
    errors: [ValidationErrorId.Ebay_EbayListingAttributeBandMaterial],
  },
  {
    part: FormPart.Style,
    errors: [ValidationErrorId.Ebay_EbayListingAttributeStyle],
  },
  {
    part: FormPart.Department,
    errors: [ValidationErrorId.Ebay_EbayListingAttributeDepartment],
  },
  {
    part: FormPart.DressLength,
    errors: [ValidationErrorId.Ebay_EbayListingAttributeDressLength],
  },
  {
    part: FormPart.OuterShellMaterial,
    errors: [ValidationErrorId.Ebay_EbayListingAttributeOuterShellMaterial],
  },
  {
    part: FormPart.FrameColor,
    errors: [ValidationErrorId.Ebay_EbayListingAttributeFrameColor],
  },
  {
    part: FormPart.ShoeSize,
    errors: [ValidationErrorId.Ebay_EbayListingAttributeUsShoeSize],
  },
  {
    part: FormPart.UpperMaterial,
    errors: [ValidationErrorId.Ebay_EbayListingAttributeUpperMaterial],
  },
  {
    part: FormPart.SizeType,
    errors: [ValidationErrorId.Ebay_EbayListingAttributeSizeType],
  },
  {
    part: FormPart.Inseam,
    errors: [ValidationErrorId.Ebay_EbayListingAttributeInseam],
  },
  {
    part: FormPart.SleeveLength,
    errors: [ValidationErrorId.Ebay_EbayListingAttributeSleeveLength],
  },
  {
    part: FormPart.Designer,
    errors: [ValidationErrorId.Ebay_EbayListingAttributeDesignerBrand],
  },
  {
    part: FormPart.ExteriorMaterial,
    errors: [ValidationErrorId.Ebay_EbayListingAttributeExteriorMaterial],
  },
  {
    part: FormPart.ExteriorColor,
    errors: [ValidationErrorId.Ebay_EbayListingAttributeExteriorColor],
  },
  {
    part: FormPart.SkirtLength,
    errors: [ValidationErrorId.Ebay_EbayListingAttributeSkirtLength],
  },
  {
    part: FormPart.MainStone,
    errors: [ValidationErrorId.Ebay_EbayListingAttributeMainStone],
  },
  {
    part: FormPart.MetalPurity,
    errors: [ValidationErrorId.Ebay_EbayListingAttributeMetalPurity],
  },
  {
    part: FormPart.Shipping,
    errors: [ValidationErrorId.Ebay_EbayListingShippingWeight],
  },
  {
    part: FormPart.Metal,
    errors: [ValidationErrorId.Ebay_EbayListingAttributeMetal],
  },
  {
    part: FormPart.RingSize,
    errors: [ValidationErrorId.Ebay_EbayListingAttributeRingSize],
  },
  {
    part: FormPart.BeadSize,
    errors: [ValidationErrorId.Ebay_EbayListingAttributeBeadSize],
  },
  {
    part: FormPart.Price,
    errors: [ValidationErrorId.Ebay_EbayListingPrice],
  },
];

export const FormParts = Observe.Value<FormPart[]>([]);
export const UnhandledErrorIds = Observe.Value<number[]>([]);
