import { graphql } from 'react-relay';

export const gqlToggleFeatureStatuses = graphql`
  mutation toggleFeatureStatusesMutation(
    $poshmarkUserLinkId: String!
    $share: Boolean
    $shareNotForSale: Boolean
    $shareForShare: Boolean
    $followForFollow: Boolean
    $followTargeted: Boolean
    $offerToLikers: Boolean
    $shareRandomly: Boolean
    $shareSkipParties: Boolean
    $sharePrioritizeParties: Boolean
    $autoRelist: Boolean
    $comparativePrivacy: Boolean
    $sharePrioritizePartiesLoop: Boolean
    $vacationModeShares: Boolean
    $vacationModeFollows: Boolean
    $vacationModeOffers: Boolean
    $vacationModeRelists: Boolean
    $relistOldestFirst: Boolean
  ) {
    superposherToggleFeatureStatuses(
      poshmarkUserLinkId: $poshmarkUserLinkId
      share: $share
      shareNotForSale: $shareNotForSale
      shareForShare: $shareForShare
      followForFollow: $followForFollow
      followTargeted: $followTargeted
      offerToLikers: $offerToLikers
      shareRandomly: $shareRandomly
      shareSkipParties: $shareSkipParties
      sharePrioritizeParties: $sharePrioritizeParties
      autoRelist: $autoRelist
      comparativePrivacy: $comparativePrivacy
      sharePrioritizePartiesLoop: $sharePrioritizePartiesLoop
      vacationModeShares: $vacationModeShares
      vacationModeFollows: $vacationModeFollows
      vacationModeOffers: $vacationModeOffers
      vacationModeRelists: $vacationModeRelists
      relistOldestFirst: $relistOldestFirst
    ) {
      success
    }
  }
`;
