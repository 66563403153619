import { graphql } from 'react-relay';

export const gqlEditPoshmarkItem = graphql`
  mutation editPoshmarkItemMutation(
    $itemId: String!
    $sizeSystem: PoshmarkStringPutObject
    $sizeVal: PoshmarkStringPutObject
  ) {
    poshmarkEditPoshmarkItem(itemId: $itemId, sizeSystem: $sizeSystem, sizeVal: $sizeVal) {
      success
    }
  }
`;
