import * as Network from 'src/clients/Network';
import * as UtilTypes from 'src/state/lib/types';

export type BusinessManagerType = NonNullable<
  Network.gql.build.appCriticalMetadataQuery.appCriticalMetadataQueryResponse['invsysBusinessManager']
>;

export type ListingReminderSettingsType = NonNullable<
  Network.gql.build.appListingCriticalMetadataQuery.appListingCriticalMetadataQueryResponse['invsysListingReminderSettings']
>;

export type StreakTimePointType =
  Network.gql.build.appListingCriticalMetadataQuery.appListingCriticalMetadataQueryResponse['invsysCrosslistStreakTimePoints'][0];

export type ListingFeatureSettingsType = NonNullable<
  Network.gql.build.appListingCriticalMetadataQuery.appListingCriticalMetadataQueryResponse['invsysListingFeatureSettings']
>;

export type ImportInstitutionListingsRequestHistoryType = NonNullable<
  Network.gql.build.appListingCriticalMetadataQuery.appListingCriticalMetadataQueryResponse['invsysImportInstitutionListingsRequestHistory'][0]
>;
export type ImportInstitutionListingsType = NonNullable<
  Network.gql.build.invsysImportInstitutionListingsPageQuery.invsysImportInstitutionListingsPageQueryResponse['invsysImportInstitutionListingsPage']
>['items'][0];

export enum InstitutionEnum {
  Poshmark = 0,
  Mercari = 1,
  Depop = 2,
  Tradesy = 3,
  Ebay = 4,
  Grailed = 7,
  Vendoo = 11,
}

export enum ListingSupportedEnum {
  Poshmark = 0,
  Mercari = 1,
  Depop = 2,
  Tradesy = 3,
  Ebay = 4,
  Grailed = 7,
}

export enum MfaChannel {
  Sms = 0,
  Email = 1,
  InApp = 2,
}

export type InstitutionLinkType = UtilTypes.FlattenReadonly<
  Network.gql.build.appCriticalMetadataQuery.appCriticalMetadataQueryResponse['invsysInstitutionLinks']
>;

export type InstitutionLinkRequestType = UtilTypes.FlattenReadonly<
  Network.gql.build.appCriticalMetadataQuery.appCriticalMetadataQueryResponse['invsysInstitutionLinkRequests']
>;

export const InstitutionLinkStatusesPending: NonNullable<InstitutionLinkRequestType['status']>[] = [
  'PENDING',
  'PENDING_MFA_ANSWER',
  'PENDING_MFA_CHANNEL',
  'PENDING_MFA_RESPONSE',
];

export type EbayProfileType = UtilTypes.FlattenReadonly<
  Network.gql.build.appListingCriticalMetadataQuery.appListingCriticalMetadataQueryResponse['ebayProfile']
>;

export type PoshmarkUserLinkKeyType = keyof UtilTypes.FlattenReadonly<
  Network.gql.build.poshmarkUserLinksQuery.poshmarkUserLinksQueryResponse['superposherPoshmarkUserLinks']
>;

export type InvsysColorType = Network.gql.build.listingFragment_full.InvsysColorType;
export type InvsysListingConditionType = Network.gql.build.listingFragment_full.InvsysListingConditionType;
export type InvsysWeightUnitType = Network.gql.build.listingFragment_full.InvsysWeightUnitType;
export type InvsysSizeUnitType = Network.gql.build.listingFragment_full.InvsysSizeUnitType;
export type InvsysShippingProviderType = number;

// Upcoming listing information data types
export type ListingsSummaryType =
  Network.gql.build.listingsSummaryQuery.listingsSummaryQueryResponse['invsysListingsSummary'];
export type ListingsSummaryScheduledListingsType = NonNullable<
  Network.gql.build.listingsSummaryQuery.listingsSummaryQueryResponse['invsysListingsSummary']['scheduledListings']['items']
>;
export type ScheduledListingsSummaryType =
  Network.gql.build.scheduledListingsSummaryQuery.scheduledListingsSummaryQueryResponse['invsysScheduledListingsSummary'];
export type ScheduledListingsSummaryScheduledListingsType = NonNullable<
  Network.gql.build.scheduledListingsSummaryQuery.scheduledListingsSummaryQueryResponse['invsysScheduledListingsSummary']['scheduledListings']['items']
>;
export type ScheduledListingsSummaryScheduledListingType = NonNullable<
  Network.gql.build.scheduledListingsSummaryQuery.scheduledListingsSummaryQueryResponse['invsysScheduledListingsSummary']['scheduledListings']['items'][0]
>;

export type ListingType = Network.gql.build.listingFragment_full.listingFragment_full$data;
export type ListingItemType = UtilTypes.FlattenReadonly<NonNullable<ListingType>['items']>;
export type ListingCurrencyCodeType = Network.gql.build.listingFragment_full.InvsysListingCurrencyCodeType;

export type ListingAttributeSuggestionsType =
  Network.gql.build.invsysListingAttributeSuggestionsQuery.invsysListingAttributeSuggestionsQueryResponse['invsysListingAttributeSuggestions'];

export type ListingRecordsType = NonNullable<
  Network.gql.build.listingRecordsQuery.listingRecordsQueryResponse['invsysListingRecords']
>['items'][number];

export type MercariListingType = NonNullable<
  Network.gql.build.mercariListingQuery.mercariListingQueryResponse['mercariMercariListing']
>;
export type PoshmarkListingType = NonNullable<
  Network.gql.build.poshmarkListingQuery.poshmarkListingQueryResponse['poshmarkPoshmarkListing']
>;
export type TradesyListingType = NonNullable<
  Network.gql.build.tradesyListingQuery.tradesyListingQueryResponse['tradesyTradesyListing']
>;

export type DepopListingType = NonNullable<
  Network.gql.build.depopListingQuery.depopListingQueryResponse['depopDepopListing']
>;
export type EbayListingType = NonNullable<
  Network.gql.build.ebayListingQuery.ebayListingQueryResponse['ebayEbayListing']
>;
export type GrailedListingType = NonNullable<
  Network.gql.build.grailedListingQuery.grailedListingQueryResponse['grailedGrailedListing']
>;

export type ListingSearchFilterSummaryType = NonNullable<
  Network.gql.build.listingsFilterSummaryQuery.listingsFilterSummaryQuery['response']['invsysListingsFilterSummary']
>['filters'][number];

export type MercariValidateListingType = NonNullable<
  Network.gql.build.validateMercariListingQuery.validateMercariListingQueryResponse['mercariValidateMercariListing']
>;
export type PoshmarkValidateListingType = NonNullable<
  Network.gql.build.validatePoshmarkListingQuery.validatePoshmarkListingQueryResponse['poshmarkValidatePoshmarkListing']
>;
export type TradesyValidateListingType = NonNullable<
  Network.gql.build.validateTradesyListingQuery.validateTradesyListingQueryResponse['tradesyValidateTradesyListing']
>;
export type DepopValidateListingType = NonNullable<
  Network.gql.build.validateDepopListingQuery.validateDepopListingQueryResponse['depopValidateDepopListing']
>;
export type EbayValidateListingType = NonNullable<
  Network.gql.build.validateEbayListingQuery.validateEbayListingQueryResponse['ebayValidateEbayListing']
>;
export type GrailedValidateListingType = NonNullable<
  Network.gql.build.validateGrailedListingQuery.validateGrailedListingQueryResponse['grailedValidateGrailedListing']
>;
export type ListingValidateListingType = NonNullable<ListingType['validationResult']>;
export type ListingValidateErrorType = UtilTypes.FlattenReadonly<ListingValidateListingType['errors']>; // deprecated

export type ListingValidationResultType =
  Network.gql.build.listingValidationResultQuery.listingValidationResultQueryResponse['invsysListingValidationResult'];

export type ListingValidationResultErrorType = UtilTypes.FlattenReadonly<ListingValidationResultType['errors']>;

export type ListingStatusType = 'UNKNOWN' | 'UNLISTED' | 'LISTING' | 'UPDATING' | 'LISTED' | '%future added value';

export type TradesyShoeSizeByCountryType =
  Network.gql.build.editTradesyItemMutation.TradesyTradesyShoeSizeByCountryType;

export type TradesyVeilLengthType = Network.gql.build.tradesyListingQuery.TradesyTradesyVeilLengthType;

export type TradesyShoeWidthType = Network.gql.build.tradesyListingQuery.TradesyTradesyShoeWidthType;

export type TradesyHeelHeightType = Network.gql.build.tradesyListingQuery.TradesyTradesyHeelHeightType;
export type TradesyHeelStyleType = Network.gql.build.tradesyListingQuery.TradesyTradesyHeelStyleType;
export type TradesyWashlookType = Network.gql.build.tradesyListingQuery.TradesyTradesyWashlookType;
export type TradesyLengthType = Network.gql.build.tradesyListingQuery.TradesyTradesyLengthType;

export type TopRankingRankType = UtilTypes.FlattenReadonly<
  Network.gql.build.topRankingQuery.topRankingQueryResponse['invsysTopRanking']
>;
export type TopRankingFrequencyType = Network.gql.build.topRankingQuery.topRankingQueryVariables['frequency'];

export type EdgeType = {
  source: string;
  target: string;
};

export type GraphType<T> = {
  [id: string]: Array<{ id: EdgeType['target']; value: T | null }>;
};

export enum GraphIds {
  Following,
  Follower,
  Mute,
  Block,
}

export enum SocialPostReactionType {
  Fire = 0,
  Heart = 1,
  Wow = 2,
  Haha = 3,
  Sad = 4,
}

export enum SocialProfileLabelType {
  TestAccount = 0,
  CompanyAdmin = 1,
  CompanyModerator = 2,
  CommunityModerator = 3,
}

export enum ProfileRelationshipType {
  Block = 0,
  Mute = 1,
  Follow = 2,
}

export enum PostTopic {
  OneShop = 0,
  Poshmark = 1,
  Mercari = 2,
  Celebrate = 3,
  Vent = 4,
  MightBeHelpful = 5,
  NeedAdvice = 6,
  PersonalStory = 7,
  Tradesy = 8,
}

export enum SocialPostViewAccessStrategy {
  None = 0,
  All = 1,
  Deleted = 2,
}

export enum SocialPostModerationReportRule {
  Harassment = 0,
  Spam = 1,
  Illegal = 2,
  AppSupport = 3,
}

export enum NotificationTemplateId {
  ImportListingsStart = 0,
  ImportListingsFinish = 1,
  ImportListingsFailed = 2,
  SocialPostReceivedReply = 3,
  SocialPostReceivedReaction = 4,
  SocialProfileReceivedFollow = 5,
  SocialPostPinned = 6,
  MarketplaceLinkingLive = 7,
  LinkTerminalFailed = 8,
  GoalMet = 9,
  GoalSummary = 10,
  ListingPostSuccess = 11,
  ListingPostFailure = 12,
  ListingUpdateSuccess = 13,
  ListingUpdateFailure = 14,
}

export enum OrderUnitStatus {
  Reserved = 0,
  Cancelled = 1,
  Delivered = 2,
  Refunded = 3,
  Settled = 4,
  Shipped = 5,
}

export enum BalanceUpdateType {
  CreditGrossRevenue = 0,
  DebitInstitutionCut = 1,
  DebitSalesTax = 2,
  DebitVat = 3,
  DebitSellerShipping = 4,
  DebitProcessingFee = 5,
}

/**
 * Notifications
 */
export type NotificationReceiptPageType = UtilTypes.FlattenReadonly<
  Network.gql.build.notificationReceiptsQuery.notificationReceiptsQueryResponse['invsysNotificationReceipts']
>;
export type NotificationReceiptType = NonNullable<NotificationReceiptPageType>['items'][0];

/**
 * Goals
 */
export type SalesFrequencyType =
  Network.gql.build.createSalesGoalMutation.createSalesGoalMutation['variables']['frequency'];
export type SalesGoalType = UtilTypes.FlattenReadonly<
  Network.gql.build.salesGoalsQuery.salesGoalsQuery['response']['invsysSalesGoals']
>;
export type SalesGoalHistoryType = UtilTypes.FlattenReadonly<
  Network.gql.build.salesGoalHistoryQuery.salesGoalHistoryQuery['response']['invsysSalesGoalHistory']
>;

/**
 * Orders
 */

export type OrderType = Network.gql.build.orderFragment_full.orderFragment_full$data;
export type OrderInventoryUnitType = UtilTypes.FlattenReadonly<OrderType['orderInventoryUnits']>;
export type OrderBalanceUpdateType = UtilTypes.FlattenReadonly<OrderType['balanceUpdates']>;
export type OrderMercariListingPreviewType = NonNullable<OrderInventoryUnitType['mercariListingPreview']>;
export type OrderPoshmarkListingPreviewType = NonNullable<OrderInventoryUnitType['poshmarkListingPreview']>;
export type OrderDepopListingPreviewType = NonNullable<OrderInventoryUnitType['depopListingPreview']>;

/**
 * Mercari Bots
 */

export type MercariFeatureStatusesType = NonNullable<
  Network.gql.build.mercariBotCriticalMetadataQuery.mercariBotCriticalMetadataQuery['response']['mercariFeatureStatuses']
>;

export type MercariStatBucketsType = NonNullable<
  Network.gql.build.mercariBotCriticalMetadataQuery.mercariBotCriticalMetadataQuery['response']['mercariStatBuckets']
>;

/**
 * Poshmark Bots
 */

export type PoshmarkBotClosetOrderingListingType = UtilTypes.FlattenReadonly<
  Network.gql.build.poshmarkBotClosetOrderingListingsQuery.poshmarkBotClosetOrderingListingsQueryResponse['poshmarkBotClosetOrderingListings']['items']
>;
export type PoshmarkBotClosetOrderingSectionType = UtilTypes.FlattenReadonly<
  Network.gql.build.poshmarkBotClosetOrderingSectionsQuery.poshmarkBotClosetOrderingSectionsQueryResponse['poshmarkBotClosetOrderingSections']['items']
>;

export type PoshmarkBotOfferConfigurationType =
  Network.gql.build.poshmarkBotOfferConfigurationsQuery.poshmarkBotOfferConfigurationsQueryResponse['poshmarkBotOfferConfigurations'][0];

export type PoshmarkBotOfferConfigurationListingType = NonNullable<
  Network.gql.build.poshmarkBotOfferConfigurationListingsQuery.poshmarkBotOfferConfigurationListingsQueryResponse['poshmarkBotOfferConfigurationListings']
>['items'][0];

export type PoshmarkBotOfferToLikersEvidenceLogsType = NonNullable<
  Network.gql.build.poshmarkBotOfferToLikersEvidenceLogsQuery.poshmarkBotOfferToLikersEvidenceLogsQueryResponse['poshmarkBotOfferToLikersEvidenceLogs']
>['items'][0];

export type PoshmarkBotListingType = NonNullable<
  UtilTypes.FlattenReadonly<
    Network.gql.build.poshmarkBotRecentRelistActivityQuery.poshmarkBotRecentRelistActivityQuery['response']['poshmarkBotRecentRelistActivity']
  >['listing']
>;

export type PoshmarkBotRecentRelistActivityEventType = UtilTypes.FlattenReadonly<
  Network.gql.build.poshmarkBotRecentRelistActivityQuery.poshmarkBotRecentRelistActivityQuery['response']['poshmarkBotRecentRelistActivity']
>;

export type PoshmarkBotRelistScheduleEventType = UtilTypes.FlattenReadonly<
  Network.gql.build.poshmarkBotRelistScheduleQuery.poshmarkBotRelistScheduleQuery['response']['poshmarkBotRelistSchedule']
>;

/**
 * Location
 */

export type ImportStatusType = Network.gql.build.checkInstitutionImportStatusMutation.InvsysImportStatus;

export type AddressType = Network.gql.build.addressFragment_full.addressFragment_full$data;

/**
 * USPS Pickup Location
 */

export enum PickupInstruction {
  Mailbox = 0,
  Porch = 1,
  FrontDoor = 2,
  BackDoor = 3,
  SideDoor = 4,
  KnockOnDoor = 5,
  MailRoom = 6,
  Office = 7,
  Reception = 8,
  Other = 9,
}

export type UspsPickupLocationType =
  Network.gql.build.uspsPickupLocationFragment_full.uspsPickupLocationFragment_full$data;

export type UspsPickupLocationSuggestionType =
  Network.gql.build.uspsPickupLocationSuggestionQuery.uspsPickupLocationSuggestionQuery['response']['invsysUspsPickupLocationSuggestion'];

export type UspsPickupDateOption = UtilTypes.FlattenReadonly<
  Network.gql.build.uspsPickupDateOptionsQuery.uspsPickupDateOptionsQuery['response']['invsysUspsPickupDateOptions']
>;

/**
 * USPS Supplies Order
 */

export enum UspsItem {
  PriorityMailShoeBox = 1,
  PriorityMailExpressLegalFlatRateEnvelope = 2,
  PriorityMailBox7 = 3,
  PriorityMailExpressBox1093 = 4,
  PriorityMailFlatRateSmallBox = 5,
}

export enum UspsItemFormat {
  PriorityMailShoeboxPack10 = '0_SHOEBOX_X',
  PriorityMailShoeboxPack25 = '0_SHOEBOX',
  PriorityMailExpressLegalFlatRateEnvelopePack10 = 'EP13L',
  PriorityMailBox7Pack10 = 'O_BOX7X',
  PriorityMailBox7Pack25 = 'O_BOX7',
  PriorityMailExpressBox1093Pack10 = 'O_1093X',
  PriorityMailExpressBox1093Pack25 = 'O_1093',
  PriorityMailFlatRateSmallBox1to5 = 'OSMALLFRBI',
  PriorityMailFlatRateSmallBoxPack10 = 'O_SMALL_FRBX',
  PriorityMailFlatRateSmallBoxPack25 = 'O_SMALL_FRB',
}

export type UspsFreeSuppliesOrder =
  Network.gql.build.uspsFreeSupplyOrderingLedgerQuery.uspsFreeSupplyOrderingLedgerQuery['response']['invsysUspsFreeSupplyOrderingLedger'];

export type UspsFreeSuppliesOrderItem = UtilTypes.FlattenReadonly<UspsFreeSuppliesOrder['items']>;

export type UspsFreeSuppliesOrderCartItem = UtilTypes.FlattenReadonly<UspsFreeSuppliesOrderItem['cartItems']>;

export type OrderFreeSuppliesInputItemType = UtilTypes.FlattenReadonly<
  Network.gql.build.orderFreeSuppliesMutation.orderFreeSuppliesMutationVariables['items']
>;

/**
 * Shipping label
 */

export type ShippingLabelReelType = NonNullable<
  Network.gql.build.shippingLabelReelQuery.shippingLabelReelQueryResponse['invsysShippingLabelReel']
>;

/**
 * Studio
 */

export enum StudioMediaUploadStatus {
  Initialized = 1,
  Uploaded = 2,
  Failed = 3,
}

export enum StudioListingMediaGroupMembershipVariant {
  Original = 0,
  BackgroundRemoved = 1,
}

export type StudioListingMediaGroupType =
  Network.gql.build.studioListingMediaGroupFragment_full.studioListingMediaGroupFragment_full$data;
export type StudioListingMediaGroupMembershipType = StudioListingMediaGroupType['memberships'][0];
export type StudioMediaType = NonNullable<StudioListingMediaGroupMembershipType['media']>;
export type StudioListingMediaGroupMembershipVariantType = StudioListingMediaGroupMembershipType['variants'][0];
export type StorageMediaType = Network.gql.build.invsysMediaFragment_full.invsysMediaFragment_full$data;

export enum AppPlatform {
  Unknown = 0,
  iOS = 1,
  Android = 2,
  Web = 3,
}

export type StripePaymentMethodType = NonNullable<
  Network.gql.build.invsysStripePaymentMethodByCustomerDefaultSourceQuery.invsysStripePaymentMethodByCustomerDefaultSourceQuery['response']['invsysStripePaymentMethodByCustomerDefaultSource']
>;
