/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type InvsysAnalyticsCompetitionFrequencyType = 'MONTHLY' | 'WEEKLY' | '%future added value';
export type InvsysAnalyticsCompetitionType = 'GROSS_REVENUE' | 'ITEMS_LISTED' | 'NET_REVENUE' | '%future added value';
export type topRankingQueryVariables = {
  institution?: number | null;
  competition: InvsysAnalyticsCompetitionType;
  frequency: InvsysAnalyticsCompetitionFrequencyType;
};
export type topRankingQueryResponse = {
  readonly invsysTopRanking: ReadonlyArray<{
    readonly score: number;
    readonly rank: number;
    readonly imageUrl: string | null;
  }>;
};
export type topRankingQuery = {
  readonly response: topRankingQueryResponse;
  readonly variables: topRankingQueryVariables;
};

/*
query topRankingQuery(
  $institution: Int
  $competition: InvsysAnalyticsCompetitionType!
  $frequency: InvsysAnalyticsCompetitionFrequencyType!
) {
  invsysTopRanking(institution: $institution, competition: $competition, frequency: $frequency) {
    score
    rank
    imageUrl
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'competition',
    },
    v1 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'frequency',
    },
    v2 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'institution',
    },
    v3 = [
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'competition',
            variableName: 'competition',
          },
          {
            kind: 'Variable',
            name: 'frequency',
            variableName: 'frequency',
          },
          {
            kind: 'Variable',
            name: 'institution',
            variableName: 'institution',
          },
        ],
        concreteType: 'InvsysTopRankingResponseType',
        kind: 'LinkedField',
        name: 'invsysTopRanking',
        plural: true,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'score',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'rank',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'imageUrl',
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: [v0 /*: any*/, v1 /*: any*/, v2 /*: any*/],
      kind: 'Fragment',
      metadata: null,
      name: 'topRankingQuery',
      selections: v3 /*: any*/,
      type: 'Query',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [v2 /*: any*/, v0 /*: any*/, v1 /*: any*/],
      kind: 'Operation',
      name: 'topRankingQuery',
      selections: v3 /*: any*/,
    },
    params: {
      cacheID: '7b7310f8a9e7aa4456eb8c561b639725',
      id: null,
      metadata: {},
      name: 'topRankingQuery',
      operationKind: 'query',
      text: 'query topRankingQuery(\n  $institution: Int\n  $competition: InvsysAnalyticsCompetitionType!\n  $frequency: InvsysAnalyticsCompetitionFrequencyType!\n) {\n  invsysTopRanking(institution: $institution, competition: $competition, frequency: $frequency) {\n    score\n    rank\n    imageUrl\n  }\n}\n',
    },
  };
})();
(node as any).hash = '255faa31d5351f391f9782f0436ba03f';
export default node;
