import { graphql } from 'react-relay';

export const gqlPutEbayListing = graphql`
  mutation putEbayListingMutation(
    $listingId: String!
    $categoryId: EbayIntPutObject
    $pictureIds: EbayArray_StringRequired_RequiredPutObject
    $price: EbayIntPutObject
    $condition: EbayStringPutObject
    $title: EbayStringPutObject
    $description: EbayStringPutObject
    $attributes: EbayStringRequiredPutObject
    $upc: EbayStringPutObject
    $isbn: EbayStringPutObject
    $attributesBrand: EbayArray_StringRequired_RequiredPutObject
    $attributesColor: EbayArray_StringRequired_RequiredPutObject
    $attributesStyle: EbayArray_StringRequired_RequiredPutObject
    $attributesItemHeight: EbayArray_StringRequired_RequiredPutObject
    $attributesItemLength: EbayArray_StringRequired_RequiredPutObject
    $attributesItemWidth: EbayArray_StringRequired_RequiredPutObject
    $attributesType: EbayArray_StringRequired_RequiredPutObject
    $attributesMaterial: EbayArray_StringRequired_RequiredPutObject
    $attributesNumberOfItemsInSet: EbayArray_StringRequired_RequiredPutObject
    $attributesSetIncludes: EbayArray_StringRequired_RequiredPutObject
    $attributesBandMaterial: EbayArray_StringRequired_RequiredPutObject
    $attributesDepartment: EbayArray_StringRequired_RequiredPutObject
    $attributesDressLength: EbayArray_StringRequired_RequiredPutObject
    $attributesOuterShellMaterial: EbayArray_StringRequired_RequiredPutObject
    $attributesFrameColor: EbayArray_StringRequired_RequiredPutObject
    $attributesUpperMaterial: EbayArray_StringRequired_RequiredPutObject
    $attributesInseam: EbayArray_StringRequired_RequiredPutObject
    $attributesSleeveLength: EbayArray_StringRequired_RequiredPutObject
    $attributesDesignerBrand: EbayArray_StringRequired_RequiredPutObject
    $attributesExteriorMaterial: EbayArray_StringRequired_RequiredPutObject
    $attributesExteriorColor: EbayArray_StringRequired_RequiredPutObject
    $attributesSkirtLength: EbayArray_StringRequired_RequiredPutObject
    $attributesMainStone: EbayArray_StringRequired_RequiredPutObject
    $attributesMetalPurity: EbayArray_StringRequired_RequiredPutObject
    $attributesMetal: EbayArray_StringRequired_RequiredPutObject
    $attributesBeadSize: EbayArray_StringRequired_RequiredPutObject
    $attributesRingSize: EbayArray_StringRequired_RequiredPutObject
    $shippingPolicyId: EbayStringPutObject
    $shippingPrimaryServicePaymentType: EbayStringPutObject
    $domesticShippingService1: EbayStringPutObject
    $domesticShippingPrice1: EbayIntPutObject
    $shippingIntlPrimaryServicePaymentType: EbayStringPutObject
    $intlShippingService1: EbayStringPutObject
    $intlShippingPrice1: EbayIntPutObject
    $offerGlobalShipping: EbayBooleanRequiredPutObject
    $offerLocalPickup: EbayBooleanRequiredPutObject
    $localPickupPrice: EbayIntPutObject
  ) {
    ebayPutEbayListing(
      listingId: $listingId
      categoryId: $categoryId
      pictureIds: $pictureIds
      price: $price
      condition: $condition
      title: $title
      description: $description
      attributes: $attributes
      upc: $upc
      isbn: $isbn
      attributesBrand: $attributesBrand
      attributesColor: $attributesColor
      attributesStyle: $attributesStyle
      attributesItemHeight: $attributesItemHeight
      attributesItemLength: $attributesItemLength
      attributesItemWidth: $attributesItemWidth
      attributesType: $attributesType
      attributesMaterial: $attributesMaterial
      attributesNumberOfItemsInSet: $attributesNumberOfItemsInSet
      attributesSetIncludes: $attributesSetIncludes
      attributesBandMaterial: $attributesBandMaterial
      attributesDepartment: $attributesDepartment
      attributesDressLength: $attributesDressLength
      attributesOuterShellMaterial: $attributesOuterShellMaterial
      attributesFrameColor: $attributesFrameColor
      attributesUpperMaterial: $attributesUpperMaterial
      attributesInseam: $attributesInseam
      attributesSleeveLength: $attributesSleeveLength
      attributesDesignerBrand: $attributesDesignerBrand
      attributesExteriorMaterial: $attributesExteriorMaterial
      attributesExteriorColor: $attributesExteriorColor
      attributesSkirtLength: $attributesSkirtLength
      attributesMainStone: $attributesMainStone
      attributesMetalPurity: $attributesMetalPurity
      attributesMetal: $attributesMetal
      attributesBeadSize: $attributesBeadSize
      attributesRingSize: $attributesRingSize
      shippingPolicyId: $shippingPolicyId
      shippingPrimaryServicePaymentType: $shippingPrimaryServicePaymentType
      domesticShippingService1: $domesticShippingService1
      domesticShippingPrice1: $domesticShippingPrice1
      shippingIntlPrimaryServicePaymentType: $shippingIntlPrimaryServicePaymentType
      intlShippingService1: $intlShippingService1
      intlShippingPrice1: $intlShippingPrice1
      offerGlobalShipping: $offerGlobalShipping
      offerLocalPickup: $offerLocalPickup
      localPickupPrice: $localPickupPrice
    ) {
      success
    }
  }
`;
