import { graphql } from 'react-relay';

export const gqlClosetSectionListings = graphql`
  query closetSectionListingsQuery(
    $poshmarkUserLinkId: String!
    $closetSectionId: String!
    $listingsLimit: Int
    $listingsCursor: String
  ) {
    superposherPoshmarkUserLink(id: $poshmarkUserLinkId) {
      id
      closetSection(id: $closetSectionId) {
        id
        listingCount
        listings(limit: $listingsLimit, cursor: $listingsCursor) {
          items {
            id
            entities {
              sourceUrl
            }
          }
          cursor
        }
      }
    }
  }
`;
