import * as Observe from 'src/state/lib/observe';
import * as ArrayUtil from 'src/util/array';

interface ListingImagePaddingResponse {
  status: 'failed' | 'pending' | 'success';
  request: {
    mediaId: string;
    listingId: string;
    businessId: string;
  };
}

export interface EntityAsyncValueDataType<E> {
  [id: string]: E[];
}

export const ListingImagePaddingAsyncValue = Observe.AsyncValue<ListingImagePaddingResponse[]>({});

/* (aaron): Converting over to EntityAsyncValueDataType from raw list since this was previous structure and the shape is useful*/
export const ListingImagePaddingValue = Observe.DerivedValue<
  EntityAsyncValueDataType<ListingImagePaddingResponse>
>(() => {
  const values = ListingImagePaddingAsyncValue.getValueWithFallback() ?? [];

  return ArrayUtil.groupBy(values, (_) => _.request.listingId);
}, [ListingImagePaddingAsyncValue.value]);

export const ListingImagePaddingFallback = Observe.WithFallback(ListingImagePaddingValue);

export const isProcessing = (
  listingId: string,
  allProcessing: EntityAsyncValueDataType<ListingImagePaddingResponse>
) => {
  return allProcessing[listingId] != null && allProcessing[listingId].some((_) => _.status === 'pending');
};
