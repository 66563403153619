/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type removeFromStudioListingMediaGroupMutationVariables = {
  membershipIds: Array<string>;
  listingMediaGroupId: string;
};
export type removeFromStudioListingMediaGroupMutationResponse = {
  readonly studioRemoveFromListingMediaGroup: {
    readonly success: boolean;
  };
};
export type removeFromStudioListingMediaGroupMutation = {
  readonly response: removeFromStudioListingMediaGroupMutationResponse;
  readonly variables: removeFromStudioListingMediaGroupMutationVariables;
};

/*
mutation removeFromStudioListingMediaGroupMutation(
  $membershipIds: [String!]!
  $listingMediaGroupId: String!
) {
  studioRemoveFromListingMediaGroup(membershipIds: $membershipIds, listingMediaGroupId: $listingMediaGroupId) {
    success
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'listingMediaGroupId',
    },
    v1 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'membershipIds',
    },
    v2 = [
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'listingMediaGroupId',
            variableName: 'listingMediaGroupId',
          },
          {
            kind: 'Variable',
            name: 'membershipIds',
            variableName: 'membershipIds',
          },
        ],
        concreteType: 'StudioRemoveFromListingMediaGroupResponse',
        kind: 'LinkedField',
        name: 'studioRemoveFromListingMediaGroup',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'success',
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: [v0 /*: any*/, v1 /*: any*/],
      kind: 'Fragment',
      metadata: null,
      name: 'removeFromStudioListingMediaGroupMutation',
      selections: v2 /*: any*/,
      type: 'Mutation',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [v1 /*: any*/, v0 /*: any*/],
      kind: 'Operation',
      name: 'removeFromStudioListingMediaGroupMutation',
      selections: v2 /*: any*/,
    },
    params: {
      cacheID: '45ce17c0c483307cbbe27f79c8a26993',
      id: null,
      metadata: {},
      name: 'removeFromStudioListingMediaGroupMutation',
      operationKind: 'mutation',
      text: 'mutation removeFromStudioListingMediaGroupMutation(\n  $membershipIds: [String!]!\n  $listingMediaGroupId: String!\n) {\n  studioRemoveFromListingMediaGroup(membershipIds: $membershipIds, listingMediaGroupId: $listingMediaGroupId) {\n    success\n  }\n}\n',
    },
  };
})();
(node as any).hash = '1a72fae91bfa2bd8aa2465fca63cad2d';
export default node;
