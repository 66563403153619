/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from 'relay-runtime';
export type listingQueryVariables = {
  id: string;
};
export type listingQueryResponse = {
  readonly invsysListing: {
    readonly ' $fragmentRefs': FragmentRefs<'listingFragment_full'>;
  } | null;
  readonly studioListingMediaGroupByListingId: {
    readonly ' $fragmentRefs': FragmentRefs<'studioListingMediaGroupFragment_full'>;
  } | null;
};
export type listingQuery = {
  readonly response: listingQueryResponse;
  readonly variables: listingQueryVariables;
};

/*
query listingQuery(
  $id: String!
) {
  invsysListing(id: $id) {
    ...listingFragment_full
  }
  studioListingMediaGroupByListingId(listingId: $id) {
    ...studioListingMediaGroupFragment_full
  }
}

fragment listingFragment_full on InvsysSkusListing {
  id
  title
  description
  listingSku
  skus
  tags
  notes
  searchTags
  marketplacesSummary {
    marketplaces
    draftMarketplaces
  }
  price {
    id
    currencyCode
    val
  }
  createdAt
  updatedAt
  items {
    id
    title
    description
    brand
    categoryId
    sizeId
    price {
      currencyCode
      val
    }
    retailPrice {
      currencyCode
      val
    }
    costPrice {
      currencyCode
      val
    }
    size {
      val
      system
    }
    condition
    tags
    skuAlias
    colors
    weight
    height
    width
    length
    weightUnit
    sizeUnit
    packageSizeType
  }
  archived
  targetInstitutions
  validationResult {
    success
    errors {
      errorId: id
      message
      entityName
      fieldName
    }
  }
}

fragment studioListingMediaGroupFragment_full on StudioListingMediaGroup {
  id
  name
  note
  listingId
  createdAt
  updatedAt
  memberships {
    id
    listingMediaGroupId
    mediaId
    media {
      id
      createdAt
      url
      mediaType
      width
      height
      uploadStatus
      originUri
      originPlatformOs
      originPlatformMajorVersion
    }
    preparationStatus
    rank
    variants {
      membershipId
      mediaId
      variantType
      media {
        id
        createdAt
        url
        mediaType
        width
        height
        uploadStatus
        originUri
        originPlatformOs
        originPlatformMajorVersion
      }
    }
  }
  pendingVariantRequests {
    id
    listingMediaGroupId
    createdAt
    variantType
    membershipIds
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'id',
      },
    ],
    v1 = [
      {
        kind: 'Variable',
        name: 'id',
        variableName: 'id',
      },
    ],
    v2 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null,
    },
    v3 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'title',
      storageKey: null,
    },
    v4 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'description',
      storageKey: null,
    },
    v5 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'tags',
      storageKey: null,
    },
    v6 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'currencyCode',
      storageKey: null,
    },
    v7 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'val',
      storageKey: null,
    },
    v8 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'createdAt',
      storageKey: null,
    },
    v9 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'updatedAt',
      storageKey: null,
    },
    v10 = [v6 /*: any*/, v7 /*: any*/],
    v11 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'height',
      storageKey: null,
    },
    v12 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'width',
      storageKey: null,
    },
    v13 = [
      v2 /*: any*/,
      v3 /*: any*/,
      v4 /*: any*/,
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'listingSku',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'skus',
        storageKey: null,
      },
      v5 /*: any*/,
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'notes',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'searchTags',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        concreteType: 'InvsysListingMarketplacesSummary',
        kind: 'LinkedField',
        name: 'marketplacesSummary',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'marketplaces',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'draftMarketplaces',
            storageKey: null,
          },
        ],
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        concreteType: 'InvsysListingPriceType',
        kind: 'LinkedField',
        name: 'price',
        plural: false,
        selections: [v2 /*: any*/, v6 /*: any*/, v7 /*: any*/],
        storageKey: null,
      },
      v8 /*: any*/,
      v9 /*: any*/,
      {
        alias: null,
        args: null,
        concreteType: 'InvsysSkuAttribute',
        kind: 'LinkedField',
        name: 'items',
        plural: true,
        selections: [
          v2 /*: any*/,
          v3 /*: any*/,
          v4 /*: any*/,
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'brand',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'categoryId',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'sizeId',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            concreteType: 'InvsysListingPriceType',
            kind: 'LinkedField',
            name: 'price',
            plural: false,
            selections: v10 /*: any*/,
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            concreteType: 'InvsysListingPriceType',
            kind: 'LinkedField',
            name: 'retailPrice',
            plural: false,
            selections: v10 /*: any*/,
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            concreteType: 'InvsysListingPriceType',
            kind: 'LinkedField',
            name: 'costPrice',
            plural: false,
            selections: v10 /*: any*/,
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            concreteType: 'InvsysListingSizeType',
            kind: 'LinkedField',
            name: 'size',
            plural: false,
            selections: [
              v7 /*: any*/,
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'system',
                storageKey: null,
              },
            ],
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'condition',
            storageKey: null,
          },
          v5 /*: any*/,
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'skuAlias',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'colors',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'weight',
            storageKey: null,
          },
          v11 /*: any*/,
          v12 /*: any*/,
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'length',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'weightUnit',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'sizeUnit',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'packageSizeType',
            storageKey: null,
          },
        ],
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'archived',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'targetInstitutions',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        concreteType: 'InvsysListingStepResult',
        kind: 'LinkedField',
        name: 'validationResult',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'success',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            concreteType: 'InvsysListingStepErrorType',
            kind: 'LinkedField',
            name: 'errors',
            plural: true,
            selections: [
              {
                alias: 'errorId',
                args: null,
                kind: 'ScalarField',
                name: 'id',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'message',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'entityName',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'fieldName',
                storageKey: null,
              },
            ],
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ],
    v14 = [
      {
        kind: 'Variable',
        name: 'listingId',
        variableName: 'id',
      },
    ],
    v15 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'listingMediaGroupId',
      storageKey: null,
    },
    v16 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'mediaId',
      storageKey: null,
    },
    v17 = {
      alias: null,
      args: null,
      concreteType: 'StudioMedia',
      kind: 'LinkedField',
      name: 'media',
      plural: false,
      selections: [
        v2 /*: any*/,
        v8 /*: any*/,
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'url',
          storageKey: null,
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'mediaType',
          storageKey: null,
        },
        v12 /*: any*/,
        v11 /*: any*/,
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'uploadStatus',
          storageKey: null,
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'originUri',
          storageKey: null,
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'originPlatformOs',
          storageKey: null,
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'originPlatformMajorVersion',
          storageKey: null,
        },
      ],
      storageKey: null,
    },
    v18 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'variantType',
      storageKey: null,
    },
    v19 = [
      v2 /*: any*/,
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'name',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'note',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'listingId',
        storageKey: null,
      },
      v8 /*: any*/,
      v9 /*: any*/,
      {
        alias: null,
        args: null,
        concreteType: 'StudioListingMediaGroupMembership',
        kind: 'LinkedField',
        name: 'memberships',
        plural: true,
        selections: [
          v2 /*: any*/,
          v15 /*: any*/,
          v16 /*: any*/,
          v17 /*: any*/,
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'preparationStatus',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'rank',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            concreteType: 'StudioListingMediaGroupMembershipVariant',
            kind: 'LinkedField',
            name: 'variants',
            plural: true,
            selections: [
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'membershipId',
                storageKey: null,
              },
              v16 /*: any*/,
              v18 /*: any*/,
              v17 /*: any*/,
            ],
            storageKey: null,
          },
        ],
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        concreteType: 'StudioListingMediaGroupVariantRequest',
        kind: 'LinkedField',
        name: 'pendingVariantRequests',
        plural: true,
        selections: [
          v2 /*: any*/,
          v15 /*: any*/,
          v8 /*: any*/,
          v18 /*: any*/,
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'membershipIds',
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'listingQuery',
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: 'InvsysSkusListing',
          kind: 'LinkedField',
          name: 'invsysListing',
          plural: false,
          selections: [
            {
              kind: 'InlineDataFragmentSpread',
              name: 'listingFragment_full',
              selections: v13 /*: any*/,
            },
          ],
          storageKey: null,
        },
        {
          alias: null,
          args: v14 /*: any*/,
          concreteType: 'StudioListingMediaGroup',
          kind: 'LinkedField',
          name: 'studioListingMediaGroupByListingId',
          plural: false,
          selections: [
            {
              kind: 'InlineDataFragmentSpread',
              name: 'studioListingMediaGroupFragment_full',
              selections: v19 /*: any*/,
            },
          ],
          storageKey: null,
        },
      ],
      type: 'Query',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'listingQuery',
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: 'InvsysSkusListing',
          kind: 'LinkedField',
          name: 'invsysListing',
          plural: false,
          selections: v13 /*: any*/,
          storageKey: null,
        },
        {
          alias: null,
          args: v14 /*: any*/,
          concreteType: 'StudioListingMediaGroup',
          kind: 'LinkedField',
          name: 'studioListingMediaGroupByListingId',
          plural: false,
          selections: v19 /*: any*/,
          storageKey: null,
        },
      ],
    },
    params: {
      cacheID: '968b4f7a3bec73511a97f599450b62ba',
      id: null,
      metadata: {},
      name: 'listingQuery',
      operationKind: 'query',
      text: 'query listingQuery(\n  $id: String!\n) {\n  invsysListing(id: $id) {\n    ...listingFragment_full\n  }\n  studioListingMediaGroupByListingId(listingId: $id) {\n    ...studioListingMediaGroupFragment_full\n  }\n}\n\nfragment listingFragment_full on InvsysSkusListing {\n  id\n  title\n  description\n  listingSku\n  skus\n  tags\n  notes\n  searchTags\n  marketplacesSummary {\n    marketplaces\n    draftMarketplaces\n  }\n  price {\n    id\n    currencyCode\n    val\n  }\n  createdAt\n  updatedAt\n  items {\n    id\n    title\n    description\n    brand\n    categoryId\n    sizeId\n    price {\n      currencyCode\n      val\n    }\n    retailPrice {\n      currencyCode\n      val\n    }\n    costPrice {\n      currencyCode\n      val\n    }\n    size {\n      val\n      system\n    }\n    condition\n    tags\n    skuAlias\n    colors\n    weight\n    height\n    width\n    length\n    weightUnit\n    sizeUnit\n    packageSizeType\n  }\n  archived\n  targetInstitutions\n  validationResult {\n    success\n    errors {\n      errorId: id\n      message\n      entityName\n      fieldName\n    }\n  }\n}\n\nfragment studioListingMediaGroupFragment_full on StudioListingMediaGroup {\n  id\n  name\n  note\n  listingId\n  createdAt\n  updatedAt\n  memberships {\n    id\n    listingMediaGroupId\n    mediaId\n    media {\n      id\n      createdAt\n      url\n      mediaType\n      width\n      height\n      uploadStatus\n      originUri\n      originPlatformOs\n      originPlatformMajorVersion\n    }\n    preparationStatus\n    rank\n    variants {\n      membershipId\n      mediaId\n      variantType\n      media {\n        id\n        createdAt\n        url\n        mediaType\n        width\n        height\n        uploadStatus\n        originUri\n        originPlatformOs\n        originPlatformMajorVersion\n      }\n    }\n  }\n  pendingVariantRequests {\n    id\n    listingMediaGroupId\n    createdAt\n    variantType\n    membershipIds\n  }\n}\n',
    },
  };
})();
(node as any).hash = 'd0dba06e014e11291e5d245b6c614777';
export default node;
