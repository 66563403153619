import { graphql } from 'react-relay';

export const gqlGrailedListing = graphql`
  query grailedListingQuery($listingId: String!) {
    grailedGrailedListing(listingId: $listingId) {
      listingId
      updatedAt
      items {
        itemId
        grailedListingId
        grailedId
        size
        publishStatusV2 {
          status
          message
        }
      }
      buyNow
      categoryPath
      condition
      description
      designerIds
      hashtags
      makeOffer
      measurement1
      measurement2
      measurement3
      measurement4
      measurement5
      measurement6
      minimumPrice
      price
      returnAddressId
      usShippingPrice
      caShippingPrice
      shippingType
      title
      color
      externalId
      publishStatusV2 {
        status
        message
      }
      priceSuggestion {
        suggestedPrice
        feeParts {
          value
          label
        }
      }
      shippingInfo {
        shippingPrice
        shippingProvider
        shippingProviderDisplay
        payerContributions {
          contribution
          shippingPayer
          shippingPayerDisplay
        }
      }
      sizeSuggestion {
        sizeSuggestions {
          id
          display
          sizeSystem
        }
      }
    }
  }
`;
