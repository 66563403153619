import { ValidationErrorId } from 'src/constants/ValidationError';
import { Types } from 'src/state';
import * as Observe from 'src/state/lib/observe';

export interface SizeIface {
  id: string;
  display: string;
  size_system: string;
}

export interface SizeArgs {
  id: string;
  size_system: string;
}

export enum AdditionalFieldsNames {
  NationalShippingPrice = 'nationalShippingPrice',
  Size = 'size',
  //optional
  Occasion = 'occasion',
  Material = 'material',
  BodyFit = 'body-fit',
  Source = 'source',
  Age = 'age',
  Style = 'style',
}

export const Form = {
  DescriptionValue: Observe.Value<string | null>(null),
  BrandValue: Observe.Value<string | null>(null),
  CategoryValue: Observe.Value<number | null>(null),
  NationalShippingPriceValue: Observe.Value<number | null>(null),
  SizeValue: Observe.Value<SizeArgs | null>(null),
  PriceValue: Observe.Value<number | null>(null),
  AddressValue: Observe.Value<string | null>(null),
  OccasionValue: Observe.Value<string[]>([]),
  MaterialValue: Observe.Value<string[]>([]),
  BodyFitValue: Observe.Value<string[]>([]),
  SourcesValue: Observe.Value<string[] | null>(null),
  AgesValue: Observe.Value<string[] | null>(null),
  StyleValue: Observe.Value<string[] | null>(null),
  ShippingTypeValue: Observe.Value<number | null>(null),
};

export const clear = () => {
  Object.keys(Form).map((key) => Form[key as keyof typeof Form].reset());
};

export const setWithListing = (listing: Types.DepopListingType) => {
  Form.DescriptionValue.set(listing.description);
  Form.BrandValue.set(listing.brandId);
  Form.CategoryValue.set(listing.categoryIdV2);
  Form.NationalShippingPriceValue.set(listing.national_shipping_price);
  Form.SizeValue.set(
    listing.items[0].size != null
      ? {
          id: listing.items[0].size.toString(),
          size_system: 'us',
        }
      : null
  );
  Form.PriceValue.set(listing.price);
  Form.AddressValue.set(listing.addressId);
  Form.OccasionValue.set([...listing.occasion]);
  Form.MaterialValue.set([...listing.material]);
  Form.BodyFitValue.set([...listing.bodyFit]);
  Form.SourcesValue.set([...(listing.sources ?? [])]);
  Form.AgesValue.set([...(listing.ages ?? [])]);
  Form.StyleValue.set([...(listing.style ?? [])]);
  Form.ShippingTypeValue.set(listing.shippingType);
};

export enum FormPart {
  NationalShippingPrice,
  Description,
  Category,
  Size,
  Price,
  Address,
  Occasion,
  Material,
  BodyFit,
  Source,
  Age,
  Style,
  Shipping,
  Brand,
}

interface FieldIface {
  part: FormPart;
  errors: ValidationErrorId[];
}

export const FormConfig: FieldIface[] = [
  {
    part: FormPart.NationalShippingPrice,
    errors: [ValidationErrorId.Depop_DepopListingNationalShippingPrice],
  },
  {
    part: FormPart.Description,
    errors: [ValidationErrorId.Depop_DepopListingDescription],
  },
  {
    part: FormPart.Category,
    errors: [ValidationErrorId.Depop_DepopListingCategoryId],
  },
  {
    part: FormPart.Size,
    errors: [ValidationErrorId.Depop_DepopItemSize],
  },
  {
    part: FormPart.Price,
    errors: [ValidationErrorId.Depop_DepopListingPrice],
  },
  {
    part: FormPart.Address,
    errors: [ValidationErrorId.Depop_DepopListingAddress],
  },
  {
    part: FormPart.Occasion,
    errors: [ValidationErrorId.Depop_DepopListingOccasion],
  },
  {
    part: FormPart.Material,
    errors: [ValidationErrorId.Depop_DepopListingMaterial],
  },
  {
    part: FormPart.BodyFit,
    errors: [ValidationErrorId.Depop_DepopListingBodyFit],
  },
  {
    part: FormPart.Source,
    errors: [ValidationErrorId.Depop_DepopListingSources],
  },
  {
    part: FormPart.Age,
    errors: [ValidationErrorId.Depop_DepopListingAges],
  },
  {
    part: FormPart.Style,
    errors: [ValidationErrorId.Depop_DepopListingStyles],
  },
  {
    part: FormPart.Shipping,
    errors: [ValidationErrorId.Depop_DepopListingPersonalShippingPrice],
  },
  {
    part: FormPart.Brand,
    errors: [],
  },
];

export const FormParts = Observe.Value<FormPart[]>([]);
export const UnhandledErrorIds = Observe.Value<number[]>([]);
export const SkipFullFormUpdate = Observe.Value<boolean>(false);
