import { graphql } from 'react-relay';

export const gqlCaRegisteredIdentificationNumberDetails = graphql`
  query caRegisteredIdentificationNumberDetailsQuery($id: String!) {
    invsysCaRegisteredIdentificationNumberDetails(id: $id) {
      raw {
        id
        name
        url
      }
      curated {
        id
        legalName
        colloquialName
        iconUrl
        websiteUrl
        description
      }
    }
  }
`;
