/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type uncategorizedListingsQueryVariables = {
  poshmarkUserLinkId: string;
  listingsLimit?: number | null;
  listingsCursor?: string | null;
};
export type uncategorizedListingsQueryResponse = {
  readonly superposherPoshmarkUserLink: {
    readonly id: string;
    readonly uncategorizedListings: {
      readonly items: ReadonlyArray<{
        readonly id: string;
        readonly entities: ReadonlyArray<{
          readonly sourceUrl: string;
        } | null>;
      }>;
      readonly cursor: string | null;
    };
  } | null;
};
export type uncategorizedListingsQuery = {
  readonly response: uncategorizedListingsQueryResponse;
  readonly variables: uncategorizedListingsQueryVariables;
};

/*
query uncategorizedListingsQuery(
  $poshmarkUserLinkId: String!
  $listingsLimit: Int
  $listingsCursor: String
) {
  superposherPoshmarkUserLink(id: $poshmarkUserLinkId) {
    id
    uncategorizedListings(limit: $listingsLimit, cursor: $listingsCursor) {
      items {
        id
        entities {
          sourceUrl
        }
      }
      cursor
    }
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'listingsCursor',
    },
    v1 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'listingsLimit',
    },
    v2 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'poshmarkUserLinkId',
    },
    v3 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null,
    },
    v4 = [
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'id',
            variableName: 'poshmarkUserLinkId',
          },
        ],
        concreteType: 'SuperposherPoshmarkUserLink',
        kind: 'LinkedField',
        name: 'superposherPoshmarkUserLink',
        plural: false,
        selections: [
          v3 /*: any*/,
          {
            alias: null,
            args: [
              {
                kind: 'Variable',
                name: 'cursor',
                variableName: 'listingsCursor',
              },
              {
                kind: 'Variable',
                name: 'limit',
                variableName: 'listingsLimit',
              },
            ],
            concreteType: 'SuperposherClosetSectionListingsPage',
            kind: 'LinkedField',
            name: 'uncategorizedListings',
            plural: false,
            selections: [
              {
                alias: null,
                args: null,
                concreteType: 'SuperposherClosetSectionListingPageItem',
                kind: 'LinkedField',
                name: 'items',
                plural: true,
                selections: [
                  v3 /*: any*/,
                  {
                    alias: null,
                    args: null,
                    concreteType: 'SuperposherPoshmarkLookup',
                    kind: 'LinkedField',
                    name: 'entities',
                    plural: true,
                    selections: [
                      {
                        alias: null,
                        args: null,
                        kind: 'ScalarField',
                        name: 'sourceUrl',
                        storageKey: null,
                      },
                    ],
                    storageKey: null,
                  },
                ],
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'cursor',
                storageKey: null,
              },
            ],
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: [v0 /*: any*/, v1 /*: any*/, v2 /*: any*/],
      kind: 'Fragment',
      metadata: null,
      name: 'uncategorizedListingsQuery',
      selections: v4 /*: any*/,
      type: 'Query',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [v2 /*: any*/, v1 /*: any*/, v0 /*: any*/],
      kind: 'Operation',
      name: 'uncategorizedListingsQuery',
      selections: v4 /*: any*/,
    },
    params: {
      cacheID: '81396189856e20a83034615a0dae4bd6',
      id: null,
      metadata: {},
      name: 'uncategorizedListingsQuery',
      operationKind: 'query',
      text: 'query uncategorizedListingsQuery(\n  $poshmarkUserLinkId: String!\n  $listingsLimit: Int\n  $listingsCursor: String\n) {\n  superposherPoshmarkUserLink(id: $poshmarkUserLinkId) {\n    id\n    uncategorizedListings(limit: $listingsLimit, cursor: $listingsCursor) {\n      items {\n        id\n        entities {\n          sourceUrl\n        }\n      }\n      cursor\n    }\n  }\n}\n',
    },
  };
})();
(node as any).hash = 'a9abe8634d8e19531a4fefa6011805fa';
export default node;
