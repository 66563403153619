/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type SuperposherShippingDiscountType = 'DISCOUNTED' | 'FREE' | '%future added value';
export type updateOfferToLikersShippingDiscountTypeMutationVariables = {
  poshmarkUserLinkId: string;
  type: SuperposherShippingDiscountType;
};
export type updateOfferToLikersShippingDiscountTypeMutationResponse = {
  readonly superposherUpdateOfferToLikersShippingDiscountType: {
    readonly success: boolean;
  };
};
export type updateOfferToLikersShippingDiscountTypeMutation = {
  readonly response: updateOfferToLikersShippingDiscountTypeMutationResponse;
  readonly variables: updateOfferToLikersShippingDiscountTypeMutationVariables;
};

/*
mutation updateOfferToLikersShippingDiscountTypeMutation(
  $poshmarkUserLinkId: String!
  $type: SuperposherShippingDiscountType!
) {
  superposherUpdateOfferToLikersShippingDiscountType(poshmarkUserLinkId: $poshmarkUserLinkId, type: $type) {
    success
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'poshmarkUserLinkId',
      },
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'type',
      },
    ],
    v1 = [
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'poshmarkUserLinkId',
            variableName: 'poshmarkUserLinkId',
          },
          {
            kind: 'Variable',
            name: 'type',
            variableName: 'type',
          },
        ],
        concreteType: 'SuperposherUpdateOfferToLikersShippingDiscountTypeResponse',
        kind: 'LinkedField',
        name: 'superposherUpdateOfferToLikersShippingDiscountType',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'success',
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'updateOfferToLikersShippingDiscountTypeMutation',
      selections: v1 /*: any*/,
      type: 'Mutation',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'updateOfferToLikersShippingDiscountTypeMutation',
      selections: v1 /*: any*/,
    },
    params: {
      cacheID: 'a864a81088cea1242c70959044c6c1ec',
      id: null,
      metadata: {},
      name: 'updateOfferToLikersShippingDiscountTypeMutation',
      operationKind: 'mutation',
      text: 'mutation updateOfferToLikersShippingDiscountTypeMutation(\n  $poshmarkUserLinkId: String!\n  $type: SuperposherShippingDiscountType!\n) {\n  superposherUpdateOfferToLikersShippingDiscountType(poshmarkUserLinkId: $poshmarkUserLinkId, type: $type) {\n    success\n  }\n}\n',
    },
  };
})();
(node as any).hash = '48215792e2745f3706945a0b631b586f';
export default node;
