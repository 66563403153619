/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type statBucketsQueryVariables = {};
export type statBucketsQueryResponse = {
  readonly superposherPoshmarkUserLinks: ReadonlyArray<{
    readonly id: string;
    readonly statBucket7DayLookBack: ReadonlyArray<{
      readonly at: number;
      readonly shareToFollowersFinished: number;
      readonly shareToPartiesFinished: number;
      readonly shareForSharesFinished: number;
      readonly followForFollowsFinished: number;
      readonly followUserFollowersFinished: number;
      readonly offerToLikersFinished: number;
      readonly relistingsFinished: number;
      readonly relistingsToPoshmarkFinished: number;
    }>;
    readonly statBucket24HourLookBack: ReadonlyArray<{
      readonly at: number;
      readonly shareToFollowersFinished: number;
      readonly shareToPartiesFinished: number;
      readonly shareForSharesFinished: number;
      readonly followForFollowsFinished: number;
      readonly followUserFollowersFinished: number;
      readonly offerToLikersFinished: number;
      readonly relistingsFinished: number;
      readonly relistingsToPoshmarkFinished: number;
    }>;
    readonly statBucket48HourLookBack: ReadonlyArray<{
      readonly at: number;
      readonly shareToFollowersFinished: number;
      readonly shareToPartiesFinished: number;
      readonly shareForSharesFinished: number;
      readonly followForFollowsFinished: number;
      readonly followUserFollowersFinished: number;
      readonly offerToLikersFinished: number;
      readonly relistingsFinished: number;
      readonly relistingsToPoshmarkFinished: number;
    }>;
    readonly statBucketToday: ReadonlyArray<{
      readonly at: number;
      readonly shareToFollowersFinished: number;
      readonly shareToPartiesFinished: number;
      readonly shareForSharesFinished: number;
      readonly followForFollowsFinished: number;
      readonly followUserFollowersFinished: number;
      readonly offerToLikersFinished: number;
      readonly relistingsFinished: number;
      readonly relistingsToPoshmarkFinished: number;
    }>;
  }>;
};
export type statBucketsQuery = {
  readonly response: statBucketsQueryResponse;
  readonly variables: statBucketsQueryVariables;
};

/*
query statBucketsQuery {
  superposherPoshmarkUserLinks {
    id
    statBucket7DayLookBack {
      at
      shareToFollowersFinished
      shareToPartiesFinished
      shareForSharesFinished
      followForFollowsFinished
      followUserFollowersFinished
      offerToLikersFinished
      relistingsFinished
      relistingsToPoshmarkFinished
    }
    statBucket24HourLookBack {
      at
      shareToFollowersFinished
      shareToPartiesFinished
      shareForSharesFinished
      followForFollowsFinished
      followUserFollowersFinished
      offerToLikersFinished
      relistingsFinished
      relistingsToPoshmarkFinished
    }
    statBucket48HourLookBack {
      at
      shareToFollowersFinished
      shareToPartiesFinished
      shareForSharesFinished
      followForFollowsFinished
      followUserFollowersFinished
      offerToLikersFinished
      relistingsFinished
      relistingsToPoshmarkFinished
    }
    statBucketToday {
      at
      shareToFollowersFinished
      shareToPartiesFinished
      shareForSharesFinished
      followForFollowsFinished
      followUserFollowersFinished
      offerToLikersFinished
      relistingsFinished
      relistingsToPoshmarkFinished
    }
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'at',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'shareToFollowersFinished',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'shareToPartiesFinished',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'shareForSharesFinished',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'followForFollowsFinished',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'followUserFollowersFinished',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'offerToLikersFinished',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'relistingsFinished',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'relistingsToPoshmarkFinished',
        storageKey: null,
      },
    ],
    v1 = [
      {
        alias: null,
        args: null,
        concreteType: 'SuperposherPoshmarkUserLink',
        kind: 'LinkedField',
        name: 'superposherPoshmarkUserLinks',
        plural: true,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'id',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            concreteType: 'SuperposherStatBucket',
            kind: 'LinkedField',
            name: 'statBucket7DayLookBack',
            plural: true,
            selections: v0 /*: any*/,
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            concreteType: 'SuperposherStatBucket',
            kind: 'LinkedField',
            name: 'statBucket24HourLookBack',
            plural: true,
            selections: v0 /*: any*/,
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            concreteType: 'SuperposherStatBucket',
            kind: 'LinkedField',
            name: 'statBucket48HourLookBack',
            plural: true,
            selections: v0 /*: any*/,
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            concreteType: 'SuperposherStatBucket',
            kind: 'LinkedField',
            name: 'statBucketToday',
            plural: true,
            selections: v0 /*: any*/,
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: [],
      kind: 'Fragment',
      metadata: null,
      name: 'statBucketsQuery',
      selections: v1 /*: any*/,
      type: 'Query',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [],
      kind: 'Operation',
      name: 'statBucketsQuery',
      selections: v1 /*: any*/,
    },
    params: {
      cacheID: '2a04655b3ec0617b780d9d1336539d32',
      id: null,
      metadata: {},
      name: 'statBucketsQuery',
      operationKind: 'query',
      text: 'query statBucketsQuery {\n  superposherPoshmarkUserLinks {\n    id\n    statBucket7DayLookBack {\n      at\n      shareToFollowersFinished\n      shareToPartiesFinished\n      shareForSharesFinished\n      followForFollowsFinished\n      followUserFollowersFinished\n      offerToLikersFinished\n      relistingsFinished\n      relistingsToPoshmarkFinished\n    }\n    statBucket24HourLookBack {\n      at\n      shareToFollowersFinished\n      shareToPartiesFinished\n      shareForSharesFinished\n      followForFollowsFinished\n      followUserFollowersFinished\n      offerToLikersFinished\n      relistingsFinished\n      relistingsToPoshmarkFinished\n    }\n    statBucket48HourLookBack {\n      at\n      shareToFollowersFinished\n      shareToPartiesFinished\n      shareForSharesFinished\n      followForFollowsFinished\n      followUserFollowersFinished\n      offerToLikersFinished\n      relistingsFinished\n      relistingsToPoshmarkFinished\n    }\n    statBucketToday {\n      at\n      shareToFollowersFinished\n      shareToPartiesFinished\n      shareForSharesFinished\n      followForFollowsFinished\n      followUserFollowersFinished\n      offerToLikersFinished\n      relistingsFinished\n      relistingsToPoshmarkFinished\n    }\n  }\n}\n',
    },
  };
})();
(node as any).hash = 'eabb7ad1fdc9f57b38f812e39618c6fa';
export default node;
