import * as Observe from 'src/state/lib/observe';
import * as PoshmarkUserLinks from 'src/state/observe/PoshmarkUserLinks';
import { uncategorizedListingsQueryResponse } from 'src/clients/Network/gql/build/uncategorizedListingsQuery.graphql';

export type ResponseDataType = NonNullable<
  uncategorizedListingsQueryResponse['superposherPoshmarkUserLink']
>['uncategorizedListings'];

export interface EntityTransactions {
  updatedAt: Date;
  data: ResponseDataType;
}

/* Aaron: hack, need proper pagination */
export type AsyncValueDataType = {
  [poshmarkUserLinkId: string]: EntityTransactions;
};

export const AsyncValue = Observe.AsyncValue<AsyncValueDataType>({
  merge: (prev, next) => {
    if (prev == null) {
      return next;
    } else {
      const nextState: AsyncValueDataType = { ...prev };
      for (const filter in next) {
        const n = next[filter];
        const p = prev[filter] as EntityTransactions | null;
        if (p != null) {
          const nextItems = [...p.data.items, ...n.data.items];
          nextState[filter] = {
            updatedAt: n.updatedAt,
            data: {
              ...p.data,
              ...n.data,
              items: nextItems.filter((value, idx, array) => array.findIndex((_) => _.id == value.id) === idx),
            },
          };
        } else {
          nextState[filter] = n;
        }
      }
      return nextState;
    }
  },
});

export const Value = Observe.DerivedValue(() => {
  return AsyncValue.getValueWithFallback();
}, [AsyncValue.value]);

export const ValueFallback = Observe.WithFallback(Value);

export const SelectedValue = Observe.DerivedValue(() => {
  const selectedId = PoshmarkUserLinks.SelectedIdValue.get();
  if (selectedId != null) {
    const closetSectionListings = AsyncValue.getValueWithFallback() ?? {};
    return closetSectionListings[selectedId];
  } else {
    return null;
  }
}, [AsyncValue.value, PoshmarkUserLinks.SelectedIdValue]);

export const SelectedFallback = Observe.WithFallback(SelectedValue);
