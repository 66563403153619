/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type startAwsMultipartUploadMutationVariables = {
  fileSize: number;
  fileType: string;
};
export type startAwsMultipartUploadMutationResponse = {
  readonly invsysStartAwsMultipartUpload: {
    readonly success: boolean;
    readonly data: {
      readonly uploadId: string;
      readonly uploadKey: string;
      readonly uploadUrl: string;
      readonly partMaxSize: number;
      readonly partUrls: ReadonlyArray<string>;
    } | null;
  };
};
export type startAwsMultipartUploadMutation = {
  readonly response: startAwsMultipartUploadMutationResponse;
  readonly variables: startAwsMultipartUploadMutationVariables;
};

/*
mutation startAwsMultipartUploadMutation(
  $fileSize: Int!
  $fileType: String!
) {
  invsysStartAwsMultipartUpload(fileSize: $fileSize, fileType: $fileType) {
    success
    data {
      uploadId
      uploadKey
      uploadUrl
      partMaxSize
      partUrls
    }
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'fileSize',
      },
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'fileType',
      },
    ],
    v1 = [
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'fileSize',
            variableName: 'fileSize',
          },
          {
            kind: 'Variable',
            name: 'fileType',
            variableName: 'fileType',
          },
        ],
        concreteType: 'InvsysStartAwsMultipartUploadResponse',
        kind: 'LinkedField',
        name: 'invsysStartAwsMultipartUpload',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'success',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            concreteType: 'InvsysStartAwsMultipartUploadDataResponse',
            kind: 'LinkedField',
            name: 'data',
            plural: false,
            selections: [
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'uploadId',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'uploadKey',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'uploadUrl',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'partMaxSize',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'partUrls',
                storageKey: null,
              },
            ],
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'startAwsMultipartUploadMutation',
      selections: v1 /*: any*/,
      type: 'Mutation',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'startAwsMultipartUploadMutation',
      selections: v1 /*: any*/,
    },
    params: {
      cacheID: '9c13f68f6a09e473fd1daa43e84e6ff7',
      id: null,
      metadata: {},
      name: 'startAwsMultipartUploadMutation',
      operationKind: 'mutation',
      text: 'mutation startAwsMultipartUploadMutation(\n  $fileSize: Int!\n  $fileType: String!\n) {\n  invsysStartAwsMultipartUpload(fileSize: $fileSize, fileType: $fileType) {\n    success\n    data {\n      uploadId\n      uploadKey\n      uploadUrl\n      partMaxSize\n      partUrls\n    }\n  }\n}\n',
    },
  };
})();
(node as any).hash = 'cdb131750f950bf41cdf72c9a83a9510';
export default node;
