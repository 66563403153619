import { graphql } from 'react-relay';

export const gqlMercariBotCriticalMetadata = graphql`
  query mercariBotCriticalMetadataQuery {
    mercariFeatureStatuses {
      autoRelistingFeatureStatus
    }
    mercariStatBuckets {
      last24Hours {
        at
        relistingsFinished
      }
      last48Hours {
        at
        relistingsFinished
      }
      last7Days {
        at
        relistingsFinished
      }
      today {
        at
        relistingsFinished
      }
    }
  }
`;
