import { graphql } from 'react-relay';

export const gqlAddListingsToClosetSection = graphql`
  mutation addListingsToClosetSectionMutation(
    $poshmarkUserId: String!
    $closetSectionId: String!
    $listingIds: [String!]!
  ) {
    superposherAddListingsToClosetSection(
      poshmarkUserId: $poshmarkUserId
      closetSectionId: $closetSectionId
      listingIds: $listingIds
    ) {
      success
    }
  }
`;
