/* Persisted, do not change ids */
export enum ValidationErrorId {
  Poshmark_PoshmarkListingTitle = 0,
  Poshmark_PoshmarkListingDescription = 1,
  Poshmark_PoshmarkListingDepartment = 2,
  Poshmark_PoshmarkListingCategoryId = 3,
  // Poshmark_PoshmarkListingCategoryFeatureIdRequired = 4,
  Poshmark_PoshmarkListingStatusRequired = 5,
  Poshmark_PoshmarkListingCondition = 6,
  Poshmark_PoshmarkListingPrice = 7,
  Poshmark_PoshmarkListingOriginalPrice = 8,
  Poshmark_PoshmarkListingCurrencyCode = 9,
  Poshmark_PoshmarkListingCostPriceRequired = 10,
  // Poshmark_PoshmarkListingPictureIdsRequired = 11,
  // Poshmark_PoshmarkListingTitleCharacterLimit80 = 12,
  // Poshmark_PoshmarkListingDescriptionCharacterLimit1500 = 13,
  Poshmark_PoshmarkListingBrand = 14,
  Poshmark_PoshmarkListingColorsLimit2 = 15,
  Poshmark_PoshmarkListingStyleTagsLimit3 = 16,
  // Poshmark_PoshmarkListingPriceLimit15Digits = 17,
  // Poshmark_PoshmarkListingOriginalPriceLimit15Digits = 18,
  Poshmark_PoshmarkListingCostPrice = 19,
  Poshmark_PoshmarkListingListingSku = 20,
  Poshmark_ImageSquareOnly = 21,
  Poshmark_PoshmarkListingPictureIdsLimit16 = 22,
  Poshmark_PoshmarkItems = 23,
  Poshmark_PoshmarkItemsWeight = 24,
  Poshmark_PoshmarkItemsSize = 25,
  // Poshmark_PoshmarkItemSizeSystemRequired = 26,
  Tradesy_TradesyListingBrandRequired = 27,
  Tradesy_TradesyListingSizeRequired = 28,
  Tradesy_TradesyListingConditionTagsRequired = 29,
  Tradesy_TradesyListingConditionWearRequired = 30,
  Tradesy_TradesyListingStyleNameRequired = 31,
  Tradesy_TradesyListingStyleNameLimit70Characters = 32,
  Tradesy_TradesyListingAskingPriceRequired = 33,
  Tradesy_TradesyListingShippingFlagRequired = 34,
  Tradesy_TradesyListingShippingPriceRequired = 35,
  Tradesy_TradesyListingNodeIdRequired = 36,
  // Tradesy_TradesyListingImageIdsMin3 = 37,
  Tradesy_ImageWidthMin800Px = 38,
  Tradesy_ImageHeightMin800Px = 39,
  Mercari_MercariListingCategoryId = 40,
  Mercari_MercariListingConditionId = 41,
  Mercari_MercariListingDescription = 42,
  Mercari_MercariListingName = 43,
  Mercari_MercariListingPrice = 44,
  Mercari_MercariListingShippingClassIds = 45,
  // Mercari_MercariListingShippingClassIdsLength1 = 46,
  Mercari_MercariListingShippingPackageWeight = 47,
  Mercari_MercariListingShippingPayerId = 48,
  Mercari_MercariListingSuggestedShippingClassIds = 49,
  // Mercari_MercariListingSuggestedShippingClassIdsLength1 = 50,
  Mercari_MercariListingTags = 51,
  Mercari_MercariListingZipCodeRequired = 52,
  Mercari_MercariListingSizeId = 53,
  // Mercari_MercariListingPhotoIdsMinimum1 = 54,
  // Mercari_MercariListingPhotoIdsMaximum12 = 55,
  // Mercari_MercariListingNameCharacterLimit80 = 56,
  // Mercari_MercariListingDescriptionCharacterLimit1000 = 57,
  // Mercari_MercariListingDescriptionWordMin5 = 57,
  Mercari_MercariListingTagsLimit3 = 58,
  Mercari_ImageSquareOnly = 59,
  Mercari_MercariListingMinPriceForAutoPriceDropRequired = 60,
  Invsys_InvsysListingTitle = 61,
  Invsys_InvsysListingDescription = 62,
  Invsys_InvsysMedia = 63,
  Invsys_InvsysListingPrice = 64,
  Invsys_InvsysListingSkus = 65,
  Invsys_InvsysItemCategoryId = 66,
  Invsys_InvsysItemCondition = 67,
  // Invsys_InvsysItemCurrencyCodeRequired = 68,
  Invsys_InvsysItemShippingWeight = 69,
  Invsys_InvsysItemRetailPriceRequired = 70,
  Invsys_InvsysItemCostPriceRequired = 71,
  Invsys_InvsysItemBrand = 72,
  Invsys_InvsysListingCurrencyCode = 73,
  // Invsys_InvsysMediaUploadStatusNotPending = 74,
  // Invsys_InvsysMediaSquareOnly = 75,
  Tradesy_TradesyListingVeilLengthRequired = 76,
  Tradesy_TradesyListingSellingPriceRequired = 77,
  Tradesy_TradesyListingLabelSizeRequired = 78,
  Tradesy_TradesyListingConditionSampleRequired = 79,
  Tradesy_TradesyListingMeasurementWidthRequired = 80,
  Tradesy_TradesyListingMeasurementHeightRequired = 81,
  Tradesy_TradesyListingMeasurementLengthRequired = 82,
  Tradesy_TradesyListingHeelHeightRequired = 83,
  Tradesy_TradesyListingSizeByCountryRequired = 84,
  Tradesy_TradesyListingWidthRequired = 85,
  Depop_DepopListingCategoryId = 86,
  Depop_DepopListingAddress = 87,
  Depop_DepopListingNationalShippingPrice = 88,
  Depop_DepopListingPrice = 89,
  // Depop_DepopListingPhotoIdsMinimum1 = 90,
  Depop_DepopListingPhotoIdsMaximum4 = 91,
  // Depop_DepopListingDescriptionCharacterLimit1000 = 92,
  Depop_DepopListingDescription = 93,
  Depop_DepopListingColor = 94,
  Depop_DepopListingSources = 95,
  Depop_DepopListingAges = 96,
  Depop_DepopListingStyles = 97,
  Depop_DepopItemSize = 98,
  Tradesy_TradesyListingMeasurementWidthHeightLengthRequired = 99,
  Tradesy_TradesyListingPersonalShippingPriceRequired = 100,
  Depop_DepopListingPersonalShippingPrice = 101,
  Grailed_GrailedListingDesigners = 102,
  Grailed_GrailedListingSize = 103,
  Grailed_GrailedListingColor = 104,
  Grailed_GrailedListingCondition = 105,
  Grailed_GrailedListingDescription = 106,
  Grailed_GrailedListingTagsLimit10 = 107,
  Grailed_GrailedListingPrice = 108,
  // Grailed_GrailedListingBuyNowShippingRequired = 109,
  Grailed_GrailedListingSmartPricing = 110,
  // Grailed_GrailedListingSmartPricingFloorRequired = 111,
  Grailed_GrailedListingTaggedPhotoRequired = 112,
  // Grailed_GrailedListingPhotoIdsMinimum1Required = 113,
  Grailed_GrailedListingShippingAddressRequired = 114,
  Grailed_GrailedListingShippingPrice = 115,
  Grailed_GrailedListingPurchaseMode = 116,
  Invsys_InvsysItemCount = 117,
  Ebay_EbayListingCategoryId = 118,
  Ebay_EbayListingCondition = 119,
  Ebay_EbayListingPackageDimensions = 120,
  Ebay_EbayListingAttributeType = 121,
  Ebay_EbayListingAttributeColor = 122,
  Ebay_EbayListingAttributeMaterial = 123,
  Ebay_EbayListingAttributeItemsInSet = 124,
  Ebay_EbayListingAttributeBrand = 125,
  Ebay_EbayListingAttributeSetIncludes = 126,
  Ebay_EbayListingAttributeBandMaterial = 127,
  Ebay_EbayListingAttributeStyle = 128,
  Ebay_EbayListingAttributeDepartment = 129,
  Ebay_EbayListingAttributeSize = 130,
  Ebay_EbayListingAttributeDressLength = 131,
  Ebay_EbayListingAttributeOuterShellMaterial = 132,
  Ebay_EbayListingAttributeFrameColor = 133,
  Ebay_EbayListingAttributeUsShoeSize = 134,
  Ebay_EbayListingAttributeUpperMaterial = 135,
  Ebay_EbayListingAttributeSizeType = 136,
  Ebay_EbayListingAttributeInseam = 137,
  Ebay_EbayListingAttributeSleeveLength = 138,
  Ebay_EbayListingAttributeDesignerBrand = 139,
  Ebay_EbayListingAttributeExteriorMaterial = 140,
  Ebay_EbayListingAttributeExteriorColor = 141,
  Ebay_EbayListingAttributeSkirtLength = 142,
  Ebay_EbayListingTitle = 143,
  Ebay_EbayListingDescription = 144,
  Ebay_EbayListingPrice = 145,
  // Ebay_EbayListingCategoryNotSupported = 146,
  // Ebay_EbayListingAttributeCondition = 147,
  // Ebay_EbayListingAttributeDepartmentInvalidOption = 148,
  // Ebay_EbayListingAttributeSizeTypeInvalidOption = 149,
  Tradesy_TradesyListingLengthRequired = 150,
  // Poshmark_PoshmarkListingSizeInvalidOption = 151,
  // Mercari_MercariListingSizeInvalidOption = 152,
  Tradesy_TradesyListingSizeInvalidOption = 153,
  // Depop_DepopListingSizeInvalidOption = 154,
  // Grailed_GrailedListingSizeInvalidOption = 155,
  // Ebay_EbayListingSizeInvalidOption = 156,
  Invsys_InvsysListingTags = 157,
  Poshmark_PoshmarkListingCurrencyCodeDoesNotMatchAccount = 158,
  Ebay_EbayItems = 159,
  // Mercari_MercariListingPriceMax2000 = 160,
  Mercari_MercariListingImei = 161,
  // Poshmark_PoshmarkListingNonLeafCategory = 162,
  // Mercari_MercariListingNonLeafCategory = 163,
  Tradesy_TradesyListingNonLeafCategory = 164,
  // Depop_DepopListingNonLeafCategory = 165,
  // Ebay_EbayListingNonLeafCategory = 166,
  // Grailed_GrailedListingNonLeafCategory = 167,
  Ebay_EbayListingAttributeMainStone = 168,
  // Ebay_EbayListingAttributeMetalPurityInvalidOption = 169,
  Ebay_EbayListingShippingWeight = 170,
  // Invsys_InvsysMediaUploadStatusFailed = 171,
  Invsys_InvsysItemLength = 172,
  Invsys_InvsysItemWidth = 173,
  Invsys_InvsysItemHeight = 174,
  Invsys_InvsysItemSizeUnit = 175,
  Invsys_InvsysItemPackageSizeType = 176,
  Ebay_EbayListingAttributeRingSize = 177,
  Ebay_EbayListingAttributeBeadSize = 178,
  Ebay_EbayListingAttributeMetal = 179,
  Depop_DepopListingOccasion = 180,
  Depop_DepopListingMaterial = 181,
  Depop_DepopListingBodyFit = 182,
  Mercari_MercariListingDressOccasion = 183,
  Mercari_MercariListingDressStyle = 184,
  Mercari_MercariListingMaterial = 185,
  Mercari_MercariListingShaftHeight = 186,
  Mercari_MercariListingEndUse = 187,
  Mercari_MercariListingHeelHeight = 188,
  Mercari_MercariListingHeelType = 189,
  Tradesy_TradesyListingLengthInvalidOption = 190,
  Tradesy_TradesyListingHeelStyleInvalidOption = 191,
  Tradesy_TradesyListingWashLookInvalidOption = 192,
  Tradesy_TradesyListingFabricRequired = 193,
  // Invsys_InvsysTitleContainsRestrictedWord = 194,
  // Ebay_EbayListingPhotoIdsMinimum1 = 195,
  // Ebay_EbayTitleMissingSimulated = 196,
  // Invsys_InvsysDescriptionContainsRestrictedWord = 197,
  // Invsys_InvsysInvalidBrand = 198,
  // Ebay_EbayTitleMissingBrand = 199,
  Grailed_GrailedListingCategory = 200,
  Grailed_GrailedListingTitle = 201,
  Invsys_InvsysItemSizeId = 202,
  // Invsys_InvsysItemSizeInvalidOption = 203,
  // Ebay_EbayItemWeightMax150 = 204,
  Mercari_MercariListingShipping = 205,
  // Mercari_MercariListingShippingClassIdInvalid = 206,
  Poshmark_PoshmarkListingShippingDiscount = 207,
  Ebay_EbayListingAttributeMetalPurity = 208,
  // Invsys_InvsysItemConditionInvalid = 209,
  Ebay_EbayListingShipping = 210,
  // Ebay_EbayListingShippingPolicyFlatRateCost = 211,
  Ebay_EbayListingFlatDomesticShippingCost = 212,
  Ebay_EbayListingFlatIntlShippingCost = 213,
  Invsys_InvsysListingSku = 214,
  // Poshmark_InvsysItemPoorConditionProhibited = 215,
  // Mercari_MercariListingImeiInvalid = 216,
  // Depop_DepopListingCategoryIdV2Required = 217,
  Ebay_EbayListingAttributes = 218,
  Ebay_EbayListingUpc = 219,
  Ebay_EbayListingIsbn = 220,
  Ebay_EbayListingReturnPolicy = 221,
  Ebay_EbayListingBestOfferEnabled = 222,
  Ebay_EbayListingAutoDeclineAmount = 223,
  Ebay_EbayListingAutoAcceptAmount = 224,
  Ebay_EbayListingConditionDescription = 225,
  Ebay_EbayListingPromoteListingAdRate = 226,
  Mercari_MercariListingMinPriceForAutoDrop = 227,
  Grailed_GrailedListingMeasurements = 228,
  Grailed_GrailedListingExternalId = 229,
  Invsys_InvsysItemColors = 230,
  Mercari_MercariListingBrand = 231,
}
