import { fetch } from './fetch';
import {
  Environment,
  Network,
  RecordSource,
  Store,
  commitMutation,
  fetchQuery,
  MutationParameters,
  GraphQLTaggedNode,
  OperationType,
} from 'relay-runtime';

const GraphqlEnvironment = new Environment({
  network: Network.create(fetch),
  store: new Store(new RecordSource()),
});

export function promisifyMutation<Mut extends MutationParameters>(
  node: GraphQLTaggedNode,
  cbs?: ((args: Mut['variables'], r: Promise<Mut['response']>) => void)[]
): (args: Mut['variables']) => Promise<Mut['response']> {
  return (args) => {
    const p = new Promise<Mut['response']>((resolve, reject) => {
      commitMutation<Mut>(GraphqlEnvironment, {
        mutation: node,
        variables: args,
        onCompleted: (r: Mut['response'], errors) => {
          if (errors != null && errors.length > 0) {
            const error = errors[0];
            reject(new Error(`GraphQL error: ${JSON.stringify(error)}`));
          } else {
            resolve(r);
          }
        },
        onError: (e) => {
          reject(e);
        },
      });
    });
    if (cbs != null) {
      cbs.forEach((cb) => {
        cb(args, p);
      });
    }
    return p;
  };
}

export function promisifyQuery<Q extends OperationType>(
  node: GraphQLTaggedNode,
  cbs?: ((args: Q['variables'], r: Promise<Q['response']>) => void)[]
): (args: Q['variables']) => Promise<Q['response']> {
  return (args) => {
    const p = fetchQuery<Q>(GraphqlEnvironment, node, args).toPromise();
    if (cbs != null) {
      cbs.forEach((cb) => {
        cb(args, p);
      });
    }
    return p;
  };
}
