/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type cloneItemMutationVariables = {
  id: string;
  count: number;
};
export type cloneItemMutationResponse = {
  readonly invsysCloneItemMulti: {
    readonly success: boolean | null;
    readonly data: ReadonlyArray<{
      readonly id: string;
    }>;
  };
};
export type cloneItemMutation = {
  readonly response: cloneItemMutationResponse;
  readonly variables: cloneItemMutationVariables;
};

/*
mutation cloneItemMutation(
  $id: String!
  $count: Int!
) {
  invsysCloneItemMulti(id: $id, count: $count) {
    success
    data {
      id
    }
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'count',
    },
    v1 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'id',
    },
    v2 = [
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'count',
            variableName: 'count',
          },
          {
            kind: 'Variable',
            name: 'id',
            variableName: 'id',
          },
        ],
        concreteType: 'InvsysCloneItemMultiResponse',
        kind: 'LinkedField',
        name: 'invsysCloneItemMulti',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'success',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            concreteType: 'InvsysSkuAttribute',
            kind: 'LinkedField',
            name: 'data',
            plural: true,
            selections: [
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'id',
                storageKey: null,
              },
            ],
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: [v0 /*: any*/, v1 /*: any*/],
      kind: 'Fragment',
      metadata: null,
      name: 'cloneItemMutation',
      selections: v2 /*: any*/,
      type: 'Mutation',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [v1 /*: any*/, v0 /*: any*/],
      kind: 'Operation',
      name: 'cloneItemMutation',
      selections: v2 /*: any*/,
    },
    params: {
      cacheID: '85a33216b0652cabacad9c15ab50a3c9',
      id: null,
      metadata: {},
      name: 'cloneItemMutation',
      operationKind: 'mutation',
      text: 'mutation cloneItemMutation(\n  $id: String!\n  $count: Int!\n) {\n  invsysCloneItemMulti(id: $id, count: $count) {\n    success\n    data {\n      id\n    }\n  }\n}\n',
    },
  };
})();
(node as any).hash = '2f0faa885d6c47f3798ec4b9ca84214b';
export default node;
