import { graphql } from 'react-relay';

export const gqlPutListingFeatureSettings = graphql`
  mutation putListingFeatureSettingsMutation(
    $currencyCode: InvsysListingCurrencyCodeTypePutObject
    $sizeUnit: InvsysSizeUnitTypePutObject
    $weightUnit: InvsysWeightUnitTypePutObject
    $weight: InvsysFloatPutObject
    $shippingProviders: InvsysArray_IntRequired_PutObject
    $descriptionTemplate: InvsysStringPutObject
    $privateNoteTemplate: InvsysStringPutObject
    $packageSizeType: InvsysIntPutObject
    $packageCustomLength: InvsysFloatPutObject
    $packageCustomWidth: InvsysFloatPutObject
    $packageCustomHeight: InvsysFloatPutObject
    $marketplaces: InvsysArray_IntRequired_PutObject
    $poshmarkShippingDiscountId: InvsysStringPutObject
    $mercariShippingType: InvsysIntPutObject
    $mercariShippingPayerId: InvsysIntPutObject
    $depopShippingType: InvsysIntPutObject
    $depopNationalShippingPrice: InvsysIntPutObject
    $depopInternationalShippingPrice: InvsysIntPutObject
    $tradesyShippingType: InvsysIntPutObject
    $tradesyShippingPrice: InvsysIntPutObject
    $grailedShippingType: InvsysIntPutObject
    $grailedUsShippingPrice: InvsysIntPutObject
    $grailedCaShippingPrice: InvsysIntPutObject
  ) {
    invsysPutListingFeatureSettings(
      currencyCode: $currencyCode
      sizeUnit: $sizeUnit
      weightUnit: $weightUnit
      weight: $weight
      shippingProviders: $shippingProviders
      descriptionTemplate: $descriptionTemplate
      privateNoteTemplate: $privateNoteTemplate
      packageSizeType: $packageSizeType
      packageCustomLength: $packageCustomLength
      packageCustomWidth: $packageCustomWidth
      packageCustomHeight: $packageCustomHeight
      marketplaces: $marketplaces
      poshmarkShippingDiscountId: $poshmarkShippingDiscountId
      mercariShippingType: $mercariShippingType
      mercariShippingPayerId: $mercariShippingPayerId
      depopShippingType: $depopShippingType
      depopNationalShippingPrice: $depopNationalShippingPrice
      depopInternationalShippingPrice: $depopInternationalShippingPrice
      tradesyShippingType: $tradesyShippingType
      tradesyShippingPrice: $tradesyShippingPrice
      grailedShippingType: $grailedShippingType
      grailedUsShippingPrice: $grailedUsShippingPrice
      grailedCaShippingPrice: $grailedCaShippingPrice
    ) {
      success
    }
  }
`;
