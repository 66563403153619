/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from 'relay-runtime';
export type studioListingMediaGroupFragment_full = {
  readonly id: string;
  readonly name: string | null;
  readonly note: string | null;
  readonly listingId: string | null;
  readonly createdAt: number;
  readonly updatedAt: number;
  readonly memberships: ReadonlyArray<{
    readonly id: string;
    readonly listingMediaGroupId: string;
    readonly mediaId: string;
    readonly media: {
      readonly id: string;
      readonly createdAt: number;
      readonly url: string;
      readonly mediaType: string | null;
      readonly width: number;
      readonly height: number;
      readonly uploadStatus: number;
      readonly originUri: string | null;
      readonly originPlatformOs: number | null;
      readonly originPlatformMajorVersion: number | null;
    } | null;
    readonly preparationStatus: number;
    readonly rank: number;
    readonly variants: ReadonlyArray<{
      readonly membershipId: string;
      readonly mediaId: string;
      readonly variantType: number;
      readonly media: {
        readonly id: string;
        readonly createdAt: number;
        readonly url: string;
        readonly mediaType: string | null;
        readonly width: number;
        readonly height: number;
        readonly uploadStatus: number;
        readonly originUri: string | null;
        readonly originPlatformOs: number | null;
        readonly originPlatformMajorVersion: number | null;
      } | null;
    }>;
  }>;
  readonly pendingVariantRequests: ReadonlyArray<{
    readonly id: string;
    readonly listingMediaGroupId: string;
    readonly createdAt: number;
    readonly variantType: number;
    readonly membershipIds: ReadonlyArray<string>;
  }>;
  readonly ' $refType': 'studioListingMediaGroupFragment_full';
};
export type studioListingMediaGroupFragment_full$data = studioListingMediaGroupFragment_full;
export type studioListingMediaGroupFragment_full$key = {
  readonly ' $data'?: studioListingMediaGroupFragment_full$data;
  readonly ' $fragmentRefs': FragmentRefs<'studioListingMediaGroupFragment_full'>;
};

const node: ReaderInlineDataFragment = {
  kind: 'InlineDataFragment',
  name: 'studioListingMediaGroupFragment_full',
};
(node as any).hash = '0960fbb4e612c0e133eb7d5632caa820';
export default node;
