import { graphql } from 'react-relay';

export const gqlTriggerPoshmarkAuth = graphql`
  mutation triggerPoshmarkAuthMutation($poshmarkUsernameOrEmail: String!, $poshmarkPassword: String!) {
    superposherTriggerPoshmarkAuth(
      poshmarkUsernameOrEmail: $poshmarkUsernameOrEmail
      poshmarkPassword: $poshmarkPassword
    ) {
      success
    }
  }
`;
