/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type nextUncategorizedListingQueryVariables = {
  poshmarkUserLinkId: string;
};
export type nextUncategorizedListingQueryResponse = {
  readonly superposherPoshmarkUserLink: {
    readonly id: string;
    readonly nextUncategorizedListing: {
      readonly id: string;
      readonly entities: ReadonlyArray<{
        readonly sourceUrl: string;
      } | null>;
    } | null;
  } | null;
};
export type nextUncategorizedListingQuery = {
  readonly response: nextUncategorizedListingQueryResponse;
  readonly variables: nextUncategorizedListingQueryVariables;
};

/*
query nextUncategorizedListingQuery(
  $poshmarkUserLinkId: String!
) {
  superposherPoshmarkUserLink(id: $poshmarkUserLinkId) {
    id
    nextUncategorizedListing {
      id
      entities {
        sourceUrl
      }
    }
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'poshmarkUserLinkId',
      },
    ],
    v1 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null,
    },
    v2 = [
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'id',
            variableName: 'poshmarkUserLinkId',
          },
        ],
        concreteType: 'SuperposherPoshmarkUserLink',
        kind: 'LinkedField',
        name: 'superposherPoshmarkUserLink',
        plural: false,
        selections: [
          v1 /*: any*/,
          {
            alias: null,
            args: null,
            concreteType: 'SuperposherClosetSectionListingPageItem',
            kind: 'LinkedField',
            name: 'nextUncategorizedListing',
            plural: false,
            selections: [
              v1 /*: any*/,
              {
                alias: null,
                args: null,
                concreteType: 'SuperposherPoshmarkLookup',
                kind: 'LinkedField',
                name: 'entities',
                plural: true,
                selections: [
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'sourceUrl',
                    storageKey: null,
                  },
                ],
                storageKey: null,
              },
            ],
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'nextUncategorizedListingQuery',
      selections: v2 /*: any*/,
      type: 'Query',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'nextUncategorizedListingQuery',
      selections: v2 /*: any*/,
    },
    params: {
      cacheID: '1365d7a72efbd864e637ce80d72faa85',
      id: null,
      metadata: {},
      name: 'nextUncategorizedListingQuery',
      operationKind: 'query',
      text: 'query nextUncategorizedListingQuery(\n  $poshmarkUserLinkId: String!\n) {\n  superposherPoshmarkUserLink(id: $poshmarkUserLinkId) {\n    id\n    nextUncategorizedListing {\n      id\n      entities {\n        sourceUrl\n      }\n    }\n  }\n}\n',
    },
  };
})();
(node as any).hash = '77ebc09c8928767de82f81366c98af16';
export default node;
