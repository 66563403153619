/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type uspsPickupLocationSuggestionQueryVariables = {};
export type uspsPickupLocationSuggestionQueryResponse = {
  readonly invsysUspsPickupLocationSuggestion: {
    readonly email: string | null;
    readonly address: {
      readonly streetAddress: string | null;
      readonly unit: string | null;
      readonly locality: string | null;
      readonly subdivision: string | null;
      readonly postalCode: string | null;
      readonly countryCode: string | null;
      readonly postalCodeFormat: string | null;
    } | null;
  };
};
export type uspsPickupLocationSuggestionQuery = {
  readonly response: uspsPickupLocationSuggestionQueryResponse;
  readonly variables: uspsPickupLocationSuggestionQueryVariables;
};

/*
query uspsPickupLocationSuggestionQuery {
  invsysUspsPickupLocationSuggestion {
    email
    address {
      streetAddress
      unit
      locality
      subdivision
      postalCode
      countryCode
      postalCodeFormat
    }
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = [
    {
      alias: null,
      args: null,
      concreteType: 'InvsysUspsPickupLocationSuggestion',
      kind: 'LinkedField',
      name: 'invsysUspsPickupLocationSuggestion',
      plural: false,
      selections: [
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'email',
          storageKey: null,
        },
        {
          alias: null,
          args: null,
          concreteType: 'InvsysUspsAddressSuggestion',
          kind: 'LinkedField',
          name: 'address',
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              kind: 'ScalarField',
              name: 'streetAddress',
              storageKey: null,
            },
            {
              alias: null,
              args: null,
              kind: 'ScalarField',
              name: 'unit',
              storageKey: null,
            },
            {
              alias: null,
              args: null,
              kind: 'ScalarField',
              name: 'locality',
              storageKey: null,
            },
            {
              alias: null,
              args: null,
              kind: 'ScalarField',
              name: 'subdivision',
              storageKey: null,
            },
            {
              alias: null,
              args: null,
              kind: 'ScalarField',
              name: 'postalCode',
              storageKey: null,
            },
            {
              alias: null,
              args: null,
              kind: 'ScalarField',
              name: 'countryCode',
              storageKey: null,
            },
            {
              alias: null,
              args: null,
              kind: 'ScalarField',
              name: 'postalCodeFormat',
              storageKey: null,
            },
          ],
          storageKey: null,
        },
      ],
      storageKey: null,
    },
  ];
  return {
    fragment: {
      argumentDefinitions: [],
      kind: 'Fragment',
      metadata: null,
      name: 'uspsPickupLocationSuggestionQuery',
      selections: v0 /*: any*/,
      type: 'Query',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [],
      kind: 'Operation',
      name: 'uspsPickupLocationSuggestionQuery',
      selections: v0 /*: any*/,
    },
    params: {
      cacheID: '80fb044db03ba337e74f9a2870d45f4c',
      id: null,
      metadata: {},
      name: 'uspsPickupLocationSuggestionQuery',
      operationKind: 'query',
      text: 'query uspsPickupLocationSuggestionQuery {\n  invsysUspsPickupLocationSuggestion {\n    email\n    address {\n      streetAddress\n      unit\n      locality\n      subdivision\n      postalCode\n      countryCode\n      postalCodeFormat\n    }\n  }\n}\n',
    },
  };
})();
(node as any).hash = '73d803f751d51f93a8719add78a8b4dd';
export default node;
