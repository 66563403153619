import { graphql } from 'react-relay';

export const gqlListingsSummary = graphql`
  query listingsSummaryQuery {
    invsysListingsSummary {
      nextPostAt
      scheduledListingsCount
      scheduledListings {
        items {
          listingId
          title
          mediaUrl
          institution
          postAt
        }
      }
    }
  }
`;
