import { graphql } from 'react-relay';

export const gqlPutTradesyListing = graphql`
  mutation putTradesyListingMutation(
    $listingId: String!
    $imageIds: TradesyArray_StringRequired_RequiredPutObject
    $color: TradesyStringPutObject
    $conditionTags: TradesyBooleanPutObject
    $conditionWear: TradesyBooleanPutObject
    $description: TradesyStringPutObject
    $styleName: TradesyStringPutObject
    $styleKeywords: TradesyStringPutObject
    $originalPrice: TradesyIntPutObject
    $askingPrice: TradesyIntPutObject
    $shippingFlag: TradesyIntPutObject
    $shippingPrice: TradesyIntPutObject
    $acceptReturns: TradesyBooleanPutObject
    $brand: TradesyStringPutObject
    $nodeId: TradesyIntPutObject
    $veilLength: TradesyTradesyVeilLengthTypePutObject
    $shoeWidth: TradesyTradesyShoeWidthTypePutObject
    $measurementWidth: TradesyStringPutObject
    $measurementHeight: TradesyStringPutObject
    $measurementLength: TradesyStringPutObject
    $heelHeight: TradesyTradesyHeelHeightTypePutObject
    $heelStyle: TradesyTradesyHeelStyleTypePutObject
    $length: TradesyTradesyLengthTypePutObject
    $washlook: TradesyTradesyWashlookTypePutObject
    $fabric: TradesyStringPutObject
    $shippingType: TradesyIntPutObject
  ) {
    tradesyPutTradesyListing(
      listingId: $listingId
      imageIds: $imageIds
      color: $color
      conditionTags: $conditionTags
      conditionWear: $conditionWear
      description: $description
      styleName: $styleName
      styleKeywords: $styleKeywords
      originalPrice: $originalPrice
      askingPrice: $askingPrice
      shippingFlag: $shippingFlag
      shippingPrice: $shippingPrice
      acceptReturns: $acceptReturns
      brand: $brand
      nodeId: $nodeId
      veilLength: $veilLength
      shoeWidth: $shoeWidth
      measurementWidth: $measurementWidth
      measurementHeight: $measurementHeight
      measurementLength: $measurementLength
      heelHeight: $heelHeight
      heelStyle: $heelStyle
      length: $length
      washlook: $washlook
      fabric: $fabric
      shippingType: $shippingType
    ) {
      success
    }
  }
`;
