import { graphql } from 'relay-runtime';

export const gqlScheduleUspsFreePickup = graphql`
  mutation scheduleUspsFreePickupMutation(
    $uspsPickupLocationId: String
    $priorityCount: Int!
    $otherCount: Int!
    $pickupDate: String!
    $totalWeight: Int!
  ) {
    invsysScheduleUspsFreePickup(
      uspsPickupLocationId: $uspsPickupLocationId
      priorityCount: $priorityCount
      otherCount: $otherCount
      pickupDate: $pickupDate
      totalWeight: $totalWeight
    ) {
      success
    }
  }
`;
