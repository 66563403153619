/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from 'relay-runtime';
export type invsysMediaFragment_full = {
  readonly id: string;
  readonly createdAt: number;
  readonly url: string;
  readonly mediaType: string | null;
  readonly width: number;
  readonly height: number;
  readonly businessId: string | null;
  readonly businessManagerId: string | null;
  readonly uploadStatus: number;
  readonly originUri: string | null;
  readonly originPlatformOs: number | null;
  readonly originPlatformMajorVersion: number | null;
  readonly ' $refType': 'invsysMediaFragment_full';
};
export type invsysMediaFragment_full$data = invsysMediaFragment_full;
export type invsysMediaFragment_full$key = {
  readonly ' $data'?: invsysMediaFragment_full$data;
  readonly ' $fragmentRefs': FragmentRefs<'invsysMediaFragment_full'>;
};

const node: ReaderInlineDataFragment = {
  kind: 'InlineDataFragment',
  name: 'invsysMediaFragment_full',
};
(node as any).hash = 'f3e68ff39c385371fa5060108ecdbc26';
export default node;
