/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type InvsysCartItem = {
  quantity: number;
  format: string;
};
export type orderFreeSuppliesMutationVariables = {
  email: string;
  addressId: string;
  items: Array<InvsysCartItem>;
};
export type orderFreeSuppliesMutationResponse = {
  readonly invsysOrderFreeSupplies: {
    readonly success: boolean;
  };
};
export type orderFreeSuppliesMutation = {
  readonly response: orderFreeSuppliesMutationResponse;
  readonly variables: orderFreeSuppliesMutationVariables;
};

/*
mutation orderFreeSuppliesMutation(
  $email: String!
  $addressId: String!
  $items: [InvsysCartItem!]!
) {
  invsysOrderFreeSupplies(email: $email, addressId: $addressId, items: $items) {
    success
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'addressId',
    },
    v1 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'email',
    },
    v2 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'items',
    },
    v3 = [
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'addressId',
            variableName: 'addressId',
          },
          {
            kind: 'Variable',
            name: 'email',
            variableName: 'email',
          },
          {
            kind: 'Variable',
            name: 'items',
            variableName: 'items',
          },
        ],
        concreteType: 'InvsysOrderUspsFreeSuppliesResponse',
        kind: 'LinkedField',
        name: 'invsysOrderFreeSupplies',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'success',
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: [v0 /*: any*/, v1 /*: any*/, v2 /*: any*/],
      kind: 'Fragment',
      metadata: null,
      name: 'orderFreeSuppliesMutation',
      selections: v3 /*: any*/,
      type: 'Mutation',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [v1 /*: any*/, v0 /*: any*/, v2 /*: any*/],
      kind: 'Operation',
      name: 'orderFreeSuppliesMutation',
      selections: v3 /*: any*/,
    },
    params: {
      cacheID: 'a4ca8a0269de945525b0a6553eed3a61',
      id: null,
      metadata: {},
      name: 'orderFreeSuppliesMutation',
      operationKind: 'mutation',
      text: 'mutation orderFreeSuppliesMutation(\n  $email: String!\n  $addressId: String!\n  $items: [InvsysCartItem!]!\n) {\n  invsysOrderFreeSupplies(email: $email, addressId: $addressId, items: $items) {\n    success\n  }\n}\n',
    },
  };
})();
(node as any).hash = '513f8f8e7f8db4d357990184a1fff9f8';
export default node;
