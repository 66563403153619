import { graphql } from 'react-relay';

export const gqlEditTradesyItem = graphql`
  mutation editTradesyItemMutation(
    $itemId: String!
    $size: TradesyStringPutObject
    $shoeSizeByCountry: TradesyTradesyShoeSizeByCountryTypePutObject
  ) {
    tradesyEditTradesyItem(itemId: $itemId, size: $size, shoeSizeByCountry: $shoeSizeByCountry) {
      success
    }
  }
`;
