import * as Observe from 'src/state/lib/observe';

export interface CatalogIface {
  catalog: {
    departments: {
      id: string;
      display: string;
      categories: {
        id: string;
        display: string;
        parent_id: string;
        size_sets: {
          size_systems: {
            us: {
              sizes: {
                id: string;
                display: string;
              }[];
            };
            eu: {
              sizes: {
                id: string;
                display: string;
              }[];
            };
            au: {
              sizes: {
                id: string;
                display: string;
              }[];
            };
          };
        }[];
        category_features: {
          id: string;
          display: string;
          parent_id: string;
        }[];
      }[];
    }[];
  };
}

type AsyncValueDataType = CatalogIface;

export const AsyncValue = Observe.AsyncValue<AsyncValueDataType>({});

export const Value = Observe.DerivedValue(() => {
  return AsyncValue.getValueWithFallback();
}, [AsyncValue.value]);

export const ValueFallback = Observe.WithFallback(Value);
