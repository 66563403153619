/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type invsysImportInstitutionListingsPageQueryVariables = {
  requestId: string;
  cursor?: string | null;
};
export type invsysImportInstitutionListingsPageQueryResponse = {
  readonly invsysImportInstitutionListingsPage: {
    readonly cursor: string | null;
    readonly items: ReadonlyArray<{
      readonly listingId: string;
      readonly title: string | null;
      readonly thumbnail: string | null;
      readonly price: number | null;
      readonly createdAt: number | null;
    }>;
  } | null;
};
export type invsysImportInstitutionListingsPageQuery = {
  readonly response: invsysImportInstitutionListingsPageQueryResponse;
  readonly variables: invsysImportInstitutionListingsPageQueryVariables;
};

/*
query invsysImportInstitutionListingsPageQuery(
  $requestId: String!
  $cursor: String
) {
  invsysImportInstitutionListingsPage(requestId: $requestId, cursor: $cursor) {
    cursor
    items {
      listingId
      title
      thumbnail
      price
      createdAt
    }
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'cursor',
    },
    v1 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'requestId',
    },
    v2 = [
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'cursor',
            variableName: 'cursor',
          },
          {
            kind: 'Variable',
            name: 'requestId',
            variableName: 'requestId',
          },
        ],
        concreteType: 'InvsysImportInstitutionListingsPage',
        kind: 'LinkedField',
        name: 'invsysImportInstitutionListingsPage',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'cursor',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            concreteType: 'InvsysImportedInstitutionListingPreview',
            kind: 'LinkedField',
            name: 'items',
            plural: true,
            selections: [
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'listingId',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'title',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'thumbnail',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'price',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'createdAt',
                storageKey: null,
              },
            ],
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: [v0 /*: any*/, v1 /*: any*/],
      kind: 'Fragment',
      metadata: null,
      name: 'invsysImportInstitutionListingsPageQuery',
      selections: v2 /*: any*/,
      type: 'Query',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [v1 /*: any*/, v0 /*: any*/],
      kind: 'Operation',
      name: 'invsysImportInstitutionListingsPageQuery',
      selections: v2 /*: any*/,
    },
    params: {
      cacheID: '652c6f46acd69880886c0bb57092cb49',
      id: null,
      metadata: {},
      name: 'invsysImportInstitutionListingsPageQuery',
      operationKind: 'query',
      text: 'query invsysImportInstitutionListingsPageQuery(\n  $requestId: String!\n  $cursor: String\n) {\n  invsysImportInstitutionListingsPage(requestId: $requestId, cursor: $cursor) {\n    cursor\n    items {\n      listingId\n      title\n      thumbnail\n      price\n      createdAt\n    }\n  }\n}\n',
    },
  };
})();
(node as any).hash = '4dbfb3f01f5ff3ec84311996d19c6cc9';
export default node;
