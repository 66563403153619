import { graphql } from 'react-relay';

export const gqlValidateTradesyListing = graphql`
  query validateTradesyListingQuery($listingId: String!) {
    tradesyValidateTradesyListing(listingId: $listingId) {
      success
      errors {
        errorId: id
        entityName
        entityId
        fieldName
        error
        message
        timeToComplete
      }
      additionalFieldsAvailable
    }
  }
`;
