import * as LruCache from 'src/clients/LruCache';

export const UserCache = LruCache.mk<string, UserLookupIface>(50);
export const ListingCache = LruCache.mk<string, ListingLookupIface>(50);
export const EventCache = LruCache.mk<string, EventLookupIface>(1);

export interface UserLookupIface {
  data?: {
    id: string;
    username: string;
    cover_shot: null | {
      url_small: null | string;
    };
  };
}

export interface ListingLookupIface {
  cover_shot: null | {
    url_small: null | string;
  };
  title: null | string;
  price: null | number;
}

export interface EventLookupIface {
  cover_shot: null | {
    url_small: null | string;
  };
  name: null | string;
}

export const get = <T extends unknown>(url: string, cache: LruCache.CacheIface<string, T>): Promise<T> => {
  const cachedValue = cache.get(url);
  if (cachedValue != null) {
    return Promise.resolve(cachedValue);
  } else {
    return fetch(url, {
      headers: {
        'Content-Type': 'application/json',
        'Content-Encoding': 'gzip',
      },
    })
      .then((r) => r.json())
      .then((r) => r as T)
      .then((r) => {
        cache.set(url, r);
        return r;
      });
  }
};
