import { graphql } from 'react-relay';

export const gqlSalesGoalHistory = graphql`
  query salesGoalHistoryQuery {
    invsysSalesGoalHistory {
      goalType
      target
      progress
      startAt
      endAt
      goalId
      frequency
    }
  }
`;
