import { graphql } from 'react-relay';

export const gqlPutDepopListing = graphql`
  mutation putDepopListingMutation(
    $listingId: String!
    $categoryId: DepopIntPutObject
    $description: DepopStringPutObject
    $photoIds: DepopArray_StringRequired_RequiredPutObject
    $price: DepopIntPutObject
    $currencyCode: DepopListingCurrencyCodeTypePutObject
    $brandId: DepopIntPutObject
    $condition: DepopDepopListingConditionTypePutObject
    $color: DepopArray_DepopListingColorTypeRequired_RequiredPutObject
    $occasion: DepopArray_StringRequired_RequiredPutObject
    $material: DepopArray_StringRequired_RequiredPutObject
    $bodyFit: DepopArray_StringRequired_RequiredPutObject
    $sources: DepopArray_StringRequired_RequiredPutObject
    $ages: DepopArray_StringRequired_RequiredPutObject
    $style: DepopArray_StringRequired_RequiredPutObject
    $variantSetId: DepopIntPutObject
    $nationalShippingPrice: DepopIntPutObject
    $internationalShippingPrice: DepopIntPutObject
    $address: DepopStringPutObject
    $addressId: DepopStringPutObject
    $countryCode: DepopStringPutObject
    $geoLatitude: DepopFloatPutObject
    $geoLongitude: DepopFloatPutObject
    $shippingType: DepopIntPutObject
  ) {
    depopPutDepopListing(
      listingId: $listingId
      categoryId: $categoryId
      description: $description
      photoIds: $photoIds
      price: $price
      currencyCode: $currencyCode
      brandId: $brandId
      condition: $condition
      color: $color
      occasion: $occasion
      material: $material
      bodyFit: $bodyFit
      sources: $sources
      ages: $ages
      style: $style
      variantSetId: $variantSetId
      nationalShippingPrice: $nationalShippingPrice
      internationalShippingPrice: $internationalShippingPrice
      address: $address
      addressId: $addressId
      countryCode: $countryCode
      geoLatitude: $geoLatitude
      geoLongitude: $geoLongitude
      shippingType: $shippingType
    ) {
      success
    }
  }
`;
