/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type invsysSyncShopAvalaraCustomerMutationVariables = {};
export type invsysSyncShopAvalaraCustomerMutationResponse = {
  readonly invsysSyncShopAvalaraCustomer: {
    readonly success: boolean;
  };
};
export type invsysSyncShopAvalaraCustomerMutation = {
  readonly response: invsysSyncShopAvalaraCustomerMutationResponse;
  readonly variables: invsysSyncShopAvalaraCustomerMutationVariables;
};

/*
mutation invsysSyncShopAvalaraCustomerMutation {
  invsysSyncShopAvalaraCustomer {
    success
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = [
    {
      alias: null,
      args: null,
      concreteType: 'InvsysSyncShopAvalaraCustomerResponse',
      kind: 'LinkedField',
      name: 'invsysSyncShopAvalaraCustomer',
      plural: false,
      selections: [
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'success',
          storageKey: null,
        },
      ],
      storageKey: null,
    },
  ];
  return {
    fragment: {
      argumentDefinitions: [],
      kind: 'Fragment',
      metadata: null,
      name: 'invsysSyncShopAvalaraCustomerMutation',
      selections: v0 /*: any*/,
      type: 'Mutation',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [],
      kind: 'Operation',
      name: 'invsysSyncShopAvalaraCustomerMutation',
      selections: v0 /*: any*/,
    },
    params: {
      cacheID: 'b9f1bbab462e775b8c0521d275dd8acc',
      id: null,
      metadata: {},
      name: 'invsysSyncShopAvalaraCustomerMutation',
      operationKind: 'mutation',
      text: 'mutation invsysSyncShopAvalaraCustomerMutation {\n  invsysSyncShopAvalaraCustomer {\n    success\n  }\n}\n',
    },
  };
})();
(node as any).hash = 'd3e2acb403de298b760e43dee4124031';
export default node;
