/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type putMfaTokenMutationVariables = {
  requestId: string;
  mfaToken: string;
};
export type putMfaTokenMutationResponse = {
  readonly invsysPutMfaToken: {
    readonly success: boolean;
  };
};
export type putMfaTokenMutation = {
  readonly response: putMfaTokenMutationResponse;
  readonly variables: putMfaTokenMutationVariables;
};

/*
mutation putMfaTokenMutation(
  $requestId: String!
  $mfaToken: String!
) {
  invsysPutMfaToken(requestId: $requestId, mfaToken: $mfaToken) {
    success
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'mfaToken',
    },
    v1 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'requestId',
    },
    v2 = [
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'mfaToken',
            variableName: 'mfaToken',
          },
          {
            kind: 'Variable',
            name: 'requestId',
            variableName: 'requestId',
          },
        ],
        concreteType: 'InvsysPutMfaTokenResponse',
        kind: 'LinkedField',
        name: 'invsysPutMfaToken',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'success',
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: [v0 /*: any*/, v1 /*: any*/],
      kind: 'Fragment',
      metadata: null,
      name: 'putMfaTokenMutation',
      selections: v2 /*: any*/,
      type: 'Mutation',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [v1 /*: any*/, v0 /*: any*/],
      kind: 'Operation',
      name: 'putMfaTokenMutation',
      selections: v2 /*: any*/,
    },
    params: {
      cacheID: 'b7cfc97f8125cc9d44ac1cdd0ec364dc',
      id: null,
      metadata: {},
      name: 'putMfaTokenMutation',
      operationKind: 'mutation',
      text: 'mutation putMfaTokenMutation(\n  $requestId: String!\n  $mfaToken: String!\n) {\n  invsysPutMfaToken(requestId: $requestId, mfaToken: $mfaToken) {\n    success\n  }\n}\n',
    },
  };
})();
(node as any).hash = '1dda23dfa04afc0bcc8a4cb3774ac37d';
export default node;
