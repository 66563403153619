import * as Http from 'src/clients/Network/http';
import * as State from 'src/state';
import { FetchFunction } from 'relay-runtime';
import { EnvironmentManifest } from 'src/constants/Environment';

const API_DOMAIN = (): string =>
  EnvironmentManifest.API_ENV === 'production'
    ? 'https://gql-api.porter.oneshop.com'
    : 'http://gql-api.dev.porter.oneshop.com';

const gqlFetch = Http.mkFetch(API_DOMAIN);

export const fetch: FetchFunction = (operation, variables) => {
  const body = {
    query: operation.text,
    operationName: operation.name,
    variables,
  };
  return gqlFetch('/graphql', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  }).then((_) => _.json());
};
