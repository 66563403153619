import * as State from 'src/state';
import * as FragmentReader from './FragmentReader';
import * as Util from 'src/util';

export const storeAddresses = (addresses: State.Types.AddressType[]) => {
  State.Observe.Location.AddressesValue.transform((current) => {
    return {
      ...current,
      ...mkEntityMap<State.Types.AddressType>(addresses, (_) => _.id),
    };
  });
  addresses.forEach((address) => {
    if (address.isDefaultPickup) {
      State.Observe.Location.DefaultPickupAddressIdValue.set(address.id);
    }
    if (address.isDefaultShipping) {
      State.Observe.Location.DefaultShippingAddressIdValue.set(address.id);
    }
  });
};

// Note (albert): Testing this
interface EntityMapIface<E> {
  [key: string]: E;
}

export const mkEntityMap = <E extends unknown>(
  entities: E[] | readonly E[],
  idSelector: (e: E) => string
): EntityMapIface<E> => {
  const entityMap: EntityMapIface<E> = {};
  for (let i = 0; i < entities.length; i++) {
    entityMap[idSelector(entities[i])] = entities[i];
  }
  return entityMap;
};
