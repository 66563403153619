import { graphql } from 'react-relay';

export const gqlAppCriticalMetadata = graphql`
  query appCriticalMetadataQuery {
    superposherBusiness {
      appReviewer
      balance
      baseSubscriptionPrice
      createdAt
      trialEndAt
      subscriptionStatus {
        updatedAt
        status
        statusSource
      }
    }
    invsysBusinessManager {
      createdAt
      e164
    }
    invsysInstitutionLinks {
      id
      institution
      institutionIssuedId
      linkState
      lastSuccessfulAt
      lastRetryableFailedAt
      lastTerminalFailedAt
      profile {
        countryCode
        username
        profilePictureUrl
      }
      credential {
        username
      }
      limitations {
        value
        label
        url
      }
      limitationsUpdatedAt
    }
    invsysInstitutionLinkRequests {
      id
      institution
      status
      startAt
      channel
    }
    invsysNuxSurvey {
      body
      finishedAt
    }
    invsysNotificationReceiptUnarchivedCount
    invsysShippingLabelReel {
      url
      status
    }
    invsysNuxSurvey {
      finishedAt
    }
    invsysNotificationReceiptUnarchivedCount
    invsysStripePaymentMethodByCustomerDefaultSource {
      stripeId
      createdAt
      cardExpMonth
      cardExpYear
      cardLast4
    }
  }
`;
