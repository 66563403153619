/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from 'relay-runtime';
export type ordersQueryVariables = {
  cursor?: string | null;
  limit?: number | null;
};
export type ordersQueryResponse = {
  readonly invsysOrders: {
    readonly cursor: string | null;
    readonly items: ReadonlyArray<{
      readonly ' $fragmentRefs': FragmentRefs<'orderFragment_full'>;
    }>;
  } | null;
};
export type ordersQuery = {
  readonly response: ordersQueryResponse;
  readonly variables: ordersQueryVariables;
};

/*
query ordersQuery(
  $cursor: String
  $limit: Int
) {
  invsysOrders(cursor: $cursor, limit: $limit) {
    cursor
    items {
      ...orderFragment_full
    }
  }
}

fragment orderFragment_full on InvsysOrder {
  id
  institution
  institutionIssuedId
  updatedAt
  createdAt
  marketplaceLink
  earliestOrderStatus
  orderInventoryUnits {
    id
    status
    updatedAt
    createdAt
    mercariListingPreview {
      title
      thumbnail
    }
    poshmarkListingPreview {
      title
      thumbnail
    }
    depopListingPreview {
      title
      thumbnail
    }
    invsysListingAssociation {
      listingId
      itemId
      listing {
        listingSku
      }
    }
  }
  balanceUpdates {
    updateType
    currencyCode
    value
    at
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'cursor',
      },
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'limit',
      },
    ],
    v1 = [
      {
        kind: 'Variable',
        name: 'cursor',
        variableName: 'cursor',
      },
      {
        kind: 'Variable',
        name: 'limit',
        variableName: 'limit',
      },
    ],
    v2 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'cursor',
      storageKey: null,
    },
    v3 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null,
    },
    v4 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'updatedAt',
      storageKey: null,
    },
    v5 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'createdAt',
      storageKey: null,
    },
    v6 = [
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'title',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'thumbnail',
        storageKey: null,
      },
    ],
    v7 = [
      v3 /*: any*/,
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'institution',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'institutionIssuedId',
        storageKey: null,
      },
      v4 /*: any*/,
      v5 /*: any*/,
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'marketplaceLink',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'earliestOrderStatus',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        concreteType: 'InvsysOrderInventoryUnit',
        kind: 'LinkedField',
        name: 'orderInventoryUnits',
        plural: true,
        selections: [
          v3 /*: any*/,
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'status',
            storageKey: null,
          },
          v4 /*: any*/,
          v5 /*: any*/,
          {
            alias: null,
            args: null,
            concreteType: 'InvsysMercariListingPreview',
            kind: 'LinkedField',
            name: 'mercariListingPreview',
            plural: false,
            selections: v6 /*: any*/,
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            concreteType: 'InvsysPoshmarkListingPreview',
            kind: 'LinkedField',
            name: 'poshmarkListingPreview',
            plural: false,
            selections: v6 /*: any*/,
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            concreteType: 'InvsysDepopListingPreview',
            kind: 'LinkedField',
            name: 'depopListingPreview',
            plural: false,
            selections: v6 /*: any*/,
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            concreteType: 'InvsysOrderInventoryUnitInvsysListingAssociation',
            kind: 'LinkedField',
            name: 'invsysListingAssociation',
            plural: false,
            selections: [
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'listingId',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'itemId',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                concreteType: 'InvsysSkusListing',
                kind: 'LinkedField',
                name: 'listing',
                plural: false,
                selections: [
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'listingSku',
                    storageKey: null,
                  },
                ],
                storageKey: null,
              },
            ],
            storageKey: null,
          },
        ],
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        concreteType: 'InvsysBalanceUpdate',
        kind: 'LinkedField',
        name: 'balanceUpdates',
        plural: true,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'updateType',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'currencyCode',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'value',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'at',
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'ordersQuery',
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: 'InvsysOrderPage',
          kind: 'LinkedField',
          name: 'invsysOrders',
          plural: false,
          selections: [
            v2 /*: any*/,
            {
              alias: null,
              args: null,
              concreteType: 'InvsysOrder',
              kind: 'LinkedField',
              name: 'items',
              plural: true,
              selections: [
                {
                  kind: 'InlineDataFragmentSpread',
                  name: 'orderFragment_full',
                  selections: v7 /*: any*/,
                },
              ],
              storageKey: null,
            },
          ],
          storageKey: null,
        },
      ],
      type: 'Query',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'ordersQuery',
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: 'InvsysOrderPage',
          kind: 'LinkedField',
          name: 'invsysOrders',
          plural: false,
          selections: [
            v2 /*: any*/,
            {
              alias: null,
              args: null,
              concreteType: 'InvsysOrder',
              kind: 'LinkedField',
              name: 'items',
              plural: true,
              selections: v7 /*: any*/,
              storageKey: null,
            },
          ],
          storageKey: null,
        },
      ],
    },
    params: {
      cacheID: 'e0cc10dd4a315b77dbfd919f617de754',
      id: null,
      metadata: {},
      name: 'ordersQuery',
      operationKind: 'query',
      text: 'query ordersQuery(\n  $cursor: String\n  $limit: Int\n) {\n  invsysOrders(cursor: $cursor, limit: $limit) {\n    cursor\n    items {\n      ...orderFragment_full\n    }\n  }\n}\n\nfragment orderFragment_full on InvsysOrder {\n  id\n  institution\n  institutionIssuedId\n  updatedAt\n  createdAt\n  marketplaceLink\n  earliestOrderStatus\n  orderInventoryUnits {\n    id\n    status\n    updatedAt\n    createdAt\n    mercariListingPreview {\n      title\n      thumbnail\n    }\n    poshmarkListingPreview {\n      title\n      thumbnail\n    }\n    depopListingPreview {\n      title\n      thumbnail\n    }\n    invsysListingAssociation {\n      listingId\n      itemId\n      listing {\n        listingSku\n      }\n    }\n  }\n  balanceUpdates {\n    updateType\n    currencyCode\n    value\n    at\n  }\n}\n',
    },
  };
})();
(node as any).hash = 'e023a36ddcdd75f6bce821e7cd63d6f8';
export default node;
