/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type InvsysStringRequiredPutObject = {
  value: string;
};
export type InvsysIntRequiredPutObject = {
  value: number;
};
export type putStorageMediaMutationVariables = {
  id?: string | null;
  url?: InvsysStringRequiredPutObject | null;
  mediaType?: InvsysStringRequiredPutObject | null;
  width?: InvsysIntRequiredPutObject | null;
  height?: InvsysIntRequiredPutObject | null;
  uploadStatus?: InvsysIntRequiredPutObject | null;
};
export type putStorageMediaMutationResponse = {
  readonly invsysPutStorageMedia: {
    readonly success: boolean;
    readonly data: {
      readonly id: string;
    } | null;
  };
};
export type putStorageMediaMutation = {
  readonly response: putStorageMediaMutationResponse;
  readonly variables: putStorageMediaMutationVariables;
};

/*
mutation putStorageMediaMutation(
  $id: String
  $url: InvsysStringRequiredPutObject
  $mediaType: InvsysStringRequiredPutObject
  $width: InvsysIntRequiredPutObject
  $height: InvsysIntRequiredPutObject
  $uploadStatus: InvsysIntRequiredPutObject
) {
  invsysPutStorageMedia(id: $id, url: $url, mediaType: $mediaType, width: $width, height: $height, uploadStatus: $uploadStatus) {
    success
    data {
      id
    }
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'height',
    },
    v1 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'id',
    },
    v2 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'mediaType',
    },
    v3 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'uploadStatus',
    },
    v4 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'url',
    },
    v5 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'width',
    },
    v6 = [
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'height',
            variableName: 'height',
          },
          {
            kind: 'Variable',
            name: 'id',
            variableName: 'id',
          },
          {
            kind: 'Variable',
            name: 'mediaType',
            variableName: 'mediaType',
          },
          {
            kind: 'Variable',
            name: 'uploadStatus',
            variableName: 'uploadStatus',
          },
          {
            kind: 'Variable',
            name: 'url',
            variableName: 'url',
          },
          {
            kind: 'Variable',
            name: 'width',
            variableName: 'width',
          },
        ],
        concreteType: 'InvsysPutStorageMediaResponse',
        kind: 'LinkedField',
        name: 'invsysPutStorageMedia',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'success',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            concreteType: 'InvsysMedia',
            kind: 'LinkedField',
            name: 'data',
            plural: false,
            selections: [
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'id',
                storageKey: null,
              },
            ],
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: [v0 /*: any*/, v1 /*: any*/, v2 /*: any*/, v3 /*: any*/, v4 /*: any*/, v5 /*: any*/],
      kind: 'Fragment',
      metadata: null,
      name: 'putStorageMediaMutation',
      selections: v6 /*: any*/,
      type: 'Mutation',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [v1 /*: any*/, v4 /*: any*/, v2 /*: any*/, v5 /*: any*/, v0 /*: any*/, v3 /*: any*/],
      kind: 'Operation',
      name: 'putStorageMediaMutation',
      selections: v6 /*: any*/,
    },
    params: {
      cacheID: '46ed05d2aead8baf1285e9a2320fa6a0',
      id: null,
      metadata: {},
      name: 'putStorageMediaMutation',
      operationKind: 'mutation',
      text: 'mutation putStorageMediaMutation(\n  $id: String\n  $url: InvsysStringRequiredPutObject\n  $mediaType: InvsysStringRequiredPutObject\n  $width: InvsysIntRequiredPutObject\n  $height: InvsysIntRequiredPutObject\n  $uploadStatus: InvsysIntRequiredPutObject\n) {\n  invsysPutStorageMedia(id: $id, url: $url, mediaType: $mediaType, width: $width, height: $height, uploadStatus: $uploadStatus) {\n    success\n    data {\n      id\n    }\n  }\n}\n',
    },
  };
})();
(node as any).hash = 'cadae4cb166a2e68116cae3bc907e354';
export default node;
