import * as StateTypes from 'src/state/types';
import * as Observe from 'src/util/observe';

export enum CountryCode {
  UnitedStates = 'US',
  Canada = 'CA',
}

export enum CountryLabel {
  UnitedStates = 'United States',
  Canada = 'Canada',
}

/**
 * Addresses
 */

export const AddressesValue = Observe.LookupStoreValue<StateTypes.AddressType>();

export const DefaultShippingAddressIdValue = Observe.Value<undefined | null | string>(undefined);
export const DefaultShippingAddressValue = Observe.DerivedValue<undefined | null | StateTypes.AddressType>(() => {
  const defaultShippingAddressId = DefaultShippingAddressIdValue.get();
  const addresses = AddressesValue.get();
  if (defaultShippingAddressId != null) {
    return addresses[defaultShippingAddressId];
  }
  return defaultShippingAddressId === undefined ? undefined : null;
}, []);

export const DefaultPickupAddressIdValue = Observe.Value<undefined | null | string>(undefined);
export const DefaultPickupAddressValue = Observe.DerivedValue<undefined | null | StateTypes.AddressType>(() => {
  const defaultPickupAddressId = DefaultPickupAddressIdValue.get();
  const addresses = AddressesValue.get();
  if (defaultPickupAddressId != null) {
    return addresses[defaultPickupAddressId];
  }
  return defaultPickupAddressId === undefined ? undefined : null;
}, [DefaultPickupAddressIdValue]);
