import * as Observe from 'src/state/lib/observe';
import * as StateTypes from 'src/state/types';
import { ImageProps } from 'react-native';

export const MainTabValue = Observe.Value<string | null>(null);

interface OutdatedBinaryStateIface {
  deviceVersion?: string;
  remoteVersion?: string;
  outdated: boolean;
}

export const OutdatedBinaryStateValue = Observe.Value<OutdatedBinaryStateIface>({
  outdated: false,
});

export const InAppReviewValue = Observe.Value<boolean>(false);

export type PermissionStateType = 'enabled' | 'disabled' | 'uninitialized';
export const NotificationPermissionStateValue = Observe.Value<null | PermissionStateType>(null);
export const NotificationUnarchivedCountValue = Observe.Value<null | number>(null);

interface SocialReactionModalSettingsIface {
  onLayout: () => Promise<{
    x: number;
    y: number;
  }>;
  onReact: (reaction: StateTypes.SocialPostReactionType) => void;
}

export const SocialReactionModalSettingsValue = Observe.Value<SocialReactionModalSettingsIface | null>(null);

export const SocialOnboardingModalViewingValue = Observe.Value<boolean>(false);

export const QuickActionModalViewingValue = Observe.Value<boolean>(false);

export interface QuickActionModalItemIface {
  iconSource?: ImageProps['source'];
  title: string;
  dependencies?: any[];
  onPress: () => void;
  institution?: StateTypes.InstitutionEnum;
  saving?: boolean;
}
export interface QuickActionModalSettingsIface {
  title?: string;
  description?: string;
  items: QuickActionModalItemIface[];
}
export const QuickActionModalSettingsValue = Observe.Value<QuickActionModalSettingsIface>({
  items: [],
});

export const MediaViewingValue = Observe.Value<boolean>(false);
export const MediaUrlsValue = Observe.Value<string[]>([]);
export const MediaIdxValue = Observe.Value<number | null>(null);
