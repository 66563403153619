import { graphql } from 'react-relay';

export const gqlPoshmarkBotOfferConfigurations = graphql`
  query poshmarkBotOfferConfigurationsQuery {
    poshmarkBotOfferConfigurations {
      id
      percentOff
      minRevenue
      shippingDiscountId
      listingMinAgeDays
      updatedAt
      userEditOk
      usageCount
      errorCount
    }
  }
`;
