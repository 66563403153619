/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type checkListingImagePaddingMutationVariables = {};
export type checkListingImagePaddingMutationResponse = {
  readonly invsysCheckListingImagePadding: ReadonlyArray<{
    readonly status: string;
    readonly request: {
      readonly mediaId: string;
      readonly listingId: string;
      readonly businessId: string;
    };
  }>;
};
export type checkListingImagePaddingMutation = {
  readonly response: checkListingImagePaddingMutationResponse;
  readonly variables: checkListingImagePaddingMutationVariables;
};

/*
mutation checkListingImagePaddingMutation {
  invsysCheckListingImagePadding {
    status
    request {
      mediaId
      listingId
      businessId
    }
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = [
    {
      alias: null,
      args: null,
      concreteType: 'InvsysListingImagePaddingResponse',
      kind: 'LinkedField',
      name: 'invsysCheckListingImagePadding',
      plural: true,
      selections: [
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'status',
          storageKey: null,
        },
        {
          alias: null,
          args: null,
          concreteType: 'InvsysListingImagePaddingRequest',
          kind: 'LinkedField',
          name: 'request',
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              kind: 'ScalarField',
              name: 'mediaId',
              storageKey: null,
            },
            {
              alias: null,
              args: null,
              kind: 'ScalarField',
              name: 'listingId',
              storageKey: null,
            },
            {
              alias: null,
              args: null,
              kind: 'ScalarField',
              name: 'businessId',
              storageKey: null,
            },
          ],
          storageKey: null,
        },
      ],
      storageKey: null,
    },
  ];
  return {
    fragment: {
      argumentDefinitions: [],
      kind: 'Fragment',
      metadata: null,
      name: 'checkListingImagePaddingMutation',
      selections: v0 /*: any*/,
      type: 'Mutation',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [],
      kind: 'Operation',
      name: 'checkListingImagePaddingMutation',
      selections: v0 /*: any*/,
    },
    params: {
      cacheID: '148d652af89f910d9497c38533ea5fec',
      id: null,
      metadata: {},
      name: 'checkListingImagePaddingMutation',
      operationKind: 'mutation',
      text: 'mutation checkListingImagePaddingMutation {\n  invsysCheckListingImagePadding {\n    status\n    request {\n      mediaId\n      listingId\n      businessId\n    }\n  }\n}\n',
    },
  };
})();
(node as any).hash = '36f1567e2023a9c3839df935cbd6a3ef';
export default node;
