import { graphql } from 'react-relay';

export const gqlUspsPickupLocationSuggestion = graphql`
  query uspsPickupLocationSuggestionQuery {
    invsysUspsPickupLocationSuggestion {
      email
      address {
        streetAddress
        unit
        locality
        subdivision
        postalCode
        countryCode
        postalCodeFormat
      }
    }
  }
`;
