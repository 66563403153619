/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type invsysDeleteShopAddressMutationVariables = {
  id: string;
};
export type invsysDeleteShopAddressMutationResponse = {
  readonly invsysDeleteShopAddress: {
    readonly success: boolean;
  };
};
export type invsysDeleteShopAddressMutation = {
  readonly response: invsysDeleteShopAddressMutationResponse;
  readonly variables: invsysDeleteShopAddressMutationVariables;
};

/*
mutation invsysDeleteShopAddressMutation(
  $id: String!
) {
  invsysDeleteShopAddress(id: $id) {
    success
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'id',
      },
    ],
    v1 = [
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'id',
            variableName: 'id',
          },
        ],
        concreteType: 'InvsysDeleteShopAddressResponse',
        kind: 'LinkedField',
        name: 'invsysDeleteShopAddress',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'success',
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'invsysDeleteShopAddressMutation',
      selections: v1 /*: any*/,
      type: 'Mutation',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'invsysDeleteShopAddressMutation',
      selections: v1 /*: any*/,
    },
    params: {
      cacheID: 'a2d44e0ddde41cade27ddb6af9c97bf4',
      id: null,
      metadata: {},
      name: 'invsysDeleteShopAddressMutation',
      operationKind: 'mutation',
      text: 'mutation invsysDeleteShopAddressMutation(\n  $id: String!\n) {\n  invsysDeleteShopAddress(id: $id) {\n    success\n  }\n}\n',
    },
  };
})();
(node as any).hash = 'f6c43a3f86b688d3c569670637417dce';
export default node;
