/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type validateTradesyListingQueryVariables = {
  listingId: string;
};
export type validateTradesyListingQueryResponse = {
  readonly tradesyValidateTradesyListing: {
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly errorId: number;
      readonly entityName: string | null;
      readonly entityId: string | null;
      readonly fieldName: string | null;
      readonly error: string | null;
      readonly message: string;
      readonly timeToComplete: number;
    }>;
    readonly additionalFieldsAvailable: ReadonlyArray<string>;
  } | null;
};
export type validateTradesyListingQuery = {
  readonly response: validateTradesyListingQueryResponse;
  readonly variables: validateTradesyListingQueryVariables;
};

/*
query validateTradesyListingQuery(
  $listingId: String!
) {
  tradesyValidateTradesyListing(listingId: $listingId) {
    success
    errors {
      errorId: id
      entityName
      entityId
      fieldName
      error
      message
      timeToComplete
    }
    additionalFieldsAvailable
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'listingId',
      },
    ],
    v1 = [
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'listingId',
            variableName: 'listingId',
          },
        ],
        concreteType: 'TradesyListingValidationResult',
        kind: 'LinkedField',
        name: 'tradesyValidateTradesyListing',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'success',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            concreteType: 'TradesyListingValidationErrorType',
            kind: 'LinkedField',
            name: 'errors',
            plural: true,
            selections: [
              {
                alias: 'errorId',
                args: null,
                kind: 'ScalarField',
                name: 'id',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'entityName',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'entityId',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'fieldName',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'error',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'message',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'timeToComplete',
                storageKey: null,
              },
            ],
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'additionalFieldsAvailable',
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'validateTradesyListingQuery',
      selections: v1 /*: any*/,
      type: 'Query',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'validateTradesyListingQuery',
      selections: v1 /*: any*/,
    },
    params: {
      cacheID: '75e23857a17347c0c51d418c28ffec2b',
      id: null,
      metadata: {},
      name: 'validateTradesyListingQuery',
      operationKind: 'query',
      text: 'query validateTradesyListingQuery(\n  $listingId: String!\n) {\n  tradesyValidateTradesyListing(listingId: $listingId) {\n    success\n    errors {\n      errorId: id\n      entityName\n      entityId\n      fieldName\n      error\n      message\n      timeToComplete\n    }\n    additionalFieldsAvailable\n  }\n}\n',
    },
  };
})();
(node as any).hash = 'ec1c38f58f42305a9c5216b4573db15c';
export default node;
