import { graphql } from 'react-relay';

export const gqlSalesBalanceTimeseries = graphql`
  query salesBalanceTimeseriesQuery {
    invsysSalesBalanceTimeseries {
      institution
      institutionIssuedId
      thisWeekPoints {
        at
        value
      }
      thisWeekAggregationBucket
      lastWeekTotal
      thisMonthPoints {
        at
        value
      }
      thisMonthAggregationBucket
      lastMonthTotal
      thisYearPoints {
        at
        value
      }
      thisYearAggregationBucket
      lastYearTotal
      allTimePoints {
        at
        value
      }
      allTimeAggregationBucket
    }
  }
`;
