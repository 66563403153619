/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type scheduleRelistMutationVariables = {
  listingId: string;
  institutions: Array<number>;
  at?: number | null;
};
export type scheduleRelistMutationResponse = {
  readonly invsysScheduleRelist: {
    readonly success: boolean;
    readonly data: ReadonlyArray<{
      readonly success: boolean;
      readonly institution: number;
    }>;
  };
};
export type scheduleRelistMutation = {
  readonly response: scheduleRelistMutationResponse;
  readonly variables: scheduleRelistMutationVariables;
};

/*
mutation scheduleRelistMutation(
  $listingId: String!
  $institutions: [Int!]!
  $at: Float
) {
  invsysScheduleRelist(listingId: $listingId, institutions: $institutions, at: $at) {
    success
    data {
      success
      institution
    }
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'at',
    },
    v1 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'institutions',
    },
    v2 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'listingId',
    },
    v3 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'success',
      storageKey: null,
    },
    v4 = [
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'at',
            variableName: 'at',
          },
          {
            kind: 'Variable',
            name: 'institutions',
            variableName: 'institutions',
          },
          {
            kind: 'Variable',
            name: 'listingId',
            variableName: 'listingId',
          },
        ],
        concreteType: 'InvsysListingScheduleRelistResponse',
        kind: 'LinkedField',
        name: 'invsysScheduleRelist',
        plural: false,
        selections: [
          v3 /*: any*/,
          {
            alias: null,
            args: null,
            concreteType: 'InvsysListingScheduleRelistResponseMetadataType',
            kind: 'LinkedField',
            name: 'data',
            plural: true,
            selections: [
              v3 /*: any*/,
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'institution',
                storageKey: null,
              },
            ],
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: [v0 /*: any*/, v1 /*: any*/, v2 /*: any*/],
      kind: 'Fragment',
      metadata: null,
      name: 'scheduleRelistMutation',
      selections: v4 /*: any*/,
      type: 'Mutation',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [v2 /*: any*/, v1 /*: any*/, v0 /*: any*/],
      kind: 'Operation',
      name: 'scheduleRelistMutation',
      selections: v4 /*: any*/,
    },
    params: {
      cacheID: 'e94d48d7b90e64c27f1961f616a0b643',
      id: null,
      metadata: {},
      name: 'scheduleRelistMutation',
      operationKind: 'mutation',
      text: 'mutation scheduleRelistMutation(\n  $listingId: String!\n  $institutions: [Int!]!\n  $at: Float\n) {\n  invsysScheduleRelist(listingId: $listingId, institutions: $institutions, at: $at) {\n    success\n    data {\n      success\n      institution\n    }\n  }\n}\n',
    },
  };
})();
(node as any).hash = '15f368a6fbaf21ccbb6411d219628084';
export default node;
