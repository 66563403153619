import * as Observe from 'src/state/lib/observe';
import { statBucketsQueryResponse } from 'src/clients/Network/gql/build/statBucketsQuery.graphql';
import * as PoshmarkUserLinks from 'src/state/observe/PoshmarkUserLinks';

export type AsyncValueDataType = statBucketsQueryResponse['superposherPoshmarkUserLinks'];
export const AsyncValue = Observe.AsyncValue<AsyncValueDataType>({});

export const Value = Observe.DerivedValue(() => {
  return AsyncValue.getValueWithFallback();
}, [AsyncValue.value]);

export const ValueFallback = Observe.WithFallback(Value);

export const SelectedValue = Observe.DerivedValue(() => {
  const selectedId = PoshmarkUserLinks.SelectedIdValue.get();
  if (selectedId != null) {
    const userLinks = AsyncValue.getValueWithFallback() ?? [];
    return userLinks.find((_) => _.id === selectedId);
  } else {
    return null;
  }
}, [AsyncValue.value, PoshmarkUserLinks.SelectedIdValue]);

export const SelectedFallback = Observe.WithFallback(SelectedValue);
