import { graphql } from 'react-relay';

export const gqlValidatePoshmarkListing = graphql`
  query validatePoshmarkListingQuery($listingId: String!) {
    poshmarkValidatePoshmarkListing(listingId: $listingId) {
      success
      errors {
        errorId: id
        entityName
        entityId
        fieldName
        error
        message
        timeToComplete
      }
      additionalFieldsAvailable
    }
  }
`;
