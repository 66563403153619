import { graphql } from 'relay-runtime';

export const gqlCheckListingImagePadding = graphql`
  mutation checkListingImagePaddingMutation {
    invsysCheckListingImagePadding {
      status
      request {
        mediaId
        listingId
        businessId
      }
    }
  }
`;
