/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type referralProgramDetailsQueryVariables = {};
export type referralProgramDetailsQueryResponse = {
  readonly superposherReferralProgramDetails: {
    readonly signups: number;
    readonly trialing: number;
    readonly expired: number;
    readonly credited: number;
  };
};
export type referralProgramDetailsQuery = {
  readonly response: referralProgramDetailsQueryResponse;
  readonly variables: referralProgramDetailsQueryVariables;
};

/*
query referralProgramDetailsQuery {
  superposherReferralProgramDetails {
    signups
    trialing
    expired
    credited
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = [
    {
      alias: null,
      args: null,
      concreteType: 'SuperposherReferralProgramDetails',
      kind: 'LinkedField',
      name: 'superposherReferralProgramDetails',
      plural: false,
      selections: [
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'signups',
          storageKey: null,
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'trialing',
          storageKey: null,
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'expired',
          storageKey: null,
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'credited',
          storageKey: null,
        },
      ],
      storageKey: null,
    },
  ];
  return {
    fragment: {
      argumentDefinitions: [],
      kind: 'Fragment',
      metadata: null,
      name: 'referralProgramDetailsQuery',
      selections: v0 /*: any*/,
      type: 'Query',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [],
      kind: 'Operation',
      name: 'referralProgramDetailsQuery',
      selections: v0 /*: any*/,
    },
    params: {
      cacheID: '4545f893341d6437c09758c5f5a47a5b',
      id: null,
      metadata: {},
      name: 'referralProgramDetailsQuery',
      operationKind: 'query',
      text: 'query referralProgramDetailsQuery {\n  superposherReferralProgramDetails {\n    signups\n    trialing\n    expired\n    credited\n  }\n}\n',
    },
  };
})();
(node as any).hash = 'c1ab52a552a732ec3e6e65a74fdcd32b';
export default node;
