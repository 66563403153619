import * as Observe from 'src/state/lib/observe';
import * as StateTypes from 'src/state/types';

export enum InstitutionAvailability {
  NotAvailableYet = 0,
  Available = 1,
  TemporarilyUnavailable = 2,
}

export interface InstitutionMetadata {
  availability: InstitutionAvailability;
  institution: StateTypes.InstitutionEnum;
  logo: string;
  title: string;
  domain: string;
  usernameInputLabel?: string;
  passwordResetUrl?: string;
  authMethodItems?: {
    title: string;
    routeName: string;
    routeParams?: {
      downloadUrl?: string;
    };
  }[];
}

interface MetadataAsyncValueDataType {
  version: number;
  data: InstitutionMetadata[];
}

export const MetadataAsyncValue = Observe.Value<MetadataAsyncValueDataType | null>(null);

export const MetadataValue = Observe.DerivedValue<InstitutionMetadata[]>(() => {
  return MetadataAsyncValue.get()?.data ?? [];
}, [MetadataAsyncValue]);

export const SelectedInstitutionValue = Observe.Value<StateTypes.InstitutionEnum | null>(null);

export const SelectedMetadataValue = Observe.DerivedValue<InstitutionMetadata | null>(() => {
  const selectedInstitution = SelectedInstitutionValue.get();
  const authMetadata = MetadataValue.get();
  return authMetadata.find((_) => _.institution === selectedInstitution) ?? null;
}, [MetadataValue, SelectedInstitutionValue]);

export const SelectedMetadataFallback = Observe.WithFallback(SelectedMetadataValue);

export const InstitutionLinksValue = Observe.Value<StateTypes.InstitutionLinkType[]>([]);
export const InstitutionLinkRequestsValue = Observe.Value<StateTypes.InstitutionLinkRequestType[]>([]);

export const EbayProfileValue = Observe.Value<StateTypes.EbayProfileType | null>(null);

export const LatestInstitutionLinkRequestsValue = Observe.DerivedValue<StateTypes.InstitutionLinkRequestType[]>(() => {
  const authMetadata = MetadataValue.get();
  const institutionLinks = InstitutionLinksValue.get();
  const institutionLinkRequests = InstitutionLinkRequestsValue.get();
  if (authMetadata != null && institutionLinks != null && institutionLinkRequests != null) {
    const availableInstitutions = authMetadata
      .filter((_) => _.availability === InstitutionAvailability.Available)
      .map((_) => _.institution);
    const latestInstitutionLinkRequests = [...institutionLinkRequests].sort((a, b) => b.startAt - a.startAt);
    return availableInstitutions
      .map((institution) => {
        const request = latestInstitutionLinkRequests.find((_) => _.institution === institution);
        if (request != null && request.status !== 'SUCCESS' && request.status != null) {
          return request;
        } else {
          return null;
        }
      })
      .filter(Boolean) as StateTypes.InstitutionLinkRequestType[];
  } else {
    return [];
  }
}, [MetadataValue, InstitutionLinksValue, InstitutionLinkRequestsValue]);

export const LatestInstitutionLinkRequestsFallback = Observe.WithFallback(LatestInstitutionLinkRequestsValue);
export const LinkModalOpen = Observe.Value<boolean>(false);
