/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type poshmarkBotOfferConfigurationsQueryVariables = {};
export type poshmarkBotOfferConfigurationsQueryResponse = {
  readonly poshmarkBotOfferConfigurations: ReadonlyArray<{
    readonly id: string;
    readonly percentOff: number;
    readonly minRevenue: number;
    readonly shippingDiscountId: string | null;
    readonly listingMinAgeDays: number;
    readonly updatedAt: number;
    readonly userEditOk: boolean;
    readonly usageCount: number;
    readonly errorCount: number;
  }>;
};
export type poshmarkBotOfferConfigurationsQuery = {
  readonly response: poshmarkBotOfferConfigurationsQueryResponse;
  readonly variables: poshmarkBotOfferConfigurationsQueryVariables;
};

/*
query poshmarkBotOfferConfigurationsQuery {
  poshmarkBotOfferConfigurations {
    id
    percentOff
    minRevenue
    shippingDiscountId
    listingMinAgeDays
    updatedAt
    userEditOk
    usageCount
    errorCount
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = [
    {
      alias: null,
      args: null,
      concreteType: 'PoshmarkBotOfferConfiguration',
      kind: 'LinkedField',
      name: 'poshmarkBotOfferConfigurations',
      plural: true,
      selections: [
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'id',
          storageKey: null,
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'percentOff',
          storageKey: null,
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'minRevenue',
          storageKey: null,
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'shippingDiscountId',
          storageKey: null,
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'listingMinAgeDays',
          storageKey: null,
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'updatedAt',
          storageKey: null,
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'userEditOk',
          storageKey: null,
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'usageCount',
          storageKey: null,
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'errorCount',
          storageKey: null,
        },
      ],
      storageKey: null,
    },
  ];
  return {
    fragment: {
      argumentDefinitions: [],
      kind: 'Fragment',
      metadata: null,
      name: 'poshmarkBotOfferConfigurationsQuery',
      selections: v0 /*: any*/,
      type: 'Query',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [],
      kind: 'Operation',
      name: 'poshmarkBotOfferConfigurationsQuery',
      selections: v0 /*: any*/,
    },
    params: {
      cacheID: '03f89830efac4cdf971b869691160924',
      id: null,
      metadata: {},
      name: 'poshmarkBotOfferConfigurationsQuery',
      operationKind: 'query',
      text: 'query poshmarkBotOfferConfigurationsQuery {\n  poshmarkBotOfferConfigurations {\n    id\n    percentOff\n    minRevenue\n    shippingDiscountId\n    listingMinAgeDays\n    updatedAt\n    userEditOk\n    usageCount\n    errorCount\n  }\n}\n',
    },
  };
})();
(node as any).hash = 'c6308424376b946fa679ab9e3d838b27';
export default node;
