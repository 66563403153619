/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type DepopDepopListingColorType =
  | 'BLACK'
  | 'BLUE'
  | 'BROWN'
  | 'BURGUNDY'
  | 'CREAM'
  | 'GOLD'
  | 'GREEN'
  | 'GREY'
  | 'KHAKI'
  | 'MULTI'
  | 'NAVY'
  | 'ORANGE'
  | 'PINK'
  | 'PURPLE'
  | 'RED'
  | 'SILVER'
  | 'TAN'
  | 'WHITE'
  | 'YELLOW'
  | '%future added value';
export type DepopDepopListingConditionType = 'EXCELLENT' | 'FAIR' | 'GOOD' | 'LIKE_NEW' | 'NEW' | '%future added value';
export type DepopListingCurrencyCodeType = 'AUD' | 'CAD' | 'USD' | '%future added value';
export type DepopIntPutObject = {
  value?: number | null;
};
export type DepopStringPutObject = {
  value?: string | null;
};
export type DepopArray_StringRequired_RequiredPutObject = {
  value: Array<string>;
};
export type DepopListingCurrencyCodeTypePutObject = {
  value?: DepopListingCurrencyCodeType | null;
};
export type DepopDepopListingConditionTypePutObject = {
  value?: DepopDepopListingConditionType | null;
};
export type DepopArray_DepopListingColorTypeRequired_RequiredPutObject = {
  value: Array<DepopDepopListingColorType>;
};
export type DepopFloatPutObject = {
  value?: number | null;
};
export type putDepopListingV2MutationVariables = {
  listingId: string;
  categoryIdV2?: DepopIntPutObject | null;
  description?: DepopStringPutObject | null;
  photoIds?: DepopArray_StringRequired_RequiredPutObject | null;
  price?: DepopIntPutObject | null;
  currencyCode?: DepopListingCurrencyCodeTypePutObject | null;
  brandId?: DepopIntPutObject | null;
  condition?: DepopDepopListingConditionTypePutObject | null;
  color?: DepopArray_DepopListingColorTypeRequired_RequiredPutObject | null;
  occasion?: DepopArray_StringRequired_RequiredPutObject | null;
  material?: DepopArray_StringRequired_RequiredPutObject | null;
  bodyFit?: DepopArray_StringRequired_RequiredPutObject | null;
  sources?: DepopArray_StringRequired_RequiredPutObject | null;
  ages?: DepopArray_StringRequired_RequiredPutObject | null;
  style?: DepopArray_StringRequired_RequiredPutObject | null;
  variantSetId?: DepopIntPutObject | null;
  nationalShippingPrice?: DepopIntPutObject | null;
  internationalShippingPrice?: DepopIntPutObject | null;
  address?: DepopStringPutObject | null;
  addressId?: DepopStringPutObject | null;
  countryCode?: DepopStringPutObject | null;
  geoLatitude?: DepopFloatPutObject | null;
  geoLongitude?: DepopFloatPutObject | null;
  shippingType?: DepopIntPutObject | null;
};
export type putDepopListingV2MutationResponse = {
  readonly depopPutDepopListingV2: {
    readonly success: boolean;
  } | null;
};
export type putDepopListingV2Mutation = {
  readonly response: putDepopListingV2MutationResponse;
  readonly variables: putDepopListingV2MutationVariables;
};

/*
mutation putDepopListingV2Mutation(
  $listingId: String!
  $categoryIdV2: DepopIntPutObject
  $description: DepopStringPutObject
  $photoIds: DepopArray_StringRequired_RequiredPutObject
  $price: DepopIntPutObject
  $currencyCode: DepopListingCurrencyCodeTypePutObject
  $brandId: DepopIntPutObject
  $condition: DepopDepopListingConditionTypePutObject
  $color: DepopArray_DepopListingColorTypeRequired_RequiredPutObject
  $occasion: DepopArray_StringRequired_RequiredPutObject
  $material: DepopArray_StringRequired_RequiredPutObject
  $bodyFit: DepopArray_StringRequired_RequiredPutObject
  $sources: DepopArray_StringRequired_RequiredPutObject
  $ages: DepopArray_StringRequired_RequiredPutObject
  $style: DepopArray_StringRequired_RequiredPutObject
  $variantSetId: DepopIntPutObject
  $nationalShippingPrice: DepopIntPutObject
  $internationalShippingPrice: DepopIntPutObject
  $address: DepopStringPutObject
  $addressId: DepopStringPutObject
  $countryCode: DepopStringPutObject
  $geoLatitude: DepopFloatPutObject
  $geoLongitude: DepopFloatPutObject
  $shippingType: DepopIntPutObject
) {
  depopPutDepopListingV2(listingId: $listingId, categoryIdV2: $categoryIdV2, description: $description, photoIds: $photoIds, price: $price, currencyCode: $currencyCode, brandId: $brandId, condition: $condition, color: $color, occasion: $occasion, material: $material, bodyFit: $bodyFit, sources: $sources, ages: $ages, style: $style, variantSetId: $variantSetId, nationalShippingPrice: $nationalShippingPrice, internationalShippingPrice: $internationalShippingPrice, address: $address, addressId: $addressId, countryCode: $countryCode, geoLatitude: $geoLatitude, geoLongitude: $geoLongitude, shippingType: $shippingType) {
    success
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'address',
    },
    v1 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'addressId',
    },
    v2 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'ages',
    },
    v3 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'bodyFit',
    },
    v4 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'brandId',
    },
    v5 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'categoryIdV2',
    },
    v6 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'color',
    },
    v7 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'condition',
    },
    v8 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'countryCode',
    },
    v9 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'currencyCode',
    },
    v10 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'description',
    },
    v11 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'geoLatitude',
    },
    v12 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'geoLongitude',
    },
    v13 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'internationalShippingPrice',
    },
    v14 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'listingId',
    },
    v15 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'material',
    },
    v16 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'nationalShippingPrice',
    },
    v17 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'occasion',
    },
    v18 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'photoIds',
    },
    v19 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'price',
    },
    v20 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'shippingType',
    },
    v21 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'sources',
    },
    v22 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'style',
    },
    v23 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'variantSetId',
    },
    v24 = [
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'address',
            variableName: 'address',
          },
          {
            kind: 'Variable',
            name: 'addressId',
            variableName: 'addressId',
          },
          {
            kind: 'Variable',
            name: 'ages',
            variableName: 'ages',
          },
          {
            kind: 'Variable',
            name: 'bodyFit',
            variableName: 'bodyFit',
          },
          {
            kind: 'Variable',
            name: 'brandId',
            variableName: 'brandId',
          },
          {
            kind: 'Variable',
            name: 'categoryIdV2',
            variableName: 'categoryIdV2',
          },
          {
            kind: 'Variable',
            name: 'color',
            variableName: 'color',
          },
          {
            kind: 'Variable',
            name: 'condition',
            variableName: 'condition',
          },
          {
            kind: 'Variable',
            name: 'countryCode',
            variableName: 'countryCode',
          },
          {
            kind: 'Variable',
            name: 'currencyCode',
            variableName: 'currencyCode',
          },
          {
            kind: 'Variable',
            name: 'description',
            variableName: 'description',
          },
          {
            kind: 'Variable',
            name: 'geoLatitude',
            variableName: 'geoLatitude',
          },
          {
            kind: 'Variable',
            name: 'geoLongitude',
            variableName: 'geoLongitude',
          },
          {
            kind: 'Variable',
            name: 'internationalShippingPrice',
            variableName: 'internationalShippingPrice',
          },
          {
            kind: 'Variable',
            name: 'listingId',
            variableName: 'listingId',
          },
          {
            kind: 'Variable',
            name: 'material',
            variableName: 'material',
          },
          {
            kind: 'Variable',
            name: 'nationalShippingPrice',
            variableName: 'nationalShippingPrice',
          },
          {
            kind: 'Variable',
            name: 'occasion',
            variableName: 'occasion',
          },
          {
            kind: 'Variable',
            name: 'photoIds',
            variableName: 'photoIds',
          },
          {
            kind: 'Variable',
            name: 'price',
            variableName: 'price',
          },
          {
            kind: 'Variable',
            name: 'shippingType',
            variableName: 'shippingType',
          },
          {
            kind: 'Variable',
            name: 'sources',
            variableName: 'sources',
          },
          {
            kind: 'Variable',
            name: 'style',
            variableName: 'style',
          },
          {
            kind: 'Variable',
            name: 'variantSetId',
            variableName: 'variantSetId',
          },
        ],
        concreteType: 'DepopPutDepopV2ListingResponse',
        kind: 'LinkedField',
        name: 'depopPutDepopListingV2',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'success',
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: [
        v0 /*: any*/,
        v1 /*: any*/,
        v2 /*: any*/,
        v3 /*: any*/,
        v4 /*: any*/,
        v5 /*: any*/,
        v6 /*: any*/,
        v7 /*: any*/,
        v8 /*: any*/,
        v9 /*: any*/,
        v10 /*: any*/,
        v11 /*: any*/,
        v12 /*: any*/,
        v13 /*: any*/,
        v14 /*: any*/,
        v15 /*: any*/,
        v16 /*: any*/,
        v17 /*: any*/,
        v18 /*: any*/,
        v19 /*: any*/,
        v20 /*: any*/,
        v21 /*: any*/,
        v22 /*: any*/,
        v23 /*: any*/,
      ],
      kind: 'Fragment',
      metadata: null,
      name: 'putDepopListingV2Mutation',
      selections: v24 /*: any*/,
      type: 'Mutation',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [
        v14 /*: any*/,
        v5 /*: any*/,
        v10 /*: any*/,
        v18 /*: any*/,
        v19 /*: any*/,
        v9 /*: any*/,
        v4 /*: any*/,
        v7 /*: any*/,
        v6 /*: any*/,
        v17 /*: any*/,
        v15 /*: any*/,
        v3 /*: any*/,
        v21 /*: any*/,
        v2 /*: any*/,
        v22 /*: any*/,
        v23 /*: any*/,
        v16 /*: any*/,
        v13 /*: any*/,
        v0 /*: any*/,
        v1 /*: any*/,
        v8 /*: any*/,
        v11 /*: any*/,
        v12 /*: any*/,
        v20 /*: any*/,
      ],
      kind: 'Operation',
      name: 'putDepopListingV2Mutation',
      selections: v24 /*: any*/,
    },
    params: {
      cacheID: '52a96128cf4435ca214f3ea8e2465872',
      id: null,
      metadata: {},
      name: 'putDepopListingV2Mutation',
      operationKind: 'mutation',
      text: 'mutation putDepopListingV2Mutation(\n  $listingId: String!\n  $categoryIdV2: DepopIntPutObject\n  $description: DepopStringPutObject\n  $photoIds: DepopArray_StringRequired_RequiredPutObject\n  $price: DepopIntPutObject\n  $currencyCode: DepopListingCurrencyCodeTypePutObject\n  $brandId: DepopIntPutObject\n  $condition: DepopDepopListingConditionTypePutObject\n  $color: DepopArray_DepopListingColorTypeRequired_RequiredPutObject\n  $occasion: DepopArray_StringRequired_RequiredPutObject\n  $material: DepopArray_StringRequired_RequiredPutObject\n  $bodyFit: DepopArray_StringRequired_RequiredPutObject\n  $sources: DepopArray_StringRequired_RequiredPutObject\n  $ages: DepopArray_StringRequired_RequiredPutObject\n  $style: DepopArray_StringRequired_RequiredPutObject\n  $variantSetId: DepopIntPutObject\n  $nationalShippingPrice: DepopIntPutObject\n  $internationalShippingPrice: DepopIntPutObject\n  $address: DepopStringPutObject\n  $addressId: DepopStringPutObject\n  $countryCode: DepopStringPutObject\n  $geoLatitude: DepopFloatPutObject\n  $geoLongitude: DepopFloatPutObject\n  $shippingType: DepopIntPutObject\n) {\n  depopPutDepopListingV2(listingId: $listingId, categoryIdV2: $categoryIdV2, description: $description, photoIds: $photoIds, price: $price, currencyCode: $currencyCode, brandId: $brandId, condition: $condition, color: $color, occasion: $occasion, material: $material, bodyFit: $bodyFit, sources: $sources, ages: $ages, style: $style, variantSetId: $variantSetId, nationalShippingPrice: $nationalShippingPrice, internationalShippingPrice: $internationalShippingPrice, address: $address, addressId: $addressId, countryCode: $countryCode, geoLatitude: $geoLatitude, geoLongitude: $geoLongitude, shippingType: $shippingType) {\n    success\n  }\n}\n',
    },
  };
})();
(node as any).hash = '20000af0a4c11a31943e0c2fa18fade6';
export default node;
