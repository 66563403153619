import { graphql } from 'react-relay';

export const gqlNewItemsListedTimeseries = graphql`
  query newItemsListedTimeseriesQuery {
    invsysNewItemsListedTimeseries {
      thisWeekPoints {
        at
        value
      }
      thisWeekAggregationBucket
      lastWeekTotal
      thisMonthPoints {
        at
        value
      }
      thisMonthAggregationBucket
      lastMonthTotal
      thisYearPoints {
        at
        value
      }
      thisYearAggregationBucket
      lastYearTotal
      allTimePoints {
        at
        value
      }
      allTimeAggregationBucket
    }
  }
`;
