import { graphql } from 'react-relay';

export const gqlListingValidationResult = graphql`
  query listingValidationResultQuery($id: String!, $validationMarketplaces: [Int!]) {
    invsysListingValidationResult(id: $id, validationMarketplaces: $validationMarketplaces) {
      success
      errors {
        errorId: id
        message
        entityName
        fieldName
      }
    }
  }
`;
