/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type updateOfferToLikersMinRevenueTargetMutationVariables = {
  poshmarkUserLinkId: string;
  target: number;
};
export type updateOfferToLikersMinRevenueTargetMutationResponse = {
  readonly superposherUpdateOfferToLikersMinRevenueTarget: {
    readonly success: boolean;
  };
};
export type updateOfferToLikersMinRevenueTargetMutation = {
  readonly response: updateOfferToLikersMinRevenueTargetMutationResponse;
  readonly variables: updateOfferToLikersMinRevenueTargetMutationVariables;
};

/*
mutation updateOfferToLikersMinRevenueTargetMutation(
  $poshmarkUserLinkId: String!
  $target: Int!
) {
  superposherUpdateOfferToLikersMinRevenueTarget(poshmarkUserLinkId: $poshmarkUserLinkId, target: $target) {
    success
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'poshmarkUserLinkId',
      },
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'target',
      },
    ],
    v1 = [
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'poshmarkUserLinkId',
            variableName: 'poshmarkUserLinkId',
          },
          {
            kind: 'Variable',
            name: 'target',
            variableName: 'target',
          },
        ],
        concreteType: 'SuperposherUpdateOfferToLikersMinRevenueTargetResponse',
        kind: 'LinkedField',
        name: 'superposherUpdateOfferToLikersMinRevenueTarget',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'success',
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'updateOfferToLikersMinRevenueTargetMutation',
      selections: v1 /*: any*/,
      type: 'Mutation',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'updateOfferToLikersMinRevenueTargetMutation',
      selections: v1 /*: any*/,
    },
    params: {
      cacheID: '5f05397a65c071d38823d530be833096',
      id: null,
      metadata: {},
      name: 'updateOfferToLikersMinRevenueTargetMutation',
      operationKind: 'mutation',
      text: 'mutation updateOfferToLikersMinRevenueTargetMutation(\n  $poshmarkUserLinkId: String!\n  $target: Int!\n) {\n  superposherUpdateOfferToLikersMinRevenueTarget(poshmarkUserLinkId: $poshmarkUserLinkId, target: $target) {\n    success\n  }\n}\n',
    },
  };
})();
(node as any).hash = 'edf05f956f1700f101d925b5d843e7bc';
export default node;
