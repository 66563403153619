import * as State from 'src/state';
import * as FragmentReader from './FragmentReader';
import * as LocationApi from '../location';

export const storeUspsPickupLocations = (uspsPickupLocations: State.Types.UspsPickupLocationType[]) => {
  const addresses: State.Types.AddressType[] = FragmentReader.map(
    uspsPickupLocations.map((location) => location.address),
    LocationApi.FragmentReader.addressFull
  );
  LocationApi.StoreHelpers.storeAddresses(addresses);

  State.Observe.Pickup.UspsPickupLocationsValue.transform((current) => {
    return {
      ...current,
      ...mkEntityMap<State.Types.UspsPickupLocationType>(uspsPickupLocations, (_) => _.id),
    };
  });
  uspsPickupLocations.forEach((pickupLocation) => {
    if (pickupLocation.isDefaultPickupLocation) {
      State.Observe.Pickup.DefaultUspsPickupLocationsValue.set(pickupLocation);
    }
  });
};

// Note (albert): Testing this
interface EntityMapIface<E> {
  [key: string]: E;
}

export const mkEntityMap = <E extends unknown>(
  entities: E[] | readonly E[],
  idSelector: (e: E) => string
): EntityMapIface<E> => {
  const entityMap: EntityMapIface<E> = {};
  for (let i = 0; i < entities.length; i++) {
    entityMap[idSelector(entities[i])] = entities[i];
  }
  return entityMap;
};
