/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type TradesyTradesyHeelHeightType = 'FLAT' | 'HIGH' | 'LOW' | 'MEDIUM' | 'ULTRA_HIGH' | '%future added value';
export type TradesyTradesyHeelStyleType = 'CHUNKY' | 'KITTEN' | 'SLIM' | 'STILETTO' | 'WEDGE' | '%future added value';
export type TradesyTradesyLengthType = 'LONG' | 'MID_LENGTH' | 'SHORT' | '%future added value';
export type TradesyTradesyShoeWidthType = 'EXTRA_WIDE' | 'NARROW' | 'REGULAR' | 'WIDE' | '%future added value';
export type TradesyTradesyVeilLengthType = 'BIRDCAGE' | 'LONG' | 'MEDIUM' | 'SHORT' | '%future added value';
export type TradesyTradesyWashlookType =
  | 'ACID'
  | 'COATED'
  | 'DARK_RINSE'
  | 'DISTRESSED'
  | 'LIGHT_WASH'
  | 'MEDIUM_WASH'
  | '%future added value';
export type TradesyArray_StringRequired_RequiredPutObject = {
  value: Array<string>;
};
export type TradesyStringPutObject = {
  value?: string | null;
};
export type TradesyBooleanPutObject = {
  value?: boolean | null;
};
export type TradesyIntPutObject = {
  value?: number | null;
};
export type TradesyTradesyVeilLengthTypePutObject = {
  value?: TradesyTradesyVeilLengthType | null;
};
export type TradesyTradesyShoeWidthTypePutObject = {
  value?: TradesyTradesyShoeWidthType | null;
};
export type TradesyTradesyHeelHeightTypePutObject = {
  value?: TradesyTradesyHeelHeightType | null;
};
export type TradesyTradesyHeelStyleTypePutObject = {
  value?: TradesyTradesyHeelStyleType | null;
};
export type TradesyTradesyLengthTypePutObject = {
  value?: TradesyTradesyLengthType | null;
};
export type TradesyTradesyWashlookTypePutObject = {
  value?: TradesyTradesyWashlookType | null;
};
export type putTradesyListingMutationVariables = {
  listingId: string;
  imageIds?: TradesyArray_StringRequired_RequiredPutObject | null;
  color?: TradesyStringPutObject | null;
  conditionTags?: TradesyBooleanPutObject | null;
  conditionWear?: TradesyBooleanPutObject | null;
  description?: TradesyStringPutObject | null;
  styleName?: TradesyStringPutObject | null;
  styleKeywords?: TradesyStringPutObject | null;
  originalPrice?: TradesyIntPutObject | null;
  askingPrice?: TradesyIntPutObject | null;
  shippingFlag?: TradesyIntPutObject | null;
  shippingPrice?: TradesyIntPutObject | null;
  acceptReturns?: TradesyBooleanPutObject | null;
  brand?: TradesyStringPutObject | null;
  nodeId?: TradesyIntPutObject | null;
  veilLength?: TradesyTradesyVeilLengthTypePutObject | null;
  shoeWidth?: TradesyTradesyShoeWidthTypePutObject | null;
  measurementWidth?: TradesyStringPutObject | null;
  measurementHeight?: TradesyStringPutObject | null;
  measurementLength?: TradesyStringPutObject | null;
  heelHeight?: TradesyTradesyHeelHeightTypePutObject | null;
  heelStyle?: TradesyTradesyHeelStyleTypePutObject | null;
  length?: TradesyTradesyLengthTypePutObject | null;
  washlook?: TradesyTradesyWashlookTypePutObject | null;
  fabric?: TradesyStringPutObject | null;
  shippingType?: TradesyIntPutObject | null;
};
export type putTradesyListingMutationResponse = {
  readonly tradesyPutTradesyListing: {
    readonly success: boolean;
  } | null;
};
export type putTradesyListingMutation = {
  readonly response: putTradesyListingMutationResponse;
  readonly variables: putTradesyListingMutationVariables;
};

/*
mutation putTradesyListingMutation(
  $listingId: String!
  $imageIds: TradesyArray_StringRequired_RequiredPutObject
  $color: TradesyStringPutObject
  $conditionTags: TradesyBooleanPutObject
  $conditionWear: TradesyBooleanPutObject
  $description: TradesyStringPutObject
  $styleName: TradesyStringPutObject
  $styleKeywords: TradesyStringPutObject
  $originalPrice: TradesyIntPutObject
  $askingPrice: TradesyIntPutObject
  $shippingFlag: TradesyIntPutObject
  $shippingPrice: TradesyIntPutObject
  $acceptReturns: TradesyBooleanPutObject
  $brand: TradesyStringPutObject
  $nodeId: TradesyIntPutObject
  $veilLength: TradesyTradesyVeilLengthTypePutObject
  $shoeWidth: TradesyTradesyShoeWidthTypePutObject
  $measurementWidth: TradesyStringPutObject
  $measurementHeight: TradesyStringPutObject
  $measurementLength: TradesyStringPutObject
  $heelHeight: TradesyTradesyHeelHeightTypePutObject
  $heelStyle: TradesyTradesyHeelStyleTypePutObject
  $length: TradesyTradesyLengthTypePutObject
  $washlook: TradesyTradesyWashlookTypePutObject
  $fabric: TradesyStringPutObject
  $shippingType: TradesyIntPutObject
) {
  tradesyPutTradesyListing(listingId: $listingId, imageIds: $imageIds, color: $color, conditionTags: $conditionTags, conditionWear: $conditionWear, description: $description, styleName: $styleName, styleKeywords: $styleKeywords, originalPrice: $originalPrice, askingPrice: $askingPrice, shippingFlag: $shippingFlag, shippingPrice: $shippingPrice, acceptReturns: $acceptReturns, brand: $brand, nodeId: $nodeId, veilLength: $veilLength, shoeWidth: $shoeWidth, measurementWidth: $measurementWidth, measurementHeight: $measurementHeight, measurementLength: $measurementLength, heelHeight: $heelHeight, heelStyle: $heelStyle, length: $length, washlook: $washlook, fabric: $fabric, shippingType: $shippingType) {
    success
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'acceptReturns',
    },
    v1 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'askingPrice',
    },
    v2 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'brand',
    },
    v3 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'color',
    },
    v4 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'conditionTags',
    },
    v5 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'conditionWear',
    },
    v6 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'description',
    },
    v7 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'fabric',
    },
    v8 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'heelHeight',
    },
    v9 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'heelStyle',
    },
    v10 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'imageIds',
    },
    v11 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'length',
    },
    v12 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'listingId',
    },
    v13 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'measurementHeight',
    },
    v14 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'measurementLength',
    },
    v15 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'measurementWidth',
    },
    v16 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'nodeId',
    },
    v17 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'originalPrice',
    },
    v18 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'shippingFlag',
    },
    v19 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'shippingPrice',
    },
    v20 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'shippingType',
    },
    v21 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'shoeWidth',
    },
    v22 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'styleKeywords',
    },
    v23 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'styleName',
    },
    v24 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'veilLength',
    },
    v25 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'washlook',
    },
    v26 = [
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'acceptReturns',
            variableName: 'acceptReturns',
          },
          {
            kind: 'Variable',
            name: 'askingPrice',
            variableName: 'askingPrice',
          },
          {
            kind: 'Variable',
            name: 'brand',
            variableName: 'brand',
          },
          {
            kind: 'Variable',
            name: 'color',
            variableName: 'color',
          },
          {
            kind: 'Variable',
            name: 'conditionTags',
            variableName: 'conditionTags',
          },
          {
            kind: 'Variable',
            name: 'conditionWear',
            variableName: 'conditionWear',
          },
          {
            kind: 'Variable',
            name: 'description',
            variableName: 'description',
          },
          {
            kind: 'Variable',
            name: 'fabric',
            variableName: 'fabric',
          },
          {
            kind: 'Variable',
            name: 'heelHeight',
            variableName: 'heelHeight',
          },
          {
            kind: 'Variable',
            name: 'heelStyle',
            variableName: 'heelStyle',
          },
          {
            kind: 'Variable',
            name: 'imageIds',
            variableName: 'imageIds',
          },
          {
            kind: 'Variable',
            name: 'length',
            variableName: 'length',
          },
          {
            kind: 'Variable',
            name: 'listingId',
            variableName: 'listingId',
          },
          {
            kind: 'Variable',
            name: 'measurementHeight',
            variableName: 'measurementHeight',
          },
          {
            kind: 'Variable',
            name: 'measurementLength',
            variableName: 'measurementLength',
          },
          {
            kind: 'Variable',
            name: 'measurementWidth',
            variableName: 'measurementWidth',
          },
          {
            kind: 'Variable',
            name: 'nodeId',
            variableName: 'nodeId',
          },
          {
            kind: 'Variable',
            name: 'originalPrice',
            variableName: 'originalPrice',
          },
          {
            kind: 'Variable',
            name: 'shippingFlag',
            variableName: 'shippingFlag',
          },
          {
            kind: 'Variable',
            name: 'shippingPrice',
            variableName: 'shippingPrice',
          },
          {
            kind: 'Variable',
            name: 'shippingType',
            variableName: 'shippingType',
          },
          {
            kind: 'Variable',
            name: 'shoeWidth',
            variableName: 'shoeWidth',
          },
          {
            kind: 'Variable',
            name: 'styleKeywords',
            variableName: 'styleKeywords',
          },
          {
            kind: 'Variable',
            name: 'styleName',
            variableName: 'styleName',
          },
          {
            kind: 'Variable',
            name: 'veilLength',
            variableName: 'veilLength',
          },
          {
            kind: 'Variable',
            name: 'washlook',
            variableName: 'washlook',
          },
        ],
        concreteType: 'TradesyPutTradesyListingResponse',
        kind: 'LinkedField',
        name: 'tradesyPutTradesyListing',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'success',
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: [
        v0 /*: any*/,
        v1 /*: any*/,
        v2 /*: any*/,
        v3 /*: any*/,
        v4 /*: any*/,
        v5 /*: any*/,
        v6 /*: any*/,
        v7 /*: any*/,
        v8 /*: any*/,
        v9 /*: any*/,
        v10 /*: any*/,
        v11 /*: any*/,
        v12 /*: any*/,
        v13 /*: any*/,
        v14 /*: any*/,
        v15 /*: any*/,
        v16 /*: any*/,
        v17 /*: any*/,
        v18 /*: any*/,
        v19 /*: any*/,
        v20 /*: any*/,
        v21 /*: any*/,
        v22 /*: any*/,
        v23 /*: any*/,
        v24 /*: any*/,
        v25 /*: any*/,
      ],
      kind: 'Fragment',
      metadata: null,
      name: 'putTradesyListingMutation',
      selections: v26 /*: any*/,
      type: 'Mutation',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [
        v12 /*: any*/,
        v10 /*: any*/,
        v3 /*: any*/,
        v4 /*: any*/,
        v5 /*: any*/,
        v6 /*: any*/,
        v23 /*: any*/,
        v22 /*: any*/,
        v17 /*: any*/,
        v1 /*: any*/,
        v18 /*: any*/,
        v19 /*: any*/,
        v0 /*: any*/,
        v2 /*: any*/,
        v16 /*: any*/,
        v24 /*: any*/,
        v21 /*: any*/,
        v15 /*: any*/,
        v13 /*: any*/,
        v14 /*: any*/,
        v8 /*: any*/,
        v9 /*: any*/,
        v11 /*: any*/,
        v25 /*: any*/,
        v7 /*: any*/,
        v20 /*: any*/,
      ],
      kind: 'Operation',
      name: 'putTradesyListingMutation',
      selections: v26 /*: any*/,
    },
    params: {
      cacheID: 'cbce06410d2e5eef0ca337b52fdfed69',
      id: null,
      metadata: {},
      name: 'putTradesyListingMutation',
      operationKind: 'mutation',
      text: 'mutation putTradesyListingMutation(\n  $listingId: String!\n  $imageIds: TradesyArray_StringRequired_RequiredPutObject\n  $color: TradesyStringPutObject\n  $conditionTags: TradesyBooleanPutObject\n  $conditionWear: TradesyBooleanPutObject\n  $description: TradesyStringPutObject\n  $styleName: TradesyStringPutObject\n  $styleKeywords: TradesyStringPutObject\n  $originalPrice: TradesyIntPutObject\n  $askingPrice: TradesyIntPutObject\n  $shippingFlag: TradesyIntPutObject\n  $shippingPrice: TradesyIntPutObject\n  $acceptReturns: TradesyBooleanPutObject\n  $brand: TradesyStringPutObject\n  $nodeId: TradesyIntPutObject\n  $veilLength: TradesyTradesyVeilLengthTypePutObject\n  $shoeWidth: TradesyTradesyShoeWidthTypePutObject\n  $measurementWidth: TradesyStringPutObject\n  $measurementHeight: TradesyStringPutObject\n  $measurementLength: TradesyStringPutObject\n  $heelHeight: TradesyTradesyHeelHeightTypePutObject\n  $heelStyle: TradesyTradesyHeelStyleTypePutObject\n  $length: TradesyTradesyLengthTypePutObject\n  $washlook: TradesyTradesyWashlookTypePutObject\n  $fabric: TradesyStringPutObject\n  $shippingType: TradesyIntPutObject\n) {\n  tradesyPutTradesyListing(listingId: $listingId, imageIds: $imageIds, color: $color, conditionTags: $conditionTags, conditionWear: $conditionWear, description: $description, styleName: $styleName, styleKeywords: $styleKeywords, originalPrice: $originalPrice, askingPrice: $askingPrice, shippingFlag: $shippingFlag, shippingPrice: $shippingPrice, acceptReturns: $acceptReturns, brand: $brand, nodeId: $nodeId, veilLength: $veilLength, shoeWidth: $shoeWidth, measurementWidth: $measurementWidth, measurementHeight: $measurementHeight, measurementLength: $measurementLength, heelHeight: $heelHeight, heelStyle: $heelStyle, length: $length, washlook: $washlook, fabric: $fabric, shippingType: $shippingType) {\n    success\n  }\n}\n',
    },
  };
})();
(node as any).hash = 'a45e6228e039c2f0df4315cf487eb769';
export default node;
